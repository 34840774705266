import React, { useCallback } from "react";
import Accordion from "../../CustomInvoices/WizardSteps/Accordion";
import swal from "@sweetalert/with-react";
import Select from "react-select";
import PopOver from "../../global/PopOver";
import { useTranslation } from "react-i18next";
import { AppContext, getStore, useAppContext } from "../../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateCustomer from "../../Setup/Users/CreateCustomer";
// import { selectStyle } from '../../../design/selectStyle';
import AsyncSelect from "react-select/async";
import mainAxios from '../../../services/mainAxios';
import func from '../../../services/functions';
import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import selectStyle from "../../../design/selectStyle";
import { useEffect } from "react";
import InvoicePrintInfo from "../../global/InvoicePrintInfo";
import DatePicker from "react-datepicker";
import countries from "../../../services/countries.json";
import { Tooltip } from "primereact/tooltip";



const PosDetails = ({ posState, persistedData,setPersistedData,setPosState,branchesOptions, tcrList, disabled,printInvoice,isEditPos=false}) => {
  const {  selectedCompany: [selectedCompany],user:[user],refreshStatuses } = useAppContext()
  const { t } = useTranslation('translations');
  const inputRef = React.useRef(null);
  const issueDateRef = React.useRef();
  const [minIssueDate, setMinIssueDate] = React.useState("");
  const { formatNumber, countDecimals } = func;

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const currenciesPos = [
    { label: "Lek", value: "ALL" },
    { label: "US Dollar", value: "USD" },
    { label: "Euro", value: "EUR" },
  ];

  const buyerIDTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
  ];

  let profileIdPos = [
    { "value": "P1", "label": "P1 - Invoicing the supply of goods and services ordered on a contract basis", "types": ["380", "388"] },
    { "value": "P2", "label": "P2 - Periodic invoicing of contract-based delivery", "types": ["380", "388", "82"] }
  ];

  if (isEditPos) profileIdPos.push({ "value": "P10", "label": "P10 - Corrective Invoicing", "types": ["384"] })
  const addCustomer = () => {
    const store = getStore();

    swal(
      <AppContext.Provider value={store}>
        <CreateCustomer swal={swal} isPos={true} />
      </AppContext.Provider>,
      {
        className: "swal-wide",
        buttons: false,
      }
    );
  };


  const isRefreshStatus=useCallback((invoice)=>{
    let status = posState?.status?.status;
    let resendStatuses = refreshStatuses?.filter(resendStatus=>resendStatus?.isActive==true)?.map(resendStatus=>resendStatus?.status)?? []
    let staticRefreshConditions=invoice?.einvoice && invoice?.cisStatus==="SUCCESS" && (!invoice?.eic || invoice.eic=="")

    return  (resendStatuses.includes(status)) || staticRefreshConditions
},[refreshStatuses])

const getDefaultCustomer=() =>{
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomer",
        value: null,
        params: {
          defaultCustomer: "true",
          company: selectedCompany
        },
      })
      .then((res) => {
        if (res?.data?.content?.length > 0) {
          let buyer = res.data.content[0];
          buyer = {
            ...buyer,
            label: buyer.name + " - " + buyer.tin,
            value: buyer.name,
          }
          handleInvoiceBuyerChange(null, "buyer", buyer)
        }
      });
  }

  const loadOptions = async (name, callback) => {
    let customers = [];
    if (name.length > 2) {
      return await mainAxios
        .post("apiEndpoint/search", {
          object: "GetCustomer",
          value: null,
          params: {
            customerLiveSearch: name,
          },
        })
        .then((res) => {
          if (res.data.status == true) {
            customers = res.data.content.map((el) => {
              return {
                ...el,
                label: el.name + " - " + el.tin,
                value: el.name,
              };
            });
          }

          return customers;
        });
    }
  };

  const handleFocus = (event) => event.target.select();

  const firstUpdate = React.useRef(null)

  useEffect(() => {
    if (!isEditPos) getDefaultCustomer()
  }, [])



  useEffect(() => {
    const generalCustomer = {
      tin: "9999",
      name: "General Customer",
      address: " ",
      country: "Albania",
      town: " ",
      type: "2",
    };

    const isGeneralCustomer = posState.parties.buyer?.tin?.length == 0 && posState.parties.buyer?.name?.length == 0
    if (isGeneralCustomer) {
      handleInvoiceBuyerChange(null, "buyer", generalCustomer);
    }
  }, [posState.parties.buyer])

  const handleIssueDateChange = (date) => {
    const dueDate = date > posState.dueDate ? "" : posState.dueDate;
    setPosState((posState) => ({
      ...posState,
      issueDate: date,
      dueDate,
    }));
    issueDateRef.current.setOpen(false);

    let today = new Date();
    let technicalErrorMinDate = func.dateBeforeSomeDays(1, today);
    let boundBookMinDate = func.dateBeforeSomeDays(10, today);
    let currentDate = date ? new Date(date).setHours(0, 0, 0, 0) : null;
    today = today.setHours(0, 0, 0, 0);
    technicalErrorMinDate = technicalErrorMinDate.setHours(0, 0, 0, 0);
    boundBookMinDate = boundBookMinDate.setHours(0, 0, 0, 0)
    if (currentDate >= today) {
      setPosState(posState => ({
        ...posState,
        subSequenceDelivType: ""
      }))
      return;
    }
    if (currentDate >= technicalErrorMinDate && currentDate < today) {
      setPosState(posState => ({
        ...posState,
        subSequenceDelivType: "SERVICE"
      }))
    }
    else if (currentDate >= boundBookMinDate && boundBookMinDate < today) {
      setPosState(posState => ({
        ...posState,
        subSequenceDelivType: "BOUNDBOOK"
      }))
    }
    else {
      setPosState(posState => ({
        ...posState,
        subSequenceDelivType: "NOINTERNET",
      }));
    }
  };


  const handleDueDateChange = (date) => {
    setPosState((invoiceState) => ({
      ...invoiceState,
      dueDate: date,
    }));
  };
  const subseqValues = [
    { value: "", label: "Select" },
    { value: "NOINTERNET", label: "No Internet" },
    { value: "BOUNDBOOK", label: "Boundbook" },
    { value: "SERVICE", label: "Service" },
    { value: "TECHNICALERROR", label: "Technical Error" },
  ];
  const popOver = {
    "NOINTERNET": t("hints.noInternet"),
    "BOUNDBOOK": t("hints.boundBook"),
    "SERVICE": t("hints.service"),
    "TECHNICALERROR": t("hints.TechnicalError"),
  }

  const CustomOption = ({ innerProps, isDisabled, label, data }) =>
    !isDisabled ? (
      <div className="option-custom-style"  {...innerProps}>
        {/* {label} <PopOver title={popoOver.find(popover=>popover.value==data.value).popoOver} /> */}
        {label} <PopOver title={popOver[data.value]} />
        {innerProps.label}
      </div>

    ) : null;

  const handleInvoiceBuyerChange = (e, selectKey, selectValue, length) => {
    let key = e ? e.target.name : selectKey;
    let value = e ? e.target.value : selectValue;
    if (key === "buyer") {
      let customer = value
      if (customer?.label && customer?.value) {
        delete customer["label"]
        delete customer["value"]
      }
      let type = customer?.type ? buyerIDTypes.find(type => type.value == customer.type).label : ""
      setPosState((posState) => ({
        ...posState,
        parties: {
          ...posState.parties,
          buyer: {
            tin: value?.tin,
            name: value?.name,
            address: value?.address,
            country: func.getFullCountry(value?.country),
            town: value?.town,
            buyerIDType: posState.einvoice ? buyerIDTypes[0].label : !posState?.einvoice && customer?.type ? type : buyerIDTypes[1].label,
            email: value?.email ?? null
          },
        },
        customer: customer ?? {
          tin: "",
          name: "",
          address: "",
          country: "Albania",
          town: "",
          type: "1",
          email: null,
        }
      }));
      inputRef.current.blur();
      inputRef.current.focus();
    } else {
      value = length != undefined ? value.slice(0, length) : value;

      setPosState((posState) => ({
        ...posState,
        parties: {
          ...posState.parties,
          buyer: {
            ...posState?.parties?.buyer,
            [key]: value,
          },
        },
      }));
    }
  };


  const handleSubseqChange = (subseq) => {
    const { value, label } = subseq;
    setPosState((posState) => ({
      ...posState,
      subSequenceDelivType: value,
    }));

    var newDate = new Date();
    if (value === "BOUNDBOOK") {
      newDate = func.dateBeforeSomeDays(11, newDate);

    } else if (value === "SERVICE" || value === "TECHNICALERROR") {
      newDate = func.dateBeforeSomeDays(1, newDate);
    } else {
      newDate = func.getMinIssueDate();
    }
    const { issueDate } = posState;

    setMinIssueDate(newDate);

    if ((value === "BOUNDBOOK" || value === "SERVICE" || value === "TECHNICALERROR") && issueDate < newDate) {
      setPosState(posState => ({
        ...posState,
        issueDate: new Date(),
      }));
      issueDateRef.current.setOpen(true);
    }
  };

  const handleBusinessUnitCodeChange = (businessUnitCode) => {
    const branch =
      branchesOptions?.find((branch) => branch.businUnitCode == businessUnitCode)
    const tcrCode = branch.tcrTypes.filter(tcrType => { return tcrType?.allowInvoice })[0]?.tcrCode || ""

    if (!isEditPos) setPersistedData({ ...persistedData, businessUnitCode, tcrCode })
    const seller = branch ? {
      ...posState.parties.seller,
      address: branch.sellerAddress,
      country: countries.find((country) => country.label === branch.sellerCountry)
        ?.value || "Albania",
      town: branch.sellerTown,
      name: company.name,
    } : posState.parties.seller

    setPosState((posState) => ({
      ...posState,
      parties: { ...posState.parties, seller },
      businessUnitCode,
      tcrCode,
    }));
  };

  const handleChange = (key, value) => {
    if (key == "einvoice") {
      let paymentMethodType = "noncash"
      let buyerIDType = "NUIS"
      if (value == false) {
        if (posState.customer?.type) buyerIDType = buyerIDTypes.find(type => type.value == posState.customer?.type).label || buyerIDType
        paymentMethodType = "cash"
      }
      setPosState(posState => ({
        ...posState,
        einvoice: value,
        paymentMethodType,
        parties: {
          ...posState.parties,
          buyer: {
            ...posState.parties.buyer,
            buyerIDType,
          }
        },
      }))
      return
    }
    setPosState(posState => ({
      ...posState,
      [key]: value
    }))
  }
  const handleDiscountChange = (key, value) => {
    const discountValue = value;

    setPosState((posState) => ({
      ...posState,
      discount: {
        ...posState.discount,
        [key]: discountValue,
      },
    }));
  };

  const handleIicRefChange = (value) => {
    setPosState(state => ({
      ...state,
      iic_ref: value,
      refInvIIC: value,
    }))
  }

  const handleInputChanged = (input, reason, length) => {
    const {
      parties: { buyer },
    } = posState;

    if (reason?.action === "input-blur" || reason?.action === "menu-close") {
      return;
    } else if (reason?.action === "set-value") {
      return;
    } else {
      var value = length != undefined ? input.slice(0, length) : input;

      setPosState((posState) => ({
        ...posState,
        parties: {
          ...posState.parties,
          buyer: {
            ...buyer,
            name: value,
          },
        },
      }));
    }
  };
  const currency = currenciesPos.find(currency => currency.value == (isEditPos ? posState.currency : persistedData.currency))
  return (
    <div className="col-12">
      <div className="row align-items-start flex-md-nowrap">
        {disabled && isEditPos && <div className="card border-0 mr-1 col-12 col-md-4 mb-3">
          <InvoicePrintInfo invoice={posState} preview={printInvoice} />
        </div>}
        <div className={`card border mr-1 col-12 ${disabled ? "col-md-2" : "col-md"} mb-2`}>
          <Accordion
            overflow={true}
            heading={
              <div className="d-flex border-0">
                <h4 className="mb-0"> Profile ID ·  {!disabled && isEditPos == false ? persistedData.profileId : posState?.profileId}</h4>
                {posState.cisStatus && !disabled &&
                  <>
                    <Tooltip target=".error-tooltip" />
                    {posState.cisError ? (
                  <small
                  className={`error-tooltip badge  badge-${isRefreshStatus(posState)?"warning":"danger"} d-inline-flex align-items-center ml-2`} style={{zIndex:100000000}}
                  data-pr-tooltip={posState?.cisError}
                  data-pr-position="right"
                  data-pr-my="left center-2"
              >
                  {isRefreshStatus(posState)? <span style={{fontWeight:600}}>PENDING</span> :"Error"}
                  <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />
              </small>
                    ) : (
                      <small className="badge badge-danger ml-2">Error</small>
                    )}
                  </>
                }
              </div>
            }
          >
            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                {t("home.branches")}
                <PopOver title={t("hints.branches")} />
              </label>
              <Select
                styles={selectStyle}
                options={branchesOptions}
                isSearchable={true}
                onChange={(branch) =>
                  !disabled && handleBusinessUnitCodeChange(branch.businUnitCode)
                }
                value={branchesOptions?.find(
                  (branch) =>
                    branch.businUnitCode === posState?.businessUnitCode
                )}
                isDisabled={disabled}
                name="tcrCode"
              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label mb-0 ">
                {t("home.tcr")}
              </label>
              <Select
                styles={selectStyle}
                options={tcrList}
                isSearchable={true}
                onChange={(tcr) => { if (!disabled && isEditPos == false) setPersistedData({ ...persistedData, tcrCode: tcr.value }); handleChange("tcrCode", tcr.value) }}
                value={
                  tcrList?.find((tcr) => tcr.value == posState.tcrCode) ||
                  ""
                }
                isDisabled={disabled}
                name="tcrCode"
              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label"> {t("invoice.profileId")}
              </label>

              <Select
                styles={selectStyle}
                options={profileIdPos}
                isSearchable={true}
                onChange={(profileId) => { if (!disabled && isEditPos == false) setPersistedData({ ...persistedData, profileId: profileId.value }); handleChange("profileId", profileId.value) }}
                value={profileIdPos.find(profileId => profileId.value == posState.profileId)}
                isDisabled={disabled}
                name="profileId"

              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label">
                {t("invoice.issueDate")}
              </label>
              <DatePicker
                ref={issueDateRef}
                selected={posState.issueDate}
                disabled={disabled}
                dateFormat="dd/MM/yyyy"
                name="issueDate"
                autoComplete="off"
                minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
                showDisabledMonthNavigation
                onChange={handleIssueDateChange}
                customInput={<input className="form-control" />}
              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label">
                Subseq
              </label>
              <Select

                components={{ Option: CustomOption }}
                options={subseqValues}
                value={subseqValues.find(
                  (subseq) => posState.subSequenceDelivType === subseq.value
                )}
                isDisabled={disabled}
                styles={selectStyle}
                onChange={(subseq) => handleSubseqChange(subseq)}
                name="subseq"
              />
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label">
                {t("invoice.paymentDate")}
              </label>
              <DatePicker
                selected={posState.dueDate}
                disabled={disabled}
                dateFormat="dd/MM/yyyy"
                name="dueDate"
                autoComplete="off"
                minDate={posState.issueDate}
                showDisabledMonthNavigation
                onChange={handleDueDateChange}
                customInput={<input className="form-control" />}
              />
            </div>
            {posState?.correctiveInv && isEditPos && <div className="col-md-12 mb-2">
              <label className="form-label mt-2">
                {t("purchases.iicRef")}
                <PopOver title={t("invoice.IICInvoiceCorreced")} />
              </label>
              <input
                name="iicRef"
                id="iicRef"
                type="text"
                className="form-control"
                disabled={disabled}
                value={posState.iic_ref}
                onChange={e => handleIicRefChange(e.target.value)}
              />
            </div>}
          </Accordion>
        </div>

        <div className={`card border mr-1 col-12 ${disabled ? "col-md-2" : "col-md"} mb-2`}>
          <Accordion
            overflow={true}
            heading={
              <div className="d-flex border-0">
                <h4 className="mb-0"> {currency.value} {currency.value != 'ALL' && (" · " + posState.rate)} </h4>
              </div>
            }
          >

            <div className="col-md-12 mb-4">
              <label className="form-label mb-0">
                {t("invoice.currency")}
                <PopOver title={t("hints.currencySelect")} />
              </label>
              <Select
                styles={selectStyle}
                options={currenciesPos}
                value={currenciesPos.find(
                  (currency) => currency?.value === posState.currency
                )}
                isSearchable={true}
                onChange={(currency) => { if (!disabled && isEditPos == false) setPersistedData({ ...persistedData, currency: currency.value, rate: "1" }); handleChange("currency", currency.value); handleChange("rate", "1") }
                }
                isDisabled={disabled}
                name="currency"
              />
            </div>

            <div className="col-md-12">
              {posState?.currency !== "ALL" && (
                <>
                  <label className="form-label mb-0 ">
                    Exchange Rate
                    <span className="text-danger"> *</span>
                  </label>
                  <div>
                    <input
                      name="rate"
                      type="number"
                      step="0.01"
                      min="0"
                      className="form-control"
                      value={posState.rate}
                      onChange={(e) => { if (!disabled && isEditPos == false) setPersistedData({ ...persistedData, rate: e.target.value }); handleChange("rate", e.target.value) }
                      }
                      disabled={disabled}
                    />
                  </div>
                </>
              )}
            </div>

          </Accordion>
        </div>
        <div className={`card border mr-1 col-12 ${disabled ? "col-md-4" : "col-md"} mb-2`}>
          <Accordion
            overflow={false}
            heading={

              <div className="d-flex border-0 mt-0 pt-0 ">
                <h4 className="mb-0"> {posState.parties.buyer.name || t("navbar.customers")}</h4>
                <div className="col-md-6 d-flex justify-content-end ml-2" style={{ marginTop: "-10px", marginBottom: "-5px", paddingTop: "8px", paddingBottom: "0px" }}>
                  <div className="custom-control align-items-center custom-checkbox mr-sm-2 ">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isEinvoice"
                      name="isEinvoice"
                      checked={posState?.einvoice}
                      onChange={(e) =>
                        handleChange("einvoice", e.target.checked)
                      }
                      disabled={disabled}
                    />
                    <label
                      style={{ paddingTop: "4px", paddingLeft: "2px", fontSize: "14px" }}
                      htmlFor="isEinvoice"
                      className="custom-control-label"
                    >
                      E-Invoice
                      <PopOver title={t("hints.einvoice")} />
                    </label>
                  </div>
                </div>
              </div>
            }
          >

            <div className="mb-4 d-flex align-items-end">
              <button
                onClick={addCustomer}
                className="btn btn-outline-primary btn-sm ml-3"
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t("customers.addCustomer")}
              </button>
            </div>

            <div className="col-md-12 mb-2">

              <label className="form-label mb-0">
                {" "}
                {t("customers.name")}
              </label>
              <AsyncSelect
                styles={selectStyle}
                name="buyer"
                onFocus={handleFocus}
                ref={inputRef}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                className="basic-multi-select"
                onChange={(value) => {
                  handleInvoiceBuyerChange(null, "buyer", value);
                }}
                cacheOptions
                value={posState?.parties?.buyer?.name}
                inputValue={posState?.parties?.buyer?.name}
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={(input, reason) =>
                  handleInputChanged(input, reason, 200)
                }
                isDisabled={disabled}
                isClearable={true}
              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                Type ID
              </label>
              <Select
                styles={selectStyle}
                options={posState.einvoice == false ? buyerIDTypes : [buyerIDTypes[0]]}
                value={buyerIDTypes.find(
                  (type) =>
                    type.label === posState?.parties?.buyer?.buyerIDType
                )}
                isSearchable={true}
                onChange={(buyerIDtype) => handleInvoiceBuyerChange(null, "buyerIDType", buyerIDtype.label)}
                isDisabled={disabled}
                name="buyerIdType"
              />
            </div>
            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                {posState?.parties?.buyer?.buyerIDType}
              </label>
              <div>
                <div className="input-length-validation">
                  <input
                    name="tin"
                    type="text"
                    className="form-control"
                    value={posState?.parties?.buyer?.tin}
                    onChange={(e) =>
                      handleInvoiceBuyerChange(e, null, null, 20)
                    }
                    disabled={disabled}
                  />
                  <div className={`length-text : ""}`}>
                    {posState?.parties?.buyer?.tin?.length == 20
                      ? "text-danger"
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                Email
              </label>
              <div>
                <div className="input-length-validation">
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    value={posState?.parties?.buyer?.email}
                    onChange={(e) =>
                      handleInvoiceBuyerChange(e, null, null, 50)
                    }
                    disabled={disabled}
                  />
                  <div className={`length-text : ""}`}>
                    {posState?.parties?.buyer?.email?.length == 50
                      ? "text-danger"
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                {t("taxpayer.city")}
              </label>
              <div>
                <div className="input-length-validation">
                  <input
                    name="town"
                    type="town"
                    className="form-control"
                    value={posState?.parties?.buyer?.town}
                    onChange={(e) =>
                      handleInvoiceBuyerChange(e, null, null, 50)
                    }
                    disabled={disabled}
                  />
                  <div className={`length-text : ""}`}>
                    {posState?.parties?.buyer?.town?.length == 50
                      ? "text-danger"
                      : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label mb-0">
                {t("invoice.discount")}

                <PopOver title={t("hints.invoiceDiscount")} />
              </label>
              <div className="input-group">
                <input
                  type={disabled ? "text" : "number"}
                  step="0.01"
                  min="0.01"
                  id="discount"
                  name="discount"
                  className="form-control form-control-sm"
                  disabled={disabled}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  value={
                    disabled
                      ? posState.discount
                        ? formatNumber(posState.discount?.value || null)
                        : ""
                      : posState.discount?.value
                  }
                  onChange={(e) => handleDiscountChange("value", e.target.value)}
                />
                <div className="input-group-append dropup">
                  <button
                    className={
                      "btn btn-sm btn-outline-primary dropdown-toggle" +
                      (disabled ? " disabled" : "")
                    }
                    style={{ height: "29px" }}
                    disabled={disabled}
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {posState?.discount?.type == "Percentage" ? "%" : posState?.currency}
                  </button>
                  <div className="dropdown-menu">
                    <a
                      className="dropdown-item"
                      href="#"
                      disabled={disabled}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDiscountChange("type", "Percentage");
                      }}
                    >
                      {t("invoice.percentage")}
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDiscountChange("type", "value");
                      }}
                    >
                      {t("invoice.value")}
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default PosDetails;

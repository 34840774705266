import { faCheck, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
// import selectStyle from '../../design/selectStyle';
import Select from "react-select";
import Loader from '../Layout/Loader';
import { toast } from 'react-toastify';
import { useAppContext } from '../../AppContext';
import mainAxios from '../../services/mainAxios';
import { useTranslation } from 'react-i18next';
import { tinTypes } from '../../services/data';

const EditAddSupplies = ({ tcrList,onDataSuccess,cancelSupplier,supplier  }) => {
  const [loading,setLoading]=useState(false)
  const [validationErrors, setValidationErrors] = useState({});
  const editDefault={
          name: '',
          idValue: "",
          nickname: '',
          address: '',
          phoneNumber: '',
          email: '',
          vendorCode: '',
          town: '',
          purchaseUnit: '',
          country2a: '',
          country3a: '',
          country: '',
          idType: "NUIS",
      }
  const [editClients, setEditClients] = useState(supplier ?? editDefault);
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  


  const selectStyle = {
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#000" : styles.color,
        backgroundColor: state.isSelected ? "#0075670d" : styles.color,
        borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
        "&:hover": {
            color: "#000000",
            backgroundColor: "#0075670d",
        }
    }),
    control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ? "0 0 0 0.1rem rgba(68, 68, 68, 0.135)" : 0,
        borderWidth: state.isFocused ? "2px" : "1px",
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
        border: validationErrors.idType && validationErrors.idValue ? '1px solid red' : '',
        zIndex: state.isFocused ? 2 : 1,
        "&:hover": {
            borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
        }
    })
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditClients((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const { t } = useTranslation("translations");
  const [sellerInputClass, setSellerInputClass] = useState("");
  const [inputClass, setInputClass] = React.useState("");

  const handleClientChange = (key, value, length,searchInput=false) => {
    value = length !== undefined ? value.slice(0, length) : value;
    if (searchInput && inputClass.length > 0) setInputClass("");
    
    setEditClients((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  


  const handleSellerTaxPayerSearch = () => {
    let buyerNipt = editClients.idValue.split(" ").join("");
    if (
      buyerNipt &&
      !new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyerNipt)
    ) {
      toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
      setSellerInputClass("error");
      return;
    }

    setSellerInputClass("loading");
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetTaxpayersRequest",
        value: buyerNipt,
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        const content = res.data.content;
        if (res?.data?.status === true) {
          if (content.length === 0) {
            toast.warning(t(["toast.noTaxPayer"]), {
              containerId: "all_toast",
            });
            setSellerInputClass("error");
          } else {
            const clientData = content[0];
            
            setEditClients((prevData) => ({
              ...prevData,
              idValue: clientData?.tin ?? (editClients?.idValue),
              name: clientData.name,
              address: clientData.address,
              country: clientData.country,
            }));

            setSellerInputClass("success");
          }
        } else {
          setSellerInputClass("error");
        }
      })
      .catch((e) => {
        setSellerInputClass("error");
      });
  };

  const validate = () => {
    const errors = {};

    if (!editClients.name) errors.name = true;
    if (!editClients.town) errors.town = true;
    if (!editClients.country) errors.country = true;
    if (!editClients.idType) errors.idType = true;

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
};

const handleSaveChanges = () => {
    if (!validate()) {
        toast.error("Please fix validation errors before saving.");
        return;
    }
    const nuis = user.companiesRights.find(
      (company) => company.id === parseInt(selectedCompany)
    )?.nuis;
    
    if (!nuis) {
      toast.error("Nuis not found.");
      return;
    }
    
    const isUpdating = editClients.id !== undefined;
    setLoading(true)
    
    mainAxios
        .post(`/apiEndpoint/saveOrUpdate`, {
            object: "Connections",
            nuis: nuis,
            username: user.username,
            content: {
                ...editClients,
                company: Number(selectedCompany),
                supplier: true,
            },
        })
        .then((response) => {
          setLoading(false)
            if (response.data.status) {
                if (isUpdating) {
                    toast.success(t(["mySuppliers.updateSupplier"]), {
                        containerId: "all_toast",
                    });
                } else {
                    toast.success(t(["mySuppliers.newSupplier"]), {
                        containerId: "all_toast",
                    });
                }
                onDataSuccess()
                setEditClients(response.data.content);
            } else {
                toast.error(response.data.message || "Failed to save supplier. Please try again.");
            }
        })
        .catch((error) => {
          setLoading(false)
            console.error("Error saving supplier:", error);
            toast.error("An error occurred while saving the supplier.");
        });

};

  console.log({editClients});


  const content = (
    <div className="container">
      <div className="row">
        {/* ID Type and ID Value in one row */}
        <div className="form-group col-md-6">
          <label htmlFor="idType">{t('invoice.idType')}</label>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={tinTypes}
            value={
              tinTypes?.find((idType) => idType?.label === editClients?.idType) || null
            }
            onChange={(idType) =>
              handleClientChange("idType", idType?.label)
            }
            placeholder={t("invoice.select")}
            name="idType"
          />
          {validationErrors.idType && <span className='text-danger'>ID Type cannot be empty!</span>}
        </div>
  
        {/* Conditional Field for NUIS in the same row */}
        <div className="form-group col-md-6">
          {editClients.idType === "NUIS" ? (
            <>
              <label className="form-label">
                (NIPT)
                <span className="text-danger"> *</span>
              </label>
              <div className="input-group position-relative">
                <input
                  name="idValue"
                  type="text"
                  className={`form-control required ${
                    sellerInputClass === "error"
                      ? "border-danger"
                      : sellerInputClass === "success"
                      ? "border-success"
                      : ""
                  }`}
                  value={editClients.idValue}
                  onChange={(e) =>
                    handleClientChange("idValue", e.target.value, 100, true)
                  }
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={handleSellerTaxPayerSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>
                {sellerInputClass === "loading" && (
                  <div className="input-icon input-loader">
                    <Loader size="xs" />
                  </div>
                )}
                {sellerInputClass === "error" && (
                  <div className="input-icon text-danger">
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
                {sellerInputClass === "success" && (
                  <div className="input-icon text-success">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <label htmlFor="idValue">{editClients.idType ?? "Nipt"}</label>
              <input
                type="text"
                id="idValue"
                name="idValue"
                className={`form-control ${validationErrors.idValue ? "is-invalid" : ""}`}
                value={editClients.idValue}
                onChange={handleChange}
              />
            </>
          )}
        </div>
  
        {/* Name */}
        <div className="form-group col-md-6">
          <label htmlFor="name">{t("invoice.name")}</label>
          <input
            type="text"
            id="name"
            name="name"
            className={`form-control ${validationErrors.name ? "is-invalid" : ""}`}
            value={editClients.name}
            onChange={handleChange}
          />
          {validationErrors.name && <div className="invalid-feedback">Name cannot be empty!</div>}
        </div>
  
        {/* Nick Name */}
        <div className="form-group col-md-6">
          <label htmlFor="nickname">{t("invoice.nickName")}</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            className="form-control"
            value={editClients.nickname}
            onChange={handleChange}
          />
        </div>

        {/* Town */}
        <div className="form-group col-md-6">
          <label htmlFor="town">{t("customers.town")}</label>
          <input
            type="text"
            id="town"
            name="town"
            className={`form-control ${validationErrors.town ? "is-invalid" : ""}`}
            value={editClients.town}
            onChange={handleChange}
          />
          {validationErrors.town && <span className="text-danger">Town cannot be empty!</span>}
        </div>
  
        {/* Phone Number */}
        <div className="form-group col-md-6">
          <label htmlFor="phoneNumber">{t("users.phone")}</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            className="form-control"
            value={editClients.phoneNumber}
            onChange={handleChange}
          />
        </div>
  
        {/* Email */}
        <div className="form-group col-md-6">
          <label htmlFor="email">{t("email.email")}</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={editClients.email}
            onChange={handleChange}
          />
        </div>
  
        {/* Vendor Code */}
        <div className="form-group col-md-6">
          <label htmlFor="vendorCode">Vendor Code</label>
          <input
            type="text"
            id="vendorCode"
            name="vendorCode"
            className="form-control"
            value={editClients.vendorCode}
            onChange={handleChange}
          />
        </div>
  
        {/* Country */}
        <div className="form-group col-md-6">
          <label htmlFor="country">{t("customers.country")}</label>
          <input
            type="text"
            id="country"
            name="country"
            className={`form-control ${validationErrors.country ? "is-invalid" : ""}`}
            value={editClients.country}
            onChange={handleChange}
          />
          {validationErrors.country && <span className="text-danger">Country cannot be empty!</span>}
        </div>
  
        {/* Country 2A */}
        <div className="form-group col-md-6">
          <label htmlFor="country2a">Country 2A</label>
          <input
            type="text"
            id="country2a"
            name="country2a"
            maxLength={2}
            className="form-control"
            value={editClients.country2a}
            onChange={handleChange}
          />
        </div>
  
        {/* Country 3A */}
        <div className="form-group col-md-6">
          <label htmlFor="country3a">Country 3A</label>
          <input
            type="text"
            id="country3a"
            name="country3a"
            maxLength={3}
            className="form-control"
            value={editClients.country3a}
            onChange={handleChange}
          />
        </div>
  
        {/* Purchase Unit */}
        <div className="form-group col-md-6">
          <label htmlFor="purchaseUnit">{t("purchases.purchaseUnit")}</label>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={tcrList}
            value={
              tcrList?.find(
                (purchaseUnit) =>
                  purchaseUnit?.value === editClients?.purchaseUnit
              ) ?? ""
            }
            isSearchable={true}
            onChange={(purchaseUnit) =>
              handleClientChange(
                "purchaseUnit",
                purchaseUnit.value
              )
            }
            placeholder={t("invoice.select")}
            name="purchaseUnit"
          />
        </div>

  
        {/* Address as a single row */}
        <div className="form-group col-md-12">
          <label htmlFor="address">{t("customers.address")}</label>
          <input
            type="text"
            id="address"
            name="address"
            className="form-control"
            value={editClients.address}
            onChange={handleChange}
          />
        </div>
  

      </div>
    </div>
  );
  

  const footer=(
    <div className='d-flex justify-content-end gap-2 mt-4'>
          <button disabled={loading} onClick={() => cancelSupplier()} className="btn btn-secondary">
            {t("common.cancel")}
        </button>
        <button disabled={loading} onClick={handleSaveChanges} className="btn btn-primary">
            {t("common.save")}
        </button>
    </div>
  )

  return (
    <div className=" p-3">
      {content}
      {footer}
    </div>
  )
}

export default EditAddSupplies
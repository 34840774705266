import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import mainAxios from "../../../services/mainAxios";
import Loader from "../../Layout/Loader";
import Page404 from "../../Page404";
import invoiceActions from "../../../services/invoiceActions.json";
import invoiceStatuses from "../../../services/invoiceStatuses.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faQuestionCircle,
  faRedo,
  faPrint,
  faPaperPlane,
  faChevronRight,
  faChevronLeft,
  faCopy,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accordion from "../WizardSteps/Accordion";
import ThermalPrinterInvoiceContent from "../ThermalPrinterInvoiceContent";
import AlertCertificate from "../../global/AlertCertificate";
import moment from "moment";
import { useAppContext, usePersistedState } from "../../../AppContext";
import func, { removePropertiesRecursive, validateInvoice } from "../../../services/functions";
import DemoInvoiceTotals from "./DemoInvoiceTotals";
import DemoTable from "./DemoTable";
import DemoInvoiceFiscalization from "./DemoInvoiceFiscalization";
import PopOver from "../../global/PopOver";
import { Card } from "react-bootstrap";
import DemoInvoiceTabsNAT from "./DemoInvoiceTabsNAT";
import DemoParties from "./DemoParties";
import DemoSideMenu from "./DemoSideMenu";
import DemoInvoiceDatas from "./DemoInvoiceDatas";
import Swal from "sweetalert2";
import { typeOfPaymentMeansCash, typeOfPaymentMeansNonCash } from "../WizardSteps/data";
import useShowInvoicePdf from "../../global/useShowInvoicePdf";

const DemoEditInvoice = (props) => {
  const { t, i18n } = useTranslation("translations");
  const [invoice, setInvoice] = React.useState(null);

  const [pageLoading, setPageLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [duplicateLoading, setDuplicateLoading] = React.useState(false);
  const [bankAccounts, setBankAccounts] = React.useState([]);
  const [unitsOfMeasure, setUnitsofMeasure] = React.useState([]);
  const [visible, setVisible] = useState(false);
  const [sideBarVisible, setSideBarVisible] = usePersistedState(true,"sideBar1")
  const [refreshInvoice,setRefreshInvoice]=useState(false)

  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany],
    refreshStatuses=[]
  } = useAppContext();
  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  
  const invoiceId = props.match.params.id;

  const [invoiceAutosave,setInvoiceAutosave]=usePersistedState("false","invoiceAutosave")

  const lastStateRef = useRef();
  const lastStateHelper = useRef();
  const stateRef = useRef();
  const invoiceAutosaveRef=useRef()



  React.useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomInvoice",
        params: {
          id: invoiceId,
        },
      })
      .then((res) => {
        if (res?.data?.status) {
          const parsedInvoice = func.parseCustomInvoiceResponse(
            res.data.content[0]
          );
          lastStateRef.current = parsedInvoice
          //this is needed for calculations inside demotable,because the calculations change the invoiceState totals type from number to string that effects autosave to see this as a change of state and it will save the invoice unneccessarly
          lastStateHelper.current={saved:true}
          setInvoice(parsedInvoice);
          setPageLoading(false);
        }
      });
  }, [selectedCompany, props.match.params.id,refreshInvoice]);

  React.useEffect(() => {
    if (fullCompany?.id) {
      setBankAccounts(fullCompany.bankAccounts || []);
    }
  }, [fullCompany?.id]);


  useEffect(()=>{
    invoiceAutosaveRef.current = invoiceAutosave
  },[invoiceAutosave])

  useEffect(()=>{
    stateRef.current = invoice
  },[invoice])

  useEffect(() => {
    if(invoiceAutosave!=="1") return

    const interval = setInterval(()=>{
      const saveStatusId=stateRef?.current?.status?.id
      if(invoiceAutosaveRef.current !=="1" || (saveStatusId && saveStatusId!==7)) return
      const isEqual = JSON.stringify(stateRef.current) === JSON.stringify(lastStateRef.current)

      if(!isEqual){
        handleInvoice({autosave:true,autoSaveState:stateRef.current})
      }

    },30000)

    return () => clearInterval(interval);

  }, [invoiceAutosave])





  const [errors, setErrors] = useState({});
  const validatedInvoice = () => {
    const {
      hasTransport,
      vehiclePlate,
      transporter,
      destinationAddress,
      badDebtInv,
      selfInvoice,
      sumInv,
      correctiveInv,
    } = invoice;

    const types = [badDebtInv, selfInvoice, sumInv, correctiveInv];
    if (types.filter((type) => type).length > 1) {
      toast.warning(
        "Invoice can only be one of the following 'Bad Debt', 'Self Invoice', 'Summary Invoice', or 'Corrective Invoice'",
        { containerId: "all_toast" }
      );
    } else if (sumInv && !["380", "82", "388"].includes(invoice.type)) {
      toast.warning(
        "Summary Invoice needs to be of types '380', '82', or '388'",
        { containerId: "all_toast" }
      );
    } else if (
      (badDebtInv || sumInv || correctiveInv) &&
      (!invoice.iic_ref || !invoice.refInvIssueDateTime)
    ) {
      document.querySelector("#iicRef").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      toast.warning("IIC Reference and Reference Issue Date are required!", {
        containerId: "all_toast",
      });
    }

    else if (invoice.parties.buyer.tin.length < 1) {
      document.querySelector("#invoice_buyer").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document.querySelector("#invoice_buyer [name='buyer']").focus();
      toast.warning(t(["toast.buyerInfo"]), { containerId: "all_toast" });
      return false;
    } else if (
      (process.env.REACT_APP_ALLOW_INVOICE_NR === "true" &&
        invoice.number?.length < 1) ||
      (!invoice.issueDate || invoice.issueDate.length) < 1 ||
      !invoice.dueDate ||
      invoice.dueDate.length < 1
    ) {
      document.querySelector("#invoice_buyer").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document.querySelector("#invoice_data [name='number']").focus();
      toast.warning(t(["toast.invoiceInfo"]), { containerId: "all_toast" });
      return false;
    } else if (
      hasTransport === true &&
      !(vehiclePlate && transporter && destinationAddress)
    ) {
      toast.warning(t(["toast.emptyTransporter"]), {
        containerId: "all_toast",
      });
    } else {
      const inCompleteItems = invoice.items
        .filter(
          (item) =>
            item.code?.length < 1 ||
            item.name?.length < 1 ||
            item.quantity?.length < 1 ||
            item.priceWithVat?.length < 1
        )
        .map((item, index) => index);
      if (inCompleteItems?.length > 0) {
   
        toast.warning(t(["toast.itemsInfo"]), { containerId: "all_toast" });
        return false;
      } else {
        return true;
      }
    }
  };

  const handleInvoice = ({autosave, autoSaveState}) => {
    let newState =autoSaveState ?? invoice
    const validateInvoiceFields = validateInvoice({ setErrors, toast, state: newState,autoSaveState })
    if (!validateInvoiceFields) return

    if (
      (new Date(newState.invoiceStartDate).getMonth() !==
      new Date(newState.invoiceEndDate).getMonth())
    ) {
      if(!autoSaveState){
        toast.error(
          `${t(["invoice.sDate"])} dhe ${t([
            "invoice.eDate",
          ])} duhet te jene brenda ne te njejtin muajin !`,
          { containerId: "all_toast" }
        );
      }

      return;
    }
    if (newState.businessUnitCode) newState.businessUnitCode = newState.businessUnitCode.trim();
    if (newState.opCode) newState.opCode = newState.opCode.trim();
    if (newState.parties.buyer.name) newState.parties.buyer.name = newState.parties.buyer.name.trim();
    if (newState.parties.seller.name) newState.parties.seller.name = newState.parties.seller.name.trim();

    let loadingToastId=null
    if(autoSaveState){
      loadingToastId=toast.loading("Auto Saving...",{
        containerId: "all_toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      })
    }

    setLoading(true);

    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(newState),
          action: invoiceActions[1],
          status: invoiceStatuses[0],
        },
        nuis: company.nuis.trim(),
      })
      .then((res) => {
        if(loadingToastId){
          setTimeout(()=>{
            toast.dismiss(loadingToastId)
          },2000)
        }
        if (res?.data?.status === true) {

          if(!autoSaveState)toast.success(t(["toast.draftSaved"]), { containerId: "all_toast" });
          const parsedInvoice=func.parseCustomInvoiceResponse(res.data.content[0])

          lastStateRef.current = parsedInvoice
          lastStateHelper.current={saved:true,date:moment().format("HH:mm:ss")}
          setInvoice(parsedInvoice);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
    



  const handleReadyForApproval = async () => {
    let parsedInvoice = func.validateMultiPayment({ state: invoice, toast })

    const {
      type,
      typeOfSelfInvoice,
      invoiceEndDate,
      invoiceStartDate,
      rate,
      issueDate,
      dueDate,
    } = parsedInvoice;

    const { buyer, seller, ...rest } = parsedInvoice.parties;

    const issDate = moment(issueDate).endOf("day");
    const today = moment().endOf("day");
    const validateInvoiceFields = validateInvoice({ setErrors, toast, state: invoice, validateOnlyItems: false })
    if (!validateInvoiceFields) return
        // Check if payment method is "cash" and total invoice exceeds 500,000
  if (parsedInvoice?.parties?.buyer?.buyerIDType !== "NUIS" && parsedInvoice.paymentMethodType === "cash" &&((parsedInvoice.currency =="ALL" && (parseFloat(parsedInvoice?.invoiceTotalWithVat) > 500000)) || (parsedInvoice.currency == "EUR" && parseFloat(parsedInvoice?.invoiceTotalWithVat) > 5000) )) {
    const confirmation = await Swal.fire({
      text: t("toast.amountExceedesAlert",{ amount: parsedInvoice?.currency==="EUR"?"5000 EURO":"500000 LEK"}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#007567",
      confirmButtonText: t("OK"),
      cancelButtonText: t("invoice.backToInvoice"),
    });

    if (!confirmation.isConfirmed) {
      return;
    }
  }
    if (
      parsedInvoice?.parties?.buyer?.buyerIDType === "NUIS" &&
      parsedInvoice?.paymentMethodType === "cash" &&
      parsedInvoice?.invoiceTotalWithVat > 150000
    ) {
      toast.error(t(["toast.errorPos"]), {
        containerId: "all_toast",
      });
    }
      else if (!rate) {
      toast.error(t(["toast.noExchangeRate"]), {
        containerId: "all_toast",
      });
    } else if (issDate > today) {
      toast.error(t(["toast.issueDateErr"]), {
        containerId: "all_toast",
      });
    } else if (
      buyer.buyerIDType === "NUIS" &&
      !new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyer.tin)
    ) {
      toast.error(t(["toast.incorrectNiptForBuyer"]), {
        containerId: "all_toast",
      });
    } else if (!invoiceEndDate && invoiceStartDate) {
      toast.error("Please complete end date", { containerId: "all_toast" });
    } else if (invoiceEndDate && !invoiceStartDate) {
      toast.error("Please complete Start Date", { containerId: "all_toast" });
    } else if (
      parsedInvoice.parties.seller.tin === buyer.tin &&
      parsedInvoice.profileId != "P10" &&
      parsedInvoice.profileId != "P12"
    ) {
      toast.error(t(["toast.diffNipt"]), { containerId: "all_toast" });
    } else {
      if (validatedInvoice()) {
        setLoading(true);
        mainAxios
          .post("endpoint/v2.2/saveOrUpdate", {
            object: "CustomInvoice",
            content: {
              ...func.parseCustomInvoiceRequest(parsedInvoice),
              action: invoiceActions[5],
              status: invoiceStatuses[1],
            },
            nuis: company.nuis,
          })
          .then((res) => {
            if (res?.data?.status === true) {
              toast.success(t(["toast.draftApproval"]), {
                containerId: "all_toast",
              });
              setInvoice(func.parseCustomInvoiceResponse(res.data.content[0]));
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

   const handleDuplicateInvoice = () => {
    const validateInvoiceFields = validateInvoice({ setErrors, toast, state: invoice });
    if (!validateInvoiceFields) return;
    setDuplicateLoading(true)
    const {
      recordDate,
      recordUser,
      modificationDate, 
      modificationUser,
      source,
      customInvoice,
      invoiceHistorics,
      attachmentPath,
      invoiceExtraCharge,
      invoicePrepaidAmount,
      iic,
      fic,
      eic,
      badDebtInv,
      correctiveInv,
      sumInv,
      param3,
      param4,
      qrurl,
      cisStatus,
      cisError,
      ...restInvoice
    } = invoice;
    const pathsToPreservId = ['items','bankAccount'];
    const cleanedRestInvoice = removePropertiesRecursive(restInvoice, pathsToPreservId);
    cleanedRestInvoice.action = {
      "id": 5,
      "action": "CREATED"
    };
  
    cleanedRestInvoice.status = {
      "id": 7,
      "status": "DRAFT"
    };
    
    cleanedRestInvoice.items = cleanedRestInvoice.items.map(items => {
      const { id,...restItems } = items
      return restItems
    })
  
    cleanedRestInvoice.paymentMethod = cleanedRestInvoice?.paymentMethod?.map(pm => {
      const {
        modificationDate,
        modificationUser,
        customInvoice,
        recordUser,
        recordDate,
        ...restPm } = pm ?? null
        return restPm
    }) ?? []
  
    cleanedRestInvoice.issueDate = new Date()
    cleanedRestInvoice.dueDate = new Date();
    cleanedRestInvoice.internalId = null
    cleanedRestInvoice.opCode = user?.userCompanyOperator?.find(
      (companyOperator) => companyOperator.company === company.id
    )?.operator || null
   
    if (cleanedRestInvoice.businessUnitCode) cleanedRestInvoice.businessUnitCode = cleanedRestInvoice.businessUnitCode.trim();
    if (cleanedRestInvoice.opCode) cleanedRestInvoice.opCode = cleanedRestInvoice.opCode.trim();
    if (cleanedRestInvoice.parties.buyer.name) cleanedRestInvoice.parties.buyer.name = cleanedRestInvoice.parties.buyer.name.trim();
    if (cleanedRestInvoice.parties.seller.name) cleanedRestInvoice.parties.seller.name = cleanedRestInvoice.parties.seller.name.trim();

    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(cleanedRestInvoice),
        },
        nuis: company.nuis.trim(),
      })
      .then((res) => {
        setDuplicateLoading(false)
        if (res?.data?.status === true) {
          const duplicatedInvoice = func.parseCustomInvoiceResponse(res.data.content[0]);
          setInvoice(duplicatedInvoice);
          props.history.push("/edit-invoice/" + res.data.content[0].id);
          toast.success(t(['invoice.isInvoiceDuplicated']), { containerId: "all_toast" })
          
        }
        setLoading(false);
      })
      .catch(() => {
        setDuplicateLoading(false)
      });
  };

  const handleApprove = () => {
    if (!invoice.invoiceTotalVat.includes("-") || invoice.type === "384") {
      setLoading(true);
      mainAxios
        .post("endpoint/v2.2/saveOrUpdate", {
          object: "CustomInvoice",
          content: {
            ...func.parseCustomInvoiceRequest(invoice),
            action: invoiceActions[2],
            status: invoiceStatuses[2],
          },
          nuis: company.nuis,
        })
        .then((res) => {
          if (res?.data?.error) {
            toast.error(res?.data?.error.errorDescription, {
              containerId: "all_toast",
            });
            setInvoice({
              ...invoice,
              status: {
                ...invoice.status,
                status: res.data.error.errorCode,
              },
              cisError: res?.data?.error.errorDescription,
              cisStatus: res.data.error.errorCode,
            });
          } else {
            if (res?.data?.status === true) {
              toast.success(t(["toast.successApproval"]), {
                containerId: "all_toast",
              });
              setInvoice(func.parseCustomInvoiceResponse(res.data.content[0]));
            }
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      toast.warning(t(["toast.invoice.approve.warning"]), {
        containerId: "all_toast",
      });
    }
  };


  const handleRefreshStatus=()=>{
    if (!invoice.invoiceTotalVat.includes("-") || invoice.type === "384") {
      setLoading(true);
      mainAxios
        .post("endpoint/v2.2/saveOrUpdate", {
          object: "RefreshStatus",
          content: {
            ...func.parseCustomInvoiceRequest(invoice),
            action: invoiceActions[2],
            status: invoiceStatuses[2],
          },
          nuis: company.nuis,
        })
        .then((res) => {
          if (res?.data?.error) {
            toast.error(res?.data?.error.errorDescription, {
              containerId: "all_toast",
            });
            setInvoice({
              ...invoice,
              status: {
                ...invoice.status,
                status: res.data.error.errorCode,
              },
              cisError: res?.data?.error.errorDescription,
              cisStatus: res.data.error.errorCode,
            });
          } else {
            if (res?.data?.status === true) {
              toast.success(t(["invoice.refreshedInvoice"]), {
                containerId: "all_toast",
              });
              setRefreshInvoice((state)=>!state)
            }
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      toast.warning(t(["toast.invoice.approve.warning"]), {
        containerId: "all_toast",
      });
    }
  }
  const handleReject = () => {
    setLoading(true);
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(invoice),
          action: invoiceActions[3],
          status: invoiceStatuses[0],
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.invoiceRejected"]), {
            containerId: "all_toast",
          });
          setInvoice(func.parseCustomInvoiceResponse(res.data.content[0]));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(invoice),
          action: invoiceActions[4],
          status: invoiceStatuses[3],
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.draftDeleted"]), {
            containerId: "all_toast",
          });
          if (props?.isPos) {
            props.history.push("/pos-transactions");
          } else {
            props.history.push("/my-invoices");
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setLoading(true);
    const correctiveInvoice = JSON.parse(JSON.stringify(invoice));
    delete correctiveInvoice["id"];
    delete correctiveInvoice["invoiceHistorics"];
    delete correctiveInvoice["internalId"];
    const opCode=user?.userCompanyOperator?.find(
      (uco) => parseInt(uco.company) === parseInt(selectedCompany)
    )?.operator ?? correctiveInvoice.opCode

    correctiveInvoice.opCode = opCode
    if(correctiveInvoice?.discount?.id) delete correctiveInvoice.discount.id

    correctiveInvoice.attachments = correctiveInvoice.attachments.map(
      (attachment) => {
        const { id, ...rest } = attachment;
        return rest;
      }
    );

    correctiveInvoice.items = correctiveInvoice.items.map((item) => {
      const { id, quantity, totalWithoutVat, totalWithVat, ...rest } = item;

      return {
        ...rest,
        quantity: -1 * quantity,
      };
    });

    if (correctiveInvoice?.paymentMethod?.length > 0) {
      correctiveInvoice.paymentMethod = correctiveInvoice.paymentMethod.map(payment => {
        const { paymentMethodType, paymentMeans } = invoice
        let paymentMeansValue = payment.paymentMeansValue

        if (!paymentMeansValue) {
          const paymentOptions = paymentMethodType == "cash" ? typeOfPaymentMeansCash(t) : typeOfPaymentMeansNonCash(t)
          const currentPaymentOption = paymentOptions.find(opt => opt.paymentMeans === paymentMeans)
          if (currentPaymentOption?.paymentMeansValue) paymentMeansValue = currentPaymentOption.paymentMeansValue
        }

        return {
          ...payment,
          id:null,
          paymentAmount: -1 * payment.paymentAmount,
          paymentMeansValue
        }
      })
    }
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(correctiveInvoice),
          issueDate: new Date(),
          action: invoiceActions[0],
          status: invoiceStatuses[0],
          iic: null,
          fic: null,
          eic: null,
          recordDate: null,
          recordUser: null,
          number: "",
          profileId: "P10",
          type: "384",
          correctiveInv: true,
          correctiveInvType: "CORRECTIVE",
          badDebtInv: false,
          reverseCharge: false,
          selfInvoice: false,
          sumInv: false,
          cisStatus: null,
          cisError: null,
          internalId: "",
          iic_ref: correctiveInvoice.iic,
          refInvIIC: correctiveInvoice.iic,
          refInvIssueDateTime: invoice.issueDate,
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          // return

          toast.success("Corrective Invoice Created!", {
            containerId: "all_toast",
          });
          if (props?.isPos) {
            props.history.push("/edit-pos/" + res?.data?.content[0].id);
          } else {
            props.history.push("/edit-invoice/" + res?.data?.content[0].id);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /** USER ROLES
   * "Role Name: sysadmin,  ID: 1"
   * "Role Name: admin,     ID: 2"
   * "Role Name: useradmin, ID: 3"
   * "Role Name: Create ,   ID: 4"
   * "Role Name: Approve ,  ID: 5"
   * "Role Name: Purchase,  ID: 6"
   * "Role Name: View,      ID: 7"
   */
  const userCanCreate = [1, 2, 4, 15].includes(user.role.id);
  const userCanSave = [1, 2, 4, 15].includes(user.role.id);
  const userCanSubmitForApproval = [1, 2, 4, 15].includes(user.role.id);
  const userCanDelete = [1, 2, 4, 15].includes(user.role.id);
  const userCanCancel = [1, 2, 4, 15].includes(user.role.id);
  const userCanApproveOrReject = [1, 2, 5, 15].includes(user.role.id);

  let disabled = false;
  let showRefresh=false

  if (invoice){
    disabled =
      invoice.status.status === "READY_FOR_APPROVAL" ||
      invoice.status.status === "APPROVED";
      
      let status = invoice?.status?.status;
      let resendStatuses = refreshStatuses?.filter(resendStatus=>resendStatus?.isActive==true)?.map(resendStatus=>resendStatus?.status)?? []
      let staticRefreshConditions=(invoice?.einvoice && invoice?.cisStatus==="SUCCESS" && (!invoice?.eic || invoice.eic=="")) || invoice?.cisStatus =="PENDING"
  
      showRefresh= (resendStatuses.includes(status)) || staticRefreshConditions
  }

  let badgeClass = "secondary";
  let statusLabel = "Draft";


  let buttons = (
    <>
      {invoice?.status?.id === 7 && <a
        href="#"
        style={{minWidth:"max-content"}}
        className={
          "btn btn-sm btn-secondary mr-2 mb-2 mb-md-0 " +
          (loadingPdf || duplicateLoading || loading ? " disabled" : "")
        }
        onClick={(e) => {
          e.stopPropagation();
          showInvoicePdf({key:"id", invoice,e});
        }}
      >
        <FontAwesomeIcon
          icon={faFilePdf}
          className="mr-1"
        />
        Print no Fisc
      </a>
      }

      {userCanCreate && (
        <Link to="/create-invoice" className="btn btn-sm btn-info  mb-2 mb-md-0">
          {t(["navbar.create"])}
        </Link>
      )}
      {userCanDelete && !showRefresh && (
        <button
          className="btn btn-sm btn-danger ml-2 mb-2 mb-md-0"
          disabled={loading || duplicateLoading}
          onClick={handleDelete}
        >
          {loading ? <Loader size="xs" color="white" /> : t(["common.delete"])}
        </button>
      )}
      {userCanSave && (
        <button
          className="btn btn-sm btn-success ml-2 mb-2 mb-md-0"
          disabled={loading || duplicateLoading}
          onClick={handleInvoice}
        >
          {loading ? <Loader size="xs" color="white" /> : t(["common.save"])}
        </button>
      )}
      {userCanSubmitForApproval && (
        <button
          className="btn btn-sm btn-primary ml-2  mb-2 mb-md-0"
          disabled={loading || duplicateLoading}
          onClick={handleReadyForApproval}
          style={{minWidth:"max-content"}}
        >
          {loading ? (
            <Loader size="xs" color="white" />
          ) : (
            t(["common.submitApproval"])
          )}
        </button>
      )}
    </>
  );

  if (invoice) {
    let status = invoice?.status?.status;

    if (showRefresh ) {
      disabled=true
      buttons = (
        <>
          <button
            className="btn btn-warning  mb-2 mb-md-0"
            disabled={loading}
            style={{ minWidth: "80px" }}
            onClick={handleRefreshStatus}
          >
            {loading ? (
              <Loader size="xs" color="white" />
            ) : (
              <>
                <FontAwesomeIcon icon={faRedo} className="mr-2" /> Refresh
              </>
            )}
          </button>
        </>
      );
    } else if (status === "READY_FOR_APPROVAL") {
      badgeClass = "info";
      statusLabel = "Ready For Approval";
      buttons = (
        <>
        <a
               href="#"
               style={{minWidth: "max-content"}}
              className={
                "btn btn-sm  btn-secondary d-flex-align-items-center mr-3  mb-2 mb-md-0" +
                (loadingPdf ? " disabled" : "")
              }
              onClick={(e) => {
                e.stopPropagation();
                showInvoicePdf({key:"id", invoice,e});
              }}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                className="mr-1"
              />
              Print no Fisc
            </a>
          
          {userCanCreate && (
            <Link to="/create-invoice" className="btn btn-sm  btn-info mr-3  mb-2 mb-md-0">
              Create
            </Link>
          )}
          {userCanApproveOrReject && (
            <button
              className="btn btn-sm btn-danger mr-3 mb-2 mb-md-0"
              disabled={loading}
              style={{ minWidth: "80px" }}
              onClick={handleReject}
            >
              {loading ? (
                <Loader size="xs" color="white" />
              ) : (
                t(["status.reject"])
              )}
            </button>
          )}
          {userCanApproveOrReject && (
            <button
              className="btn btn-sm btn-primary  mb-2 mb-md-0"
              disabled={loading}
              style={{ minWidth: "80px" }}
              onClick={handleApprove}
            >
              {loading ? (
                <Loader size="xs" color="white" />
              ) : (
                t(["status.approve"])
              )}
            </button>
          )}
        </>
      );
    } else if (status === "APPROVED") {
      badgeClass = "success";
      statusLabel = "Approved";
      buttons = invoice && invoice.status.id === 9 && (
        <>
          {userCanCreate && (
            <Link to="/create-invoice" className="btn btn-sm btn-info mb-2 mb-md-0">
              {t("navbar.create")}
            </Link>
          )}
          {userCanCancel && (
            <button
              className="btn btn-sm btn-danger ml-3"
              style={{ minWidth: "80px" }}
              onClick={handleCancel}
            >
              {loading ? (
                <Loader size="xs" color="white" />
              ) : (
                t("common.cancelInvoice")
              )}
            </button>
          )}
        </>
      );
    }
  }

  const handleInvoiceTcrCodeChange = (tcrCode) => {
    setInvoice((invoice) => ({
      ...invoice,
      tcrCode,
    }));
  };
  const handleSelfInvoiceChange = (e) => {
    const value = e.target.checked;
    setInvoice((invoice) => ({
      ...invoice,
      selfInvoice: value,
    }));
    if (value === false) {
      setInvoice((invoice) => ({
        ...invoice,
        typeOfSelfInvoice: "",
        reverseCharge: false,
      }));
    }
  };

  const handleSelfInvoiceTypeChange = (key, value) => {
    setInvoice((invoice) => ({
      ...invoice,
      [key]: value,
    }));
  };

  const handleReverseChargeChange = (e) => {
    const value = e.target.checked;
    setInvoice((invoice) => ({
      ...invoice,
      reverseCharge: value,
    }));
  };

  const handlePaymentMethodTypeChange = (newPaymentMethodType) => {
    const { paymentMethodType, paymentMeans, ...rest } = invoice;

    let newPaymentMeans = "";

    if (paymentMethodType === "noncash") newPaymentMeans = "10";
    else newPaymentMeans = "ZZZ";
    setInvoice((invoice) => ({
      ...invoice,
      paymentMethodType: newPaymentMethodType,
      paymentMeans: newPaymentMeans,
    }));
  };

  const handlePaymentMeansChange = (key, value) => {
    setInvoice((invoice) => ({
      ...invoice,
      [key]: value,
    }));
  };

  const { showInvoicePdf,pdfEmbed,loadingPdf } = useShowInvoicePdf()
  const isLoadingPdf=loadingPdf?.id==invoice?.id && loadingPdf?.loading


  const handlePrint = () => {
    const content = document.getElementById("thermalPrinterInvoice");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;

    pri.document.open();
    pri.document.write(
      `<html><style>@page{margin:0mm auto;margin-bottom:10mm;font-family: 'EB Garamond', serif;}</style>${content.innerHTML}</html>`
    );
    pri.document.close();
    pri.focus();
    pri.print();
  };

  if (invoice) {
    if (props.isPos && invoice.source !== "MP") {
      return <Page404 />;
    } else if (!props.isPos && invoice?.source !== "MI") {
      return <Page404 />;
    }
  }

  const handleInvoiceChange = (value, key) => {
    const keys = key.split(".");

    switch (keys.length) {
      case 1:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: value,
        }));
        break;
      case 2:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: value,
          },
        }));
        break;
      case 3:
        setInvoice((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: {
              ...invoiceState[keys[0]][keys[1]],
              [keys[2]]: value,
            },
          },
        }));
        break;
      default:
        return;
    }
  };

  function changeOnlyFisc() {
    if (!invoice.einvoice) {
      setInvoice((invoiceState) => ({
        ...invoiceState,
        einvoice: true,
        parties: {
          ...invoiceState.parties,
          buyer: {
            ...invoiceState.parties.buyer,
            buyerIDType: "NUIS",
          },
        },
        paymentMethodType: "noncash",
        paymentMeans: "ZZZ",
      }));
    } else {
      Swal.fire({
        title: t(["notification.einvoice"]),
        icon: "warning",
        width: "800px",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value == true) {
          setInvoice((invoiceState) => ({
            ...invoiceState,
            einvoice: false,
          }));
        }
      });
    }
  }

  const sideBarToggle=({...props}={})=>(
    <div className="side-bar-handle" style={{...props }}>
      <div className="side-bar-handle-content"  onClick={() => setSideBarVisible((barVisible)=>barVisible ==="false"?"true":"false")}>
        {/* <img src={sideMenuIcons} alt="side"/> */}
        <FontAwesomeIcon icon={sideBarVisible==="true"?faChevronRight:faChevronLeft} className="" />
      </div>
    </div>
  )


  return !pageLoading ? (
    invoice ? (
      <>
        <div className="demoInvoice">
          {(invoice.iic || invoice.eic || invoice.fic ) && (
            <div className="card border-0 bg-transparent card-accordion d-flex justify-content-center align-items-center" >
              <Accordion
                heading={
                  <>
                    {invoice.iic && (
                      <a
                        href="#"
                        className={
                          "btn btn-sm btn-info d-flex-align-items-center ml-3"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePrint();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPrint}
                          className="mr-1"
                        />
                        Print Thermal Fisc
                      </a>
                    )}

                  
                    {invoice.iic && (
                      <a
                        href="#"
                        className={
                          "btn btn-sm btn-info d-flex-align-items-center ml-3" +
                          ((isLoadingPdf && loadingPdf?.key=="iic") ? " disabled" : "")
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          showInvoicePdf({key:"iic", invoice,e});
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className="mr-1"
                        />
                        Print Fisc
                      </a>
                    )}
                    {invoice.eic && (
                      <a
                        href="#"
                        className={
                          "btn btn-sm btn-info d-flex-align-items-center ml-3" +
                          ((isLoadingPdf && loadingPdf?.key=="eic")  ? " disabled" : "")
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          showInvoicePdf({key:"eic", invoice});
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className="mr-1"
                        />
                        Print Einv
                      </a>
                    )}
                    {invoice.qrurl && (
                      <a
                        href={invoice.qrurl}
                        onClick={(e) => e.stopPropagation()}
                        className="btn btn-sm btn-info d-flex-align-items-center ml-3"
                        target="_blank"
                      >
                        QR Url
                      </a>
                    )}
                    {invoice.iic && (
                      <a
                        href="#"
                        className={
                          "btn btn-sm btn-info d-flex-align-items-center ml-3"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          func.sendEmailPdf(
                            invoice.iic,
                            invoice.customer,
                            invoice?.parties?.buyer?.tin,
                            t
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="mr-1"
                        />
                        {t("email.sendByEmail")}
                      </a>
                    )}
                  </>
                }
              >
                <div style={{ padding: "10px 20px 20px" }}>
                  <p>
                    <strong>FIC: </strong> {invoice.fic}
                  </p>
                  <p>
                    <strong>IIC: </strong> {invoice.iic}
                  </p>
                  <p>
                    <strong>EIC: </strong> {invoice.eic}
                  </p>
                </div>
              </Accordion>
            </div>
          )}
          <div className="row ">
            <div className="d-flex justify-content-center align-items-center">


            </div>

            <div className={`col-12 col-md-${sideBarVisible==="true"?"10":"12"}`} style={{position:"relative"}}>

              <Card className="p-2">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-1 mt-1 gap-y-2">
                  <div className="d-flex align-items-center flex-wrap">
                    <h3 className="mb-0 mr-4">
                      {t("invoice.editDraft")}
                      <small className={`badge badge-${badgeClass} ml-2`} data-tip="Profisc Status">
                        {statusLabel}
                      </small>
                      <div className="d-none">
                        {invoice?.action?.action === "APPROVED" && (
                          <ThermalPrinterInvoiceContent invoice={invoice} />
                        )}
                      </div>
                      {!invoice.cisStatus ? null : invoice.cisStatus ===
                        "SUCCESS" ? (
                        <small className="badge badge-success ml-2" data-tip="Cis Status">
                          Success
                        </small>
                      ) : invoice.cisError ? (
                        <small
                          className={`badge badge-${showRefresh?"warning":"danger"} d-inline-flex align-items-center ml-2`}
                          data-tip={invoice.cisError}
                        >
                         {showRefresh? <span style={{fontWeight:600}}>PENDING</span> :"Error"}
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="ml-2"
                          />
                        </small>
                      ) : (
                        <small className="badge badge-danger ml-2">Error</small>
                      )}
                    </h3>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isEinvoice"
                        name="isEinvoice"
                        checked={!invoice.einvoice}
                        onChange={changeOnlyFisc}
                        disabled={invoice.type == 389 || disabled}
                      />
                      <label
                        style={{ paddingTop: "3px", paddingLeft: "2px" }}
                        htmlFor="isEinvoice"
                        className="custom-control-label"
                      >
                        {t("parameters.onlyFiscalization")}
                        <PopOver title={t("hints.onlyFiscalization")} />
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="sumInv"
                        name="sumInv"
                        checked={invoice.sumInv}
                        onChange={(e) =>
                          handleInvoiceChange(e.target.checked, "sumInv")
                        }
                        disabled={disabled}
                      />
                      <label
                        style={{ paddingTop: "3px", paddingLeft: "2px" }}
                        htmlFor="sumInv"
                        className="custom-control-label"
                      >
                        {t("invoice.summaryInvoice")}
                        <PopOver title="Zgjidhni kte opsion ne qofte se fature eshte permbledhese" />
                      </label>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center ml-3 mt-1">
                      <div className="custom-control custom-switch d-flex align-items-center" >
                        <input
                          type="checkbox"
                          id="invoice_autosave"
                          className="custom-control-input"
                          checked={invoiceAutosave==="1"}
                          onChange={(event) => {
                            setInvoiceAutosave(event.target.checked ? "1" : "0")
                          }}
                        />
                        <label className="custom-control-label " htmlFor="invoice_autosave" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
                          {t("invoice.autoSave")}
                          <PopOver title={t("invoice.autoSaveDesc")}  />

                          </label>

                      </div>
                      {lastStateHelper?.current?.date &&<div style={{fontSize:12,fontWeight:700}}>
                      {t("invoice.lastAutosaved")}: {lastStateHelper?.current?.date ?? ""}
                      </div>}
                      </div>

                  </div>
                  <div>
                    <AlertCertificate />
                    <iframe
                      id="ifmcontentstoprint"
                      className="d-none"
                    ></iframe>
                  </div>
                  <div>

                    <div className="d-flex align-items-start justify-content-end flex-wrap flex-md-nowrap" >
                  { invoice.status.status !== "ERROR" && <div className="actions mr-3">
                      <button
                        onClick={handleDuplicateInvoice}
                        className={`btn btn-sm ${duplicateLoading || loading ?"disabled":""} btn-outline-primary`}
                        style={{minWidth:"max-content"}}

                      >
                        {duplicateLoading ? <FontAwesomeIcon className='mr-1' size='lg' icon={faSpinner} spin /> : <FontAwesomeIcon className="mr-1" icon={faCopy} />}
                        {t("invoice.duplicate")}
                      </button>
                    </div>}
                      {buttons}
                    </div>
                  </div>
                </div>

                <div className="my-2 border-top border-success"></div>

                <div className="row">
                  {/*  type  */}
                  <div className="col-12 col-md-4 border-right h-100 ">
                    <DemoInvoiceFiscalization
                      handleInvoiceTcrCodeChange={handleInvoiceTcrCodeChange}
                      handleSelfInvoiceChange={handleSelfInvoiceChange}
                      handleSelfInvoiceTypeChange={handleSelfInvoiceTypeChange}
                      handleReverseChargeChange={handleReverseChargeChange}
                      handlePaymentMethodTypeChange={
                        handlePaymentMethodTypeChange
                      }
                      handlePaymentMeansChange={handlePaymentMeansChange}
                      invoiceState={invoice}
                      setInvoiceState={setInvoice}
                      disabled={disabled}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  </div>
                  {/* //////// */}

                  {/*  Seller   */}
                  <div className="col-12 col-md-4 h-100">
                    <DemoParties
                      invoiceState={invoice}
                      setInvoiceState={setInvoice}
                      disabled={disabled}
                      visible={visible}
                      setVisible={setVisible}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  </div>
                  {/* ////// */}

                  {/* Buyer */}
                  <div className="col-12 col-md-4  border-left h-100">
                    <DemoInvoiceDatas
                      handleInvoiceChange={handleInvoiceChange}
                      invoiceState={invoice}
                      setInvoiceState={setInvoice}
                      disabled={disabled}
                      errors={errors}
                      setErrors={setErrors}
                      edit={true}
                    />
                  </div>
                  {/* /// */}
                </div>

                <DemoTable
                  invoiceState={invoice}
                  setInvoiceState={setInvoice}
                  disabled={disabled}
                  setErrors={setErrors}
                  errors={errors}
                  lastStateRef={lastStateRef}
                  lastStateHelper={lastStateHelper}
                />

                <div className="row mt-1 mx-1">
                  {/* InvoiceNotes */}
                  <div className="col-12 border-top col-md-6">
                    {!props.isPos && (
                      <DemoInvoiceTabsNAT
                        errors={errors}
                        invoiceState={invoice}
                        setInvoiceState={setInvoice}
                        disabled={disabled}
                      />
                    )}
                  </div>
                  {/* /// */}
                  {/* InvoiceTotals */}
                  <div className="col-12 col-md-6 border-top total-list">
                    <DemoInvoiceTotals invoiceState={invoice} />
                  </div>
                  {/* /// */}
                </div>
              </Card>
              {sideBarToggle({right:-2,left:"auto"})}
            </div>

            {/* sidreBar */}
            {sideBarVisible=="true" && <div className="col-12 col-md-2">
              <div className=" pt-md-1 pt-xl-2">
           
                <DemoSideMenu
                  invoiceState={invoice}
                  setInvoiceState={setInvoice}
                  bankAccounts={bankAccounts}
                  disabled={disabled}
                />
              </div>
            </div>}
            {/*  */}
          </div>
        </div>
        {pdfEmbed}
        <ReactTooltip />
      </>
    ) : (
      <Page404 />
    )
  ) : (
    <Loader />
  );
};

export default DemoEditInvoice;

export const tinTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
];

export const defaultTableRows ={
  totalRows: 0,
  rowsPerPage: 10,
  currentPage: 1,
}


export const paymentOptions=[
    {
      value:"NONCASH",
      label:"Non Cash"
    },
    {
      value:"CASH",
      label:"Cash"
    },
  ]

export const subscriptionStatus={
  active:"1",
  upcoming:"2",
  expired:"4",
  canceled:"3",
  surpased:"5"

}

import React, { useEffect, useState } from 'react'
import PrimeTable from '../../PrimeTable/PrimeTable'
import { useTranslation } from 'react-i18next';
import TooltipOverlay from '../../global/TooltipOverlay';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faExclamationTriangle, } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Loader from '../../Layout/Loader';
import mainAxios from '../../../services/mainAxios';
import { toast } from 'react-toastify';
import { Dialog } from "primereact/dialog";
import AddCompanyBranch from './AddCompanyBranch';
import EditCompanyBranch from './EditCompanyBranch';
import { useAppContext, usePersistedState } from '../../../AppContext';
import ExportCsvPrime from '../../global/ExportCsvPrime';
import useRegisterBranchInCis from './useRegisterBranchInCis';



const Branches = ({ company, setCompany, getCompany }) => {

    const { t } = useTranslation("translations");

    const {
        user: [user, setUser],
        selectedCompany: [selectedCompany],
        getUser
    } = useAppContext();
    const [loadingBussiness, setLoadingBussiness] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)

    const [editBranchComp, setEditBranchComp] = useState({ show: false, id: null })
    const [addtBranchComp, setAddBranchComp] = useState({ show: false, id: null })
    const [branchesForCsv, setBranchesForCsv] = useState([]);

    const defaultFilters = { params: {} }
    const [filters, setFilters] = React.useState(defaultFilters);
    const [tableRows, setTableRows] = useState({
        totalRows: 0,
        rowsPerPage: 10,
        currentPage: 1,
    });

    const [branches, setBranches] = useState([])
    const refreshGrid=()=>getBranches({updatedFilters:filters,rowsPerPage:tableRows.rowsPerPage,currentPage:tableRows.currentPage})
    const {registerBranchesInCisActions,selectedRowsColumn} = useRegisterBranchInCis({branches,company,refreshGrid})


    const getBranches = ({updatedFilters,rowsPerPage=10, currentPage=1, exportCsv = false,sortCriteria}={})=>{
    return  mainAxios.post("apiEndpoint/search", {
            object: "GetBranches",
            company: company?.id,
            params:updatedFilters?.params,
            pagination: exportCsv ? null : {
                pageSize: rowsPerPage,
                pageNumber: currentPage,
              },

        }).then(res => {
            setLoadingPage(false)
            if (res.data.status) {
                if (exportCsv) {
                    setBranchesForCsv(res.data.content)
                    return res.data.content
                  } else {
                    setBranches(res.data.content)
                    setTableRows((tableRows) => ({
                        ...tableRows,
                        totalRows: res?.data?.totalSize,
                    }));
                  }
            }
        })
            .catch(err => {
                setLoadingPage(false)
            })
        }
    
    useEffect(()=>{
        getBranches()
    },[company.id])

    const branchColumns = [
        {
            header: t(["home.actions"]),
            style: { minWidth: "100px", maxWidth: "100px" },
            field:"actions",
            display: "flex",
            default: true,
            //width:'350px',
            body: (branch) => (
                <div className="d-flex align-items-center ">
                    <div className="d-flex" aria-labelledby="dropdownMenu2">
                        {user.role.id==1 && <TooltipOverlay title={t("company.editBranch")} show={0} hide={0}>
                            <Link
                                to="#"
                                onClick={() => setEditBranchComp({ show: true, id: branch.id.toString() })}
                                // to={`/companies/${company.id}/branches/${branch.id}`}
                                className="nav-link p-0"
                            >
                                <FontAwesomeIcon icon={faEdit} className="mr-3" />

                                {/* {t("common.edit")} */}
                            </Link>
                        </TooltipOverlay>}
                        {!branch.default &&user.role.id==1&& (
                            <TooltipOverlay title={t("company.setDefault")} show={0} hide={0}>
                                <Link
                                    to="#"
                                    className="nav-link p-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDefaultBranch(e, branch.id);
                                    }}

                                >
                                    <FontAwesomeIcon icon={faCheck} className="" />
                                </Link>
                            </TooltipOverlay>
                        )}
                    </div>

                    {branch?.default && (
                        <div className="badge badge-success">Default</div>
                    )}

                </div>
            ),

        },

        {

            header: t("purchases.bussinesCode"),
            style: { minWidth: "170px", maxWidth: "180px" },
            field: "businUnitCode",
            filter: true,
            sortable: true,
            default: true,
        },
        {
            header: t(["company.sellerAddress"]),
            sortable: true,
            filter: true,
            field: "sellerAddress",
            style: { minWidth: "200px", maxWidth: "200px" },
            format: (address) => (address.sellerAddress ?? "").replaceAll(",", " "),
            body: (company) =>

                company.sellerAddress ? (
                    <div
                        style={{
                            backgroundColor: company?.default ? "#d4edda" : "",
                        }}
                    >

                        {company.sellerAddress.replaceAll(",", " ")}
                    </div>
                ) : (
                    ""
                ),
        },   
        {
            header: t(["company.branchSellerName"]),
            style: { minWidth: "200px", maxWidth: "200px" },
            sortable: true,
            field: "sellerName",
            filter: true,
            default:true,
        },
        {
            header: t(["company.branchSellerCis"]),
            style: { minWidth: "200px", maxWidth: "200px" },
            sortable: true,
            field: "cisBuName",
            filter: true,
            default:true,
        },
        {
            header: t(["company.sellerTown"]),
            sortable: true,
            filter: true,
            default:false,
            field: "sellerTown",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t("company.nextNumber"),
            sortable: true,
            filter: true,
            field: "nextNumber",
            formattedExportValue:true,
            body:(branch)=>branch?.nextNumberSimpleDTO?.nextNumber ?? "",
            style: { minWidth: "220px", maxWidth: "220px" },
            default: true,
        },
        {
            header: t(["company.profiscStat"]),
            sortable: true,
            filter: true,
            filterType:"TRISTATECHECKBOX",
            default:true,
            dataType:"boolean",
            field: "profiscStatus",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t("purchases.warnings"),
            field: "expiresThisMonth",
            default: false,
            style: { minWidth: "270px", maxWidth: "320px", },
            body: (user) => (
                <div className='d-flex flex-column items-center w-full'>
                {user?.expiresThisMonth 
                    ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        {t(["company.expiresWarning"])}
                    </div>
                    : null}
                    {user?.profiscStatus &&  !user?.registerInCis && user?.existCis &&<div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center',marginTop:user?.expiresThisMonth?5:"auto",fontSize:11 }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        {t("company.existProfiscNotRegisteredInCis")}
                    </div>}
                    {user?.profiscStatus && !user?.existCis &&<div style={{ backgroundColor: '#FF5F15', color: '#fff', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center',marginTop:5,fontSize:12,width:220}}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        {t("company.existInProfiscNotInCis")}
                    </div>}
                </div>

            ),
        },
        {
            header: t("subscription.validFrom"),
            field: "validFrom",
            filterType: "DATE",
            like: false,
            style: { minWidth: 180, maxWidth: 200 },
            sortable: true,
            filter: true,
            filterConfig: {
                field: "branchesValidFrom"
            },
            body: (branch) =>
                branch?.validFrom
                    ? moment(branch?.validFrom).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
            default:true,
        },
        {
            header: t("subscription.validTo"),
            field: "validTo",
            filterType: "DATE",
            filterConfig: {
                field: "branchesValidTo"
            },
            like: false,
            style: { minWidth: 150, maxWidth: 200 },
            filter: true,
            default:true,
            body: (branch) =>
                branch?.validTo
                    ? moment(branch?.validTo).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
        },
        {
            header: "Exist CIS",
            sortable: true,
            filterType:"TRISTATECHECKBOX",
            filter: true,
            field: "existCis",
            dataType:"boolean",
            default:true,
            style: {
                minWidth: "200px",
                maxWidth: "200px",
                textAlign: "center", 
            },
        },
        {
            header: t("company.cisUpdate"),
            sortable: true,
            field: "cisLastUpdate",
            body: (branch) =>
                branch?.cisLastUpdate
                    ? moment(branch?.cisLastUpdate).format("DD/MM/YYYY HH:mm")
                    : "",
            style: { minWidth: "240px", maxWidth: "250px" },
            formattedExportValue: true,
            default: true,
        },
        {
            header: "CIS Address",
            default: false,
            sortable: true,
            filter: true,
            field: "cisAddress",
            style: { minWidth: "200px", maxWidth: "200px" },
            format:(address)=> (address.cisAddress??'').replaceAll(`,`,' '),
        },
        {
            header: t('nslf.softCode'),
            sortable: true,
            filter: true,
            default:true,
            field: "softCode",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t('company.cisEndDate'),
            sortable: true,
            filter: false,
            default:true,
            field: "cisEndDate",
            style: { minWidth: "200px", maxWidth: "200px" },
            body: (branch) =>
                branch?.cisEndDate
                    ? moment(branch?.cisEndDate).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
            
        },
        {
            header: t("company.cisStartDate"),
            sortable: true,
            default:true,
            filter: false,
            field: "cisStartDate",
            style: { minWidth: "200px", maxWidth: "220px" },
            body: (branch) =>
                branch?.cisStartDate
                    ? moment(branch?.cisStartDate).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,

        },
        {
            header: t("company.cisRegistrationDate"),
            sortable: true,
            default:true,
            filter: false,
            field: "cisRegisterDate",
            style: { minWidth: "200px", maxWidth: "300px" },
            body: (branch) =>
                branch?.cisRegisterDate
                    ? moment(branch?.cisRegisterDate).format("DD/MM/YYYY HH:mm")
                    : "",
            formattedExportValue: true,
           
        },
        {
            header: t("company.registerInCis"),
            sortable: true,
            filter: true,
            default:true,
            field: "registerInCis",
            filterType:"TRISTATECHECKBOX",
            dataType:"boolean",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t("common.errorDescription"),
            body: (row) =>row?.errorDescription?.length>0 ? <TooltipOverlay title={row?.errorDescription} ><div className='truncate overflow-hidden d-flex align-items-center' style={{maxLines:1,whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{row.errorDescription}</div></TooltipOverlay>:"",
            sortable: true,
            default:false,
            filter: false,
            field: "errorDescription",
            style: { minWidth: "200px", maxWidth: "250px" },
            format:(row)=>row?.errorDescription || ""
        },

    ];

    const [selectedColumns, setSelectedColumns] = usePersistedState(
        branchColumns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
        "branchesColumns"
    );
    const setDefaultBranch = (e, branchId) => {
        e.preventDefault();
        mainAxios
            .post("apiEndpoint/saveOrUpdate", {
                object: "SetDefaultBranch",
                nuis: company.nuis,
                operation: JSON.stringify({
                    id: branchId,
                })
            })
            .then((res) => {
                if (res?.data?.status == true) {
                    toast.success("Branch was set as default", {
                        containerId: "all_toast",
                    });
                    getBranches()
                    if (selectedCompany == company.id) {
                        getCompany({reload:true,reloadParameters:false})
                        getUser()
                    }
                }
            });
    };


    const branchActiveClass = (data) => {
        return {

            'branch-default': data?.default == true
        };

    };


    const getBuFromCis = () => {
        setLoadingBussiness(true)
        mainAxios.post("/endpoint/v3/getDataFromCis", {
            object: "GetBusinessUnits",
            nuis: company.nuis,
            company: company.id,
        }).then(res => {
            setLoadingBussiness(false)
            if (res.data.status) {
                getBranches({updatedFilters:filters,rowsPerPage:tableRows.rowsPerPage, currentPage:tableRows.currentPage})
                toast.success(t(['purchases.defaultAction']), {

                    containerId: "all_toast"
                })
            }
        })
            .catch(err => {
                setLoadingBussiness(false)
            })
    }

    const exportBranches = (
        <ExportCsvPrime
          data={branchesForCsv}
          columns={branchColumns}
          onClick={() => getBranches({ exportCsv: true })}
          excludedColumns={["actions"]}
        />
      )

      const headerButtonsLeft=(
        registerBranchesInCisActions
      )

    const headerButtonsRight = (
        <div className="branches-actions d-flex justify-content-end align-items-end mt-4">
            
            <div className='d-flex align-items-center btn btn-sm btn-outline-primary mr-3' onClick={() => { if (!loadingBussiness) getBuFromCis() }}>
                {loadingBussiness ? <Loader size={"xs"} /> : "Get Bussiness Units CIS"}
            </div>
            <Link
                to="#"
                onClick={() => setAddBranchComp({ show: true })}
                className="btn btn-sm btn-outline-primary"
            >

                {t("company.addBranch")}
            </Link>
        </div>
    )

    if (!branches) return <Loader />
    return (
        <div>
           <small className="ml-3">
            {exportBranches}
          </small>
            <Dialog
                header={t('company.editBranch')}
                visible={editBranchComp.show}
                onHide={() => setEditBranchComp((state) => ({ ...state, show: false }))}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                closable={true}
                dismissableMask={true}

            >
                <EditCompanyBranch
                    company={company}
                    branchId={editBranchComp.id}
                    onEditBranchChange={() => {
                        setEditBranchComp({ show: false, id: null })
                        getBranches({ updatedFilters: filters, currentPage: tableRows.currentPage, rowsPerPage: tableRows.rowsPerPage }); 
                        getCompany({ reloadParameters: false, reload: true })
                    }}
                />
            </Dialog>

            <Dialog
                header={t('company.addBranch')}
                visible={addtBranchComp.show}
                onHide={() => setAddBranchComp((state) => ({ ...state, show: false }))}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                closable={true}
                dismissableMask={true}
            >

                <AddCompanyBranch
                    companyId={company.id}
                    onAddbranch={() => {
                        getBranches()
                        setAddBranchComp({ show: false, id: null });
                        getCompany({ reloadParameters: false, reload: true })
                    }
                    }
                />

            </Dialog>

            <PrimeTable
                loading={loadingPage}
                stripedRows={false}
                reorderableColumns={true}
                rowClassName={branchActiveClass}
                filters={[filters, setFilters]}
                tableRows={[tableRows, setTableRows]}
                value={branches ?? []}
                columns={branchColumns}
                showFilters={true}
                filterDisplay={"row"}
                responsiveLayout="scroll"
                dataKey="id"
                scrollHeight="580px"
                clearFilters={false}
                showSelectColumns
                selectedColumns={[selectedColumns, setSelectedColumns]}
                headerButtonsRight={[1].includes(user?.role?.id) && headerButtonsRight}
                headerButtonsLeft={[1].includes(user?.role?.id) && headerButtonsLeft}
                onRemoteDataChange={(remoteData) => {
                    const updatedFilters = remoteData.filters ?? filters
                    const updatedTableData = remoteData.tableRows ?? tableRows
                    let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                    sortCriteria=sortCriteria?[sortCriteria]:null
                    getBranches({updatedFilters, rowsPerPage, currentPage,sortCriteria})
                }}
            >
                {[1].includes(user?.role?.id) &&  selectedRowsColumn}
            </PrimeTable>    
        </div>
    )
}



export default Branches
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { enUS, sq } from 'date-fns/locale';

const DateRange = ({
  fromDate,
  toDate,
  onChange,
  showDateMenu = true,
  disabled = false,
  dateInputStyle,
  showYearDropdown = true,
  customDateRanges = [], 
  ...props
}) => {
  const moment = require('moment');
  const [isOpen, setIsOpen] = useState(false);
  const { t,i18n } = useTranslation("translations");
  const DateInput = React.forwardRef((props, ref) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={`btn btn-light bg-white shadow-sm dropdown-toggle btn-sm btn-custom`}
        style={{ ...dateInputStyle }}
        onClick={props.onClick}
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="text-primary mr-2" />
        {props.children}
      </button>
    );
  });


  const defaultDateRanges = [
    {
      label: t("common.today"),
      value: 'today',
      onDateChange: () => {
        let startDate = moment().startOf('day')._d;
        startDate = new Date(startDate.setHours(2, 0, 0, 0));
        let endDate = moment().endOf('day')._d;
        return [startDate, endDate];
      },
    },
    {
      label: t("common.yesterday"),
      value: 'yesterday',
      onDateChange: () => {
        let startDate = moment().subtract(1, 'days')._d;
        startDate = new Date(startDate.setHours(2, 0, 0, 0));
        let endDate = moment(startDate).endOf('day')._d;
        return [startDate, endDate];
      },
    },
    {
      label: t("common.thisWeek"),
      value: 'thisWeek',
      onDateChange: () => {
        let startDate = moment().startOf('isoWeek')._d;
        let endDate = moment().endOf('day')._d;
        return [startDate, endDate];
      },
    },
    {
      label: t("common.lastWeek"),
      value: 'lastWeek',
      onDateChange: () => {
        let startDate = moment().subtract(1, 'weeks').startOf('isoWeek')._d;
        let endDate = moment().subtract(1, 'weeks').endOf('isoWeek')._d;
        return [startDate, endDate];
      },
    },
    {
      label: t("common.thisMonth"),
      value: 'thisMonth',
      onDateChange: () => {
        let startDate = moment().startOf('month')._d;
        let endDate = moment().endOf('day')._d;
        return [startDate, endDate];
      },
    },
    {
      label: t("common.lastMonth"),
      value: 'lastMonth',
      onDateChange: () => {
        let startDate = moment().subtract(1, 'months').startOf('month')._d;
        let endDate = moment().subtract(1, 'months').endOf('month')._d;
        return [startDate, endDate];
      },
    },
  ];

  const onClickOutside = () => {
    setIsOpen(false);
    if (fromDate && !toDate) {
      onChange([fromDate, fromDate]);
    }
  };

  const combinedDateRanges = defaultDateRanges.concat(customDateRanges);

  const handleRangeClick = (onDateChange) => {
    const [startDate, endDate] = onDateChange(); 
    onChange([startDate, endDate]); 
    setIsOpen(false);
  };

  return (
    <>
      <div className="react-date-picker">
        <ReactDatePicker
          locale={i18n.language == "alb" ?sq:enUS} 
          selectsRange={true}
          onInputClick={() => setIsOpen(true)}
          onClickOutside={onClickOutside}
          open={isOpen}
          showYearDropdown={showYearDropdown}
          startDate={fromDate}
          endDate={toDate}
          onChange={(dates) => {
            if (dates[0] && dates[1]) {
              setIsOpen(false);
            }
            const fromDate = dates[0]
              ? typeof dates[0] === 'number'
                ? dates[0]
                : new Date(dates[0].setHours(2, 0, 0, 0))
              : null;
            const toDate = dates[1]
              ? typeof dates[1] === 'number'
                ? dates[1]
                : new Date(dates[1].setHours(2, 0, 0, 0))
              : null;
            onChange([fromDate, toDate]);
          }}
          customInput={
            <DateInput>
              {fromDate ? moment(fromDate).format('DD/MM/YYYY') : 'Date'} -{' '}
              {toDate ? moment(toDate).format('DD/MM/YYYY') : 'Date'}
            </DateInput>
          }
          isClearable={true}
          {...props}
        >
          {isOpen && showDateMenu && (
            <div className="d-none d-md-flex list-group date-range-actions">
              {combinedDateRanges.map((range) => (
                <button
                  key={range.value}
                  onClick={() => handleRangeClick(range.onDateChange)} // Handles date range click
                  className="list-item"
                >
                  {range.label}
                </button>
              ))}
            </div>
          )}
        </ReactDatePicker>
      </div>
    </>
  );
};

export default DateRange;

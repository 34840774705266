import React from 'react';
import { Oval, Preloader, Puff } from 'react-preloader-icon';

const LoadingLayover = ({children, loading,...props}) => {
  if (!loading) return children;

  return (
    <div className='loading-layover'>
      <div className='layover'>
        <div className='loading-spinner'>
          <Preloader 
            use={Oval} 
            size={20} 
            strokeWidth={10} 
            strokeColor="#000" 
            duration={1000} 
            {...props}
          />
        </div>
      </div>
      {children}
    </div>
  );
}

export default LoadingLayover;

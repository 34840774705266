import React, { useEffect, useRef, useState } from "react";
import mainAxios from "../../../services/mainAxios";
import func from "../../../services/functions";

import { useTranslation } from "react-i18next";
import {
  AppContext,
  getStore,
  useAppContext,
  usePersistedState,
} from "../../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCashRegister,
  faCircleCheck,
  faCompress,
  faCreditCard,
  faExpand,
  faMoneyBillAlt,
  faMoneyBillWave,
  faMoneyBillWaveAlt,
  faMoneyCheck,
  faQuestionCircle,
  faRedo,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Loader from "../../Layout/Loader";
import invoiceActions from "../../../services/invoiceActions.json";
import invoiceStatuses from "../../../services/invoiceStatuses.json";
import { toast } from "react-toastify";
import PosDetails from "../../../components/MyPos/components/PosDetails";
import PopOver from "../../global/PopOver";
import Page404 from "../../Page404";
import { useLocation } from "react-router-dom";
import InvoiceHistoric from "../../CustomInvoices/WizardSteps/InvoiceHistoric";
import swal from "@sweetalert/with-react";
import OpenTcrData from "../../CashRegister/OpenTcrData";
import moment from "moment";
import FinalizeInvoice from "./FinalizeInvoice";
import { Dialog } from 'primereact/dialog';
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";



const bigDecimal = require("js-big-decimal");
const { round } = func;

const EditPosTransaction = (props) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany]
  } = useAppContext();
  const { t } = useTranslation("translations");
  const location = useLocation();
  const firstUpdate = React.useRef(true);
  const codeRef = useRef(null);

  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [persistedData, setPersistedData] = usePersistedState(
    { rate: "1", currency: "ALL" },
    "persistedData"
  );
  const [persistedStatePos, setPersistedStatePos] = usePersistedState(
    null,
    "persistedStatePos"
  );
  const [printInvoice, setPrintInvoice] = React.useState(
    location?.state?.printInvoice || false
  );
  const [searchValue, setSearchValue] = React.useState("");
  const [code, setCode] = useState("");
  const [tcrDataOperations, setTcrDataOperations] = useState([]);
  const [posState, setPosState] = React.useState(null);
  const [reloadCashDeposit, setReloadCashDeposit] = useState(false);
  const [zeroPriceItems, setZeroPriceItems] = useState({});
  const userCanDelete = [1, 2, 4].includes(user.role.id);
  const [cashRegisterTcrType,setCashRegisterTcrType]=useState({ actionType:"",isOpen:false })




  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const invoiceId = props.match.params.id;

  const branchesOptions=company.branches.map((branch)=>{
    return {
      label: branch.sellerName + " - " + branch.sellerAddress,
      value:  branch.sellerName + " - " + branch.sellerAddress,
      ...branch
    }
  })


    const tcrList = React.useMemo(() => 
    {
      let filteredBranch= company.branches.find(branch=>branch.businUnitCode==posState?.businessUnitCode)
      let tcrTypes=filteredBranch?.tcrTypes?.filter((tcrType)=>tcrType?.allowInvoice)
      let tcrList =tcrTypes?.map((tcr)=>{
        let sellerName=filteredBranch.sellerName
        return{
          label:tcr.tcrCode + "-" +sellerName ,
          value: tcr.tcrCode,
        }
      }) ?? []

      return tcrList.length>0 ?[{label:"Select",value:""}].concat(tcrList):tcrList
    },[invoiceId,posState?.businessUnitCode])

  if (!tcrList)
    tcrList = [
      {
        label: posState.tcrCode,
        value: posState.tcrCode,
      },
    ];
  const defaultCashRegisterData = {
    tcr: posState?.tcrCode,
    initialTotal: 0,
    initialAction: 0,
    withdrawTotal: 0,
    withdrawAction: 0,
    depositTotal: 0,
    depositAction: 0,
  };
  const [cashRegisterData, setCashRegisterData] = useState(
    defaultCashRegisterData
  );

  const typeOfPaymentMeansNonCash = [
    { label: t("invoice.transfer"), value: "31" },
    { label: t("invoice.account"), value: "42" },
    { label: t("invoice.other"), value: "ZZZ" },
  ];

  const typeOfPaymentMeansCash = [
    { label: "BANKNOTE", value: "10" },
    { label: "BANK CARD", value: "48" },
  ];

  const paymentMethodTypes = [
    { label: "Cash", value: "cash" },
    { label: "Non Cash", value: "noncash" },
  ];

  const currenciesPos = [
    { label: "Lek", value: "ALL" },
    { label: "US Dollar", value: "USD" },
    { label: "Euro", value: "EUR" },
  ];


  const [state, setState] = React.useState(
    location?.state?.state || {
      categories: null,
      activeCategory: persistedStatePos?.activeCategory ?? null,
      allItems: null,
      filteredItems: null,
    }
  );


  React.useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomInvoice",
        params: {
          id: invoiceId,
        },
      })
      .then((res) => {
        if (res?.data?.status) {
          const parsedInvoice = func.parseCustomInvoiceResponse(
            res.data.content[0]
          );
          setPosState(parsedInvoice);
          setPageLoading(false);
        }
      });
  }, [selectedCompany, props.match.params.id]);

  React.useEffect(() => {
    let allItems = location?.state?.state?.allItems;
    if (!allItems || allItems?.length == 0) {
      mainAxios
        .post("apiEndpoint/search", {
          object: "GetCompanyItems",
          value: "",
        })
        .then((res) => {
          if (res?.data?.status === true) {
            const items = res.data.content;

            const categories = items.reduce(
              (obj, item) => ({
                ...obj,
                [item.category.name]: obj[item.category.name]
                  ? obj[item.category.name] + 1
                  : 1,
              }),
              {}
            );

            setState((state) => ({
              ...state,
              allItems: items,
              filteredItems: items,
              categories,
            }));
          }
        });
    }
  }, [selectedCompany]);

  useEffect(() => {
    mainAxios
      .post("/apiEndpoint/reportOnCashDeposit", {
        object: "CashDepositRequest",
        params: {
          sendDateTime: moment().format("YYYY-MM-DD"),
          issuerNUIS: company.nuis,
        },
      })
      .then((res) => {
        setTcrDataOperations(res.data.content);
      });
  }, [reloadCashDeposit]);

  React.useEffect(() => {
    if (posState) {
      const {
        items,
        invoiceTotalWithoutVat,
        invoiceTotalVat,
        invoiceTotalWithVat,
        invoiceTotalDiscount,
        invoiceDiscountedTotalWithoutVat,
      } = func.calculateInvoiceData(posState);
      setPosState((invoice) => ({
        ...invoice,
        invoiceTotalWithoutVat,
        invoiceTotalVat,
        invoiceTotalWithVat,
        invoiceTotalDiscount,
        invoiceDiscountedTotalWithoutVat,
        items,
      }));
    }
  }, [
    ...(posState?.items
      ? posState?.items.map((item) => {
          return `${item?.quantity},${item.discountValue},${item.discountType},${item.priceWithVat}`;
        })
      : []),
    posState?.items?.length,
    posState?.discount,
  ]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    let filteredItems = [];
    if (searchValue.length === 0) {
      if (state.activeCategory == null) {
        filteredItems = state.allItems;
      } else if (state.activeCategory) {
        filteredItems = state.allItems.filter(
          (item) => item.category.name === state.activeCategory
        );
      }
    } else {
      filteredItems = state.allItems.filter((item) => {
        let itemName = item.name.toLowerCase();
        let searchVal = searchValue.toLowerCase();
        return (
          searchVal !== "" &&
          itemName.includes(searchVal) &&
          (item.category.name === state.activeCategory ||
            state.activeCategory === null)
        );
      });
    }
    setState((state) => ({
      ...state,
      filteredItems,
    }));
  }, [searchValue, state.activeCategory]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (code?.length < 4) {
      return;
    }

    const indicator = setTimeout(() => {
      state.allItems.forEach((item) => {
        if (item.code == code) {
          addRemoveItem(item);
          setCode("");
        }
      });
    }, 400);
    return () => {
      clearTimeout(indicator);
    };
  }, [code]);

  useEffect(() => {
    if (tcrDataOperations.length == 0) return;
    const result = Object.values(
      tcrDataOperations
        .filter((o) => o.tcr == posState?.tcrCode)
        .reduce((a, { tcr, ...props }) => {
          if (!a[tcr]) a[tcr] = Object.assign({}, { tcr, operations: [props] });
          else a[tcr].operations.push(props);
          return a;
        }, {})
    );
    if (result.length == 0) {
      setCashRegisterData(defaultCashRegisterData);
    }
    let initial = result[0]?.operations?.find(
      (el) => el.operation == "INITIAL"
    );
    let withdraw = result[0]?.operations?.find(
      (el) => el.operation == "WITHDRAW"
    );
    let deposit = result[0]?.operations?.find(
      (el) => el.operation == "DEPOSIT"
    );
    setCashRegisterData({
      tcr: posState?.tcrCode,
      initialTotal: initial?.total ?? 0,
      initialAction: initial?.actions ?? 0,
      depositTotal: deposit?.total ?? 0,
      depositAction: deposit?.actions ?? 0,
      withdrawTotal: withdraw?.total ?? 0,
      withdrawAction: withdraw?.actions ?? 0,
    });
  }, [posState?.tcrCode, tcrDataOperations]);

  const convertedValue = posState?.items?.reduce((sum, item) => {
    return bigDecimal.add(
      bigDecimal.add(
        round(bigDecimal.multiply(item.totalWithoutVat, posState?.rate), 2),
        round(
          bigDecimal.multiply(
            bigDecimal.multiply(item.totalWithoutVat, posState?.rate),
            bigDecimal.divide(item.vatCategory, 100)
          ),
          2
        )
      ),
      sum
    );
  }, 0);



  const approveInvoice = ({ invoiceState }) => {
    const { invoiceStartDate, invoiceEndDate } = invoiceState
    if (!invoiceEndDate && invoiceStartDate) {
     toast.error("Please complete end date", { containerId: "all_toast" });
    }
    else if (invoiceEndDate && !invoiceStartDate) {
      toast.error("Please complete Start Date", { containerId: "all_toast" });
    }else{
    setLoading(true);
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(invoiceState),
          action: invoiceActions[2],
          status: invoiceStatuses[2],
        },
        nuis: company.nuis,
      })
      .then((res) => {
        swal.close();
        if (res?.data?.status === true) {
          toast.success(t(["toast.successApprovalPOS"]), {
            containerId: "all_toast",
          });
          const parsedInvoice = func.parseCustomInvoiceResponse(
            res.data.content[0]
          );
          setPosState(parsedInvoice);
          setPersistedData((state) => ({
            ...state,
            rate: parsedInvoice.rate,
            currency: parsedInvoice.currency,
          }));
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  };

    const handleRefreshStatus=()=>{
    if (!posState.invoiceTotalVat.includes("-") || posState.type === "384") {
      setLoading(true);
      mainAxios
        .post("endpoint/v2.2/saveOrUpdate", {
          object: "RefreshStatus",
          content: {
            ...func.parseCustomInvoiceRequest(posState),
            action: invoiceActions[2],
            status: invoiceStatuses[2],
          },
          nuis: company.nuis,
        })
        .then((res) => {
          if (res?.data?.error) {
            toast.error(res?.data?.error.errorDescription, {
              containerId: "all_toast",
            });
            setPosState({
              ...posState,
              status: {
                ...posState.status,
                status: res.data.error.errorCode,
              },
              cisError: res?.data?.error.errorDescription,
              cisStatus: res.data.error.errorCode,
            });
          } else {
            if (res?.data?.status === true) {
              toast.success(t(["invoice.refreshedInvoice"]), {
                containerId: "all_toast",
              });
              setPosState(func.parseCustomInvoiceResponse(res.data.content[0]));
            }
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      toast.warning(t(["toast.invoice.approve.warning"]), {
        containerId: "all_toast",
      });
    }
  }

  const draftInvoice = ({ invoiceState }) => {
    setLoading(true);
    const { businessUnitCode, tcrCode, ...restPersistedData } = persistedData;
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          action: invoiceActions[0],
          status: invoiceStatuses[0],
          ...func.parseCustomInvoiceRequest(invoiceState),
          // ...restPersistedData
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.draftSaved"]), {
            containerId: "all_toast",
          });
          props.history.push({
            pathname: "/pos-transactions/edit/" + res.data.content[0].id,
            state: { state: { ...state }, printInvoice },
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        
      });
  }; 
  const handleDelete = () => {
    setLoading(true);
    mainAxios
      .post("endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(posState),
          action: invoiceActions[4],
          status: invoiceStatuses[3],
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.draftDeleted"]), {
            containerId: "all_toast",
          });
          props.history.push("/pos-transactions");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setLoading(true);
    const correctiveInvoice = JSON.parse(JSON.stringify(posState));
    delete correctiveInvoice["id"];
    delete correctiveInvoice["invoiceHistorics"];
    delete correctiveInvoice["internalId"];
    if(correctiveInvoice?.discount?.id) delete correctiveInvoice.discount.id

    
    correctiveInvoice.attachments = correctiveInvoice.attachments.map(
      (attachment) => {
        const { id, ...rest } = attachment;
        return rest;
      }
    );
    correctiveInvoice.items = correctiveInvoice.items.map((item) => {
      const { id, quantity, totalWithoutVat, totalWithVat, ...rest } = item;

      return {
        ...rest,
        quantity: -1 * quantity,
      };
    });

    mainAxios
      .post("/endpoint/v2.2/saveOrUpdate", {
        object: "CustomInvoice",
        content: {
          ...func.parseCustomInvoiceRequest(correctiveInvoice),
          issueDate:new Date(),
          dueDate: new Date(),
          action: invoiceActions[0],
          status: invoiceStatuses[0],
          iic: null,
          fic: null,
          opCode: user?.userCompanyOperator?.find(
            (uco) => parseInt(uco.company) === parseInt(selectedCompany)
          )?.operator,
          eic: null,
          opCode: user?.userCompanyOperator?.find(
            (uco) => parseInt(uco.company) === parseInt(selectedCompany)
          )?.operator,
          recordDate: null,
          recordUser: null,
          number: "",
          profileId: "P10",
          type: "384",
          correctiveInv: true,
          correctiveInvType: "CORRECTIVE",
          badDebtInv: false,
          reverseCharge: false,
          selfInvoice: false,
          sumInv: false,
          cisStatus: null,
          cisError: null,
          internalId: "",
          iic_ref: correctiveInvoice.iic,
          refInvIIC: correctiveInvoice.iic,
          refInvIssueDateTime: posState?.issueDate,
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success("Corrective POS Created!", {
            containerId: "all_toast",
          });
          props.history.push(
            "/pos-transactions/edit/" + res?.data?.content[0].id
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (category) => {
    setPersistedStatePos({
      ...persistedStatePos,
      activeCategory: category ?? null,
    });
    setState((state) => ({
      ...state,
      activeCategory: category ?? null,
    }));
  };

  const handleDiscountChange = (key, value, index) => {
    const {
      unitPrice,
      discountType: dt,
      discountValue: dv,
      vatCategory: vat,
    } = posState.items[index];

    const discountType = key === "discountType" ? value : dt;
    const discountValue = key === "discountValue" ? value : dv;
    let discount;
    let isNumOrEmptyStr = /^$|^[+-]?\d+(\.\d+)?$/.test(discountValue);
    if (!isNumOrEmptyStr) return;
    let count = func.countDecimals(discountValue);
    if (discountType === "Percentage") {
      if (count > 6) {
        return;
      }
      discount = bigDecimal.multiply(
        unitPrice,
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      if (count > 2) {
        return;
      }
      discount = discountValue;
    }

    const priceWithVat =
      vat == 0
        ? bigDecimal.subtract(unitPrice, discount)
        : bigDecimal.add(
            unitPrice,
            bigDecimal.multiply(unitPrice, bigDecimal.divide(vat, 100))
          );

    // bigDecimal.multiply(
    //   bigDecimal.subtract(unitPrice, discount),
    //   bigDecimal.add(1, bigDecimal.divide(vat, 100))
    // );

    setPosState((posState) => ({
      ...posState,
      items: [
        ...posState.items.slice(0, index),
        {
          ...posState.items[index],
          [key]: value,
          // priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
          priceWithVat: parseFloat(priceWithVat).toFixed(2),
        },
        ...posState.items.slice(index + 1),
      ],
    }));
  };

  const handlePriceWithVatChange = (e, item, index) => {
    let value = e.target.value;
    if (value.split(".")[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 2);
    }
    if (value.includes("-")) {
      value = value.split("-")[1] || "";
    }

    const {
      vatCategory: vat,
      discountValue,
      discountType,
    } = posState.items[index];
    const priceWithVat = new bigDecimal(value).getValue();
    const priceWithoutVat =
      vat == 0
        ? priceWithVat
        : bigDecimal.divide(
            priceWithVat,
            bigDecimal.add(1, bigDecimal.divide(vat, 100))
          );
    let discount;

    if (discountType === "Percentage") {
      discount = bigDecimal.multiply(
        bigDecimal.divide(
          priceWithoutVat,
          bigDecimal.subtract(1, bigDecimal.divide(discountValue, 100))
        ),
        bigDecimal.divide(discountValue, 100)
      );
    } else {
      discount = discountValue;
    }

    const unitPrice = bigDecimal.add(priceWithoutVat, discount);

    const updatedItems = posState.items.map((oldItem, oldIndex) =>
      oldIndex !== index
        ? oldItem
        : {
            ...item,
            unitPrice: parseFloat(unitPrice).toFixed(2),
            priceWithVat,
            priceWithoutVat: parseFloat(priceWithoutVat).toFixed(2),
          }
    );

    setPosState((posState) => ({
      ...posState,
      items: updatedItems,
    }));
  };

  const addRemoveItem = (item, quantity = "1", fixedQuantity = false) => {
    let isNumOrEmptyStr = /^$|^[+-]?\d+(\.\d+)?$/.test(quantity);
    if (
      !isNumOrEmptyStr ||
      (fixedQuantity && parseFloat(quantity) < 0) ||
      func.countDecimals(quantity) > 3
    ) {
      return;
    }
    let itemIndex = posState.items.findIndex(
      (currItem) => item.code == currItem.code
    );
    let newItem;
    if (itemIndex >= 0) {
      let item = posState.items[itemIndex];
      if (
        (!fixedQuantity &&
          (quantity == "1" ||
            (quantity == -"1" && parseFloat(item.quantity) > 1))) ||
        fixedQuantity
      ) {
        newItem = {
          ...item,
          quantity: fixedQuantity
            ? quantity
            : bigDecimal.add(item.quantity, quantity.toString()),
        };
        setPosState((posState) => ({
          ...posState,
          items: [
            ...posState.items.slice(0, itemIndex),
            newItem,
            ...posState.items.slice(itemIndex + 1),
          ],
        }));
      } else if (
        (quantity == -"1" && item.quantity == "1") ||
        quantity == "0"
      ) {
        let items = [...posState.items];
        items.splice(itemIndex, 1);
        setPosState((posState) => ({
          ...posState,
          items,
        }));
      }
    } else {
      newItem = {
        code: item.code,
        description: item.vatReason,
        discountType: "Percentage",
        discountValue: "",
        investment: item.investment,
        name: item.name,
        priceWithVat: item?.priceWvatAll?.toString() ?? "0",
        priceWithoutVat: item?.priceWovatAll?.toString() ?? "0",
        quantity: "1",
        totalWithVat: item?.priceWvatAll?.toString() ?? "0",
        totalWithoutVat: item?.priceWovatAll?.toString() ?? "0",
        unitOfMeasureId: item?.unitOfMeasureId,
        unitPrice: item?.priceWovatAll?.toString() ?? "0",
        vatCategory: item.vatCategory,
        vatLabel: null,
      };
      setPosState((posState) => ({
        ...posState,
        items: [...posState.items, newItem],
      }));
      if (newItem.priceWithoutVat == 0) {
        setZeroPriceItems((state) => ({
          ...state,
          [newItem.code]: true,
        }));
      }
    }
  };

  const handleChange = (key, value) => {
    setPosState((posState) => ({
      ...posState,
      [key]: value,
    }));
  };

  const handlePaymentMean = async (value, paymentMethodType) => {
    const { tcrCode, businessUnitCode } = posState

      // Check if payment method is "cash" and total invoice exceeds 500,000
      if (posState?.parties?.buyer?.buyerIDType !== "NUIS" && paymentMethodType === "cash" &&((posState.currency =="ALL" && (parseFloat(posState?.invoiceTotalWithVat) > 500000)) || (posState.currency == "EUR" && parseFloat(posState?.invoiceTotalWithVat) > 5000) )) {
        const confirmation = await Swal.fire({
        text: t("toast.amountExceedesAlert",{ amount: posState?.currency==="EUR"?"5000 EURO":"500000 LEK"}),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007567",
        confirmButtonText: t("OK"),
        cancelButtonText: t("invoice.backToInvoice"),
      });

    if (!confirmation.isConfirmed) {
      return;
    }
  }

    if (posState.items.length == 0) {
      toast.error("You need to chose at least one item.", {
        containerId: "all_toast",
      });
      return;
    }
    if (tcrCode?.length === 0) {
      toast.error(t(["toast.tcrCode"]), {
        containerId: "all_toast",
      });
      return;
    }
    if (businessUnitCode?.length === 0) {
      toast.error(t(["toast.bussinUnitCode"]), {
        containerId: "all_toast",
      });
      return;
    }
    if (
      posState?.parties?.buyer?.buyerIDType === "NUIS" &&
      posState?.paymentMethodType === "cash" &&
      posState?.invoiceTotalWithVat > 150000
    ) {
      toast.error(t(["toast.errorPos"]), {
        containerId: "all_toast",
      });
      return;
    }

    let itemPriceValidation = true;
    posState.items.forEach((item) => {
      if (item.priceWithVat == 0) {
        itemPriceValidation = false;
        return;
      }
    });
    if (!itemPriceValidation) {
      toast.error("Item price must not be 0", {
        containerId: "all_toast",
      });
      return;
    }

    swal(
      <FinalizeInvoice
        swal={swal}
        fullCompany={fullCompany}
        approveInvoice={approveInvoice}
        posState={{ ...posState, paymentMeans: value, paymentMethodType }}
        isEditPos={true}
        isCorrective={isCorrective}
      />,
      {
        className: "swal-pos",
        buttons: false,
      }
    );

    handleChange("paymentMeans", value);
    // approveInvoice({paymentMeans:value});
  };


  const { allItems, filteredItems, activeCategory, categories } = state;
  const paymentMeans = posState?.paymentMeans ?? "";
  const paymentMethodType = posState?.paymentMethodType ?? "";
  let disabled = false;
  let isDraft=true
  let retryInvoice=false
  let badgeClass = "secondary";
  let statusLabel = "Draft";
  let status=posState?.status?.status
  if (posState) {
    retryInvoice=(["T991","T920", "T999", "T101", "T8101"].includes(status) ||
    posState?.cisStatus === "TF991"||
    posState?.cisStatus === "TE991" ||
    posState?.cisStatus === "T991")


    disabled =
      posState?.status?.status === "APPROVED" || retryInvoice;



    if(status==="READY_FOR_APPROVAL"){
      badgeClass = "info";
      statusLabel = "Ready For Approval";
      isDraft=false
    }else if(status==="APPROVED"){
      badgeClass = "success";
      statusLabel = "Approved";
      isDraft=false
    }
  }

  const isCorrective=posState?.profileId ==="P10"
  return !pageLoading ? (
    posState ? (
      <div
        className={classnames({
          "full-screen": isFullScreen,
          "py-2": !isFullScreen,
          "pb-3": isFullScreen,
        })}
      >
        <div className="row my-pos">
          <div className="col-md-12 mr-2 d-flex">
            <PosDetails
              posState={posState}
              setPosState={setPosState}
              persistedData={persistedData}
              setPersistedData={setPersistedData}
              currenciesPos={currenciesPos}
              branchesOptions={branchesOptions}
              tcrList={tcrList}
              disabled={disabled}
              printInvoice={printInvoice}
              isEditPos={true}
            />
            </div>
            <ReactTooltip />
          <Dialog header={cashRegisterTcrType?.actionType} visible={cashRegisterTcrType?.isOpen} style={{ width: '45vw' }} onHide={() => setCashRegisterTcrType({ isOpen: false })}>
            {cashRegisterTcrType?.isOpen && <OpenTcrData
              onClose={() => { setCashRegisterTcrType(null) }}
              onChange={() => { setReloadCashDeposit((state) => !state) }}
              data={{
                ...cashRegisterData,
                actionType: cashRegisterTcrType?.actionType,
                businUnitCode: posState?.businessUnitCode,
              }}
            />}
          </Dialog>
          <div className="col-12">
            <div className="row">
              <div className="col-md-8">
                <div className="shadow border-0">
                  <div className="search-items d-flex justify-content-between align-items center">
                    <div className="col-9">
                      <input
                        type="text"
                        className="search-items-input"
                        placeholder="Search items..."
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                    {posState?.status?.status!="APPROVED" && <div className="col-3 d-flex align-items-center mr-5">
                      <input
                        type="text"
                        id="code"
                        name="code"
                        ref={codeRef}
                        className="form-control"
                        placeholder={t("item.scanCode")}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                        }}
                      />
                      <PopOver
                        customStyle={{
                          borderRadius: "40%",
                          width: "20px",
                          height: "20px",
                          transform: "scale(0.80)",
                          backgroundColor: "#343a40",
                          color: "white",
                        }}
                        title={t("hints.scanItem")}
                      />
                    </div>}
                    {posState?.status?.status=="APPROVED" && <h3 className="d-flex align-items-center m-0 mr-3">
                      <small className={`badge badge-${badgeClass} ml-2`} data-tip="Profisc Status">
                        {statusLabel}
                      </small>
                      <div className="d-none">
                        {/* {posState?.action?.action === "APPROVED" && <ThermalPrinterInvoiceContent invoice={invoice} />} */}
                      </div>
                      {!posState.cisStatus ? null : posState.cisStatus === "SUCCESS" ? (
                        <small className="badge badge-success ml-2" data-tip="Cis Status">Success</small>
                      ) : posState.cisError ? (
                        <small
                          class="badge badge-danger d-inline-flex align-items-center ml-2"
                          data-tip={posState.cisError}
                        >
                          Error
                          <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />
                        </small>
                      ) : (
                        <small class="badge badge-danger ml-2">Error</small>
                      )}
                    </h3>}
                  </div>
                  <div className="card card-body p-0">
                    {allItems !== null ? (
                      <div className="row">
                        <div className="col-md-3 p-0 fixed-content">
                          <div className="categories">
                            <button
                              className={classnames("category", {
                                active: activeCategory === null,
                              })}
                              onClick={() => handleCategoryChange(null)}
                            >
                              All
                              <span className="item-count">
                                {allItems.length}
                              </span>
                            </button>
                            {Object.entries(categories).map(
                              ([category, count], index) => (
                                <button
                                  key={index}
                                  disabled={disabled}
                                  className={classnames("category", {
                                    active: activeCategory === category,
                                  })}
                                  onClick={() => handleCategoryChange(category)}
                                >
                                  {category}
                                  <span className="item-count">{count}</span>
                                </button>
                              )
                            )}
                          </div>
                        </div>
                        <div className="col-md-9 p-0">
                          <div className="items fixed-content">
                            <div className="row pl-1 pt-1 m-0">
                              {filteredItems.map((item) => (
                                <div className="col-6 col-sm-4 col-md-3 p-0 pr-1 pb-1">
                                  <button
                                    disabled={disabled}
                                    key={item.id}
                                    className="item"
                                    onClick={() => addRemoveItem(item)}
                                  >
                                    <div className="text-left truncate">
                                      {item.name}
                                    </div>
                                    <div className="item-price">
                                      {item.priceWvatAll}
                                    </div>
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="card shadow border-0">
                  <div className="card-header bg-white font-weight-bold invoice-header">
                    <div className="">Invoice</div>
                    <div className="custom-control custom-checkbox mr-sm-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="printInvoice"
                        name="printInvoice"
                        checked={printInvoice}
                        onChange={(e) => setPrintInvoice(e.target.checked)}
                      />
                      <label
                        style={{ paddingTop: "3px", paddingLeft: "2px" }}
                        htmlFor="printInvoice"
                        className="custom-control-label"
                      >
                        Preview {"&"} Print Invoice
                      </label>
                    </div>
                    {posState?.items?.length > 0 ? (
                      <button
                        className={`btn btn-primary mr-3`}
                        style={{ minWidth: "100px" }}
                        disabled={[7].includes(user?.role?.id)}
                        onClick={() =>
                          props.history.push({
                            pathname: "/pos-transactions/create",
                            state: { state, printInvoice },
                          })
                        }
                      >
                        New Invoice
                      </button>
                    ) : (
                      <div />
                    )}
                  </div>
                  <div
                    className="d-flex justify-content-center card-header flex-wrap"
                    style={{ padding: "10px 0px" }}
                  >
                    <>
                      <div className="text-primary">Operator Code:</div>{" "}
                      <div className="d-flex ml-1 mr-3">
                        {" "}
                        {posState?.opCode}{" "}
                      </div>
                      <div className="text-primary">TCR:</div>{" "}
                      <div className="d-flex ml-1 mr-3">
                        {" "}
                        {posState?.tcrCode}{" "}
                      </div>
                      <div className="text-primary">Branches:</div>{" "}
                      <div className="d-flex ml-1">
                        {posState?.businessUnitCode}
                      </div>
                    </>
                  </div>

                  <div className="card-body p-0 mb-1">
                    <div className="total-items fixed-content">
                      {posState?.items?.length > 0 ? (
                        <div>
                          <div className="d-flex justify-content-center mb-2 px-4">
                            <div className="flex-4">{t("pos.item")}</div>
                            <div className="flex-4 ml-2">
                              {t("item.quantity")}
                            </div>
                            <div style={{ flex: 3 }}>Item Discount</div>
                            <div className="flex-4 text-right mr-3">
                              {t("pos.price")}
                            </div>
                          </div>
                          {posState.items.map((item, index) => (
                            <div
                              key={item.code}
                              className={`d-flex pos-item justify-content-between align-items-center my-1 px-4`}
                              >
                              <div className="flex-4">
                                <div className="item-name">{item.name}</div>
                                <div className="d-flex align-items-end flex-nowrap">
                                  <div
                                    className="d-flex align-self-center"
                                    style={{ maxWidth: 60 }}
                                  >
                                    <div>{item.quantity} </div>
                                    <div className="mx-1">x </div>
                                  </div>
                                  {!zeroPriceItems[item?.code] ? (
                                    <div className="mr-1">
                                      {func.formatNumber(item.priceWithVat)}
                                    </div>
                                  ) : (
                                    <div className="text-center mr-1 mt-1">
                                      <input
                                        style={{ height: 32, width: 80 }}
                                        type={"number"}
                                        min="0.01"
                                        name="priceWithVat"
                                        disabled={disabled}
                                        autoFocus={disabled}
                                        className="form-control text-center posPriceWithVat"
                                        placeholder="E.g. 100"
                                        value={item.priceWithVat}
                                        onChange={(e) =>
                                          handlePriceWithVatChange(
                                            e,
                                            item,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className="mr-2 align-self-center">
                                    {/* {persistedData.currency} */}LEK
                                  </div>
                                </div>
                              </div>
                              <div className="quantity buttons_added justify-content-start flex-4">
                                <input
                                  type="button"
                                  defaultValue="-"
                                  className="minus"
                                  disabled={disabled}
                                  style={{
                                    cursor: disabled ? "no-drop" : "pointer",
                                  }}
                                  onClick={() => addRemoveItem(item, -"1")}
                                />
                                <input
                                  type="number"
                                  value={item.quantity}
                                  step={1}
                                  name="quantity"
                                  title="Qty"
                                  className="input-text qty text"
                                  size={4}
                                  disabled={disabled}
                                  onChange={(e) =>
                                    addRemoveItem(item, e.target.value, true)
                                  }
                                />
                                <input
                                  type="button"
                                  defaultValue="+"
                                  className="plus"
                                  disabled={disabled}
                                  style={{
                                    cursor: disabled ? "no-drop" : "pointer",
                                  }}
                                  onClick={() => addRemoveItem(item, "1")}
                                />
                              </div>
                              <div className="input-group custom-discount flex-nowrap flex-4">
                                <input
                                  type="number"
                                  id="discount"
                                  name="discount"
                                  min={0}
                                  className="form-control"
                                  disabled={disabled}
                                  value={item.discountValue}
                                  onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleDiscountChange(
                                      "discountValue",
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    disabled={disabled}
                                    // data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{ zIndex: "1", cursor: "auto" }}
                                  >
                                    {item.discountType == "Percentage"
                                      ? "%"
                                      : "💰︎"}
                                  </button>
                                  <div className="dropdown-menu">
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                    disabled={disabled}

                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDiscountChange(
                                          "discountType",
                                          "Percentage",
                                          index
                                        );
                                      }}
                                    >
                                      {t("invoice.percentage")}
                                    </a>
                                    {/* <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleDiscountChange(
                                        "discountType",
                                        "Value",
                                        index
                                      );
                                    }}
                                  >
                                    {t("invoice.value")}
                                  </a> */}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end align-items-center flex-4">
                                <div className="total-item-price">
                                  {func.formatNumber(item.totalWithVat)}{" "}
                                  {posState.currency}
                                </div>
                                <FontAwesomeIcon
                                  className={`${
                                    disabled ? "text-muted" : "text-danger"
                                  } ml-2`}
                                  icon={faTimesCircle}
                                  size={"1x"}
                                  style={{
                                    cursor: disabled ? "no-drop" : "pointer",
                                  }}
                                  disabled={disabled}
                                  onClick={() => {
                                    if (disabled) return;
                                    addRemoveItem(item, 0);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <h4 className="text-center">No items added yet!</h4>
                      )}
                    </div>
                    <div className="invoice-totals">
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type">
                          {t("invoice.totalNoVat")}
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(
                            posState?.invoiceTotalWithoutVat
                          )}{" "}
                          {posState.currency}
                        </div>
                      </div>
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type">
                          {t("invoice.totalDiscount")}
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(
                            posState?.invoiceTotalDiscount
                          )}{" "}
                          {posState?.currency ?? 0}
                        </div>
                      </div>
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type">
                          {t("invoice.totalVat")}
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(
                            posState?.invoiceTotalVat
                          )}{" "}
                          {posState?.currency ?? 0}
                        </div>
                      </div>
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type">
                          {t("item.totalWithVat")}
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(
                            posState?.invoiceTotalWithVat ?? 0
                          )}{" "}
                          {posState?.currency ?? 0}
                        </div>
                      </div>
                      <div className="invoice-totals-group">
                        <div className="invoice-total-type font-weight-bold">
                          {t("invoice.totalPay")}({posState.currency})
                        </div>
                        <div className="invoice-total-value">
                          {func.getFormattedMoneyValue(
                            posState?.invoiceTotalWithVat ?? 0
                          )}{" "}
                          {posState.currency}
                        </div>
                      </div>
                      {posState.currency !== "ALL" && (
                        <div className="invoice-totals-group">
                          <div className="invoice-total-type font-weight-bold">
                            {t("invoice.totalPay")} (LEK)
                          </div>
                          <div className="invoice-total-value">
                            {func.getFormattedMoneyValue(convertedValue)} LEK
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <InvoiceHistoric invoiceState={posState} />
          </div>

          <div className="row justify-content-center payment-options mt-4 m-0 shadow">
              <div className="ml-md-auto column-5">
                <button
                  style={{ backgroundColor: "#b33771" }}
                  type="button"
                  className="btn posbutton payment-btn"
                  disabled={[7].includes(user?.role?.id)}
                  onClick={() => {
                    setCashRegisterTcrType({ actionType: "INITIAL", isOpen:true });
                  }}
                >
                  <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
                  CASH INITIAL
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ backgroundColor: "#4a1326" }}
                  type="button"
                  className="btn posbutton payment-btn"
                  disabled={[7].includes(user?.role?.id)}
                  onClick={() => {
                    setCashRegisterTcrType({ actionType: "DEPOSIT",isOpen:true });
                  }}
                >
                  <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
                  CASH DEPOSIT
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ backgroundColor: "#319398" }}
                  type="button"
                  disabled={[7].includes(user?.role?.id)}
                  className="btn posbutton payment-btn"
                  onClick={() => {
                    setCashRegisterTcrType({ actionType: "WITHDRAW",isOpen:true });
                  }}
                >
                  <FontAwesomeIcon icon={faCashRegister} className="mr-1" />
                  CASH WITHDRAW
                </button>
              </div>
              
              <div className="column-5">
                <button
                  style={{ backgroundColor: "#af3b3b" }}
                  type="button"
                  name="transfer"
                  onClick={() =>
                    handlePaymentMean(typeOfPaymentMeansNonCash[0].value, "noncash")
                  }
                  disabled={(!isCorrective && isDraft?retryInvoice:(disabled || paymentMeans !== typeOfPaymentMeansNonCash[0].value)) || [7].includes(user?.role?.id)
                  }
                  className={`btn posbutton payment-btn ${
                    paymentMeans == typeOfPaymentMeansNonCash[0].value &&
                    disabled
                      ? "clicked"
                      : ""
                  }`}
                >
                  {loading &&
                  paymentMeans == typeOfPaymentMeansNonCash[0].value ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faMoneyBillAlt} className="mr-1" />
                      {typeOfPaymentMeansNonCash[0].label.toUpperCase()}
                    </>
                  )}
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ backgroundColor: "#5f27cd" }}
                  type="button"
                  name={"account"}
                  onClick={() =>
                    handlePaymentMean(typeOfPaymentMeansNonCash[1].value, "noncash")
                  }
                  disabled={
                    (!isCorrective && isDraft?retryInvoice:(disabled || paymentMeans !== typeOfPaymentMeansNonCash[1].value)) || [7].includes(user?.role?.id)
                  }
                  className={`btn posbutton payment-btn ${
                    paymentMeans == typeOfPaymentMeansNonCash[1].value &&
                    disabled
                      ? "clicked"
                      : ""
                  }`}
                >
                  {loading &&
                  paymentMeans == typeOfPaymentMeansNonCash[1].value ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                      {typeOfPaymentMeansNonCash[1].label.toUpperCase()}
                    </>
                  )}
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ backgroundColor: "#607d8b" }}
                  type="button"
                  name={"other"}
                  onClick={() =>
                    handlePaymentMean(typeOfPaymentMeansNonCash[2].value, "noncash")
                  }
                  disabled={
                    (!isCorrective && isDraft?retryInvoice:
                    (disabled ||
                      paymentMeans !== typeOfPaymentMeansNonCash[2].value )) || [7].includes(user?.role?.id)
                  }
                  className={`btn posbutton payment-btn ${
                    paymentMeans == typeOfPaymentMeansNonCash[2].value &&
                    disabled
                      ? "clicked"
                      : ""
                  }`}
                >
                  {loading &&
                  paymentMeans == typeOfPaymentMeansNonCash[2].value ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                      {typeOfPaymentMeansNonCash[2].label.toUpperCase()}
                    </>
                  )}
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ background: "#0984e3" }}
                  type="button"
                  name="bankCard"
                  onClick={() =>
                    handlePaymentMean(typeOfPaymentMeansCash[1].value, "cash")
                  }
                  disabled={
                    (!isCorrective && isDraft?paymentMethodType == "noncash":(disabled || ( paymentMeans !== typeOfPaymentMeansCash[1].value ))) || [7].includes(user?.role?.id)
                  }
                  className={`btn posbutton payment-btn ${
                    paymentMeans == typeOfPaymentMeansCash[1].value && disabled
                      ? "clicked"
                      : ""
                  }`}
                >
                  {loading &&
                  paymentMeans == typeOfPaymentMeansCash[1].value ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faMoneyBillAlt} className="mr-1" />{" "}
                      {typeOfPaymentMeansCash[1].label.toUpperCase()}
                    </>
                  )}
                </button>
              </div>
              <div className="column-5">
                <button
                  style={{ background: "#007567" }}
                  type="button"
                  name="bankNote"
                  onClick={() =>
                    handlePaymentMean(typeOfPaymentMeansCash[0].value, "cash")
                  }
                  disabled={
                   ((!isCorrective && isDraft)?paymentMethodType == "noncash":( disabled || paymentMeans !== typeOfPaymentMeansCash[0].value )) || [7].includes(user?.role?.id)
                  }
                  className={`btn posbutton payment-btn ${
                    paymentMeans == typeOfPaymentMeansCash[0].value && disabled
                      ? "clicked"
                      : ""
                  }`}
                >
                  {loading &&
                  paymentMeans == typeOfPaymentMeansCash[0].value ? (
                    <Loader size="xs" color="white" />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faMoneyBillAlt} className="mr-1" />{" "}
                      {typeOfPaymentMeansCash[0].label.toUpperCase()}
                    </>
                  )}
                </button>
              </div>
              {(retryInvoice) && (
                <div className="column-5">
                  <button
                    style={{ backgroundColor: "#e28d02" }}
                    type="button"
                    disabled={[7].includes(user?.role?.id)}
                    className="btn posbutton"
                    onClick={() => handleRefreshStatus({ invoiceState: posState })}
                  >
                    <FontAwesomeIcon icon={faRedo} className="mr-1" />
                    RETRY
                  </button>
                </div>
              )}
              {disabled && !retryInvoice&& (
                <div className="column-5">
                  <button
                    style={{ backgroundColor: "red" }}
                    type="button"
                    disabled={[7].includes(user?.role?.id)}
                    className="btn posbutton"
                    onClick={handleCancel}
                  >
                    <FontAwesomeIcon icon={faBan} className="mr-1" /> Cancel
                  </button>
                </div>
              )}
              {(posState?.status?.status === "DRAFT" ||
                posState?.status?.status === "ERROR") && !retryInvoice && (
                <div className="column-5">
                  <button
                    style={{ backgroundColor: "#ed4c3c" }}
                    type="button"
                    className="btn posbutton"
                    onClick={handleDelete}
                    disabled={[7].includes(user?.role?.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-1" />{" "}
                    {t("common.delete")}
                  </button>
                </div>
              )}

            {isDraft && !retryInvoice && <div className="column-5 ml-auto">
              <button
                style={{ background: "#49cb8f", float: "right" }}
                type="button"
                name="bankNote"
                disabled={[7].includes(user?.role?.id)}
                onClick={() => (draftInvoice({ invoiceState: posState }))}
                className={`btn posbutton payment-btn`}
              >
                <FontAwesomeIcon icon={faCircleCheck} className="mr-1" />
                {t("common.save")}
              </button>
            </div>}
              <div className="ml-auto mt-3 column-5">
                <button
                  className="btn btn-info mr-4"
                  style={{ float: "right" }}
                  onClick={() =>
                    setIsFullScreen((isFullScreen) => !isFullScreen)
                  }
                >
                  {isFullScreen ? (
                    <FontAwesomeIcon icon={faCompress} />
                  ) : (
                    <FontAwesomeIcon icon={faExpand} />
                  )}
                </button>
              </div>
          </div>
        </div>
      </div>
    ) : (
      <Page404 />
    )
  ) : (
    <Loader />
  );
};

export default EditPosTransaction;

import React, { useEffect, useState } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import mainAxios from '../../services/mainAxios'
import func from '../../services/functions'
import Table from '../Table'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useOnScreen from '../global/hooks/useOnScreen'
import { useAppContext } from '../../AppContext'
import { DashboardCard } from '../global/CommonComponents'

const moment = require("moment")
const TopTenSuppliers = ({title, fromDate, toDate}) => {
const limit=10
const { t } = useTranslation("translations")
const [suppliers,setSuppliers]=useState([])
  const {
    selectedCompany: [selectedCompany],
  } = useAppContext();


  const topTenSuppliersRef = React.useRef()
  const isVisible = useOnScreen(topTenSuppliersRef, [selectedCompany])

useEffect(() => {
  if (!isVisible) return
  mainAxios.post('/endpoint/v2/reports/suppliers', {
    fromDate,
    toDate,
    params:{
      fiscInvoiceType:"NONCASH",
      limit
    },   
  }).then(res => {
    if (res?.data?.status && res.data.content) {
        setSuppliers(res?.data?.content)
      }
  })
}, [fromDate, toDate, isVisible])

const columns=[
  {
    name:"  ",
    width: "2%",
    cell: suppliers => (
      <FontAwesomeIcon
          icon={faBuilding}
          size={'lg'}
          className={`ml-2`}
          color={"#1cbb8c"}
      />)
    },
    
    {
      name: t(["topten.supplierName"]),
      selector: "sellerName",
      width:"30%"
      
    },
    {
      name: t(["topten.sellerNuis"]),
      selector: "sellerNuis",
      width:"20%"
    },
    {
      name: t(["topten.salesNo"]),
      selector: "salesNo",
      width: "15%",
    },
    {
      name: t(["topten.salesTot"]),
      selector: "salesTot",
      format: suppliers => func.getFormattedMoneyValue(suppliers.salesTot),
      style:{
        color: "green",
        fontWeight: "630",
      },
      width:"17%"
    }
]
  
  return (
    <DashboardCard ref={topTenSuppliersRef} title={title}>
      <div className="col-lg-12">
        <Table
        columns={columns}
        data={suppliers}
        disablePagination={true}
        customStyles={{
          cells: {
            style: {
              fontSize:"12px",
            },
        },
        }}
        />

      </div>
    </DashboardCard>

  )
}

export default memo(TopTenSuppliers)
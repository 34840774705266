import React, { useState } from "react";
//import unitsOfMeasureAlb from "../../../services/unitOfMeasureAlb.json";
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle, { uomStyle } from "../../../design/selectStyle";
import Loader from "../../Layout/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import mainAxios from "../../../services/mainAxios";
import { useAppContext } from "../../../AppContext";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import PopOver from "../../global/PopOver";
import ReactSelectPagination from "../../global/ReactSelectPagination";

const bigDecimal = require("js-big-decimal");

export default function AddItem({ onClose }) {
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);
  const history = useHistory();
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
    unitsOfMeasure,getUnitOfmeasure,
    uomPagination,
    setUomPagination
  } = useAppContext();



  const [categoryItems, setCategoryItems] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const { t, i18n } = useTranslation("translations");
  

  const unitOfMeasureLabel = (unitOfMeasure) => {
    return i18n.language == "alb"
      ? unitOfMeasure?.albanianName
      : unitOfMeasure?.englishName;
  };

  const unitOfMeasureCellConfig={
    type: "REACT-SELECT",
    options: unitsOfMeasure,
    getOptionLabel:(uom)=>uom?.code + '-' + unitOfMeasureLabel(uom),
    getOptionValue:(option)=>option.id,
    optionValue:"id",
    placeholder:t("item.measure"),
    menuPosition:"fixed",
    isClearable:false,
    getFilteredResults:getUnitOfmeasure,
    pagination:uomPagination,
    setPagination:setUomPagination,
    }

  const defaultProduct = {
    name: "",
    code: "",
    description: "",
    investment: false,
    unitOfMeasure:"",
    unitOfMeasureId: null,
    category: "",
    vatCategory: "20",
    vatReason: "",
    priceWvatAll: "",
    priceWovatAll: "",
    priceWvatEur: "",
    priceWovatEur: "",
    priceWvatUsd:"",
    priceWovatUsd:"",
    company: selectedCompany,
  };

  const [product, setProduct] = useState(defaultProduct);
  

  React.useEffect(()=>{
    let unitsOfMeasureDefault =
    unitsOfMeasure?.find((unitsOfMeasure) => unitsOfMeasure?.code === "C62") ?? null;
    if(product?.unitOfMeasureId==null){
      setProduct((product)=>({
        ...product,
        unitOfMeasure:unitsOfMeasureDefault.code,
        unitOfMeasureId:unitsOfMeasureDefault
      }))
    }

  },[unitsOfMeasure])

  const [errors, setErrors] = useState({
    name: null,
    code: null,
    priceWvatAll: null,
    priceWovatAll:null
  });

  const typeOfVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];


  const handleChange = (e, selectKey, selectValue, length,number=true) => {
    const key = e ? e.target.name : selectKey;
    let value = e ? e.target.value : selectValue;
    if (number==true && value?.split(".")?.[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 10);
      value = new bigDecimal(value).getValue();
    }
    
    value = length != undefined ? value.slice(0, length) : value;
    if (errors[key]) {
      setErrors((prevState) => {
        return { ...prevState, [key]: null };
      });
    }
    if (key === "priceWovatAll" || key==="priceWovatEur" || key==="priceWovatUsd") {
      const { vatCategory } = product ?? 0;
      let keyToUpdate="priceWvatAll"
      if(key=="priceWovatEur"){
        keyToUpdate="priceWvatEur"
      }else if(key=="priceWovatUsd"){
        keyToUpdate="priceWvatUsd"
      }

      let priceWithVat =
        vatCategory == 0
          ? value
          : bigDecimal.multiply(
              value,
              bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
            );
      if (priceWithVat?.split(".")?.[1]) {
        priceWithVat = priceWithVat?.split(".")
        let trimmedValue = priceWithVat?.[1]?.replace(/0+$/, '')?.slice(0, 10) ?? ""

        priceWithVat = priceWithVat[0] + (trimmedValue?.length > 0 ? ("." + trimmedValue) : "")

      }    

        
      setProduct((product) => ({
        ...product,
        [keyToUpdate]:priceWithVat,
      }));
    }

    if (key === "priceWvatAll" || key=="priceWvatEur" || key=="priceWvatUsd" ) {
      const { vatCategory } = product;

      let keyToUpdate="priceWovatAll"
      if(key=="priceWvatEur"){
        keyToUpdate="priceWovatEur"
      }else if(key=="priceWvatUsd"){
        keyToUpdate="priceWovatUsd"
      }

      let priceWIthoutVat =
        vatCategory == 0
          ? value
          : bigDecimal.divide(
              value,
              bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
            );
      

      if (priceWIthoutVat?.split(".")?.[1]) {
        priceWIthoutVat = priceWIthoutVat?.split(".")
        let trimmedValue = priceWIthoutVat?.[1]?.replace(/0+$/, '')?.slice(0, 10) ?? ""

        priceWIthoutVat = priceWIthoutVat[0] +( trimmedValue?.length > 0 ? ("." + trimmedValue) : "")

      }    

      setProduct((product) => ({
        ...product,
        [keyToUpdate]: priceWIthoutVat,
      }));
    }
    setProduct((product) => ({
      ...product,
      [key]: value,
    }));
  };

  const investementOptions = [
    { label: "False", value: false },
    { label: "True", value: true },
  ];

  const vatTypeOptions = [
    { label: t("vat.none"), value: "" },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];

  const handleVatCategoryChange = (value) => {
    const vatCategory = value.value;
    const priceWvatAll =
      vatCategory == 0
        ? product.priceWovatAll
        : bigDecimal.multiply(
            product?.priceWovatAll,
            bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
          );
    const priceWvatEur =
      vatCategory == 0
        ? product.priceWovatEur
        : bigDecimal.multiply(
            product?.priceWovatEur,
            bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
          );
    const priceWvatUsd =
      vatCategory == 0
        ? product.priceWovatUsd
        : bigDecimal.multiply(
            product?.priceWovatUsd,
            bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
          );
    setProduct((product)=>({
      ...product,
      vatCategory: vatCategory,
      priceWvatAll: priceWvatAll != "" ? parseFloat(priceWvatAll).toFixed(2) : priceWvatAll,
      priceWvatEur: priceWvatEur != "" ? parseFloat(priceWvatEur).toFixed(2) : priceWvatEur,
      priceWvatUsd: priceWvatUsd != "" ? parseFloat(priceWvatUsd).toFixed(2) : priceWvatUsd,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, code, priceWovatAll,unitOfMeasureId,priceWovatEur ,priceWovatUsd} = product;
    let checkError = !name || !code  || (!priceWovatAll && !priceWovatEur && !priceWovatUsd);
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: t("toast.itemNameError") };
      });
    }
    if (!code) {
      setErrors((errors) => {
        return { ...errors, code: t("toast.itemCodeError") };
      });
    }
    if(!unitOfMeasureId){
      setErrors((errors) => {
        return { ...errors, unitOfMeasureId: t("toast.itemCodeError") };
      });
    }
    if (!priceWovatAll && !priceWovatEur && !priceWovatUsd) {
      setErrors((errors) => {
        return { ...errors, priceWovatAll: t("item.noItemPrice")};
      });
    }
    if (checkError) {
      setLoading(false);

      return;
    }
    setLoading(true);
    
    let {label,value,...category}=product.category
    if(category===""){
      toast.success("Category is Required", {
        containerId: "all_toast",
      });
      return
    }
    mainAxios
      .post("/apiEndpoint/saveOrUpdate", {
        object: "CompanyItems",
        content: {
          ...product,
          category
          },
        nuis: user.companiesRights.find(
          (data) => data.id === parseInt(selectedCompany)
        )?.nuis,
        company: selectedCompany,
        type: "basic",
      })
      .then((res) => {

        if (res?.data?.status === true) {
          toast.success(t(["toast.successAddItems"]), {
            containerId: "all_toast",
          });
          // history.push("/items");
          setProduct(defaultProduct);
          onClose();
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred! Please try again later.");
      });
  };

  React.useEffect(() => {
    setLoadingCategories(true);
    mainAxios
      .post("/apiEndpoint/search", {
        object: "GetCategories",
        nuis: user.companiesRights.find(
          (data) => data.id === parseInt(selectedCompany)
        )?.nuis,
        company: selectedCompany,
        type: "basic",
      })
      .then((res) => {
        setLoadingCategories(false);
        if (res?.data?.status === true) {
          let categoryOptions = res?.data?.content.map((categoryObj) => {
            return {
              ...categoryObj,
              value: categoryObj.id,
              label: categoryObj.name,
            };
          });
          if (categoryOptions.length > 0) {
            setCategoryItems(categoryOptions);
            setTimeout(()=>{
              setProduct((product) => ({
                ...product,
                category: categoryOptions[0],
              }));
            },300)
          }
        }
      })
      .catch((error) => {
        setLoadingCategories(false);
        toast.error("An error occurred! Please try again later.");
      });
  }, []);
  //
  return (
    <div className="row">
      <form
        className="col-xl-12 col-lg-8 col-md-8 col-sm-10 col-12 mx-auto"
        onSubmit={handleSubmit}
      >
        <div>
          <div className=" d-flex align-items-center border-0">
            {categoryItems.length == 0 && !loadingCategories && (
              <div
                className="alert alert-warning mb-0 px-3 py-2 ml-3"
                role="alert"
              >
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                {t("item.categoryMessage")}
                <Link
                  className="btn btn-sm btn-outline-primary ml-2"
                  to="/items-category/add"
                >
                  {" "}
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  {t("itemsCategory.addCategory")}
                </Link>
              </div>
            )}
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_code">{t("item.code")}</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    value={product.code}
                    name="code"
                    className={`form-control ${
                      errors.code ? "is-invalid" : ""
                    } `}
                    onChange={(e) => handleChange("", "code", e.target.value, 50, false)}
                    required
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                  {errors.code && (
                    <div className="invalid-feedback">
                      {t("toast.itemCodeError")}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group input-length-validation">
                  <label htmlFor="name">{t("item.name")}</label>
                  <span className="text-danger"> *</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    } `}
                    value={product?.name}
                    name="name"
                    id="name"
                    required
                    onChange={(e) =>
                      handleChange("", "name", e.target.value, 50,false)
                    }
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                  <div
                    className={`length-text ${
                      product?.name?.length == 50 ? "text-danger" : ""
                    }`}
                  >
                    {product?.name?.length ??
                      0}
                    /50
                  </div>
                  {errors.name && (
                    <div className="invalid-feedback">
                      {t("toast.itemNameError")}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_description">
                    {t("item.description")}
                  </label>
                  <PopOver
                    customStyle={{ borderRadius: "40%", width: "20px", height: "20px", transform: "scale(0.80)", backgroundColor: "#343a40", color: "white" }}
                    title={t('hints.descItem')}
              />
                  <input
                    type="text"
                    value={product.description}
                    name="description"
                    className="form-control"
                    onChange={(e) => handleChange(null,"description",e.target.value,null,false)}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_investment">
                    {t("item.investment")}
                  </label>
                  <span className="text-danger"> *</span>
                  <Select
                    styles={selectStyle}
                    name="investment"
                    required
                    value={investementOptions.find(
                      (el) => el.value === product.investment
                    )}
                    options={investementOptions}
                    onChange={(option) => {
                      handleChange(null, "investment", option.value,null,false);
                    }}
                    isDisabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_measure">{t("item.measure")}</label>
                  <span className="text-danger"> *</span>
                  <ReactSelectPagination
                    styles={uomStyle}
                    value={product?.unitOfMeasureId}
                    isDisabled={categoryItems.length === 0 ? true : false}
                    onChange={(value) => {
                      setProduct((product)=>({...product,unitOfMeasure:value?.code,
                        unitOfMeasureId:value}))
                    }}
                    {...unitOfMeasureCellConfig}

                      />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_category">{t("item.category")}</label>
                  <span className="text-danger"> *</span>
                  <Select
                    styles={selectStyle}
                    options={categoryItems}
                    value={categoryItems.find(
                      (category) => category.id == product?.category?.value
                    )}
                    name="category"
                    required
                    onChange={(option) => {
                      handleChange(null, "category", option,null,false);
                    }}
                    isDisabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_vatCategory">
                    {t("item.vatCategory")}
                  </label>
                  <Select
                    styles={selectStyle}
                    type="text"
                    id="vatCategory"
                    name="vatCategory"
                    options={typeOfVatCategoryOptions}
                    value={
                      typeOfVatCategoryOptions.find(
                        (VatC) => VatC.value === product.vatCategory
                      ) || null
                    }
                    onChange={(value) => handleVatCategoryChange(value)}
                    isDisabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                {(
                  <div className="form-group select-h-m">
                    <label htmlFor="vatLabel">
                      {t("item.vatLabel")}
                      <span className="text-danger"> *</span>
                      <PopOver title={t("hints.itemVatLabel")} />
                    </label>
                    <Select
                      styles={selectStyle}
                      type="text"
                      id="vatReason"
                      name="vatReason"
                      options={vatTypeOptions}
                      value={
                        vatTypeOptions.find(
                          (VatC) => VatC.value === product.vatReason
                        ) || ""
                      }
                      onChange={(reason) =>
                        handleChange(null, "vatReason", reason.value,null,false)
                      }
                      isDisabled={product.vatCategory !== "0"}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalLekWithoutVat">
                    {t("item.totalLekWithoutVat")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="number"
                    value={
                       product.priceWovatAll
                      }
                    name="priceWovatAll"
                    className={`form-control ${
                      errors.priceWovatAll ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                  {errors.priceWovatAll && (
                    <div className="invalid-feedback">
                      {t("toast.noItemPrice")}
                    </div>
                  )}
                </div>

              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalWithVat">
                    {t("item.totalLekWithVat")}
                  </label>
                  <input
                    type="number"
                    value={product?.priceWvatAll}
                    name="priceWvatAll"
                    className="form-control"
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalEuroWithoutVat">
                    {t("item.totalEuroWithoutVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWovatEur}
                    name="priceWovatEur"
                    className={`form-control`}
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalEuroWithVat">
                    {t("item.totalEuroWithVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWvatEur}
                    name="priceWvatEur"
                    className="form-control"
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalDollarWithoutVat">
                    {t("item.totalDollarWithoutVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWovatUsd}
                    name="priceWovatUsd"
                    className={`form-control`}
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalDollarWithVat">
                    {t("item.totalDollarWithVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWvatUsd}
                    name="priceWvatUsd"
                    className="form-control"
                    onChange={handleChange}
                    disabled={categoryItems.length === 0 ? true : false}
                  />
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary mt-2 "
              disabled={loading || (categoryItems.length === 0 ? true : false)}
              style={{ minWidth: "100px" }}
              type="submit"
              onClick={handleSubmit}
            >
              {loading ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  {t("navbar.create")}
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useState } from "react";
import { useAppContext } from "../../../AppContext";
import mainAxios from "../../../services/mainAxios";
import Select from "react-select";
import selectStyle from "../../../design/selectStyle";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../../Layout/Loader";
import { faCheck,faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countries from "../../../services/countries.json";
import {useHistory} from 'react-router-dom';
import func from "../../../services/functions";
import { buyerIDTypes } from "../../CustomInvoices/WizardSteps/data";
import PopOver from "../../global/PopOver";


export default function CreateCustomer({swal,isPos=false,invoiceState,onCustomerUpdate,setAddBuyer,fromInvoice,setInvoiceState,...props}) {

  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
  } = useAppContext();

  const { t, i18n } = useTranslation("translations");
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const customerCleared = {
    name: "",
    email: "",
    tin: "",
    address: "",
    town: "",
    country: "ALB",
    type: "1",
    defaultCustomer: false,
    company: selectedCompany,
  };

  const tinTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];

  const [customer, setCustomer] = useState(customerCleared);

  const [errors, setErrors] = useState({
    name: null,
    tin: null,
    email: null,
    address: null,
    town: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(errors[name]){
      setErrors(prevState=>{return {...prevState,[name]:null}})
    }
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, tin, address, town, } = customer;
    let checkNiptFormat = new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(customer.tin);
    let checkError = !name || !tin || !address || !town || (!checkNiptFormat && customer.type == tinTypes[0].value);
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: "toast.customerNameError" };
      });
    }
    if (customer.type == tinTypes[0].value && !checkNiptFormat) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.incorrectNiptForCustomer" };
      });
    }
    if (!tin) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.customerTinError" };
      });
    }
    if (!address) {
      setErrors((errors) => {
        return { ...errors, address: "toast.customerAddressError" };
      });
    }
    if (!town) {
      setErrors((errors) => {
        return { ...errors, town: "toast.customerTownError" };
      });
    }
    if (checkError) {
      setLoading(false);
      return;
    }
    if(props.onCustomerUpdate){
      props.onCustomerUpdate(customer)
      if(invoiceState) return 
    }
    setLoading(true);

    if(customer.tin) customer.tin = customer.tin.trim()

    mainAxios
      .post("/apiEndpoint/saveOrUpdate", {
        object: "Customers",
        content: customer,
        nuis: user.companiesRights.find(
          (data) => data.id === parseInt(selectedCompany)
        )?.nuis,
        company: selectedCompany,
        type: "basic",
      })
      .then((res) => {
        setLoading(false);
        //if res.status === true
        if (res?.data?.status === true) {
          //notify user success
          toast.success("Customer created Succesfully!", {
            containerId: "all_toast",
          });
          //clear all fields of customer
           if(onCustomerUpdate) onCustomerUpdate(res.data.content[0])
          setCustomer(customerCleared);
          if (!onCustomerUpdate) {
            return history.replace("/customers");
          }
        }
      })
      .catch(err=>setLoading(false))
  };

  const createOnInvoice = () => {
    let { name, tin,  address, town, } = customer;
    tin =tin!=null? tin.trim():tin

    let checkNiptFormat=new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(tin);
    let checkError=!name || !tin ||  !address || !town || (!checkNiptFormat && customer.type==tinTypes[0].value);
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: "toast.customerNameError" };
      });
    }

    if (customer.type==tinTypes[0].value && !checkNiptFormat ) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.incorrectNiptForCustomer" };
      });
    }
    if (!tin) {
      setErrors((errors) => {
        return { ...errors, tin: "toast.customerTinError" };
      });
    }

    if(!address){
      setErrors((errors) => {
        return { ...errors, address: "toast.customerAddressError" };
      });
    }

    if(!town){
      setErrors((errors) => {
        return { ...errors, town: "toast.customerTownError" };
      });
    }

    if(checkError){
      setLoading(false);

      return;
    }
    let type = customer?.type
    ? buyerIDTypes.find((type) => type.value == customer.type).label
    : "";
    setInvoiceState((invoiceState)=>({
      ...invoiceState,
      parties: {
        ...invoiceState.parties,
        buyer:{
          ...invoiceState.parties.buyer,
          tin: tin ?? "",
              name: customer?.name ?? "",
              address: customer?.address ?? "",
              country: func.getFullCountry(customer?.country, false),
              town: customer?.town ?? "",
              email: customer?.email ?? null,
              buyerIDType: invoiceState.einvoice
                ? buyerIDTypes[0].label
                : !invoiceState?.einvoice && customer?.type
                  ? type
                  : buyerIDTypes[1].label,
        }
      }
    }))
    setAddBuyer(false)
  }
  const typeDisabled = fromInvoice?(invoiceState?.einvoice):false

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="customer_name">{t("customers.name")}</label>
            <span className="text-danger"> *</span>
            <input
              type="text"
              className={`form-control ${errors.name ? "is-invalid" : ""}`}
              name="name"
              value={customer.name}
              onChange={handleChange}
            />

            {errors.name && (
              <div className="invalid-feedback">{t(errors.name)}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="customer_email">{t("email.email")}</label>
            <input
              type="text"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              name="email"
              value={customer.email}
              onChange={handleChange}
            />

            {errors.email && (
              <div className="invalid-feedback">{t(errors.email)}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
          <label>
            {t("customers.identificationType")}
            {typeDisabled && <PopOver title={t("invoice.identificationType")} />}
          </label>
            <Select
              styles={selectStyle}
              name="type"
              options={tinTypes}
              value={tinTypes.find((type) => type.value === customer.type)}
              onChange={(type) =>
                setCustomer({ ...customer, type: type.value })
              }
              isDisabled={typeDisabled}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="customer_tin">{tinTypes.find((type) => type.value === customer.type)?.label ?? "NUIS"}</label>
            <span className="text-danger"> *</span>
            <input
              type="text"
              className={`form-control ${errors.tin ? "is-invalid" : ""}`}
              name="tin"
              value={customer.tin}
              onChange={handleChange}
            />

            {errors.tin && (
              <div className="invalid-feedback">{t(errors.tin)}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="customer_address">
              {t("customers.address")}
            </label>
            <span className="text-danger"> *</span>
            <textarea
              type="text"
              rows={1}
              className={`form-control ${errors.address ? "is-invalid" : ""
                }`}
              name="address"
              value={customer.address}
              onChange={handleChange}
            />
            {errors.address && (
              <div className="invalid-feedback">{t(errors.address)}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="customer_town">{t("customers.town")}</label>
            <span className="text-danger"> *</span>
            <input
              type="text"
              className={`form-control ${errors.town ? "is-invalid" : ""
                }`}
              name="town"
              value={customer.town}
              onChange={handleChange}
            />
            {errors.town && (
              <div className="invalid-feedback">{t(errors.town)}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group" style={{ position: 'relative', zIndex: 1 }}>
            <label htmlFor="customer_country">{t("customers.country")}</label>
            <span className="text-danger"> *</span>
            <Select
              styles={{ ...selectStyle, menu: (provided) => ({ ...provided, zIndex: 2 }) }}
              name="country"
              options={countries}
              value={
                countries.find((c) => c.shortCode === customer?.country) ?? ""
              }
              menuPosition="fixed"
              onChange={(value) =>
                setCustomer({ ...customer, country: value.shortCode })
              }
              defaultValue={countries.find((c) => c.label === "Albania")}
              required
            />
          </div>
        </div>
        {!fromInvoice && (

          <div className="col-md-12" style={{ marginTop: "35px", }}>
            <div className="form-group custom-control custom-checkbox mr-sm-2" style={{zIndex:0}}>
              <input
                type="checkbox"
                className="custom-control-input"
                id="defaultCustomer"
                name="defaultCustomer"
                checked={customer?.defaultCustomer}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    defaultCustomer: e.target.checked,
                  })
                }
              />
              <label
                style={{ paddingTop: "3px", paddingLeft: "2px" }}
                htmlFor="defaultCustomer"
                className="custom-control-label"
              >
                {t("customers.default")}
              </label>
            </div>
          </div>)
        }
      </div>  
      {
      fromInvoice && <button className="btn btn-outline-primary mr-2" onClick={createOnInvoice}>
    {loading ? (
      <Loader size="xs" color="white" />
    ) : (
      <>
      <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
        {t("invoice.saveInIvoice")}
      </>
    )}
  </button>
  }
  <button className="btn btn-primary" onClick={handleSubmit}>
    {loading ? (
      <Loader size="xs" color="white" />
    ) : (
      <>
        <FontAwesomeIcon icon={faCheck} className="mr-2" />
        {t("invoice.saveInSystem")}
      </>
    )}
  </button>
    </>
  );
}

import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import Table from "../../Table";
import "../../../design/tableUser.css";
import { mainAxios } from "../../../services/mainAxios";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../../AppContext";
import { useTranslation, withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Accordion from "../../CustomInvoices/WizardSteps/Accordion";
var _ = require("lodash");

const UsersTable = ({ state, setState,refreshUser }) => {
  const { t, i18n } = useTranslation("translations");
  const [loadingTcr, setLoadingTcr] = useState(false)
  const { user: [loggedInUser], selectedCompany: [selectedCompany], getUser } = useAppContext()
  const [tableRows, setTableRows] = useState({
    totalRows: 10,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [showOnlyWithTcrRights, setShowOnlyWithTcrRights] = useState(false);

  let companiesForTable = state?.companiesForTable
  let userCompaniesRights = state?.user?.companiesRights

  const getTcrRights = useCallback((user) => {
    let selectedUserCompaniesRights=user?.companiesRights ?? userCompaniesRights
    let rights = []
    selectedUserCompaniesRights.forEach((company) => {
      company.branches.forEach((branch) => {
        branch.tcrTypes.forEach((tcr) => {
          rights.push(tcr.id)
        })
      })
    })
    return rights
  }, [companiesForTable, userCompaniesRights])

  const isLoggedInUser = state?.user?.id == loggedInUser?.id
  const [tcrRights, setTcrRights] = useState(getTcrRights())

  useEffect(()=>{
    setTcrRights(getTcrRights())
  },[state?.user?.companiesRights])
  
  const updateUserAccess = () => {
    setLoadingTcr(true)
    let body = {
      object: "UpdateUserAccess",
      operation: JSON.stringify({ userId: state.user.id, tcrRights: tcrRights.join(",") }),
      nuis: loggedInUser.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
      )?.nuis,
    }
    mainAxios.post('apiEndpoint/saveOrUpdate', body).then(async (res) => {
      setLoadingTcr(false)
      if (res.data.status == true) {
        const currentUser=await refreshUser()
        setTcrRights(getTcrRights(currentUser))
        if(isLoggedInUser) {
          getUser()
        }
        toast.success(t(['toast.userUpdate']), {
          containerId: "all_toast"
        })
      } else {
        setTcrRights(getTcrRights())
      }
    })
      .catch((err) => {
        setLoadingTcr(false)
        setTcrRights(getTcrRights())
      })
  }

  const firstUpdate = useRef(true);

  useEffect(() => {
    if(showOnlyWithTcrRights){
      return
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const indicator = setTimeout(() => {
      getFilteredResults(10, 1, false)
    }, 400);

    return () => {
      clearTimeout(indicator);
    };
  }, [searchQuery]);

  const columns = [
    {
      selector: "name",
    },
  ];


  useEffect(() => {
    getFilteredResults(tableRows.rowsPerPage, tableRows.currentPage);

  }, [tableRows.currentPage, tableRows.rowsPerPage])

  const getFilteredResults = (rowsPerPage = 10, currentPage = 1) => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCompany",
        value: "",
        params: {
          companyLiveSearch: searchQuery
        },
        pagination: {
          pageSize: rowsPerPage,
          pageNumber: currentPage,
        },
      })
      .then((res) => {
        if (res.data.status) {
          let companies = res.data.content;
          let userCompanies = loggedInUser.companiesRights;
          let companiesForTable = companies;
          if (loggedInUser?.role?.id != 1) {
            companiesForTable = companiesForTable.filter((t2) =>
              userCompanies.find((t1) => t1?.id == t2?.id)
            );
          }
          setState((state) => ({
            ...state,
            companiesForTable
          }))
          setTableRows((tableRows) => ({
            ...tableRows,
            totalRows: res?.data?.totalSize,
          }));

        }
      });
  }
  return <>
    <div className="card-title p-3 ml-2 text-left d-flex align-items-center">
        <h3>{t("navbar.companies")}</h3>
        {/* Toggle Checkbox for TCR Rights */}
      <div className="custom-control custom-switch ml-auto mb-3">
        <input
          type="checkbox"
          className="custom-control-input"
          id="showOnlyWithTcrRights"
          checked={showOnlyWithTcrRights}
          onChange={(e) => setShowOnlyWithTcrRights(e.target.checked)}
        />
        <label
          className="custom-control-label ml-2"
          htmlFor="showOnlyWithTcrRights"
        >
          {t("users.branchWithRights")}
        </label>
      </div>

      </div>
      <div className="card-body">
        <div className="reports table-green">
          <Table
            columns={columns}
            data={showOnlyWithTcrRights ?(userCompaniesRights ??[]):(companiesForTable ??[]) }
            setSearchQuery={setSearchQuery}
            clearSearch={() => { setSearchQuery('') }}
            searchableKeys={["name"]}
            seachInputClasses="col-md-5"
            renderItem={(company) => {
              if (company.branches.length == 0) {
                return <div className="d-flex justify-content-center my-4">{t("invoice.noBranchFound")} </div>
              }
              return (
                <ul className="list-group">
                  <table className="table table-bordered mt-3 mb-2">
                    <thead>
                      <tr>
                        <th scope="col" className="d-flex justify-content-between align-items-center w-100">
                          <h3 className="mb-0">{t("home.branches")}</h3>
                          <div className="d-flex">
                            <button
                              className="btn btn-light btn-sm ml-4 text-primary"
                              onClick={(e) => {
                                let newTcrRights = []
                                company.branches.forEach(branch => {
                                  branch.tcrTypes.forEach(tcr => {
                                    if (newTcrRights)
                                      newTcrRights.push(tcr.id)
                                  })
                                })
                                let filtered = tcrRights.filter(tcr => !newTcrRights.includes(tcr))

                                setTcrRights(filtered)
                              }}
                            >
                              Unselect All <span style={{ fontWeight: 700 }}>Company</span>  Tcrs
                            </button>
                            <button
                              className="btn btn-light btn-sm ml-4 text-primary"
                              onClick={(e) => {
                                let newTcrRights = []
                                company.branches.forEach(branch => {
                                  branch.tcrTypes.forEach(tcr => {
                                    if (newTcrRights)
                                      newTcrRights.push(tcr.id)
                                  })
                                })
                                let uniqueTcrRights = Array.from(new Set([...tcrRights, ...newTcrRights]))

                                setTcrRights(uniqueTcrRights)
                              }}
                            >
                              Select All <span style={{ fontWeight: 700 }}>Company</span> Tcrs
                            </button>
                          </div>

                        </th>
                      </tr>
                    </thead>
                    {company.branches.map((branch) => {
                      return (
                        <div key={branch.id}>
                          <Accordion
                            padding="5px"
                            overflow={false}
                            heading={
                              <div style={{ fontWeight: 400 }}>
                                {branch.sellerName} | <span style={{ fontWeight: 500 }}>{branch?.businUnitCode}</span>
                              </div>

                            }
                          >
                            {branch.tcrTypes.length > 0 ? <table className="table table-bordered ">
                              <thead>
                                <tr className="mx-4">
                                  <th scope="col" className="d-flex justify-content-between align-items-center w-100">
                                    <h3 className="mb-0">List Of TCR</h3>
                                    <div>
                                      <button
                                        className="btn btn-light btn-sm ml-4 text-primary"
                                        onClick={(e) => {
                                          let filtered = tcrRights.filter(tcrId => !branch.tcrTypes.map(tcr => tcr.id).includes(tcrId))
                                          setTcrRights(filtered)
                                        }}
                                      >
                                        Unselect All <span style={{ fontWeight: 700 }}>Branch</span> Tcr
                                      </button>
                                      <button
                                        className="btn btn-light btn-sm ml-4 text-primary"
                                        onClick={(e) => {
                                          let newTcrRights = Array.from(new Set([...tcrRights, ...branch.tcrTypes.map(tcr => tcr.id)]))
                                          setTcrRights(newTcrRights)
                                        }
                                        }
                                      >
                                        Select All <span style={{ fontWeight: 700 }}>Branch</span> Tcr
                                      </button>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              {branch.tcrTypes.map((tcrType) => {
                                return (
                                  <tr key={tcrType.id}>
                                    <td className="d-flex align-items-center checkbox-wrapper">

                                      <div className="custom-control custom-checkbox align-items-center ml-4 mr-sm-2 cursor-pointer branch-tcr-border">
                                        <input
                                          type="checkbox"
                                          id={`dataFrom_${tcrType.id}`}
                                          className="custom-control-input mr-2"
                                          checked={tcrRights.find(tcrCode => tcrCode == tcrType.id) || false}
                                          onClick={() => {
                                            let foundTcr = tcrRights.find((tcrCode) => tcrCode == tcrType.id)
                                            if (foundTcr) {
                                              let filteredTcrRights = tcrRights.filter((tcr) => tcr != foundTcr)

                                              setTcrRights(filteredTcrRights)
                                            } else {
                                              setTcrRights([...tcrRights, tcrType.id])
                                            }
                                          }

                                          }
                                        />

                                        <label
                                          htmlFor={`dataFrom_${tcrType.id}`}
                                          className="custom-control-label w-100"
                                          style={{ paddingTop: "3px", paddingLeft: "3px" }}
                                        >
                                          {tcrType?.tcrCode}
                                        </label>
                                      </div>
                                    </td>
                                  </tr>)

                              })}
                            </table> :
                              <div className="d-flex justify-content-center my-4">{t("invoice.noTcrFound")} </div>}
                          </Accordion>
                        </div>
                      )
                    })}
                  </table>
                </ul>
              )
            }}
            enablePagination={showOnlyWithTcrRights?null:{
              paginationServer: true,
              totalRows: tableRows?.totalRows,
              onChangeRowsPerPage: (rowsPerPage) => {
                setTableRows((tableRows) => ({
                  ...tableRows,
                  rowsPerPage,
                }));
              },
              onChangePage: (currentPage) => {
                setTableRows((tableRows) => ({
                  ...tableRows,
                  currentPage,
                }));
              },
            }}
          />

          <button disabled={loadingTcr} className="btn btn-primary mr-3" onClick={updateUserAccess}>
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            {t("common.save")}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
            }}
          >
            {t("common.cancel")}
          </button>
        </div>
      </div>
  </>
};


export default UsersTable;

import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import AuthorizedRoute from "./components/Auth/AuthorizedRoute";
import CustomInvoicesNew from "./components/CustomInvoices/CustomInvoices_new";
import CreateInvoice from "./components/CustomInvoices/CreateInvoice";
// import EditInvoice from "./components/CustomInvoices/EditInvoice";
import DemoEditInvoice from "./components/CustomInvoices/DemoWizardSteps/DemoEditInvoice";
import CreateWTN from "./components/CustomInvoices/WTN/Create";
import EditWTN from "./components/CustomInvoices/WTN/Edit";
import ViewAllWTN from "./components/CustomInvoices/WTN/ViewAll";
import CashRegistry from "./components/MyPos/CashRegistry";
import CreatePosTransaction from "./components/MyPos/Create";
import ViewPosTransaction from "./components/MyPos/View";
import PosTransactions from "./components/MyPos/Transactions";
import TaxPayers from "./components/Search/TaxPayers";
import SearchInvoices from "./components/Search/SearchInvoices";
import Companies from "./components/Companies/Companies";
import ViewCompany from "./components/Companies/ViewCompany";
import EditCompanyCertificate from "./components/Companies/EditCompanyCertificate";
import AddCompany from "./components/Companies/Company/AddCompany";
import SendEmail from "./components/SendEmail";
import SendNotification from "./components/SendNotification";
import CreateSubscription from "./components/Subscription/CreateSubscription";
import EditSubscription from "./components/Subscription/EditSubscription";
import Subscriptions from "./components/Subscription/Subscriptions";
import Parameters from "./components/Setup/Parameters";
import Users from "./components/Setup/Users/Users";
import AddUser from "./components/Setup/Users/AddUser";
// import ViewUser from './components/Setup/Users/ViewUser'
import EditUser from "./components/Setup/Users/EditUser";
import Logs from "./components/Setup/Logs";
import Page404 from "./components/Page404";
import Wrapper from "./components/Layout/Wrapper";

import { AppContextProvider, useAppContext } from "./AppContext";
import {  setAuthToken } from "./services/mainAxios";
import func from "./services/functions";
import { ToastContainer } from "react-toastify";

import withClearCache from "./ClearCache";
import ViewTcrTypes from "./components/Companies/ViewTcrTypes";
import Items from "./components/Setup/Users/Items";
import Customers from "./components/Setup/Customers";
import EditItem from "./components/Setup/Users/EditItem";
import EditCustomers from "./components/Setup/EditCustomers";
import AddItem from "./components/Setup/Users/AddItem";
import GenerateNslf from "./components/Setup/GenerateNsfl";
import OpenTcrData from "./components/CashRegister/OpenTcrData";
import ItemsCategory from "./components/Setup/Users/ItemsCategory";
import EditCategories from "./components/Setup/Users/EditCategories";
import ForgetPassword from './components/Auth/ForgetPassword'
import ResetPassword from './components/Auth/ResetPassword'
import EditPassword from "./components/Setup/Users/EditPassword";
import EditPurchase from "./components/purchases/EditPurchase";
import CertificateExpirationDate from "./components/CertificateExpirationDate";
import UktReports from "./components/Setup/UktReports";
import EditPosDetails from "./components/MyPos/components/EditPos";
import EditEmail from "./components/Setup/Users/EditEmail";
import ResetEmail from "./components/Auth/ResetEmail";
// import ResetEmail from "./components/Auth/ResetEmail";
import Dashboard from "./components/dashboard/Dashboard";
import MySuppliers from "./components/Setup/MySuppliers";
// import ErrorInvocies from "./components/Invoice/ErrorInvocies";
import CashRegisterNew from "./components/CashRegister/CashRegister_new";
import SaleBook from "./components/Books/SaleBook";
import PurchasesBook from "./components/Books/PurchasesBook";
import SaleInvoices from "./components/Invoice/SaleInvoices";
import PurchasesNew from "./components/purchases/PurchasesNew";
import OperatorsCis from "./components/Setup/Users/OperatorsCis";
import GenerateWtnIc from "./components/Setup/Users/GenerateWtnIc";
// import './services/overrides'

/** USER ROLES
 * "Role Name: sysadmin,  ID: 1"
 * "Role Name: admin,     ID: 2"
 * "Role Name: useradmin, ID: 3"
 * "Role Name: Create ,   ID: 4"
 * "Role Name: Approve ,  ID: 5"
 * "Role Name: Purchase,  ID: 6"
 * "Role Name: View,      ID: 7"
 */


const App = withClearCache(() => {
  setAuthToken(func.getCookie("token"));
  console.log =
    process.env.REACT_APP_CONSOLE + "" === "true" ? console.log : () => { };
  return (
    <AppContextProvider>
      <Router>
        <Wrapper>
          <AppWithContext/>
        </Wrapper>
      </Router>
      <ToastContainer containerId="all_toast" enableMultiContainer />
    </AppContextProvider>
  );
});


const AppWithContext=()=>{
    const { user: [user,], selectedCompany: [selectedCompany,],forbidden:[forbidden,],showVersion } = useAppContext()
  let redirect;
  if(user?.role?.id==15) redirect ={path:"/",to:"/my-invoices"};
  else if(user?.role?.id==16) redirect ={path:"/",to:"/view-wtn"};
  return (
    <Switch>
      {redirect && <Redirect path={redirect.path} to={redirect.to} exact />}
      <AuthorizedRoute
        path="/"
        component={Dashboard}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14]}
      />
      <AuthorizedRoute
        path="/sale-invoices"
        component={SaleInvoices}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 7]}
      />
      {/* <AuthorizedRoute
      path="/error-invoices"
      component={ErrorInvocies}
      exact
      needSpecificRole={[1, 2, 3, 4, 5, 7]}
    /> */}
      <AuthorizedRoute
        path="/my-invoices"
        component={CustomInvoicesNew}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/create-invoice"
        component={CreateInvoice}
        exact
        needSpecificRole={[1, 2, 4, 15]}
      />
      {/* <AuthorizedRoute path="/upload"                                           component={Upload}                  exact/> */}

      <AuthorizedRoute
        path="/edit-invoice/:id"
        component={DemoEditInvoice}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/edit-purchase/:id"
        component={EditPurchase}
        exact
        needSpecificRole={[1, 2, 4, 5, 6, 7, 8]}
      />
      {/* <AuthorizedRoute
        path="/purchases"
        component={Purchases}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 6, 7, 8]}
      /> */}
      <AuthorizedRoute
        path="/purchases-new"
        component={PurchasesNew}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 6, 7, 8]}
      />
      <AuthorizedRoute
        path="/generate-nslf"
        component={GenerateNslf}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 6, 7, 8]}
      />
      <AuthorizedRoute
        path="/generate-wtnic"
        component={GenerateWtnIc}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 6, 7, 8]}
      />
      <AuthorizedRoute
        path="/create-wtn"
        component={CreateWTN}
        exact
        needSpecificRole={[1, 2, 4, 5, 16]}
      />
      <AuthorizedRoute
        path="/edit-wtn/:id"
        component={EditWTN}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 16]}
      />
      <AuthorizedRoute
        path="/view-wtn"
        component={ViewAllWTN}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 16]}
      />
      <AuthorizedRoute
        path="/cash-registry"
        component={CashRegistry}
        exact
        needSpecificRole={[1, 2, 4, 5, 7]}
      />
      <AuthorizedRoute
        path="/pos-transactions/create"
        component={CreatePosTransaction}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/pos-transactions"
        component={PosTransactions}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/pos-transactions/edit/:id"
        component={EditPosDetails}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/pos-transactions/:id"
        component={ViewPosTransaction}
        exact
        needSpecificRole={[1, 2, 4, 5, 7, 15]}
      />
      <AuthorizedRoute
        path="/search-tax-payers"
        component={TaxPayers}
        exact
        needSpecificRole={[1, 2, 4, 5, 6, 7, 15]}
      />
      <AuthorizedRoute
        path="/search-invoices"
        component={SearchInvoices}
        exact
        needSpecificRole={[1, 2, 4, 5, 6, 7]}
      />

      <AuthorizedRoute
        path="/search-certificates"
        component={CertificateExpirationDate}
        exact
        needSpecificRole={[1]}
      />
      <AuthorizedRoute
        path="/parameters"
        component={Parameters}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      {/* <AuthorizedRoute
      path="/send-email"
      component={SendEmail}
      exact
      needSpecificRole={[1]}
    /> */}
      <AuthorizedRoute
        path="/send-notification"
        component={SendNotification}
        exact

        needSpecificRole={[1]}
      />
      {/* <AuthorizedRoute path="/subscriptions/add"                                    component={CreateSubscription}      exact needSpecificRole={[1]} needSpecificUser={[1,45]}/>
    <AuthorizedRoute path="/subscriptions/edit/:id"                               component={EditSubscription}        exact needSpecificRole={[1]} needSpecificUser={[1,45]}/>
    <AuthorizedRoute path="/subscriptions"                                        component={Subscriptions}           exact needSpecificRole={[1]} needSpecificUser={[1,45]}/> */}
      <AuthorizedRoute
        path="/users"
        component={Users}
        exact
        needSpecificRole={[1]}
      />
      <AuthorizedRoute
        path="/operators-in-cis"
        component={OperatorsCis}
        exact
        needSpecificRole={[1]}
      />
      <AuthorizedRoute
        path="/items"
        component={Items}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/items/update/:id"
        component={EditItem}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      {/* <AuthorizedRoute
      path="/my-clients"
      component={MyClients}
      exact
      needSpecificRole={[1,2,3]}
    /> */}
      {/* <AuthorizedRoute
      path="/my-clients/update/:id"
      component={EditMyClients}
      exact
      needSpecificRole={[1,2,3]}
    /> */}
      <AuthorizedRoute
        path="/customers/update/:id"
        component={EditCustomers}
        exact
        needSpecificRole={[1, 2]}
      />
      <AuthorizedRoute
        path="/customers"
        component={Customers}
        exact
        needSpecificRole={[1, 2]}
      />
      <AuthorizedRoute
        path="/items-category/"
        component={ItemsCategory}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/users/add"
        component={AddUser}
        exact
        needSpecificRole={[1, 2]}
      />
      {/* <AuthorizedRoute path="/users/:id"                                        component={ViewUser}                exact needSpecificRole={[1]}/> */}
      <AuthorizedRoute
        path="/users/edit/:id"
        component={EditUser}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/users/edit-password/:id"
        component={EditPassword}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/users/edit-email/:id"
        component={EditEmail}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute path="/logs" component={Logs} exact needSpecificRole={[1]}/>
      <AuthorizedRoute
        path="/companies"
        component={Companies}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/companies/add"
        component={AddCompany}
        exact
        needSpecificRole={[1]}
      />
      <AuthorizedRoute
        path="/items/add"
        component={AddItem}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/companies/:id"
        component={ViewCompany}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/companies/:id/certificate"
        component={EditCompanyCertificate}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/users"
        component={Users}
        exact
        needSpecificRole={[1]}
      />
      <AuthorizedRoute
        path="/users/add"
        component={AddUser}
        exact
        needSpecificRole={[1]}
      />
      {/* <AuthorizedRoute path="/users/:id"                                component={ViewUser}                exact needSpecificRole={[1]}/> */}
      <AuthorizedRoute
        path="/users/edit/:id"
        component={EditUser}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/users/edit-password/:id"
        component={EditPassword}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute path="/logs" component={Logs} exact />

      {/* <AuthorizedRoute
      path="/cashregister"
      component={CashRegister}
      exact
      needSpecificRole={[1, 2, 3]}
    /> */}
      <AuthorizedRoute
        path="/cashregister"
        component={CashRegisterNew}
        exact
        needSpecificRole={[1, 2, 3, 15]}
      />
      <AuthorizedRoute
        path="/my-suppliers"
        component={MySuppliers}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
      />
      <AuthorizedRoute
        path="/company/:companyId/tcr/:branchId"
        component={ViewTcrTypes}
        exact
        needSpecificRole={[1, 2, 3]}
      />
      <AuthorizedRoute
        path="/cashregister/tcr"
        component={OpenTcrData}
        exact
        needSpecificRole={[1, 2, 3, 15]}
      />
      <AuthorizedRoute
        path="/books/sales"
        component={SaleBook}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 7]}
      />
      <AuthorizedRoute
        path="/books/purchases"
        component={PurchasesBook}
        exact
        needSpecificRole={[1, 2, 3, 4, 5, 7]}
        needsPurchaseRole={[8,13,14]}
      />
      {/* <AuthorizedRoute
        path="/ukt-report"
        component={UktReports}
        exact
        needSpecificRole={[1]}
      /> */}
      <Route path="/forget-password" component={ForgetPassword} exact />
      <Route path="/reset-password" component={ResetPassword} exact />
      <Route path="/reset-email" component={ResetEmail} exact />

      <Route component={Page404} />
    </Switch>
  );
}

export default App;

import React, {useRef} from 'react'
import DatePicker from "react-datepicker"
import func from '../../../services/functions'
import { useAppContext } from '../../../AppContext'

import { faCheck, faPlus, faSearch, faTimes, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mainAxios } from '../../../services/mainAxios'
import Loader from '../../Layout/Loader'
import { toast } from 'react-toastify'

import {useTranslation, withTranslation} from "react-i18next";
import DateRange from '../../global/DateRange'
import moment from 'moment'
import { useHistory } from 'react-router-dom';

const AddCompany = () => {
    const {user: [user, setUser], selectedCompany: [selectedCompany, setSelectedCompany]} = useAppContext()
    const {t,i18n} = useTranslation('translations');
    const [inputClass, setInputClass] = React.useState("");
    // const history=useHistory();

    const [state, setState] = React.useState({
        active: true,
        name: "",
        nuis: "",
        type: "",
        issuerInVat: true,
        invoicesNr: 0,
        certificate: {
            file: null,
            name: null,
            type: "PKCS12",
            alias: "",
            password: "",
            keyPass: ""
        },
        branches: [
            {
                businUnitCode: "",
                sellerCountry: "",
                sellerTown: "",
                sellerAddress: "",
                sellerName: "",
                default: true
            }
        ],
        beginningDate:"",
        expirationDate: "",
    })
    const [sellerInputClass, setSellerInputClass] = React.useState("");
    const [seller, setSeller] = React.useState("");
    const [loading, setLoading] = React.useState(false)
    const [errors,setErrors]=React.useState({})
    const formRef = useRef()

    const validateBranches=()=>{
        const validationErrors={}
        let valid=true
        state.branches.forEach((branch,index)=>{
            Object.keys(branch).forEach(key => {
                let branchData=branch[key]
                if((typeof branchData=="number" )&& branchData === 0){
                    valid = false
                    validationErrors[index + "_" +key] = true;
                }else if((typeof branchData=="object") && JSON.stringify(branchData).length === 0){
                    valid = false
                    validationErrors[index + "_" +key] = true;
                }else if(branchData ==null || (typeof branchData=="string"&& branchData?.length === 0)){
                    valid = false
                    validationErrors[index + "_" +key] = true;
                }

            })

        })
        if (!valid) {
            setErrors(validationErrors)
            formRef.current.reportValidity()
            return false
          }
          return true
    }


    const addCompany = () => {
        setLoading(true)
        const {beginningDate,expirationDate}=state
        if(beginningDate.length==0 || expirationDate.length==0){
            toast.warning(t("toast.dateSubscriptionEmpty"), {
                containerId: "all_toast",
              });
              setLoading(false)
            return
        }

        if(state.nuis) state.nuis=state.nuis.trim()
        if(state.businessUnitCode) state.businessUnitCode=state.businessUnitCode.trim()

        mainAxios.post('apiEndpoint/saveOrUpdate', {
            object: "Company",
            content: {
                ...state
            }
        }).then(res => {
            setLoading(false)
            if(res?.data?.status === true) {
                // const id=res?.data?.content?.[0]?.id
                // if(id){
                //     history.push('/companies/'+id)
                // }
                toast.success(t(['toast.addCompany']), {
                    containerId: "all_toast"
                })
            }
        })
    }

    const handleIssuerInVatChange = (e) => {
        let value = e.target.checked
        setState(state => ({
            ...state,
            issuerInVat: value
        }))

    }

    const handleShowCancelInvoiceButtonChange = (e) => {
        let value = e.target.checked
        setState(state => ({
            ...state,
            showCancelInvoiceButton: value
        }))

    }

    const handleIsApiChange = (e) => {
        let value = e.target.checked
        setState(state => ({
            ...state,
            showMyPurchaseDownloadedStatus: value
        }))

    }

    const handleCompanyInputs = (e) => {
        const key = e.target.name
        const value = e.target.value

        setState(state => ({
            ...state,
            [key]: value
        }))
    }

    const handleCertificate = (e) => {
        let file = e.target.files[0]

        if(file) {
            const reader = new FileReader();

            reader.onload = (readerEvent) => {
                let binaryString = readerEvent.target.result
                setState(state => ({
                    ...state,
                    certificate: {
                        ...state.certificate,
                        file: btoa(binaryString),
                        name: file.name
                    }
                }))
            }

            reader.readAsBinaryString(file)
        }
    }




    const addBranch = () => {
        const valid = validateBranches()

        if(state.branches.length > 0 && valid) {
            setErrors({})
            setState(state => ({
                ...state,
                branches: [
                    {
                        businUnitCode: "",
                        sellerCountry: "",
                        sellerTown: "",
                        sellerAddress: "",
                        sellerName: "",
                        default: false
                    },
                    ...state.branches,
                ]
            }))
        }
        else {
            formRef.current.reportValidity()
        }
    }

    const handleBranchInputs = (e, index, selector = null, selectorValue = null) => {
        const key = selector ? selector : e.target.name
        const value = selectorValue ? selectorValue : e.target.value
        let updatedBranches = [...state.branches]
        updatedBranches[index] = {
            ...updatedBranches[index],
            [key]: value
        }
        setState(state => ({
            ...state,
            branches: updatedBranches
        }))
    }


    const getBranchesFromCis = ({nuis}) => {
        mainAxios.post("/endpoint/v3/getDataFromCis", {
            object: "GetBusinessUnits",
            nuis,
        }).then(res => {
            if (res.data.status && res.data?.content?.length>0) {
                let branches = res.data.content.map(branch=>{
                    let { sellerName, sellerTown, sellerAddress, sellerCountry,businUnitCode } = branch
                    sellerName = sellerName ? sellerName.slice(0, 99) : null;
                    sellerTown = sellerTown ? sellerTown.slice(0, 99) : null;
                    sellerAddress = sellerAddress ? sellerAddress.slice(0, 200) : null;
                    sellerCountry = sellerCountry ? func.getFullCountry(sellerCountry) : null;
                    return {
                        sellerName,sellerTown,sellerAddress,sellerCountry,businUnitCode
                    }
                })
                setState(state=>{
                    return {
                        ...state,
                        branches
                    }
                })
            }
        })
    }

    const handleSellerTaxPayerSearch = () => {
        let sellerNIPT = seller.split(" ").join("");
        setSeller(sellerNIPT);
          if (new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(sellerNIPT)) {
            setSellerInputClass("loading");
            mainAxios
              .post("apiEndpoint/search", {
                object: "GetTaxpayersRequest",
                value: sellerNIPT,
              })
              .then((res) => {
                if (res?.data?.status === true) {
                  if (res.data.content.length === 0) {
                    toast.warning(t(["toast.noTaxPayer"]), {
                      containerId: "all_toast",
                    });
                    setSellerInputClass("error");
                  } else {
                      const taxPayer = res.data.content[0];
                      let { name:sellerName, tin } = taxPayer;
                    //   if(tin)getBranchesFromCis({nuis:tin})
                    sellerName = sellerName ? sellerName.slice(0, 99) : null;
                    setState(state=>{
                        return {
                            ...state,
                            name:sellerName,
                            nuis:tin
                        }
                    })

                    setSellerInputClass("success");
                  }
                } else {
                  setSellerInputClass("error");
                }
              })
              .catch((e) => {
                setSellerInputClass("error");
              });
          } else {
            toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
            setSellerInputClass("error");
          }
      };

      const handleSellerTaxPayerChange = (e) => {
        let value = e.target.value;

        setSeller(value);
        if (inputClass.length > 0) setSellerInputClass("");
      };

    const deleteBranch=(index)=>{
        setState(state=>{
            const branches=[...state.branches]
            branches.splice(index, 1)
            return {...state,branches}
        })
        setErrors({})
    }


    return (
        <form onSubmit={addCompany} ref={formRef}>
            <h3 className="mt-4 mb-4">Add Company</h3>
            <div className="card border-0 shadow mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className='col-12 col-md-2 mb-3'>
                            <label className="field-label mr-2">
                                {t("navbar.search")} (NIPT)
                            </label>
                            <div >
                                <div className="input-group position-relative">
                                    <input
                                        name="seller"
                                        type="text"
                                        className={
                                            "form-control form-control-sm required" +
                                            (sellerInputClass === "error"
                                                ? " border-danger"
                                                : sellerInputClass === "success"
                                                    ? " border-success"
                                                    : "")
                                        }
                                        value={seller}
                                        onChange={handleSellerTaxPayerChange}
                                    />
                                    <div className="input-group-append">
                                        <span
                                            className="input-group-text cursor-pointer"
                                            onClick={handleSellerTaxPayerSearch}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </div>
                                    {sellerInputClass === "loading" && (
                                        <div className="input-icon input-loader">
                                            <Loader size="xs" />
                                        </div>
                                    )}
                                    {sellerInputClass === "error" && (
                                        <div className="input-icon text-danger">
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                    )}
                                    {sellerInputClass === "success" ? (
                                        <div className="input-icon text-success">
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 pl-1">
                            <div className="form-group">
                            <label htmlFor="company_name" className='ml-2'>{t("company.beggingingExpDate")}</label>
                            <DateRange
                                showYearDropdown
                                fromDate={state.beginningDate}
                                toDate={state.expirationDate}
                                showDateMenu={false}
                                onChange={(dates) => {
                                    let fromDate = dates[0];
                                    let toDate = dates[1];
                                    setState(state => ({
                                        ...state,
                                        beginningDate: fromDate,
                                        expirationDate: toDate

                                    }))

                                }}
                            />
                            </div>

                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="company_name">{t('company.name')} (Max 99)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_name"
                                    name="name"
                                    maxLength="99"
                                    placeholder="Name"
                                    value={state.name}
                                    onChange={handleCompanyInputs}
                                    required />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="company_nuis">NIPT</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_nuis"
                                    name="nuis"
                                    placeholder="Company NIPT"
                                    value={state.nuis.trim()}
                                    onChange={handleCompanyInputs}
                                    required />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="company_type">{t('company.type')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_type"
                                    name="type"
                                    placeholder="E.g 'Person Fizik'"
                                    value={state.type}
                                    onChange={handleCompanyInputs}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-3">
                            <label htmlFor="certificate">Certificate</label>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="certificate"
                                    name="certificate"
                                    placeholder="Upload Certificate"
                                    onChange={handleCertificate} />
                                <label className="custom-file-label" htmlFor="certificate">{state.certificate.name ? state.certificate.name : "Choose File"}</label>
                            </div>
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="certificate_alias">Alias</label>
                            <input
                                type="text"
                                className="form-control"
                                id="certificate_alias"
                                name="certificate_alias"
                                placeholder="Certificate Alias"
                                value={state.certificate.alias}
                                onChange={(e) => {
                                    const value = e.target.value
                                    setState(state => ({...state, certificate: {...state.certificate, alias: value}}))
                                }} />
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="certificate_password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="certificate_password"
                                name="certificate_password"
                                placeholder="Certificate Password"
                                value={state.certificate.password}
                                onChange={(e) => {
                                    const value = e.target.value
                                    setState(state => ({...state, certificate: {...state.certificate, password: value}}))
                                }} />
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="certificate_password">Key Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="key_pass"
                                name="keyPass"
                                placeholder="Key Password"
                                value={state.certificate.keyPass}
                                onChange={(e) => {
                                    const value = e.target.value
                                    setState(state => ({...state, certificate: {...state.certificate, keyPass: value}}))
                                }} />
                        </div>
                        <div className="col-lg-12 align-self-end mt-2">
                            <div className="d-flex justify-content-start">
                                <div className="form-group">
                                    <div className="custom-control custom-switch mr-3">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="issuerInVat"
                                            name="issuerInVat"
                                            checked={state.issuerInVat}
                                            onChange={handleIssuerInVatChange}/>
                                        <label className="custom-control-label" htmlFor="issuerInVat">
                                            <h4 className="mb-0">{t("company.isInVat")}</h4>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-switch mr-3">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="showCancelInvoiceButton"
                                            name="showCancelInvoiceButton"
                                            checked={state?.showCancelInvoiceButton}
                                            onChange={handleShowCancelInvoiceButtonChange}/>
                                        <label className="custom-control-label" htmlFor="showCancelInvoiceButton">
                                            <h4 className="mb-0">{t('company.showCancelInvoiceButton')}</h4>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="showMyPurchaseDownloadedStatus"
                                            name="showMyPurchaseDownloadedStatus"
                                            checked={state?.showMyPurchaseDownloadedStatus}
                                            onChange={handleIsApiChange}/>
                                        <label className="custom-control-label" htmlFor="showMyPurchaseDownloadedStatus">
                                            <h4 className="mb-0">{t('company.showMyPurchaseDownloadedStatus')}</h4>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {state.branches.length === 0 &&
                    <div className="d-flex">
                        <button
                            className="btn btn-outline-info rounded-pill ml-auto"
                            onClick={(e) => {e.preventDefault(); addBranch()}}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Add Branch
                        </button>
                    </div>}
                </div>
            </div>
            {state.branches.length > 0 &&
            <>
                <div className='d-flex align-items-center mb-4'>
                    <h4 className="m-0 mr-3">Branches</h4>
                    <div className="d-flex">
                        <button
                            className="btn btn-outline-info rounded-pill ml-auto"
                            onClick={(e) => { e.preventDefault(); addBranch() }}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Add Another Branch
                        </button>
                    </div>
                </div>
                <div style={{maxHeight:800,overflowY:"scroll"}}>
                {sellerInputClass === "loading"? <div className="card border-0 shadow mb-4">
                    <Loader size={"md"}/>

                </div>:state.branches.map((branch, index) => (
                    <div className="card border-0 shadow mb-4">
                        <div className="card-body">
                            { state.branches?.length > 1 && <div className="d-flex justify-content-end">
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="mr-3"
                                style={{color:"red",cursor:"pointer"}}
                                onClick={()=>deleteBranch(index)}
                            />
                            </div>}
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="business_unit_code">Business Unit Code</label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                errors?.[`${index}_businUnitCode`]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            id="business_unit_code"
                                            name="businUnitCode"
                                            placeholder="E.g 'ab123ab123'"
                                            value={branch.businUnitCode.trim()}
                                            onChange={(e) => {handleBranchInputs(e, index)}}
                                            required/>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="seller_country">Seller Country</label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                errors?.[`${index}_sellerCountry`]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            id="seller_country"
                                            name="sellerCountry"
                                            placeholder="E.g 'Albania'"
                                            value={branch.sellerCountry}
                                            onChange={(e) => {handleBranchInputs(e, index)}}
                                            required/>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="seller_town">Seller Town (Max 99)</label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                errors?.[`${index}_sellerTown`]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            id="seller_town"
                                            name="sellerTown"
                                            maxLength="99"
                                            placeholder="E.g 'Tirane'"
                                            value={branch.sellerTown}
                                            onChange={(e) => {handleBranchInputs(e, index)}}
                                            required/>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="seller_address">Seller Address (Max 200)</label>
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                errors?.[`${index}_sellerAddress`]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            id="seller_address"
                                            name="sellerAddress"
                                            maxLength="200"
                                            placeholder="E.g 'Rr. Tish Dahija'"
                                            value={branch.sellerAddress}
                                            onChange={(e) => {handleBranchInputs(e, index)}}
                                            required/>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label htmlFor="seller_name">Seller Name</label>
                                        <input
                                            type="text"
                                            min="0"
                                            className={`form-control ${
                                                errors?.[`${index}_sellerName`]
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                            id="seller_name"
                                            name="sellerName"
                                            placeholder="E.g 'Tetra PRO'"
                                            value={branch.sellerName}
                                            onChange={(e) => {handleBranchInputs(e, index)}}
                                            required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>

            </>}
            <div className="d-flex justify-content-between align-items-center">
                <button
                    type="button"
                    onClick={()=>{const valid=validateBranches();if(valid)addCompany() }}
                    disabled={loading}
                    style={{minWidth: "100px"}}
                    className="btn btn-primary">
                    {loading ? <Loader size="xs" color="white"/> : 
                    (<>
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />Save
                    </>)}
                </button>
            </div>
        </form>
    )
}

export default AddCompany
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFilePdf, faTrash, faBan, faPrint, faEdit, faEye,faCopy,faSpinner} from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import func from '../../services/functions'
import invoiceTypes from '../../services/InvoiceTypes.json'
import { getStore, useAppContext } from '../../AppContext'
import CellInput from '../PrimeTable/CellInput'
import profileIDS from "../../services/profileIDS.json"
import { Menu } from "primereact/menu";
import { paymentOptions } from '../../services/data'
import LoadingLayover from '../global/LoadingLayover'


const bigDecimal = require("js-big-decimal")
const moment = require("moment")
const { bodyNumber } = func

const generateCustomInvoiceColumns = (
{
    showInvoicePdf,
    loadingPdf,
    handleInvoiceDelete,
    handleInvoiceCancel,
    t,
    handleThermalPrint,
    isPos,
    invoiceTypeOptions,
    spinLoading,
    handleDublicateInvoice,
    isRefreshStatus
}) => {

    const { user: [user], company: [fullCompany] } = getStore()
    const userCanDelete = [1, 2, 4,15].includes(user.role.id)
    const userCanCancel = [1, 2, 4,15].includes(user.role.id)
    const profileIdOptions = profileIDS.map((profId) => ({ ...profId, label: t([profId.label]) }))


    return [
        {
            header: t(["home.status"]),
            field: 'status',
            style: { minWidth: "150px", maxWidth: "300px" },
            persisted:true,
            body: invoice => {
                let badgeClass = "secondary"
                let statusLabel = "Draft"

                let invoiceStatus = invoice.status.status
                if(isRefreshStatus(invoice)){
                    badgeClass = "warning"
                    statusLabel = t("status.pending")
                }
                else if(invoiceStatus==="READY_FOR_APPROVAL"){
                    badgeClass = "info"
                    statusLabel =  t("status.readyForApproval")
                }else if(invoiceStatus==="APPROVED"){
                    badgeClass = "success"
                    statusLabel = t("status.approved")
                }else if(invoiceStatus==="ERROR"){
                    badgeClass = "danger"
                    statusLabel = "Error"
                }else if(invoiceStatus==="DELETED"){
                    badgeClass = "danger"
                    statusLabel = "Deleted"
                }

                if (statusLabel === "Error") return (
                    <h4 className="mb-0"><span className={"badge badge-" + badgeClass}>{statusLabel}</span></h4>
                )

                else return (

                    <h4 className="mb-0"><span className={"badge badge-" + badgeClass}>{statusLabel}</span></h4>
                )
            },
            format:invoice=>{
                if(isRefreshStatus(invoice)){
                    return t("status.pending")
                }
                return invoice?.status?.status ?? ""
            }
        },
        {
            header: t(["invoice.print"]),
            field: 'print',
            style: { minWidth: "150px", maxWidth: "300px" },
            body: invoice => {
                const isLoadingPdf=loadingPdf?.id==invoice?.id && loadingPdf?.loading
                return <div className='d-flex align-items-center'>

                    {invoice.iic &&
                        <a
                            href="#"
                            className={"nav-link p-0 d-flex-align-items-center mr-1"}
                            onClick={(e) => { e.preventDefault(); handleThermalPrint(invoice.id) }}>
                            <img src="https://profisc.al/wp-content/uploads/2021/10/kupon.svg" title={t('invoice.printThermal')}  width="38" height="32"  />
                        </a>}
                    {invoice.iic &&
                        <a
                            href="#"
                            id={invoice.iic}
                            className={"nav-link p-0 d-flex-align-items-center mr-1"}
                            onClick={(e) =>  showInvoicePdf({key:'iic', invoice,e}) }>
                             <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key==="iic")) }>
                                <img src="https://profisc.al/wp-content/uploads/2021/10/Asset-11.svg" title={t('invoice.printFisc')} width="38" height="32" />
                            </LoadingLayover>
                        
                        </a>}
                    {invoice.eic &&
                        <a
                            href="#"
                            className={"nav-link p-0 d-flex-align-items-center mx-1"}
                            onClick={(e) =>  showInvoicePdf({key:'eic', invoice,e}) }>
                             <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key==="eic")) }>
                            < img src="https://profisc.al/wp-content/uploads/2021/10/Asset-12.svg" title={t('invoice.electronicInv')}  width="38" height="32" />
                            </LoadingLayover>

                        </a>}
                    {invoice.status.id == 7 || invoice.status.id == 8 ?
                        <a
                            href="#"
                            className={"nav-link p-0 d-flex-align-items-center mx-1"}
                            onClick={(e) => showInvoicePdf({key:'id', invoice,e})}>
                             <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key==="id")) }>
                                <img src="https://profisc.al/wp-content/uploads/2023/08/PrintfiscDraft.png" title={t('invoice.printFisc')} width="26" height="30" />
                             </LoadingLayover>
                        
                        </a>
                        : null}

                </div>
            },
            persisted:true
        },
        {
            header: "Email",
            field: 'sendEmail',
            style: { minWidth: "50px", maxWidth: "300px" },
            body: invoice => (
                <>
                    {invoice.iic &&
                        <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            size={'2x'}
                            className={`ml-2`}// ${loadingPdf ? "text-secondary" : "text-primary"}`}
                            style={{ cursor: "pointer", height: '25px'}}
                            color={"#6fc483"}
                            onClick={() => func.sendEmailPdf(invoice.iic, invoice.customer, invoice?.parties?.buyer?.tin, t)}
                            title={t('email.sendByEmail')}
                        />
                    }
                </>
            ),
        },
        {
            header: t(['home.actions']),
            field: 'actions',
            style: { minWidth: "300px", maxWidth: "300px" },
            body: invoice => (
                <div className='d-flex align-items-center'>
                    <Link
                        to={`/${isPos?"pos-transactions/edit":"edit-invoice"}/${invoice.id}`}
                        style={{color:"#0275d8"}}
                        className={"nav-link p-0 d-flex align-items-center mr-2"}>
                        <FontAwesomeIcon icon={invoice.status.id === 9 ? faEye : faEdit} className="mx-1" />
                        {invoice.status.id === 9 ? t('common.view') : t('common.edit')}
                    </Link>


                    {(invoice.status.id === 7 && userCanDelete) &&
                        <a
                            href="#"
                            className="nav-link p-0 d-flex align-items-center text-danger ml-1"
                            onClick={e => handleInvoiceDelete(e, invoice)}>
                            <FontAwesomeIcon icon={faTrash} className="mr-1" />
                            {t('common.delete')}

                        </a>}
                    {(invoice.status.id === 9 && userCanCancel) &&
                        <a
                            href="#"
                            className="nav-link p-0 d-flex align-items-center text-danger "
                            onClick={e => handleInvoiceCancel(e, invoice)}>
                            <FontAwesomeIcon icon={faBan} className="mr-1" />
                            {t('common.cancel')}

                        </a>
                        }
                            {invoice.status.status != 'ERROR' && <a
                            href="#"
                            className={`${spinLoading?"disabled":"" } nav-link p-0 d-flex align-items-center ml-2`}
                            onClick={(e)=> handleDublicateInvoice(e,invoice)}>
                              <FontAwesomeIcon className="mr-1" icon={faCopy} />
                            {t("invoice.duplicate")}
                        </a>}
                </div>
            ),
            persisted: true
        },





        {
            header: t(['table.issueDatetime']),
            field: 'issueDate',
            style: { minWidth: "180px", maxWidth: "300px" },
            sortable: true,
            filter: true,
            filterType: "DATE",
            // filterConfig: {
            //     like: false,
            //     field:"invoiceIssueDateTime"
            // },
            persisted: true,
            formattedExportValue: true,
            preventExcelFormat: true,
            body: invoice => invoice.issueDate ? moment(invoice.issueDate).format("DD/MM/YYYY HH:mm") : "",
        },
        {
            header: t("invoice.rev/Self/Debt/Summary"),
            field: 'invoiceTypeIcons',
            style: { minWidth: "150px", maxWidth: "220px" },
            persisted: true,
            formattedExportValue: true,
            body: invoice => {
                if (invoice.badDebtInv) return "Bad Debt"
                else if (invoice.reverseCharge) return "Reverse"
                else if (invoice.selfInvoice) return "Self Invoice"
                else if (invoice.sumInv) return "Summary"
                else return t(['vat.none'])
            },
        },
        {
            header: t(['table.recordDate']),
            field: 'recordDate',
            style: { minWidth: "200px", maxWidth: "300px" },
            filter: true,
            sortable: true,
            filterType: "DATE",
            filterConfig: {
                like: false,
                field:'invoiceDate'
            },
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (invoice) =>
            invoice.recordDate
                ? moment(invoice.recordDate).format("DD/MM/YYYY HH:mm:ss")
                : "",
            default: true
        },
        {
            header: t(['invoice.businessUnit']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'businessUnitCode',
            filter: true,
            default: true
        },
        {
            header: t(['invoice.TcrCode']),
           style: { minWidth: "200px", maxWidth: "300px" },
            field: 'tcrCode',
            filter:true,
        },
        {
            header: "Cash/NonCash",
            style: { minWidth: "230px", maxWidth: "300px" },
            field: 'paymentMethodType',
            filter:true,
            filterType:"DROPDOWN",
            filterConfig:{
              options: paymentOptions,
            },
            body: invoice => invoice.paymentMethodType.toUpperCase() ?? "",
        },
        {
            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID",
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'internalId',
            filter: true,
            sortable: true,
            body: (invoice) => <CellInput value={invoice?.internalId ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },
        {

            header: t("invoice.IIC"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'iic',
            persisted: true,
            filter:true,
            body: (invoice) => <CellInput value={invoice?.iic ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },
        {

            header: "EIC",
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'eic',
            persisted: true,
            filter: true,
            body: (invoice) => <CellInput value={invoice?.eic ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },
        {

            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId1")?.value ?? (t("pos.additional") + " 1"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'param1',
            filter: true,
            body: (row)=><CellInput value={row?.param1} width={150}/>
        },
        {

            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId2")?.value ?? (t("pos.additional") + " 2"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'param2',
            filter: true,
            body: (row)=> <CellInput value={row?.param2} width={150} />
        },
        {
            header: t(["home.buyerName"]),
            style: { minWidth: "250px", maxWidth: "300px" },
            field: 'customer',
            filter: true,
            filterConfig:{
                field:"buyerName",
                like:false
            },
            persisted: true,
            formattedExportValue: true,
            body: invoice => invoice?.parties?.buyer?.name || "",
        },
        {
            header: t(['invoice.seller']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'seller',
            filterConfig:{
                field:"sellerName",
                like:false
            },
            filter:true,
            body: invoice => invoice.parties.seller?.name ? invoice.parties.seller.name : "",
            formattedExportValue: true,
        },
        {
            header: t("customers.type"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "type",
            body: invoice => invoice.type ? t([invoiceTypes.find(invoiceType => invoiceType.value === invoice.type)?.label??""]) : "",
            filter: true,
            filterType:"MULTISELECT",
            filterConfig:{
                options: invoiceTypeOptions
            }
        },
        {
            header: t("invoice.Operator"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "opCode",
            filter: true,
        },
        {
            header: t(['home.amountALL']),
            field: 'invoiceTotalWithVatALL',
            style: { minWidth: "200px", maxWidth: "300px" },
            body: invoice => invoice.currency !== "ALL" ? func.getFormattedMoneyValue(bigDecimal.multiply(invoice.rate, invoice.invoiceTotalWithVat)) : "",
        },
        {
            header: t(["hints.itemtotalWithVat"]),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'invoiceTotalWithVat',
            sortable: true,
            align: "right",
            body: invoice => func.getFormattedMoneyValue(invoice?.invoiceTotalWithVat),
        },

        {
            header:  t(["hints.itemtotalWithoutVat"]),
            field: "invoiceTotalWithoutVat",
            style: { minWidth: "280px", maxWidth: "300px" },
            sortable: true,
            default: true,
            align: "right",
            body: (invoice) => func.getFormattedMoneyValue(invoice?.invoiceTotalWithoutVat),
        },

        {
            header: t(["books.vatAmount"]),
            field: "invoiceTotalVat",
            style: { minWidth: "280px", maxWidth: "300px" },
            sortable: true,
            default: true,
            align: "right",
            body: (invoice) => func.getFormattedMoneyValue(invoice?.invoiceTotalVat),
        },
        {
            header: t(["home.exchangeRate"]),
            field: "rate",
            default: true,
            style: { minWidth: "150px", maxWidth: "300px" },
            body: (invoice) =>
            invoice.rate !== "ALL" ?  invoice.rate : "",
        },
        {
            header: t(['invoice.currency']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'currency',
        },
        {
            header: t("table.sellerNIPT"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "sellerNipt",
            body: invoice => invoice?.parties?.seller?.tin || "",
            formattedExportValue:true,
        },
        {
            header: t(['table.buyerNIPT']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "buyerNipt",
            filter: true,
            filterConfig: {
                field: "customer.tin",
                like: false
            },
            persisted: true,
            body: invoice => invoice?.parties?.buyer?.tin || "",
            formattedExportValue: true,
        },
        {
            header: t(['common.username']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "recordUser",
            filter: true
        },
        {
            header: t(['invoice.profileId']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'profileId',
            default:true,
            filter: true,
            filterType:"MULTISELECT",
            filterConfig:{
                like:false,
                options: profileIdOptions
            }
        },
        {
            header: t(['invoice.nr']),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'number',
            persisted: true,
            format: (invoice)=>func?.preventExcelFormatFn(invoice?.number || ""),
            sortable: true,
            filter: true
        },
        {
            header: t("invoice.FIC"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'fic',
            filter:true,
            persisted:true,
            body: (invoice) => <CellInput value={invoice?.fic ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },
        {
            header: t("table.dueDate"),
            field: 'dueDate',
            style: { minWidth: "200px", maxWidth: "300px" },
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (invoice) =>
            invoice.dueDate
                ? moment(invoice.dueDate).format("DD/MM/YYYY HH:mm:ss")
                : "",
            filter:true,
            filterType: "DATE",
            filterConfig: {
                like: false
            },
            default: true
        },
    ];
}


export default generateCustomInvoiceColumns

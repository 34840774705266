import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import func from "../../../services/functions";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import PopOver from "../../global/PopOver";
import currencies from "../../../services/currencies.json";
import { useAppContext } from "../../../AppContext";

const { countDecimals } = func;
const DemoInvoiceDatas = ({ errors,handleInvoiceChange, ...props }) => {
  const now = new Date();
  const { invoiceState, setInvoiceState, bankAccounts, disabled, history, stateDefault, edit = false } =
    props;
  const [minIssueDate, setMinIssueDate] = React.useState("");
  const { t, i18n } = useTranslation("translations");
  const issueDateRef = React.useRef();
  const popOver = {
    NOINTERNET: t("hints.noInternet"),
    BOUNDBOOK: t("hints.boundBook"),
    SERVICE: t("hints.service"),
    TECHNICALERROR: t("hints.TechnicalError"),
  };

  const { company:[fullCompany] } = useAppContext()

  const subseqValues = [
    { value: "", label: "Select" },
    { value: "NOINTERNET", label: "No Internet" },
    { value: "BOUNDBOOK", label: "Boundbook" },
    { value: "SERVICE", label: "Service" },
    { value: "TECHNICALERROR", label: "Technical Error" },
  ];

  const handleIssueDateChange = (date) => {
    const dueDate = date > invoiceState.dueDate ? "" : invoiceState.dueDate;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      issueDate: date,
      dueDate,
    }));
    issueDateRef.current.setOpen(false);

    let today = new Date();
    let technicalErrorMinDate = func.dateBeforeSomeDays(1, today);
    let boundBookMinDate = func.dateBeforeSomeDays(10, today);
    let dateMilli = new Date(date).setHours(0, 0, 0, 0);
    let todayMilli = new Date().setHours(0, 0, 0, 0);
    
    if (dateMilli >= todayMilli) {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        subSequenceDelivType: "",
      }));
      return;
    }
    if (dateMilli >= technicalErrorMinDate && dateMilli < todayMilli) {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        subSequenceDelivType: "SERVICE",
      }));
    } else if (dateMilli >= boundBookMinDate && dateMilli < todayMilli) {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        subSequenceDelivType: "BOUNDBOOK",
      }));
    } else {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        subSequenceDelivType: "NOINTERNET",
      }));
    }
  };

  const handleDueDateChange = (date) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      dueDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      invoiceEndDate: date,
    }));
  };

  const handleInvoiceDataChange = (e, label, val) => {
    let key = e ? e.target.name : label;
    let value = e ? e.target.value : val;
    if (key == "rate") {
      let count = countDecimals(value);
      if (count > 6) return;
    }

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  const handleStartDateChange = (date) => {
    const invoiceEndDate =
      date > invoiceState.invoiceEndDate ? "" : invoiceState.invoiceEndDate;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      invoiceStartDate: date,
      invoiceEndDate,
    }));
  };

  const handleSubseqChange = (subseq) => {
    const { value, label } = subseq;
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      subSequenceDelivType: value,
    }));
    var newDate = new Date();
    
    if (value === "BOUNDBOOK") {
      newDate = func.dateBeforeSomeDays(11, newDate);
    } else if (value === "SERVICE" || value === "TECHNICALERROR") {
      newDate = func.dateBeforeSomeDays(1, newDate);
    } else {
      newDate = func.getMinIssueDate();
    }
    const { issueDate } = invoiceState;
    setMinIssueDate(newDate);

    if (
      (value === "BOUNDBOOK" ||
        value === "SERVICE" ||
        value === "TECHNICALERROR") &&
      issueDate < newDate
    ) {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        issueDate: new Date(),
      }));
      issueDateRef.current.setOpen(true);
    }
  };

  
  let additional1 = (
    <div className="input-length-validation">
      <label className="field-label mt-1">
        {fullCompany?.companyAttributes?.find(
          (a) => a?.attribute?.param == "AdditionalId1"
        )?.value ?? t("pos.additional") + " 1"}
      </label>
      <input
        type="text"
        name="param1"
        disabled={disabled}
        maxLength={50}
        onChange={(e) => handleInvoiceChange(e.target.value, "param1")}
        className="form-control form-control-sm"
        value={invoiceState?.param1}
      />
      <div className={`length-text ${invoiceState?.param1?.length == 50 ? "text-danger" : ""}`}>
        {invoiceState?.param1?.length ?? "0"}/50
      </div>
    </div>
  );

  let additional2 = (
    <div className="input-length-validation">
      <label className="field-label mt-1">
        {fullCompany?.companyAttributes?.find(
          (a) => a?.attribute?.param == "AdditionalId2"
        )?.value ?? t("pos.additional") + " 2"}
      </label>
      <input
        type="text"
        name="param2"
        maxLength={50}
        disabled={disabled}
        onChange={(e) => handleInvoiceChange(e.target.value, "param2")}
        className="form-control form-control-sm"
        value={invoiceState?.param2}
      />
        <div className={`length-text  ${invoiceState?.param2?.length == 50 ? "text-danger" : ""}`}>
        {invoiceState?.param2?.length ?? "0"}/50
      </div>
    </div>
  );

  const CustomOption = ({ innerProps, isDisabled, label, data }) =>
    !isDisabled ? (
      <div className="option-custom-style" {...innerProps}>
        {/* {label} <PopOver title={popoOver.find(popover=>popover.value==data.value).popoOver} /> */}
        {label} <PopOver title={popOver[data.value]} />
        {innerProps.label}
      </div>
    ) : null;

  const handleCurrencyChange = async (currency) => {
    let res = false;
    const items = invoiceState.items
    if (!edit && (items.length > 1 || (items.length == 1 && items?.[0] && items?.[0].name != ""))) {
      res = await func.swalAlert({ title: t("invoice.currencyChange"), t })
    }
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      currency,
      items: !edit && res ? [{ ...stateDefault.items[0] }] : [...invoiceState.items],
      bankAccount: null,
      rate: currency != "ALL" ? "" : "1"
    }));
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "1px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      minHeight: "14px",
      maxHeight: "34px",
      fontSize: "0.8rem",
      marginBottom: "0.1rem",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      },
    }),
  };

  return (
    <div>
      <Form.Label className="fw-bold header ml-3 border-bottom">{t("invoice.data")}</Form.Label>
      <div className="row mx-2 mx-md-auto">
        <div className="col-12 col-md-6">
          <label className="field-label">
            {t("invoice.issueDate")}
            <span className="text-danger"> *</span>
            <PopOver title={t("hints.issueDate")} />
          </label>
          <DatePicker
            ref={issueDateRef}
            selected={invoiceState?.issueDate}
            disabled={disabled}
            dateFormat="dd/MM/yyyy"
            name="issueDate"
            autoComplete="off"
            minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
            showDisabledMonthNavigation
            onChange={handleIssueDateChange}
            customInput={
              <input
                className={`form-control form-control-sm ${
                  errors?.issueDate && "is-invalid"
                }`}
              />
            }
          />

          <label className="field-label">
            {t("invoice.sDate")}
            <PopOver title={t("hints.paymentStartDate")} />
          </label>
          <DatePicker
            selected={invoiceState.invoiceStartDate}
            disabled={disabled}customStyles
            // minDate={new Date(now.getFullYear(), now.getMonth(), 1)}
            maxDate={new Date(now.getFullYear(), now.getMonth() + 1, 0)}
            dateFormat="dd/MM/yyyy"
            onChange={handleStartDateChange}
            customInput={<input className="form-control form-control-sm" />}
          />

          <label className="field-label">
            Subseq
            <PopOver title={t("invoice.subseq")} />
          </label>
          <Select
            components={{ Option: CustomOption }}
            options={subseqValues}
            value={subseqValues.find(
              (subseq) => invoiceState.subSequenceDelivType === subseq.value
            )}
            isDisabled={disabled}
            styles={customStyles}
            onChange={(subseq) => handleSubseqChange(subseq)}
            name="subseq"
          />

          {additional1}
        </div>
        <div className="col-12 col-md-6">
          <label className="field-label">
            {t("invoice.paymentDate")}
            <span className="text-danger"> *</span>
            <PopOver title={t("hints.paymentDate")} />
          </label>
          <DatePicker
            selected={invoiceState.dueDate}
            disabled={disabled}
            dateFormat="dd/MM/yyyy"
            name="dueDate"
            autoComplete="off"
            minDate={invoiceState.dueDate}
            showDisabledMonthNavigation
            onChange={handleDueDateChange}
            customInput={
              <input
                className={`form-control form-control-sm ${
                  errors?.dueDate && "is-invalid"
                }`}
              />
            }
          />

          <label className="field-label">
            {t("invoice.endDate")}
            <PopOver title={t("hints.paymentEndDate")} />
          </label>
          <DatePicker
            selected={invoiceState?.invoiceEndDate}
            disabled={disabled}
            minDate={
              invoiceState?.invoiceStartDate?.length != 0
                ? invoiceState?.invoiceStartDate
                : new Date(now)
            }
            maxDate={
              invoiceState.invoiceStartDate?.length != 0
                ? new Date(
                    new Date(invoiceState?.invoiceStartDate).getFullYear(),
                    new Date(invoiceState.invoiceStartDate).getMonth() + 1,
                    0
                  )
                : new Date(now.getFullYear(), now.getMonth() + 1, 0)
            }
            dateFormat="dd/MM/yyyy"
            onChange={handleEndDateChange}
            customInput={<input className="form-control form-control-sm" />}
          />
          <label className="field-label">
            {t("invoice.currency")}
            <PopOver title={t("hints.currencySelect")} />
          </label>

          {disabled ? (
            <input
              className="form-control form-control-sm"
              value={
                currencies.find(
                  (currency) => currency.value === invoiceState.currency
                ).label
              }
              disabled
            />
          ) : (
            <Select
              styles={customStyles}
              options={currencies}
              value={currencies.find(
                (currency) => currency.value === invoiceState.currency
              )}
              isSearchable={true}
              onChange={(currency) => handleCurrencyChange(currency.value)}
              name="currency"
            />
          )}
          {additional2}

          {invoiceState.currency !== "ALL" && (
            <div>
              <label className="field-label">
                Exchange Rate
                <span className="text-danger"> *</span>
                <PopOver title={t("invoice.exchangeRateInfo")} />
              </label>
              <input
                name="rate"
                type="number"
                step="0.001"
                min="0"
                className="form-control form-contro-sm required"
                disabled={disabled}
                value={invoiceState.rate}
                onChange={handleInvoiceDataChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoInvoiceDatas;

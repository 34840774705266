import React from 'react'
import { useTranslation } from 'react-i18next';
import { enUS, sq } from 'date-fns/locale';
import DatePicker from 'react-datepicker';


const CalendarWrapper = (props) => {
  const { i18n } = useTranslation("translations");

  return (
    <DatePicker {...props} locale={i18n.language == "alb" ?sq:enUS} />
  )
}

export default CalendarWrapper
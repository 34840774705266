import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import func from '../../../services/functions'
import { useTranslation, withTranslation } from "react-i18next";
import TooltipOverlay from '../../global/TooltipOverlay'
const InvoiceStatus = ({ fiscStatus, einvoiceStatus, ublInvoiceTypeCode, comments, correctiveInvoiceType, einvoice, cisStatus,isRefreshStatus,invoice }) => {
    const { t, i18n } = useTranslation('translations');

    if(isRefreshStatus(invoice)){
        return (
            <TooltipOverlay show={0} hide={0} title={t("status.TE991DESC")}>
            <small className="badge badge-warning">
                Pending
                <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />

            </small>
        </TooltipOverlay>
        )
    }

    switch (func.getQueueStatus(fiscStatus, einvoiceStatus, ublInvoiceTypeCode, correctiveInvoiceType, einvoice, cisStatus)) {
        case 1: // Success (fiscStatus = 100, eInvoiceStatus = 100)
            return (
                <small className="badge badge-success">
                    eInvoice Success
                </small>
            )
        case 2: // Corrective Success (ublInvoiceTypeCode = "384", fiscStatus = 100, eInvoiceStatus = 100)
            return (
                <small className="badge badge-success">
                    Corrective Success F
                </small>
            )
        case 3: // Corrective Error F (ublInvoiceTypeCode = "384", 0 < fiscStatus < 100, eInvoiceStatus = null)
            return (
                <div className="d-inline-flex align-items-center">
                    <small className="badge badge-danger">
                        Corrective Error F
                    </small>
                    {comments && <><FontAwesomeIcon icon={faQuestionCircle} className="ml-2" data-tip={comments} /><ReactTooltip /></>}
                </div>
            )
        case 4: // Corrective Error E (ublInvoiceTypeCode = "384", fiscStatus = 100, 0 < eInvoiceStatus < 100)
            return (
                <div className="d-inline-flex align-items-center">
                    <small className="badge badge-danger">
                        Corrective Error E
                    </small>
                    {comments && <><FontAwesomeIcon icon={faQuestionCircle} className="ml-2" data-tip={comments} /><ReactTooltip /></>}
                </div>
            )
        case 5: // Corrective eInvoice (fiscStatus = -100, eInvoiceStatus = -100)
            return (
                <div className="d-inline-flex align-items-center">
                    <small className="badge badge-success">
                        Corrective Success E
                    </small>
                    {comments && <><FontAwesomeIcon icon={faQuestionCircle} className="ml-2" data-tip={comments} /><ReactTooltip /></>}
                </div>
            )
        case 6: //Corrective Fiscalization (fiscStatus = -100, eInvoiceStatus = null || 0 < eInvoiceStatus < 100)
            return (
                <div className="d-inline-flex align-items-center">
                    <small className="badge badge-warning">
                        Corrected Fiscalization
                    </small>
                    {comments && <><FontAwesomeIcon icon={faQuestionCircle} className="ml-2" data-tip={comments} /><ReactTooltip /></>}
                </div>
            )
        case 7: // Error Fiscalization (0 < fiscStatus < 100, eInvoiceStatus = null)
            return (
                <small className="badge badge-danger">
                    Error Fiscalization | {fiscStatus}
                </small>
            )
        case 8: // Error eInvoice (fiscStatus = 100, 0 < eInvoiceStatus < 100)
            return (
                <small className="badge badge-danger">
                    Error eInvoice | {einvoiceStatus}
                </small>
            )
        case 9: // Error Connection F (fiscStatus = -1, eInvoiceStatus = null)
            return (
                <small className="badge badge-danger">
                    Error Connection F
                </small>
            )
        case 10: // Error Connection E (fiscStatus = 100, eInvoiceStatus = -1)
            return (
                <TooltipOverlay show={0} hide={0} title={t("status.pendingEDesc")}>
                    <small className="badge badge-warning">
                        Pending E
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />
                    </small>
                </TooltipOverlay>

            )
        case 11: // Corrective Success (ublInvoiceTypeCode = "384" || correctiveInvoiceType = "CORRECTIVE", fiscStatus = 100)
            return (
                <small className="badge badge-success">
                    Corrective Success
                </small>
            )
        case 12: // Fiscalization Success (fiscStatus = 100, eInvoiceStatus = 100)
            return (
                <small className="badge badge-success">
                    Fiscalization Success
                </small>
            )
        case 13: //fiscStatus===100 && einvoice ===true && (eInvoiceStatus===56 || eInvoiceStatus===101 || eInvoiceStatus===8101)
            return (
                <TooltipOverlay show={0} hide={0} title={t("status.sucessPending")}>
                    <small className="badge badge-warning">
                        eInvoice Success
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />

                    </small>
                </TooltipOverlay>

            )
        case 14: // cisStatus ==TE991
            return (
                <TooltipOverlay show={0} hide={0} title={t("status.TE991DESC")}>
                    <small className="badge badge-warning">
                        Electronic Timeout
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />

                    </small>
                </TooltipOverlay>

            )
        case 15: // cisStatus ==TF991
            return (
                <TooltipOverlay show={0} hide={0} title={t("status.TF991DESC")}>
                    <small className="badge badge-warning">
                        Fisc Timeout
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />

                    </small>
                </TooltipOverlay>

            )
        case 16: // cisStatus ==T991
            return (
                <TooltipOverlay show={0} hide={0} title={t("status.T991DESC")}>
                    <small className="badge badge-warning">
                        Invoice Timeout
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />

                    </small>
                </TooltipOverlay>

            )
        default: // Unhandled Statuses
            return (
                <small className="badge badge-warning">

                </small>
            )
    }
}


export default InvoiceStatus
import React, { useState } from 'react'
import mainAxios from '../services/mainAxios';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useSubscriptionRequests = ({company,onSubscriptionChange}) => {
  const [actionLoading,setActionLoading]=useState(false)
  const [companySubscriptionsLoading,setCompanySubscriptionsLoading]=useState(false)
  const { user: [user], selectedCompany: [selectedCompany] } = useAppContext()
  const { t } = useTranslation('translations');


  const getSubscriptionPackages=async (id)=>{
    setCompanySubscriptionsLoading(true)
    return await mainAxios.post('apiEndpoint/search', {
        object: "GetSubscriptions",
        company: company.id,
      }).then(res => {
        setCompanySubscriptionsLoading(false)
        if (res.data.status) {
          return res.data.content
        }
        return null
      })
      .catch(err=>{
        setCompanySubscriptionsLoading(false)
        return null
      })
  }


  const getCompanySubscriptionAddons=async (companySubscription)=>{
    return await mainAxios.post('apiEndpoint/search', {
      object: "GetCompanySubscriptionAddons",
      company: company.id,
      params:{
        companySubscription
      }
    }).then(res => {
      if (res.data.status) {
        return res.data.content
      }
      return null
    })
    .catch(err=>{
      return null
    })
  }

const getSubscriptionById= async (id)=>{
  return await mainAxios.post('apiEndpoint/search', {
    object: "GetCompanySubscriptionsById",
    company: company.id,
    params:{
      id:id.toString()
    }
  }).then(res => {
    if (res.data.status) {
      return res.data.content[0]
    }
    return null
  })
  .catch(err=>{
    return null
  })
}

    const parseAddons= (addons) => {
        return addons.map(addon=>{
          let { addonsSubscription, status ,recordDate, recordUser, modificationDate, modificationUser,addonsSubscriptionId,statusId,...restAddon} = addon
          return { 
            addonsSubscriptionId:addonsSubscriptionId ?? addonsSubscription.id, //addonsSubscriptionId in case of addon
            statusId:statusId ?? status?.id, // //statusId in case of addon
            ...restAddon 
          }
        })
    }

   const parseSaveSubscription=({state})=>{
    let {
      expiresThisMonth,
      companySubscriptionAddons:addons=[],
      modificationUser,
      modificationDate,
      recordDate,
      recordUser,
      status,
      subscription,
      subscriptionId,
      period,
      ...restState
    }=state

    if (period === "custom") {
      const startDate = moment(state.startDate);
      const endDate = moment(state.endDate);
      
      period = endDate.diff(startDate, 'months', true); 
      period = Math.round(period * 100) / 100; 
    }
    const parsedAddons = parseAddons(addons)

    const parsedState={
      subscriptionId:subscriptionId ?? state?.subscription?.id,//subscriptionId if is new subscription
      statusId:state?.status?.id,
      addons:parsedAddons,
      company:company.id,
      period,
      ...restState,
    }
    return parsedState
   }

  const saveCompanySubscription=({state,onSubscriptionChange})=>{
    const content=parseSaveSubscription({state})
    setActionLoading(true); 
    
    return mainAxios
    .post('/apiEndpoint/saveOrUpdate', {
      object: "CompanySubscription",
      content,
      nuis: user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
      )?.nuis,
    })
    .then((res) => {
        setActionLoading(false)
      if (res?.data?.status === true) {
        toast.success(t("common.actionSuccessfully"), { containerId: "all_toast" })
        if(onSubscriptionChange)onSubscriptionChange(res?.data?.content?.[0])
      } 
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error('An error occurred');
      setActionLoading(false)
    });
  }
  return { 
    saveCompanySubscription,
    getSubscriptionPackages,
    companySubscriptionsLoading,
    actionLoading,
    getCompanySubscriptionAddons,
    getSubscriptionById
  }
}


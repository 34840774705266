import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext, usePersistedState } from '../../../AppContext';

import mainAxios from '../../../services/mainAxios';
import PrimeTable, { onColReorder } from "../../PrimeTable/PrimeTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faEdit, faExclamationTriangle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from "primereact/dialog";
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { swalAlert } from '../../../services/functions';
import Loader from '../../Layout/Loader';
import Addons from './Addons';
import AddEditSubscription from './AddEditSubscription';
import { subscriptionStatus as status, subscriptionStatus } from '../../../services/data';
import { useSubscriptionRequests } from '../../../requests/SubscriptionsRequests';

const moment = require("moment");


const Subscriptions = ({ company}) => {
  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();
  const [subscriptions, setSubscriptions] = useState([])
  const [subModal, setSubModal] = useState({
    subscription:null,
    open:false
  })
  const [addonModal, setAddonModal] = useState({
    open:false,
    subscription:false
  })
  const { t } = useTranslation("translations");
  const defaultFilters = { params: {} }
  const [filters, setFilters] = React.useState(defaultFilters);
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [expandedRows, setExpandedRows] = useState(null)
  const [loadingExpandData, setLoadingExpandData] = useState(false);
  const [expandedData, setExpandedData] = useState([]);
  const [loadingGrid,setLoadingGrid]=useState(false)
  const defaultSortCriteria={
    field: "startDate",
    direction: "DESC"
  } 

  const { getSubscriptionById,saveCompanySubscription }=useSubscriptionRequests({company})

const addOnActive=(id)=>{
  return [status.active,status.upcoming].includes(id)
} 
  const getGridSubscriptions=({ data,pageSize = 10,page = 1 }={},sortCriteria = [ defaultSortCriteria ])=>{
    setLoadingGrid(true)
    return mainAxios.post('apiEndpoint/search', {
      object: "GetCompanySubscriptions",
      company: company.id,
      params:{
        company:company.id.toString()
      },
      pagination:  {
        pageSize,
        pageNumber: page,
      },
      sortCriteria
    }).then(res => {
      setLoadingGrid(false)
      if (res.data.status) {
        setTableRows((tableRows)=>({...tableRows,totalRows:res?.data?.totalSize}))
        setSubscriptions(res.data.content)
      }
    })
    .catch(err=>{
      setLoadingGrid(false)
    })
  }
  

  const cancelSubscription=async (sub)=>{
    setLoadingGrid(true)
    let cancellingSub= await getSubscriptionById(sub.id)
    if(!cancellingSub){
      toast.error(t("common.somethingHappend"), { containerId: "all_toast" });
      return 
    }

    cancellingSub.statusId=subscriptionStatus.canceled
    cancellingSub.cancellationUser=user.username
    cancellingSub.cancellationDate= Date.now()

    await saveCompanySubscription({state:cancellingSub,onSubscriptionChange:()=>getGridSubscriptions()})
    setLoadingGrid(false)

  }

  const cancelAddon=async (currentAddon)=>{
    setLoadingGrid(true)
    const parsedAddons= expandedData?.companySubscriptionAddons?.map(addon=>{
      if(addon.id===currentAddon.id){
        return {
          ...addon,
          statusId:subscriptionStatus.canceled,
          cancellationUser:user.username,
          cancellationDate:Date.now()
        }
      }else{
        return {...addon}
      }
    })

    const parsedSubscription={
      ...expandedData,
      companySubscriptionAddons:parsedAddons
      
    }
    // return
    await saveCompanySubscription({state:parsedSubscription,onSubscriptionChange:(res)=>{
      getGridSubscriptions().then((_=>onRowExpand({data:res}))) 
    }})
    setLoadingGrid(false)

  }


  useEffect(()=>{
    getGridSubscriptions()
  },[company.id])

  const rowClass = (data) => {
    return {
        'disable-row': ![status.upcoming,status.active].includes(data.status)
    };
};


const calculateExpiresThisMonth=useCallback((row)=>{
  return row?.endDate && [status.active,status.upcoming].includes(row.status) &&  moment(row.endDate).isSame(moment(), 'month');
},[])


  const statusBody=(sub)=>{

    return (
      <>
      <div className='d-flex align-items-center'>
        {sub.status == status.active && 
          <div className="badge badge-success">{t("company.active")}</div>
        }
        {sub.status == status.canceled && 
          <div className="badge badge-danger">{t("common.cancelled")}</div>
        }
        {sub.status == status.upcoming && 
          <div className="badge badge-info">Upcoming</div>
        }
        {sub.status == status.expired && 
          <div className="badge badge-warning">Expired</div>
        }
      </div>
    </>
    )
  }

  const addOnStatusBody=(addon)=>{
    return (
      <>
      <div className='d-flex align-items-center'>
        {addon.status.id == status.active && 
          <div className="badge badge-success">{t("company.active")}</div>
        }
        {addon.status.id == status.canceled && 
          <div className="badge badge-danger">{t("common.cancelled")}</div>
        }
        {addon.status.id == status.upcoming && 
          <div className="badge badge-info">Upcoming</div>
        }
        {addon.status.id !=status.canceled && moment(addon.endDate).isBefore(new Date())&&
          <div className="badge badge-warning">Expired</div>
        }
      </div>
    </>
    )
  }

  const checkCorporate=(row,key)=>{
    return row.subscriptionId==5 && row[key]==0?'\u221E':row[key]
  }

  const columns = [

    {
      header: t(["home.actions"]),
      field: "actions",
      style: { minWidth: "280px", maxWidth: "220px" },
      persisted:true,
      body: (sub) => (<>
        {
          (sub.status==status.active || sub.status==status.upcoming ) && 
          <div className='d-flex align-items-center'>
          <Link onClick={(e) => { e.preventDefault(); setSubModal({open:true,subscription:sub})}}  className="nav-link p-0 mr-2">
            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("common.edit")}
          </Link>
          <Link onClick={(e) => { e.preventDefault(); setAddonModal({open:true,subscription:sub})}}  className="nav-link text-info p-0 mr-2">
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            Addon
          </Link>
          {[status.active,status.upcoming].includes(sub.status.toString()) &&<Link onClick={(e) => { e.preventDefault(); handleCancel(sub); }}  className="nav-link p-0 mr-2 text-danger">
            <FontAwesomeIcon icon={faCancel} className="mr-1" />
            {t("common.cancel")}
          </Link>}
        </div>
        }
      </>
      ),
      hidden:user?.role?.id!==1
    },
    {
      header: "Status",
      field: "status",
      style: { minWidth: "90px", maxWidth: "90px" },
      body: (sub) =>statusBody(sub),
      persisted:true,
    },
        {
      header: t("purchases.warnings"),
      field: "expiresThisMonth",
      style: { minWidth: "190px", maxWidth: "200px" },
      body: (row) => {
        const expiresThisMonth=calculateExpiresThisMonth(row)
        const statusExpired = row.status == status.expired 

        const inActiveCompany = (!company?.active) && [status.active,status.upcoming].includes(row.status)
          return <div className='d-flex flex-column'>
          {(expiresThisMonth && ![status.canceled,status.expired].includes(row.status) )&& !inActiveCompany && !statusExpired
            ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center',width:"160px" }}>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              {t(["company.expiresWarning"])}
            </div>
            : null}
            {inActiveCompany && <div style={{ backgroundColor: '#dc3545', color: '#fff', padding: '2px 7px',marginTop:5, borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center',width:"200px"}}>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              {t(["company.isCompanyActive"])}
            </div>}
          </div>

        },
      format:(row)=>calculateExpiresThisMonth(row)
    },
    {
      header: t(["subscription.packName"]),
      field: "name",
      body: (sub) => sub?.subscriptionName,
      style: { minWidth: "160px", maxWidth: "180px" },
      persisted:true,
    },
    {
      header: t(["invoice.sDate"]),
      field: "startDate",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (subs) =>
      subs.startDate
          ? moment(subs.startDate).format("DD/MM/YYYY HH:mm")
          : "",
      persisted:true,
      
    },
    {
      header: t(["invoice.endDate"]),
      field: "endDate",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (subs) =>
      subs.endDate
          ? moment(subs.endDate).format("DD/MM/YYYY HH:mm")
          : "",
      persisted:true,
      
    },
    {
      header: t(["subscription.cancelationDate"]),
      field: "cancellationDate",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (subs) =>
      subs.cancellationDate
          ? moment(subs.cancellationDate).format("DD/MM/YYYY HH:mm")
          : "",
      persisted:true,
      
    },
    {
      header: t("subscription.subPeriod"),
      field: "totPeriod",
      style: { minWidth: "180px", maxWidth: "200px" },
      body: (sub) => sub?.totPeriod + " " + "months",
      default:true,
      persisted:true,
    },
  
    {
      header:t("subscription.totalInvoices"),
      field: "sentInvoices",
      body: (sub) =>{
        const sentInvoices=sub?.sentInvoices
        return  <>                       
      {(sentInvoices!=null ?sentInvoices:"-" )+" /"}  {checkCorporate(sub,"availableInvoices")}
      </>
      },
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    {
      header:t("subscription.leftInv"),
      field: "leftInvoices",
      body: (sub) =><>{sub?.leftInvoices }</>,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("subscription.totalUsers"),
      field: "totalUsers",
      body: (sub) =>{
        const usedUsers=sub?.usedUsers
        return <>                       
      {(usedUsers!=null ?usedUsers:"-" )+" /"}  {checkCorporate(sub,"totalUsers")}
      </>
      },
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("subscription.totalTcrs"),
      field: "totalTcrs",
      body: (sub) =>{
        const usedTcrs=sub?.usedTcrs
        return <>                       
      {(usedTcrs!=null ?usedTcrs:"-" )+" /"}  {checkCorporate(sub,"totalTcrs")}
      </>
      },
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("item.notes"),
      field: "notes",
      body: (sub) =>sub?.notes ,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header:t("table.recordUser"),
      field: "recordUser",
      body: (sub) => sub?.recordUser,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header: t("table.recordDate"),
      field: "recordDate",
      body: (sub) =>sub?.recordDate?moment(sub.recordDate).format("DD/MM/YYYY HH:mm"):"",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    {
      header: t("table.cancellationUser"),
      field: "cancellationUser",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
  ];
  
  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
    "subscriptions2"
);



  const addSubscription = (
    <div className="page-actions mr-3 mt-4">
      <Link onClick={(e) => { e.preventDefault(); setSubModal({open:true,subscription:null})}}  className="btn btn-sm btn-outline-primary">
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        {t("subscription.addSubscription")}
      </Link>
    </div>
  )


  const handleCancel = (sub) => {
    swalAlert({ title: t("notification.cancelSubscription"), t })
      .then((res) => {
        if (res) {
          cancelSubscription(sub);
        } else {
          console.log('Cancellation canceled.');
        }
      });
  };
  const handleCancelAddon = (addon) => {
    swalAlert({ title: t("notification.cancelAddon"), t })
      .then((res) => {
        if (res) {
          cancelAddon(addon);
        } else {
          console.log('Cancellation canceled.');
        }
      });
  };



  const expansionColumns=[
    {
      header:"",
      field:"",
      style: { minWidth: "100px", maxWidth: "100px" },
    },
    {
      header: t(["home.actions"]),
      field: "actions",
      style: { minWidth: "100px", maxWidth: "100px" },
      
      body: (addon) => {
        const active = [status.upcoming,status.active].includes(addon?.status?.id?.toString())
        if(active){
          return <>
            <div className='d-flex align-items-center'>
            <Link onClick={(e) => { e.preventDefault(); handleCancelAddon(addon)}}  
            className="nav-link p-0 mr-2 text-danger">
              <FontAwesomeIcon icon={faCancel} className="mr-1" />
              {t("common.cancel")}
            </Link>
          </div>
        </>
        }
        return <></>
      },
      // hidden:user?.role?.id!==1
    },
    {
      header:"Status",
      field: "status",
      style: { minWidth: "90px", maxWidth: "90px" },
      body: (sub) => addOnStatusBody(sub)    
    },
    {
      header:t("subscription.addonName"),
      field: "name",
      style: { minWidth: "220px", maxWidth: "220px" },
      body: (row) =>row?.addonsSubscription?.name ?? "",
    },
    {
      header: t("item.quantity"),
      field: "unit",
      style: { minWidth: "100px", maxWidth: "100px" },
      body: (row) =>row?.unit
    },
    // {
    //   header: t("table.recordUser"),
    //   field: "companySubscriptionAddonsRecordUser",
    //   style: { minWidth: "150px", maxWidth: "150px" },
    //   body: (row) =>row?.companySubscriptionAddonsRecordUser
    // },
    // {
    //   header: t("table.recordDate"),
    //   field: "companySubscriptionAddonsRecordDate",
    //   style: { minWidth: "150px", maxWidth: "150px" },
    //   body: (row) =>row?.companySubscriptionAddonsRecordDate?moment(row?.companySubscriptionAddonsRecordDate).format("DD/MM/YYYY"):""
    // },
    {
      header: t("table.cancellationUser"),
      field: "cancellationUser",
      style: { minWidth: "150px", maxWidth: "150px" },
      default:true
    },
    {
      header: t("subscription.cancelationDate"),
      field: "cancellationDate",
      style: { minWidth: "150px", maxWidth: "150px" },
      body: (row) =>row?.cancellationDate?moment(row?.cancellationDate).format("DD/MM/YYYY"):""
    },
    // {
    //   header: t("purchases.warnings"),
    //   field: "expiresThisMonth",
    //   style: { minWidth: "220px", maxWidth: "220px" },
    //     body: (row) => (
    //     row?.expiresThisMonth
    //       ? <div style={{ backgroundColor: '#ffc107', color: '#664d03', padding: '2px 7px', borderRadius: '5px', fontSize: "13px", fontWeight: "500", display: 'inline-flex', alignItems: 'center' }}>
    //         <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
    //         {t(["company.expiresWarning"])}
    //       </div>
    //       : null
    //   ),
    // },
    {
      header: t("item.notes"),
      field: "notes",
      style: { minWidth: "220px", maxWidth: "220px" },
    }, 
    {
      header:t("table.recordUser"),
      field: "recordUser",
      body: (sub) => sub?.recordUser,
      style: { minWidth: "170px", maxWidth: "170px" },
      default:true
    },
    {
      header: t("table.recordDate"),
      field: "recordDate",
      style: { minWidth: "150px", maxWidth: "150px" },
      body: (row) =>row?.recordDate?moment(row?.recordDate).format("DD/MM/YYYY"):""
    },
  ]


  const expansionRowClass=(sub)=>{
    return {
      'disable-row':  [status.active,status.upcoming].includes(sub.status) 
  }
}

  const columnsFiltered=useMemo(()=>{
    return columns.filter(col=>col.hidden!==true)
  },[user?.role])

  const expansionColumnsFiltered=useMemo(()=>{
    return expansionColumns.filter(col=>col.hidden!==true)
  },[user?.role,expandedRows,expandedData])

  const rowExpansionTemplate = (data) => {
    if (loadingExpandData) return (
      <div className="d-flex justify-content-start" style={{width:"100vw"}}>
        <Loader color="white"></Loader>;
      </div>
    )
    return (
      <div className="prime-header-sticky" style={{ width: "80vw", }}>
        <h3 className='my-2' style={{marginLeft:110,textDecoration:"underline"}}>Add-Ons</h3>
        <PrimeTable
            stripedRows={false}
            reorderableColumns={true}
            columns={expansionColumnsFiltered}
            responsiveLayout="scroll"
            showHeader={false}
            dataKey="id"
            rowClassName={(addOn)=>expansionRowClass(addOn)}
            simpleTable={true}
            sortField={"active"}
            sortOrder={-1}
 
            value={expandedData?.companySubscriptionAddons ?? []}
            columnResizeMode="expand"
            scrollable={true}
            scrollHeight="350px"

        />
      </div>
    )
  }

  const onRowExpand =async (event) => {
    setLoadingExpandData(true);
    setExpandedRows([event.data]);
    const content=await getSubscriptionById(event.data.id)
    setLoadingExpandData(false);
    setExpandedData(content);
  };

  const onRowCollapse = (event) => {
    setExpandedRows([]);
  };

  return (
    <div className='overflow-y-scroll'>
    <Dialog
        header={subModal.subscription?t("subscription.edit"):t("subscription.addSubscription")}
        visible={subModal.open}
        onHide={() => setSubModal(false)}
        style={{ width: "25vw",minHeight:"55%", overflow: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        {subModal?.open && <AddEditSubscription
          subscription={subModal.subscription}
          company={company}
          onSubscriptionChange={() => {setSubModal({open:false,subscription:null});getGridSubscriptions()}} 
        />}
      </Dialog>

    <Dialog
        header={t("subscription.addons")}
        visible={addonModal?.open}
        onHide={() => setAddonModal({open:false,subscription:null})}
        style={{ width: "25vw",minHeight:"55%", overflow: "auto" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        {addonModal?.open && <Addons
          subscriptionsHistory={subscriptions}
          subscriptionId={addonModal?.subscription?.id}
          company={company}
          onSubscriptionChange={() => {  setAddonModal({open:false,subscription:null});getGridSubscriptions()}}
        />}
      </Dialog>

      <div className="border-0">
        <div className="card-body">
        <PrimeTable
          // globalFilterValue={[searchQuery, setSearchQuery]}
          stripedRows={false}
          rowClassName={rowClass}
          rowExpansionTemplate={rowExpansionTemplate}
          tableRows={[tableRows, setTableRows]}
          value={subscriptions ?? []}
          columns={columnsFiltered}
          showFilters={false}
          loading={loadingGrid}
          filterDisplay={"row"}
          responsiveLayout="scroll"
          scrollHeight="580px"
          // filters={[filters, setFilters]}
          defaultFilters={defaultFilters}
          showHeader={true}
          simpleTable={true}
          sortField={"active"}
          sortOrder={-1}
          // showGlobalSearch={false}
          dataKey="id"
          headerButtonsRight= {addSubscription}
          // showSelectColumns= {false}
          clearFilters= {false}
          selectedColumns={[selectedColumns, setSelectedColumns]}
          expandedRows={expandedRows}
          reorderableColumns={true}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          onColReorder={(reorder) => onColReorder({ reorder, setSelectedColumns, childrenCount: 1 })}
          onRemoteDataChange={(remoteData) => {
            const updatedFilters = remoteData.filters ?? filters
            const updatedTableData = remoteData.tableRows ?? tableRows
            let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
            sortCriteria=sortCriteria?[sortCriteria]:[]
            getGridSubscriptions({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria })
          }}
      >
                <Column 
            expander={() => true}
            style={{ width: "0.5em" }}
            header=""
            field="expansion"
            frozen={true}
          />
        </PrimeTable>
        </div>
      </div>
    </div>
  );





};

export default Subscriptions;


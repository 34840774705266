import React, {useState, useRef, useEffect} from "react"
import Table from "../Table"

import { Link } from "react-router-dom"

import { mainAxios } from '../../services/mainAxios'

import "react-datepicker/dist/react-datepicker.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons"
import Loader from "../Layout/Loader"
import { useAppContext, usePersistedState } from '../../AppContext'

import {useTranslation, withTranslation} from "react-i18next";
import AlertCertificate from "../global/AlertCertificate"
import ExportCsv from "../global/ExportCsv"
import PrimeTable from "../PrimeTable/PrimeTable"
import ExportCsvPrime from "../global/ExportCsvPrime"
import { useHistory } from 'react-router-dom';
const moment = require("moment")


const Companies = (props) => {
  const history=useHistory();
    const {t,i18n} = useTranslation('translations');
    const [companiesForCsv, setCompaniesForCsv] = useState([]);
    const { user: [user,], selectedCompany: [selectedCompany,] } = useAppContext()
    const [loadingGrid,setLoadingGrid]=useState(false)
    const [expandedRows,setExpandedRows]=useState([])
    const [companies, setCompanies] = useState(null)
    const defaultSortCriteria={
      field: "recordDate",
      direction: "DESC"
    }
    const defaultFilters = {
      active: null
    }
    const [filters, setFilters] = useState({
      params:{}
    });
    const [tableRows, setTableRows] = useState({
        totalRows: 0,
        rowsPerPage: 10,
        currentPage: 1,
        sortCriteria:defaultSortCriteria
      });

      const [searchQuery, setSearchQuery] = useState('');

      const isSysAdmin=user?.role?.id===1

      React.useEffect(() => {
        getFilteredResults();
      },[]);

      const getFilteredResults = async({
        updatedFilters,
        rowsPerPage = 10,
        currentPage = 1,
        exportCsv = false,
        sortCriteria=[ defaultSortCriteria ],
      } = {}) => {
        let requestBody = {
          object: "GetSimpleCompany",
          value: null,
          params: updatedFilters?.params,
          pagination: exportCsv ? null : {
            pageSize: rowsPerPage,
            pageNumber: currentPage,
          },
          sortCriteria,
        };
        setLoadingGrid(true);
        return await mainAxios
          .post("apiEndpoint/search", requestBody)
          .then((res) => {
            setLoadingGrid(false);
            if (res?.data?.status === true) {
              let companies = res?.data?.content;
              if (exportCsv) {
                 setCompaniesForCsv(res.data.content);
                return true;
              } else {
                setCompanies(companies);
                setTableRows((tableRows) => ({
                  ...tableRows,
                  totalRows: res?.data?.totalSize,
                }));
              }
            }
          })
          .catch((err) => {
            console.log({ err });
            setLoadingGrid(false);
          });
      };


    const columns = [
        {
          expander: true,
          style: { width: "0.5em" },
          field: "expansionCol",
          frozen: true,
        },
        {
            header: t(['company.name']),
            field: 'name',
            sortable: true,
            style:{ minWidth: '150px', maxWidth:"150px" },
            filter:true
        },
        {
            header: 'NIPT',
            field: 'nuis',
            sortable: true,
            style:{ minWidth: '150px', maxWidth:"150px" },
            filter:true
        },
        {
            header: t('company.issuerInVat'),
            field: 'issuerInVat',
            dataType: "boolean",
            filterType: "TRISTATECHECKBOX",
            format:(company)=>company?.issuerInVat?"TRUE":"FALSE",
            sortable: true,
            style:{ minWidth: '150px', maxWidth:"150px" },
            filter:true
        },
        {
            header:  t(['home.dateActivProfisc']),
            field: 'recordDate',
            // filterType: "DATE",
            sortable: true,
            formattedExportValue: true,
            preventExcelFormat: true,
            // filter:true,
            body: company => company.recordDate ? moment(company.recordDate).format("DD/MM/YYYY HH:mm") : ""
        },
        {
            header: t(['company.type']),
            field: 'type',
            sortable: true
        },
        {
          header: t(['company.beginofSubscription']),
          field: 'beginningDate',
          filterType: "DATE",
          formattedExportValue: true,
          preventExcelFormat: true,
          style:{ minWidth: '170px', maxWidth:"170px" },
          body: company => company.beginningDate ? moment(company.beginningDate).format("DD/MM/YYYY HH:mm") : "",
          filter: true,
          sortable: true,
          persisted:false
        },
        {
            header: t(['company.endofSubscription']),
            width: "220px",
            formattedExportValue: true,
            preventExcelFormat: true,
            body: company => company.expirationDate ? moment(company.expirationDate).format("DD/MM/YYYY HH:mm") : "",
            field: 'expirationDate',
            sortable: true,
            filterType: "DATE",
            style:{ minWidth: '150px', maxWidth:"150px" },
            filter:true
        },
          {
          header: t(['company.active']),
          field: 'isActive',
          dataType: "boolean",
          filter:true,
          align:"center",
          filterType:"TRISTATECHECKBOX",
          format: (company) => {
            return company.active ? 'Yes' : 'No'
         },
          body: company => company.active ? t(['company.yes']) : t(['company.no'])
        },
        {
            field:"actions",
            header:  t(['home.actions']),
            body: company => (
                <>
                    <div
                        onClick={()=> history.push("/companies/" + company.id,tableRows.totalRows)}
                        className="nav-link text-primary p-0 mr-2 cursor-pointer">
                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                        {t('company.view')}
                    </div>
                </>
            )
        }
    ];


    const expandableColumns = [
        {
            header:  t(['company.businUnitCode']),
            field: 'businUnitCode',
            style: { minWidth: "100px", maxWidth: "100px" },
        },
        {
          header: t("company.profiscStat"),
          field: 'profiscStatus',
          sortable: true,
          style:{ minWidth: '150px', maxWidth:"150px"},
          align:"center",
          dataType:"boolean",
        },
        {
            header: t(['home.dateActivProfisc']),
            field: 'recordDate',
            body: row => row?.recordDate ? moment(row.recordDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: "200px", maxWidth: "200px" },
        },
        {
            header: t(['company.sellerAddress']),
            field: 'sellerAddress',
            style: { minWidth: "350px", maxWidth: "350px" },
        },
        {
            header: t(['company.default']),
            field: 'default',
            body: row => row?.default ? t(['company.yes']) : t(['company.no']),
            style: { minWidth: "150px", maxWidth: "150px" },
        }
    ];

    let data = companies ?? null

    const rowExpansionTemplate = (e)=>{
      return (
        <div className="prime-header-sticky" style={{ marginBottom:20 }}>
        <PrimeTable
          value={e?.branches ?? []}
          columns={expandableColumns}
          simpleTable={true}
          showFilters={false}
          paginator={false}
          filterDisplay={false}
          responsiveLayout="scroll"
          // scrollable={true}
          // scrollHeight="400px"
      />
  </div>)

    }
    return data ? (
      <>
        <div className="d-flex flex-wrap justify-content-between align-items-center my-2">
          <div className="d-flex align-items-center">
          <h3 className="p-0 m-0">{t("navbar.companies")}</h3> <small className="ml-3">
            <ExportCsvPrime
              data={companiesForCsv}
              columns={columns}
              onClick={() => getFilteredResults({exportCsv:true,updatedFilters:filters})}
              excludedColumns={["expansionCol","actions"]}
            />
          </small>
          <AlertCertificate />
          </div>

          <div className="page-actions">
            <Link to="/companies/add" className="btn btn-sm btn-primary">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t("subscription.add")}
            </Link>
          </div>
        </div>
        <div className="card border-0 shadow">
          <PrimeTable
                loading={loadingGrid}
                filters={[filters, setFilters]}
                tableRows={[tableRows, setTableRows]}
                value={companies}
                columns={columns}
                defaultFilters={defaultFilters}
                onRowToggle={(e) => setExpandedRows(e.data)}
                expandedRows={expandedRows}
                rowExpansionTemplate={rowExpansionTemplate}
                responsiveLayout="scroll"
                dataKey="id"
                clearFilters={true}
                headerButtonsRight
                showSelectColumns={false}
                showFilters={true}
                reorderableColumns={true}
                onRemoteDataChange={(remoteData) => {
                    const updatedFilters = remoteData.filters ?? filters
                    const updatedTableData = remoteData.tableRows ?? tableRows
                    let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                    sortCriteria = sortCriteria ? [sortCriteria] : null
                    getFilteredResults({updatedFilters, rowsPerPage, currentPage,sortCriteria,searchQuery:remoteData?.searchQuery})
                }}
               defaultSortCriteria={defaultSortCriteria}

            />
        </div>
      </>
    ) : (
      <Loader />
    );
}

export default Companies;

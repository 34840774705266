import React, { useLayoutEffect, useState } from "react";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import unitsOfMeasure from "../../../services/unitsOfMeasure.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../../services/mainAxios";
import Select from "react-select";
import selectStyle, { uomStyle } from "../../../design/selectStyle";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
import Page404 from "../../Page404";
import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from "../../../AppContext";
import { react } from "@babel/types";
import ReactSelectPagination from "../../global/ReactSelectPagination";
import PopOver from "../../global/PopOver";

const bigDecimal = require("js-big-decimal");

const EditItem = ({ onClose, ...props }) => {
  const { t, i18n } = useTranslation("translations");
  const [state, setState] = React.useState({
    companyItem: null,
  });
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);


  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany],
    unitsOfMeasure,getUnitOfmeasure,
    uomPagination,
    setUomPagination
  } = useAppContext();

  const [errors, setErrors] = useState({
    name: null,
    priceWovatAll: null,

  });


  React.useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCompanyItem",
        params: {
          id: String(props?.id),
        },
      })
      .then((res) => {
        if (res) {
          setProduct(res.data.content[0]);
        }
        setPageLoading(false);
      });
  }, []);

  React.useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetCategories",
      })
      .then((res) => {
        if (res) {
          let categoryOptions = res?.data?.content.map((category) => {
            return {
              value: category.id,
              label: category.name,
            };
          });

          setCategories(categoryOptions);
        }
        setPageLoading(false);
      });
  }, []);

  const unitOfMeasureLabel = (unitOfMeasure) => {
    return i18n.language == "alb"
      ? unitOfMeasure?.albanianName
      : unitOfMeasure?.englishName;
  };

  const unitOfMeasureCellConfig={
    type: "REACT-SELECT",
    options: unitsOfMeasure,
    getOptionLabel:(uom)=>uom?.code + '-' + unitOfMeasureLabel(uom),
    getOptionValue:(option)=>option.id,
    optionValue:"id",
    placeholder:t("item.measure"),
    menuPosition:"fixed",
    isClearable:false,
    getFilteredResults:getUnitOfmeasure,
    pagination:uomPagination,
    setPagination:setUomPagination,
    }


  const typeOfVatCategoryOptions = [
    { label: "0%", value: "0" },
    { label: "6%", value: "6" },
    { label: "10%", value: "10" },
    { label: "20%", value: "20" },
  ];

  const investementOptions = [
    { label: "False", value: false },
    { label: "True", value: true },
  ];

  const editCompany = (e) => {
    e.preventDefault();
    setLoading(true);
    const { name, priceWovatAll,priceWovatEur,priceWovatUsd} = product;

    let checkError=!name ||(!priceWovatAll && !priceWovatEur && !priceWovatUsd);
    if (!name) {
      setErrors((errors) => {
        return { ...errors, name: t("toast.itemNameError") };
      });
    }
    if (!priceWovatAll && !priceWovatEur && !priceWovatUsd) {
      setErrors((errors) => {
        return { ...errors, priceWovatAll: t("toast.noItemPrice") };
      });
    }
    if(checkError){
      setLoading(false);

      return;
    }

    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "CompanyItems",
        content: {
          ...product,
        },
        nuis: user.companiesRights.find(
          (companyItem) => companyItem.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(t(["toast.successItems"]), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            containerId: "all_toast",
          });
          props.updateItem();
          onClose();
        }
          props.setVisibleItems(false)
          setLoading(false);
      });
  };

  const handleInputChange = (e, selectKey, selectValue, number = true) => {
    const key = e ? e.target.name : selectKey;
    let value = e ? e.target.value : selectValue;

    if (number == true && value?.split(".")?.[1]) {
      value = value.split(".");
      value = value[0] + "." + value[1].slice(0, 10);
      value = new bigDecimal(value).getValue();
    }
    if(errors[key]){
      setErrors(prevState=>{return {...prevState,[key]:null}})
    }

    setProduct((product) => ({
      ...product,
      [key]: value,
    }));
    if(key=="category"){
          setProduct((product) => ({
      ...product,
      category: {id:value},
    }));
    }
    if (key === "priceWovatAll" || key==="priceWovatEur" || key==="priceWovatUsd") {
      const { vatCategory } = product ?? 0;
      let keyToUpdate="priceWvatAll"
      if(key=="priceWovatEur"){
        keyToUpdate="priceWvatEur"
      }else if(key=="priceWovatUsd"){
        keyToUpdate="priceWvatUsd"
      }
      let priceWithVat =
        vatCategory == 0
          ? value
          : bigDecimal.multiply(
              value,
              bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
            );
      if (priceWithVat?.split(".")?.[1]) {
        priceWithVat = priceWithVat?.split(".")
        let trimmedValue = priceWithVat?.[1]?.replace(/0+$/, '')?.slice(0, 10) ?? ""

        priceWithVat = priceWithVat[0] + (trimmedValue?.length > 0 ? ("." + trimmedValue) : "")

      }
      setProduct((product) => ({
        ...product,
        [keyToUpdate]: priceWithVat,
      }));
    }

    if (key === "priceWvatAll" || key=="priceWvatEur" || key=="priceWvatUsd" ) {
      const { vatCategory } = product;

      let keyToUpdate="priceWovatAll"
      if(key=="priceWvatEur"){
        keyToUpdate="priceWovatEur"
      }else if(key=="priceWvatUsd"){
        keyToUpdate="priceWovatUsd"
      }
      let priceWIthoutVat =
        vatCategory == 0
          ? value
          : bigDecimal.divide(
              value,
              bigDecimal.add(1, bigDecimal.divide(vatCategory, 100))
            );

      if (priceWIthoutVat?.split(".")?.[1]) {
        priceWIthoutVat = priceWIthoutVat?.split(".")
        let trimmedValue = priceWIthoutVat?.[1]?.replace(/0+$/, '')?.slice(0, 10) ?? ""

        priceWIthoutVat = priceWIthoutVat[0] + (trimmedValue?.length > 0 ? ("." + trimmedValue) : "")

      }

      setProduct((product) => ({
        ...product,
        [keyToUpdate]: priceWIthoutVat,
      }));
    }
  };

  const vatTypeOptions = [
    { label: t("vat.none"), value: "" },
    { label: t("vat.type_1"), value: "TYPE_1" },
    { label: t("vat.type_2"), value: "TYPE_2" },
    { label: t("vat.tax_free"), value: "TAX_FREE" },
    { label: t("vat.margin_scheme"), value: "MARGIN_SCHEME" },
    { label: t("vat.export_of_goods"), value: "EXPORT_OF_GOODS" },
  ];

  return product ? (
      <div className="row">
        <form
          className="col-xl-12 col-lg-8 col-md-8 col-sm-10 col-12 mx-auto"
          onSubmit={editCompany}
        >
          <div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_code">{t("item.code")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={product.code}
                      onChange={(e) => handleInputChange("", "code", e.target.value, 50, false) }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_name">{t("item.name")}</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      } `}
                      name="name"
                      id="name"
                      value={product.name}
                      onChange={(e) => handleInputChange("", "name", e.target.value, 50, false)}
                    />

                    {errors.name && (
                      <div
                        className="invalid-feedback"
                      >
                        {t("toast.itemNameError")}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_description">
                      {t("item.description")}
                    </label>
                    <PopOver
                    customStyle={{ borderRadius: "40%", width: "20px", height: "20px", transform: "scale(0.80)", backgroundColor: "#343a40", color: "white" }}
                    title={t('hints.descItem')}
              />
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={product.description}
                      onChange={(e) => handleInputChange(null, "description", e.target.value, null, false)}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_investment">
                      {t("item.investment")}
                    </label>
                    <span className="text-danger"> *</span>
                    <Select
                      styles={selectStyle}
                      name="investment"
                      options={investementOptions}
                      value={investementOptions.find(
                        (el) => el.value === product.investment
                      )}
                      onChange={(option) => {
                        handleInputChange(null, "investment", option?.value, null, false);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_measure">{t("item.measure")}</label>
                    <span className="text-danger"> *</span>
                    <ReactSelectPagination
                    styles={uomStyle}
                    value={product?.unitOfMeasureId}
                    onChange={(value) => {
                      setProduct((product)=>({...product,unitOfMeasure:value?.code,
                        unitOfMeasureId:value}))
                    }}
                    {...unitOfMeasureCellConfig}

                      />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_category">{t("item.category")}</label>
                    <span className="text-danger"> *</span>
                    <Select
                      styles={selectStyle}
                      options={categories}
                      name="category"
                      required
                      value={categories.find(
                        (category) => category.value === product?.category?.id
                      )}
                      onChange={(option) => {
                        handleInputChange(null, "category", option.value, null, false);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_vatReason">
                      {t("item.vatReason")}
                    </label>
                    <Select
                      styles={selectStyle}
                      type="text"
                      id="vatReason"
                      name="vatReason"
                      options={vatTypeOptions}
                      value={
                        vatTypeOptions.find(
                          (VatC) => VatC.value === product.vatReason
                        ) || ""
                      }
                      onChange={(reason) =>
                        handleInputChange(null, "vatReason", reason.value)
                      }
                      isDisabled={product.vatCategory !== "0"}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_vatCategory">
                      {t("item.vatCategory")}
                    </label>
                    <span className="text-danger"> *</span>
                    <Select
                      styles={selectStyle}
                      type="text"
                      id="vatCategory"
                      name="vatCategory"
                      options={typeOfVatCategoryOptions}
                      value={
                        typeOfVatCategoryOptions.find(
                          (VatC) => VatC.value === product.vatCategory
                        ) || null
                      }
                      onChange={(option) => {
                        handleInputChange(null, "vatCategory", option?.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <div className="form-group">
                      <label htmlFor="item_totalLekWithoutVat">
                        {t("item.totalLekWithoutVat")}
                      </label>
                      <span className="text-danger"> *</span>
                      <input
                        type="number"
                        value={product.priceWovatAll}
                        name="priceWovatAll"
                        className={`form-control ${
                          errors.priceWovatAll ? "is-invalid" : ""
                        }`}
                        onChange={handleInputChange}
                        disabled={categories.length === 0 ? true : false}
                      />
                     {errors.priceWovatAll && (
                    <div className="invalid-feedback">
                      {t("toast.noItemPrice")}
                    </div>
                  )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_totalWithVat">
                      {t("item.totalLekWithVat")}
                    </label>
                    <input
                      type="number"
                      value={product?.priceWvatAll}
                      name="priceWvatAll"
                      className="form-control"
                      onChange={handleInputChange}
                      disabled={categories.length === 0 ? true : false}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="item_totalEuroWithoutVat">
                      {t("item.totalEuroWithoutVat")}
                    </label>
                    <span className="text-danger"> *</span>
                    <input
                      type="number"
                      value={product.priceWovatEur}
                      name="priceWovatEur"
                      className={`form-control`}
                      onChange={handleInputChange}
                      disabled={categories.length === 0 ? true : false}
                    />
                  </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalEuroWithVat">
                    {t("item.totalEuroWithVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWvatEur}
                    name="priceWvatEur"
                    className="form-control"
                    onChange={handleInputChange}
                    disabled={categories.length === 0 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalDollarWithoutVat">
                    {t("item.totalDollarWithoutVat")}
                  </label>
                  <span className="text-danger"> *</span>
                  <input
                    type="number"
                    value={product.priceWovatUsd}
                    name="priceWovatUsd"
                    className={`form-control`}
                    onChange={handleInputChange}
                    disabled={categories.length === 0 ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="item_totalDollarWithVat">
                    {t("item.totalDollarWithVat")}
                  </label>
                  <input
                    type="number"
                    value={product.priceWvatUsd}
                    name="priceWvatUsd"
                    className="form-control"
                    onChange={handleInputChange}
                    disabled={categories.length === 0 ? true : false}
                  />
                </div>
              </div>
              </div>
              <button
                className="btn btn-primary mt-3"
                disabled={loading}
                style={{ minWidth: "100px" }}
                type="submit"
              >
                {loading ? (
                  <Loader size="xs" color="white" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t("common.save")}
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    ) : (
      <Loader />
    )

};

export default EditItem;

import React, { useState } from 'react'
import { useAppContext } from '../../../AppContext';
import { globalHandleChange } from '../../../services/functions';
import { Checkbox } from 'primereact/checkbox';
import Loader from '../../Layout/Loader';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import DatePicker from "react-datepicker";
import { Column } from 'primereact/column';
import mainAxios from '../../../services/mainAxios';
import { toast } from 'react-toastify';


const useRegisterBranchInCis = ({ branches = [],company,refreshGrid } = {}) => {
    const defaultState={
        selectedRows: [],
        openRegisterBranchIncCis: false,
        actionLoading: false,
        validFrom:new Date(),
        validTo: company.expirationDate?new Date(company.expirationDate):"",
        requestType:null
    }
    const [state, setState] = useState(defaultState)
    const { t,i18n } = useTranslation("translations");
    const register=state.requestType==true

    const handleChange = (key, value) => globalHandleChange({ key, value, setState })

    const nuis = company.nuis
    const softCode= process.env.REACT_APP_NEW_SOFT_CODE
    
    const registerBranchesAction=()=>{
        const {validTo,requestType} = state
        const validFrom=new Date()
        handleChange("actionLoading",true)
        mainAxios.post(`/apiEndpoint/M22121029F/registerClientsFromCis`,{
            nuis,
            buCodes:state.selectedRows,
            validFrom,
            validTo,
            requestType
        }).then(res=>{
            handleChange("actionLoading",false)

            if(res.data.status){
                setState({...defaultState,validFrom})
                toast.success(register?t("company.bussinessUnitRegistered"):t("common.actionSuccessfully"), {
                    containerId: "all_toast",
                });
            }
            setState((state)=>{
                const {selectedRows,...restState} = state
                return {...restState,validFrom,selectedRows}
            })
            handleChange("openRegisterBranchIncCis",false)
            refreshGrid()
        })
        .catch(err=>{
            handleChange("actionLoading",false)

        })
    }

    const registerBranchesInCisFilter = (
        <Checkbox
            onChange={
                (e) => {
                    if (e.checked) {
                        const _selectedRows = []
                        branches.forEach(business => {
                            _selectedRows.push(business?.businUnitCode)
                        })
                        handleChange("selectedRows", _selectedRows)
                    } else {
                        handleChange("selectedRows", [])
                    }
                }
            }
            checked={state?.selectedRows?.length == branches?.length}
        />
    )

    const registerBranchesInCisBody = (business) => {
        return <Checkbox
            onChange={
                (e) => {

                    setState((state) => {
                        const _selectedRows = [...state.selectedRows ?? []]
                        const checkedValueIndex = _selectedRows.findIndex(sRow => sRow == business?.businUnitCode)
                        if (checkedValueIndex >= 0) {
                            _selectedRows.splice(checkedValueIndex, 1)
                            return { ...state, selectedRows: _selectedRows }
                        }
                        _selectedRows.push(business.businUnitCode)
                        return { ...state, selectedRows: _selectedRows }
                    })
                }
            }
            checked={!!state.selectedRows.find(sRow => sRow == business?.businUnitCode) }

        />
    }

    const registerBranchesInCisActions = (
        state?.selectedRows?.length > 0 &&
        <div className='mr-4 mt-4'>
            <div className="d-flex align-items-center">
                <button className="btn btn-sm btn-outline-primary mr-3"
                    disabled={state.actionLoading}
                    onClick={async () => {
                        if (state.actionLoading) return;
                        setState((state)=>({...state,openRegisterBranchIncCis:true,requestType:true}))
                    }}>
                    {state.actionLoading ? <Loader size="xs" /> : t("company.registerInCis")}
                </button>
                <button className="btn btn-sm btn-outline-danger mr-3" onClick={async () => {
                        if (state.actionLoading) return;
                        setState((state)=>({...state,openRegisterBranchIncCis:true,requestType:false}))
                        handleChange("openRegisterBranchIncCis", true)
                    }}>
                    {state.actionLoading ? <Loader size="xs" /> : t(["company.unRegisterInCis"])}
                </button>
                <button className="btn btn-sm btn-outline-secondary mr-3"
                    disabled={state.actionLoading}
                    onClick={async () => {
                        if (state.actionLoading) return;
                        handleChange("selectedRows", [])
                    }}>
                    {state.actionLoading ? <Loader size="xs" /> : t("common.clearSelection")}
                </button>
            </div>
            <Dialog
                header={i18n.language == "alb"?`Ju keni selektuar ${(state?.selectedRows ?? [])?.join(", ")} Business units per te ${state.requestType?"regjistruar":"çregjistruar"} ne CIS. Jeni te sigurt qe doni te vazhdoni?`:
                `You have selected ${((state?.selectedRows ?? [])?.length)} Business units to ${state.requestType ? "register" : "unregister"} in CIS. Are you sure you want to proceed?`}
                visible={state.openRegisterBranchIncCis}
                onHide={() => handleChange("openRegisterBranchIncCis", false)}
                style={{ width: "550px", height: "580px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="d-flex flex-column h-full">    
                    <div className="card border-0 flex-grow">
                        <div className="card-body">
                        <div className="form-group">
                            <label>{t('subscription.validFrom')}</label>
                            <DatePicker
                                selected={state?.validFrom ?? ""}
                                dateFormat="dd/MM/yyyy"
                                onChange={date => handleChange("validFrom", new Date(date.setHours(2, 0, 0, 0)))}
                                customInput={<input className="form-control" required />}
                            />
                        </div>
                        <div className="form-group">
                            <div className="form-group">
                                <label>{t('subscription.validTo')}
                                </label>
                                <DatePicker
                                    selected={state?.validTo ?? ""}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={date => handleChange("validTo", new Date(date.setHours(2, 0, 0, 0)))}
                                    customInput={<input className="form-control" required />}
                                />
                            </div>
                        </div>
                            <div className="form-group">
                                <label >
                                    {t(["invoice.Nuis"])}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={nuis}
                                    disabled={true}
                                    onChange={() => { }}
                                />
                            </div>
                            <div className="form-group">
                                <label >
                                    {t(["nslf.softCode"])}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={softCode}
                                    disabled={true}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <div className="btn btn-secondary mr-2" onClick={async () => { handleChange("openRegisterBranchIncCis", false) }}>
                            Close
                        </div>
                        {register ?<div className="btn btn-primary mr-2" onClick={registerBranchesAction}>
                            {state.actionLoading ? <Loader size="xs" /> : t(["company.registerInCis"])}
                        </div>
                        :<div className="btn btn-danger mr-2" onClick={registerBranchesAction}>
                            {state.actionLoading ? <Loader size="xs" /> : t(["company.unRegisterInCis"])}
                        </div>}
                    </div>
                </div>


            </Dialog>
        </div>
    )

    const selectedRowsColumn = (
        <Column
            filter={true}
            showFilterMenu={false}
            showClearButton={false}
            filterElement={registerBranchesInCisFilter}
            body={registerBranchesInCisBody}
            headerStyle={{ width: '3rem' }} frozen />
    )




    return { registerBranchesInCisActions, selectedRowsColumn,registerBranchState:state }
}

export default useRegisterBranchInCis
import axios from "axios";
import func from "./functions";
import { getStore } from "../AppContext";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";


// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const baseURL = process.env.REACT_APP_JAVA_SERVER_URL

export const mainAxios = new axios.create({
  baseURL,
  //mos vendosni ketu parametra!!!
});

export const setAuthToken = (token, header = "Authorization") => {
  if (token) {
    const value = header == "Refresh-Token" ? token : ("Bearer " + token)
    //applying token
    mainAxios.defaults.headers.common[header] = value;
  } else {
    //deleting the token from header
    delete mainAxios.defaults.headers.common[header];
  }
};

const getAccessToken = async ({token})  => {
  const refreshToken = func.getCookie("refreshToken")

  let config = {
    headers: {
      "Refresh-Token": refreshToken,
      "Authorization":  token
    }
  }
  return axios.post(baseURL + "public/refresh-token", {}, config)
    .then(res => {
      if (res.status === 200 && res?.data?.token) {
        return res?.data?.token
      }
      return null
    })
    .catch(err => { return null })
}

export const clearUser = ({ reloadWindow, setUser }) => {
  document.cookie =
    "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  setUser(null);
  setAuthToken(null);
  setAuthToken(null, "Refresh-Token");
  if (reloadWindow) window.location.reload()
}

export const logout = ({ setUser, request = false, reloadWindow = false }) => {
  if (request) {
    const token=func.getCookie("token")
    const refreshToken=func.getCookie("refreshToken")
    axios.post(baseURL+"public/logout",{},{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "Refresh-Token":refreshToken
      }
    })
  }
  clearUser({ reloadWindow, setUser })


}

// let sessionWindow=null;
export const sessionWindowsMilliseconds = 60000 * 60 //60 min

mainAxios.interceptors.request.use(
  async (request) => {
    let {
      user: [user, setUser],
      selectedCompany: [selectedCompany],
      forbidden: [forbidden, setForbidden],
      sessionWindow
    } = getStore();
    if (forbidden) setForbidden(false)
    if (user && selectedCompany) {
      if (request?.data) {
        request.data.username = user.username;
        request.data.company = request.data.company
          ? request.data.company
          : selectedCompany;
        request.data.params = JSON.stringify(request.data.params);
      }
    }
    console.log({ [(request?.data?.object ?? "") + "Request"]: request });

    const token = request?.headers?.common?.Authorization
    let decodedToken = null
    if (token) {
      const tokenExp = jwt_decode(token)?.exp
      decodedToken = func.isNumeric(tokenExp) ? tokenExp * 1000 : null;
    }
    const now = new Date()
    // const sessionWindowsMilliseconds = 10000 //10 sec

    if (token && sessionWindow?.current && (now.getTime() - sessionWindow.current.getTime() > sessionWindowsMilliseconds)) {
      sessionWindow.current=null
      throw new axios.Cancel('Session is Over due to inactivity');
    } else {
      if (token) {
        sessionWindow.current = now
      }
    }

    if (token && decodedToken && (decodedToken < now.getTime())) {
      let accessToken = await getAccessToken({token})
      if (accessToken) {
        request.headers.common.Authorization = "Bearer " + accessToken;
        setAuthToken(accessToken)
        func.setCookie("token", accessToken)
      } else {
        logout({ setUser,reloadWindow:true})
        throw new axios.Cancel('Unauthorized');
      }
    }
    return request;

  },
  (error) => {
    console.log({ error })
    return Promise.reject(error);
  }
);
mainAxios.interceptors.response.use(
  (response) => {
    console.log({
      [JSON.parse(response.config.data).object + "Response"]: response,
    });
    if (response?.data?.status === false) {
      let options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        containerId: "all_toast",
      };
      let message =
        response.data.message +
        " " +
        (response.data.faultReason ? response.data.faultReason : "");
      console.log({ response });
      if (
        document.querySelector(
          ".swal-overlay--show-modal #upload_invoice_modal"
        )
      ) {
        options.containerId = "upload_invoice";
        options.autoClose = false;
        try {
          const { error } = response.data;
          if (error) {
            message =
              response.data.message +
              (error.errorCode ? "\nCode: " + error.errorCode : "") +
              (error.errorSource ? "\nSource: " + error.errorSource : "") +
              (error.errorDescription
                ? "\nDescription: " + error.errorDescription
                : "");
          }
        } catch (e) {
          console.log({ e });
        }
      }
      toast.error(message, options);
    }
    return response;
  },
  (error) => {
    console.log({ error })
    const {
      forbidden: [forbidden, setForbidden]
    } = getStore();
    //console.log({interceptorsResponse: error})
    const {
      user: [, setUser],
    } = getStore();
    if (error.response?.status === 401) {
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setUser(null);
      setAuthToken(null);
    } else if (error.response?.status === 423) {
      return error;
    } else if (error.response?.status === 403) {
      if (func.getCookie("token")) {
        setForbidden(true)
      } else {
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setUser(null);
        setAuthToken(null);
      }
    } else {
      let options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        containerId: "all_toast",
      };
      let message = error.toJSON ? error.toJSON().message : error.message;
      if (
        document.querySelector(
          ".swal-overlay--show-modal #upload_invoice_modal"
        )
      ) {
        options.containerId = "upload_invoice";
        options.autoClose = false;
      }
      if (error?.code != "ERR_CANCELED") {
        toast.error(message, options);
      }
    }

    return Promise.reject(error);
  }
);

export default mainAxios;

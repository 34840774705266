import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ExportCsvPrime from "../global/ExportCsvPrime";
import func from "../../services/functions";
import { useAppContext, usePersistedState } from "../../AppContext";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import moment from "moment/moment";
import PrimeTable, { onColReorder } from "../PrimeTable/PrimeTable";
import {
  faQrcode,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import mainAxios from "../../services/mainAxios";
import { Column } from "primereact/column";
import CellInput from "../PrimeTable/CellInput";
import Loader from "../Layout/Loader";
import swal from "@sweetalert/with-react";
import TaxCategorization from "./TaxCategorization";
import { Dropdown } from "primereact/dropdown";
import { printStatuses } from "../../services/printInvoiceOnError";
import PopOver from "../global/PopOver";
import { paymentOptions } from "../../services/data";
import { globalFilterStatisticCard } from "../global/StatisticsCards";
import CisMessage from "../global/CisMessage";
import { defaultTableRows } from "../../services/data";
import useShowInvoicePdf from "../global/useShowInvoicePdf";
import LoadingLayover from "../global/LoadingLayover";
import CalendarWrapper from "../CalendarWrapper";



const SaleBook = () => {
  const { t, i18n } = useTranslation("translations");
  const periodRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingExpandData, setLoadingExpandData] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([])
  const [expandedData, setExpandedData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [taxCategorization, setTaxCategorization] = useState(null);
  // moment().subtract(1, 'months').format("MMMM YYYY")
  const [period, setPeriod] = useState(new Date().setHours(2, 0, 0, 0), "saleBookPeriod")
  const { unitsOfMeasure } = useAppContext()
  const loadingIdKey="issueDatetime"
  const { showInvoicePdf,pdfEmbed,loadingPdf } = useShowInvoicePdf({loadingIdKey})



  const tempFilters = [
    {
      key: "none",
      label: t("books.showAll"),
      value: null,
      showCount: false,
    },
    {
      key: "isType1Exempted",
      label: t("books.isType1Exempted"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.type1InvoicesNo,
      count2: statistics?.type1SalesWithoutVat,
      count3: statistics?.type1VatAmount,
    },
    {
      key: "isType2Exempted",
      label: t("books.isType2Exempted"),
      value: "true",
      // count: statistics?.fiscalizatedInvoicesNo,
      count1: statistics?.type2InvoicesNo,
      count2: statistics?.type2SalesWithoutVat,
      count3: statistics?.type2VatAmount,
    },
    {
      key: "isExportOfGoodsExempted",
      label: t("books.isExportOfGoodsExempted"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.exportOfGoodsInvoicesNo,
      count2: statistics?.exportOfGoodsSalesWithoutVat,
      count3: statistics?.exportOfGoodsVatAmount,
    },
    {
      key: "isTaxFreeExempted",
      label: "Tjeter, jo nenet 51,53 dhe 54",
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.taxFreeInvoicesNo,
      count2: statistics?.taxFreeSalesWithoutVat,
      count3: statistics?.taxFreeVatAmount,
    },
    {
      key: "isMarginSchemeExempted",
      label: "Marzhi I fitimit",
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.marginSchemeInvoicesNo,
      count2: statistics?.marginSchemeSalesWithoutVat,
      count3: statistics?.marginSchemeVatAmount,
    },
    {
      key: "isReverseCharge",
      label: t("books.isReverseOnSale"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.reverseChargeInvoicesNo,
      count2: statistics?.reverseChargeSalesWithoutVat,
      count3: statistics?.reverseChargeSalesVatAmount,
    },
    {
      key: "isZeroPercentInSupply",
      label: t("books.isZeroVatRated"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.zeroPercentInSupplyNo,
      count2: statistics?.zeroRateSalesWithoutVat,
      count3: statistics?.zeroRateVatAmount,
    },
    {
      key: "isStandardSale",
      label: t("books.isStandardSale"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.standardSalesInvoicesNo,
      count2: statistics?.standardSalesWithoutVat,
      count3: statistics?.standardSalesVatAmount,
    },
    {
      key: "isBadDebt",
      label: t("books.badDebt"),
      value: "true",
      // count: statistics?.einvoicesNo,
      count1: statistics?.badDebtInvoicesNo,
      count2: statistics?.badDebtSalesWithoutVat,
      count3: statistics?.badDebtSalesVatAmount,
    },
  ];

  const {
    user: [user],
    company: [fullCompany],
    selectedCompany: [selectedCompany]
  } = useAppContext();

  const defaultSortCriteria={
    field: "issueDatetime",
    direction: "DESC"
  } 

  const [tableRows, setTableRows] = useState({
    ...defaultTableRows,
    rowsPerPage:persistedRowPerPage,
    sortCriteria:defaultSortCriteria
  });
  const branches = fullCompany?.branches ?? [];
  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
      { label: "Other BU",
        value:  "otherBu",
        popover: t("hints.otherBu")
      }
    ].concat(
      fullCompany?.branches?.map((branch) => {
        return {
          ...branch,
          label: branch.businUnitCode + " | " + branch.sellerName,
          value: branch.businUnitCode,
        };
      }) ?? []
    );
  }, [branches,sessionData?.businessUnitCode]);

  const [persistedData, setPersistedData] = usePersistedState(
    null,
    "saleBookData"
  );

  const [persistedRowPerPage, setPersistedRowPerPage,] = usePersistedState(
    10,
    "rowsPerPage"
);

  const CustomOption = ({ innerProps, isDisabled, label, data }) =>
  !isDisabled ? (
    <div className="option-custom-style" {...innerProps}>
      {label}  {data?.popover &&<PopOver
      className="popover-style"
      customStyle = { {borderRadius: "40%", width: "20px", height: "20px", transform: "scale(0.80)", backgroundColor:"#343a40", color:"white"}}
      title={data.popover} />}
      {innerProps.label}
    </div>
  ) : null;

  const filterParams = {
    isEinvoice: null,
    correctiveInvoiceType: null,
    ublInvoiceTypeCode: null,
    isForeign: null,
    fiscStatus: [],
    fiscInvoiceType: null,
    ublProfileId: [],
    isOldInvoice: null,
    isDifferentPeriod: null,
    // period: new Date().setHours(2, 0, 0, 0),
    isType1Exempted: null,
    isType2Exempted: null,
    isExportOfGoodsExempted: null,
    // isMarginSchemeExempted: null,
    // isTaxFreeExempted: null,
    isReverseCharge: null,
    isZeroVatRated: null,
    // external: null,
    // isTwentyPercentVatRated: null,
    // isTenPercentVatRated: null,
    // isSixPercentVatRated: null,
    isBadDebt: null,
    saleSource:[]
  };

  const defaultFilters = {
    params: {
      ...filterParams,
    },
  };
  const [filters, setFilters] = useState(defaultFilters);

  const businessUnitSelected = businessUnitCodes.some(
    (branch) => branch?.value === persistedData?.businessUnitCode
  )
    ? persistedData?.businessUnitCode
    : null;

  const [sessionData, setSessionData] = useState({
    businessUnitCode: businessUnitSelected,
  });


  const handleStatistics = () => {
    const params = {
      period: moment(period).format("M/yyyy"),
    };

    if (sessionData?.businessUnitCode&& sessionData?.businessUnitCode != "otherBu") {
      params.businessUnitCode = sessionData?.businessUnitCode;
    }
    mainAxios
      .post("endpoint/v2/sale/statistics", {
        object: "GetSaleBookStatistics",
        params,
      })
      .then((res) => {
        if (res.data.status && res.data.content?.[0]) {
          setStatistics(res.data.content[0]);
        } else {
          setStatistics(null)
        }
      })
  };

  useEffect(() => {
    handleStatistics();
  }, [sessionData.businessUnitCode, period, fullCompany]);

  useEffect(() => {
    getFilteredResults({
      data:{ ...defaultFilters, params: { ...defaultFilters.params } },
      pageSize: (persistedRowPerPage?? defaultTableRows.rowsPerPage),
      page: defaultTableRows.currentPage,
    });
  }, [period, refreshData, sessionData.businessUnitCode, taxCategorization,fullCompany])

  const typeOptions = [
    {
      key: "isEinvoice",
      label: t("invoice.eInvoicesOption"),
      value: "true",
      count: statistics?.einvoicesNo,
      //   count: 27,
    },
    {
      key: "isEinvoice",
      label: t("parameters.onlyFiscalization"),
      value: "false",
      count: statistics?.onlyFicalizationInvoicesNo,
      //   count: 100,
    },
  ];

  const sourceOptions = [
    {
      key: "saleSource",
      label: "API",
      value: "isApiSource",
      count: statistics?.apiSourceInvoicesNo,
      multiSelect: true,

      //   count: 34,
    },
    {
      key: "saleSource",
      label: t("common.manual"),
      value: "manual",
      count: statistics?.manualInvoicesNo,
      multiSelect: true,

      //   count: 178,
    },
    {
      key: "saleSource",
      label: t("queue.fromSelfCare"),
      value: "external",
      count: statistics?.external,
      multiSelect: true,

    },

  ];

  const otherOptions = [
    {
      key: "ublProfileId",
      multiSelect: true,
      count: statistics?.selfInvoicesNo,
      //   count: 2,
      label: t("invoice.selfInvoice"),
      value: "P12",
    },
    {
      key: "ublProfileId",
      multiSelect: true,
      // count:
      count: statistics?.correctiveInvoicesNo,
      label: t("invoice.corrective"),
      value: "P10",
    },
    {
      key: "fiscInvoiceType",
      count: statistics?.cashInvoicesNo,
      //   count: 274,
      label: "Cash",
      value: "cash",
    },
    {
      key: "isForeign",
      count: statistics?.foreignInvoicesNo,
      //   count: 19,
      label: t("common.foreign"),
      value: "true",
    },
    {
      key: "isSubseq",
      count: statistics?.subseqInvoicesNo,
      //   count: 77,
      label: "Subseq",
      value: "true",
    },
    {
      key: "fiscStatus",
      multiSelect: true,
      count: statistics?.correctedInvoicesNo,
      //   count: 77,
      label: t("status.corrected"),
      value: "-100",
    },
    {
      key: "isOldInvoice",
      count: statistics?.oldInvoicesNo,
      label: t("queue.oldInvoice"),
      value: "true",
    },
    {
      key: "isDifferentPeriod",
      count: statistics?.differentPeriodInvoicesNo,
      label: t("queue.isDifferentPeriod"),
      value: "true",
    },
    // {
    //   key: "filterByPeriodDate",
    //   label: t("invoice.filterByPeriodDate"),
    //   value: "true",
    //   className:"extend-cell",
    //   showCount:false,
    //   excludeIfNull:true,
    //   userRoles:[1]
    // }
  ];

  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e.target.value : val;
    // const uiOption = uIOptions?.find((op) => op.value===key)
    // if (uiOption) {
    //     setUIType({
    //         ...uiOption,
    //         uidValue: value,
    //         value: key,
    //     });
    // } else {
    setFilters((filters) => ({
      ...filters,
      params: {
        ...filters.params,
        [key]: value,
      },
    }));
    // }
  };



  const invoiceTypeOptions = [
    {
      value: "Normale",
      label: "Normale",
    },
    {
      value: "Autofature",
      label: "Autofature",
    },
    {
      value: "Korrigjuese",
      label: "Korrigjuese",
    },
  ]

  const columns = useMemo(() => {
    // const restCols =
    //   Object.keys(data?.[0] ?? {})?.filter(
    //     (key) => !defaultCols.includes(key)
    //   ) ?? [];

    return [
      {
        field: "fiscInvoiceNumber",
        header: t("books.fiscInvNo"),
        sortable: true,
        style: { minWidth: "220px", maxWidth: "250px" },
        format: (invoice)=>func?.preventExcelFormatFn(invoice?.fiscInvoiceNumber || ""),
        filter: true,
        like: true,
        persisted: true,
        preventExcelFormat: true
      },
      {
        field: "fic",
        header: t("invoice.FIC"),
        // body: (row) => func.getFormattedMoneyValue(row?.cashInit ?? 0),
        // style: { textAlign: "right" }
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => <CellInput value={row?.fic ?? ""} width={150} />,
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "qrUrl",
        header: t("home.actions"),
        // style:{width:"15rem"},
        //   body: (row) => PrintActions({ row, type: "tcr" }),
        persisted: true,
        body: (url) => {
          return (
            <a
              href={`${url.qrUrl}`}
              target="_blank"
              className="nav-link p-0 d-flex align-items-center"
            >
              <FontAwesomeIcon size="lg" className="mr-1" icon={faQrcode} />
              {"QR Url"}
            </a>
          );
        },
        style: { minWidth: "110px", maxWidth: "110px" },
      },
      {
        field: "print",
        header: t(["invoice.print"]),
        persisted: true,
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (invoice) => {
          const isLoadingPdf=loadingPdf?.id==invoice?.[loadingIdKey] && loadingPdf?.loading
          return( <div className="row">

            <>
              {printStatuses.includes(invoice.fiscStatus) && (
                <a
                  href="#"
                  className={"nav-link p-0 d-flex-align-items-center mr-1"
                  }
                  onClick={(e) =>  showInvoicePdf({key:'iic', invoice,e}) }>
                    <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key==="iic")) }>
                      <img
                        src="https://profisc.al/wp-content/uploads/2021/10/Asset-11.svg"
                        title={t("invoice.printFisc")}
                        width="38"
                        height="32"
                      />
                    </LoadingLayover>
                  </a>

              )}
              {printStatuses.includes(invoice.einvoiceStatus) && (
                <a
                  href="#"
                  className={"nav-link p-0 d-flex-align-items-center mx-1"}
                  onClick={(e) =>  showInvoicePdf({key:'eic', invoice,e}) }>
                    <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key==="eic")) }>
                      <img
                        src="https://profisc.al/wp-content/uploads/2021/10/Asset-12.svg"
                        title={t("invoice.electronicInv")}
                        width="38"
                        height="32"
                      />
                  </LoadingLayover>

                </a>
              )}
            </>
          </div>)
        }
      },
      {
        field: "iic",
        header: t("invoice.IIC"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => <CellInput value={row?.iic ?? ""} width={150} />,
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "erpId",
        header: "ERP ID",
        // style: { textAlign: "right" }
        style: { minWidth: "200px", maxWidth: "200px" },
        sortable: true,
        body: (row) => <CellInput value={row?.erpId ?? ""} width={150} />,
        filter: true,
        like: true
      },
      {
        field: "additionalId1",
        header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId1")?.value ?? (t("pos.additional") + " 1"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => <CellInput value={row?.additionalId1 ?? ""} width={150} />,
        format: (invoice)=>func?.preventExcelFormatFn(invoice?.additionalId1 || ""),
        filter: true,
     filterConfig :{
          like:false
        },
        preventExcelFormat: true
      },
      {
        field: "additionalId2",
        header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId2")?.value ?? (t("pos.additional") + " 2"),
        style: { minWidth: "200px", maxWidth: "200px" },
        body: (row) => <CellInput value={row?.additionalId2 ?? ""} width={150} />,
        format: (invoice)=>func?.preventExcelFormatFn(invoice?.additionalId2 || ""),
        filter: true,
        filterConfig :{
          like:false
        },
        preventExcelFormat: true
      },
      {
        field: "issueDatetime",
        header: t("invoice.issueDate"),
        formattedExportValue: true,
        body: (row) => moment(row?.issueDatetime).format("DD/MM/YYYY"),
        style: { minWidth: "220px", maxWidth: "220px" },
        sortable: true,
        persisted: true,
        preventExcelFormat: true

      },
      {
        field: "invoiceType",
        header: t("customers.type"),
        style: { minWidth: "160px", maxWidth: "160px" },
        filter: true,
        filterType: "DROPDOWN",
        filterConfig: {
          options: invoiceTypeOptions,
          optionValue: "value",
          optionLabel: "label",
        },
        persisted: true
      },
      {
        field: "ublSupplierRegistrationName",
        header: t("company.sellerName"),
        style: { minWidth: "250px", maxWidth: "250px" },
        filter: true,
        like: true,
        persisted: true
      },
      {
        field: "buyerName",
        header: t("home.buyerName"),
        style: { minWidth: "250px", maxWidth: "250px", overflow: "hidden" },
        filter: true,
        like: true,
        persisted: true

      },
      {
        field: "buyerNuis",
        header: t("invoice.buyerNuis"),
        style: { minWidth: "150px", maxWidth: "150px" },
        sortable: true,
        filter: true,
        persisted: true

      },
      {
        field: "ublCustomerCityName",
        header: t("table.ublCustomerCityName"),
        style: { minWidth: "150px", maxWidth: "150px" },
        persisted: true
      },
      {
        field: "ublCustomerCountryCode",
        header: t("company.buyerCountry"),
        style: { minWidth: "140px", maxWidth: "140px" },
        persisted: true
      },
      {
        field: "salesTot",
        header: t("books.totalSales"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.salesTot ?? 0),
        align: "right",
        persisted: true
      },
      {
        field: "exemptedSalesTot",
        header: t("books.exludeSales"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.exemptedSalesTot ?? 0),
        align: "right"
      },
      {
        field: "zeroPercentSupply",
        header: t("books.isZeroVatRated"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.zeroPercentSupply ?? 0),//replace taxFreeSalesTot
        align: "right"
      },

      // {
      //   field: "zeroPercentSupply",
      //   header: t("books.isZeroVatRated"),
      //   style: { minWidth: "150px", maxWidth: "150px" },
      //   body: (row) => func.getFormattedMoneyValue(row?.taxFreeSalesTot ?? 0),
      //   align: "right"
      // },
      {
        field: "zeroVatSalesTot",
        header: t("books.salesWoVat"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => func.getFormattedMoneyValue(row?.zeroVatSalesTot ?? 0),
        align: "right"
      },
      {
        field: "exportOfGoodsSalesTot",
        header: "Export",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.exportOfGoodsSalesTot ?? 0),
        align: "right"
      },
      {
        field: "taxableAmountTwentyPercent",
        header: t("books.taxableValue") + " " + "20%",
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxableAmountTwentyPercent ?? 0),
        align: "right"
      },
      {
        field: "taxAmountTwentyPercent",
        header: t("books.vat") + " " + "20%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxAmountTwentyPercent ?? 0),
        align: "right"
      },
      {
        field: "taxableAmountTenPercent",
        header: t("books.taxableValue") + " " + "10%",
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxableAmountTenPercent ?? 0),
        align: "right"
      },
      {
        field: "taxAmountTenPercent",
        header: t("books.vat") + " " + "10%",
        style: { minWidth: "100px", maxWidth: "100px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxAmountTenPercent ?? 0),
        align: "right"
      },
      {
        field: "taxableAmountSixPercent",
        header: t("books.taxableValue") + " " + "6%",
        style: { minWidth: "200px", maxWidth: "300px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxableAmountSixPercent ?? 0),
        align: "right"
      },
      {
        field: "taxAmountSixPercent",
        header: t("books.vat") + " " + "6%",
        style: { minWidth: "200px", maxWidth: "300px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.taxAmountSixPercent ?? 0),
        align: "right"
      },
      {
        field: "selfInvoiceTaxableAmount",
        header: t("books.autoLoadTax"),
        style: { minWidth: "230px", maxWidth: "230px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.selfInvoiceTaxableAmount ?? 0),
        align: "right"
      },
      {
        field: "selfInvoiceTaxAmount",
        header: t("books.autoLoad") + " " + t("books.vat"),
        style: { minWidth: "170px", maxWidth: "170px" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.selfInvoiceTaxAmount ?? 0),
        align: "right"
      },
      {
        field: "badDebtTaxableAmount",
        header: t("books.badDebtTax"),
        style: { minWidth: "200px", maxWidth: "200px", textAlign: "right" },
        body: (row) =>
          func.getFormattedMoneyValue(row?.badDebtTaxableAmount ?? 0),
        align: "right"
      },
      {
        field: "badDebtTaxAmount",
        header: t("books.badDebt") + " " + t("books.taxAmount"),
        style: { minWidth: "180px", maxWidth: "180px", textAlign: "right" },
        body: (row) => func.getFormattedMoneyValue(row?.badDebtTaxAmount ?? 0),
        align: "right"
      },
      {
        field: "marginSchemeSalesTot",
        header: t("books.marginSchemeSalesTot"),
        style: { minWidth: "180px", maxWidth: "180px" },
        body: (row) => func.getFormattedMoneyValue(row?.marginSchemeSalesTot ?? 0),
        align: "right",
        persisted: true
      },
      {
        field: "softwareCode",
        header: t("books.softwareCode"),
        style: { minWidth: "150px", maxWidth: "150px" },
        body: (row) => row?.softwareCode ?? 0,
        filter:true,
        filterType:"DROPDOWN",
        filterConfig:{
          filter:true,
          options: data?.softwareCodes ?? [],
        },
        align: "right",
      },
      {
        header: "Cash/NonCash",
        style: { minWidth: "220px", maxWidth: "220px" },
        field: "fiscInvoiceType",
        default: true,
        filter:true,
        filterType:"DROPDOWN",
        filterConfig:{
          options: paymentOptions,
        },
        body: (queue) => queue.fiscInvoiceType || "",
    },
      //   ...restCols.map((key) => ({
      //     header: func.camelToFlat(key),
      //     field: key,
      //   })),
    ];
  }, [i18n.language, data,loadingPdf]);


  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({ field: col.field, show: !!col.default, ...(col?.persisted ? { persisted: true } : {}) })),
    "saleBooksColumns4"
  );



  const getFilteredResults = ({
    data,
    exportCsv = false,
    sortCriteria = [ defaultSortCriteria ],
    pageSize = (persistedRowPerPage?? defaultTableRows.rowsPerPage),
    page = defaultTableRows.currentPage,
  }) => {
    const queueData = data ?? filters
    let correctiveInvoiceType = null;
    let ublInvoiceTypeCode = null;

    if (queueData?.params?.correctiveInvoiceType) {
      if (queueData.params.correctiveInvoiceType == 380) {
        correctiveInvoiceType = null;
        ublInvoiceTypeCode = queueData.params.correctiveInvoiceType;
      } else {
        correctiveInvoiceType = queueData.params.correctiveInvoiceType;
        ublInvoiceTypeCode = null;
      }
    }
    queueData.params.correctiveInvoiceType = correctiveInvoiceType;
    queueData.params.ublInvoiceTypeCode = ublInvoiceTypeCode;
    // if (uIType?.uidValue && !clearFilters) {
    //
    //   if(uIType?.like==true){
    //     let val = "<L>" + uIType.uidValue + "<L>";
    //     queueData.params[uIType.value] = val;
    //   }else{
    //     if (uIType.type === "date") {
    //       queueData.params[uIType.value] = uIType?.uidValue ?moment(uIType.uidValue).format("M/yyyy"):""
    //     } else {
    //       queueData.params[uIType.value] = uIType.uidValue
    //     }
    //   }
    // }
    // if (csvData.length > 0) setCsvData([]);
    let fiscStatus = queueData.params.fiscStatus;

    let invStatCorrectedAndSuccessful = [null, "-100"].every((status) => {
      return fiscStatus.includes(status);
    });

    if (invStatCorrectedAndSuccessful) {
      fiscStatus = "-100";
    } else {
      fiscStatus = fiscStatus.join(",") || null;
    }

    // {
    //   label: t("invoice.businessUnitCode"),
    //   value: (sessionData?.businessUnitCode !== "" && sessionData?.businessUnitCode !== null) ?
    //   sessionData?.businessUnitCode : branches.filter(x => x.businUnitCode != null).map(x => x.businUnitCode).join(","),
    // },
    // { label: "Other BU",
    //   value:  "<n>"+(branches?.filter(x => x.businUnitCode != null)?.map(x => x.businUnitCode)?.join(",") ?? "")+"<n>",
    const { rowsPerPage, currentPage } = tableRows

    let buCodes
    if(sessionData?.businessUnitCode==="otherBu"){
      buCodes="<n>"+(branches?.filter(x => x.businUnitCode != null|| x.businUnitCode!=="otherBu")?.map(x => x.businUnitCode)?.join(",") ?? "")+"<n>"
    }else{
      buCodes=sessionData?.businessUnitCode
    }

    const params= {
      ...queueData.params,
      ublProfileId: queueData.params?.ublProfileId.join(",") || null,
      period: moment(period).format("M/yyyy"),
      fiscStatus,
      ...(taxCategorization?.key && taxCategorization?.value ? { [taxCategorization?.key]: taxCategorization?.value } : {}),
      buCodes,
      // businessUnitCode: sessionData?.businessUnitCode
      // external: queueData.params.external,
      saleSource: queueData.params.saleSource?.join(",") || null,

    }

    // if(params?.isApiSource==="true" && params?.manual==="true"){
    //   params.external="false"
    //   params.isApiSource=null
    //   params.manual=null
    // }

    let requestBody = {
      object: "GetSaleBook",
      ...queueData,
      params,
      pagination: exportCsv ? null : {
        pageSize: pageSize,
        pageNumber: page,
      },
      sortCriteria
    };

    return mainAxios
      .post(`apiEndpoint/search`, requestBody)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === true) {
          let content = res.data.content[0];
          if (exportCsv) {
            setCsvData(content?.saleBookList ?? [])
          } else {
            setData(content);
            setTableRows((state) => ({
              ...state,
              totalRows: res?.data?.totalSize,
            }));
          }
          return true;
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        return false;
      });
  };

  const filteredColumns = useMemo(() => {
    let _filteredColumns = [];
    selectedColumns.forEach((persistedCol) => {
        const column = columns.find((col) => col.field === persistedCol.field);
        if (column && (persistedCol.show || column.persisted)) _filteredColumns.push(column);
    });
    return _filteredColumns;
  }, [selectedColumns, data, i18n.language, csvData]);

  const onRowExpand = (event) => {
    setLoadingExpandData(true);
    setExpandedRows([event.data]);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetSaleInvoiceItems",
        params: {
          saleInvoiceId: event?.data?.saleInvoiceId?.toString(),
        },
      })
      .then((res) => {
        if (res) {
          setExpandedData(res?.data?.content);
          setLoadingExpandData(false);
        }
      });
  };

  const onRowCollapse = (event) => {
    setExpandedRows([]);
  };

  const handleRefreshData = () => {
    const dateForRefresh = Date.now();
    setLoading(true);
    mainAxios
      .post("apiEndpoint/call", {
        object: "RefreshSaleBookMv",
        fromDate: dateForRefresh,
        toDate: dateForRefresh,
      })
      .then((res) => {
        if (res.data.status === true) {
          setRefreshData(!refreshData);
          getFilteredResults({});
        }
      });
  };

  const unitofMeasureBody = useCallback((row) => {
    const uom = unitsOfMeasure.find(uom => uom.englishName === row.unitOfMeasure);
    if (uom) {
      return i18n.language == "alb" ?
        uom?.albanianName : uom?.englishName
    }
    return row?.unitOfMeasure ?? ""
  },
    [unitsOfMeasure, i18n]
  )


  const expandedColumns = [
    {
      field: "",
      header: "",
      style: { minWidth: 40, maxWidth: 40 },
    },
    {
      field: "itemCode",
      header: t("books.itemCode"),
      style: { minWidth: 30,maxWidth:150 },
      sortable: true,
    },
    {
      field: "itemName",
      header: t("books.itemName"),
      style: { minWidth: 50,maxWidth:150 },
      sortable: true,
    },
    {
      field: "unitOfMeasure",
      header: t("books.unitOfMeasure"),
      style: { minWidth: 50 , maxWidth: 150 },
      body: unitofMeasureBody
    },
    {
      field: "itemQuantity",
      header: t("books.itemQuantity"),
      style: { minWidth: 50,maxWidth:150 },
    },
    {
      field: "unitPriceBefore",
      header: t("books.unitPriceBefore"),
      style: { minWidth: 50,maxWidth:150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceBefore ?? 0),
      align: "right"
    },
    {
      field: "unitPriceAfter",
      header: t("books.unitPriceAfter"),
      style: { minWidth: 50, maxWidth: 180 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceAfter ?? 0),
      align: "right"
    },
    {
      field: "vatRate",
      header: t("books.vatRate"),
      style: { minWidth: 50,maxWidth:100 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatRate ?? 0),
      align: "right"
    },
    {
      field: "linePriceBefore",
      header: t("books.linePriceBefore"),
      style: { minWidth: 50, maxWidth: 200 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.linePriceBefore ?? 0),
      align: "right"
    },
    {
      field: "vatAmount",
      header: t("books.vatAmount"),
      style: { minWidth: 50, maxWidth: 150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatAmount ?? 0),
      align: "right"
    },
    // {
    //   field: "",
    //   header: "",
    //   style: { width: 2500 },
    // },

  ];

  const rowExpansionTemplate = () => {
    //  {loading ? (
    //         <Loader size="xs" color="white" />
    //       ) : (
    //         t("common.cancelInvoice")
    //       )}
    if (loadingExpandData) return (
      <div className="d-flex justify-content-start" style={{width:"100vw"}}>

        <Loader color="white"></Loader>;
      </div>)
    return (
      <div style={{width:"95vw"}}>
      <PrimeTable
        value={expandedData}
        columns={expandedColumns}
        showFilters={false}
        paginator={false}
        filterDisplay={false}
        responsiveLayout="scroll"
      />
    </div>

    );
  };

  const filterStatisticCard=(typeOptions,props)=>globalFilterStatisticCard(typeOptions,{...props,handleParamsChange,getFilteredResults,user,filters})


  const exportColumns = useCallback(
    ({ simpleExport }) => {
      if (simpleExport) {
        return (
          filteredColumns?.map((col) => ({
            ...col,
            field: col.field,
            header: col.header ?? func.camelToFlat(col.field),
          })) ?? []
        );
      }
      return (
        columns?.map((col) => ({
          ...col,
          field: col.field,
          header: col.header ?? func.camelToFlat(col.field),
        })) ?? []
      );
    },
    [data, columns, filteredColumns, i18n.language]
  );


  const openDropDownModal = () => {
    swal(
      <TaxCategorization
        tempFilters={tempFilters}
        totalCount={tableRows.totalRows}
      />,
      {
        className: "books-modal",
        closeOnClickOutside: true,
        closeOnEsc: true,
        buttons: false,
        title: `${t("books.taxCategorization")}`,
      }
    );
  }
  const headerButtonsLeft = (
    <>
      <div className={`p-2 d-flex align-items-center justify-content-start`} style={{ width: 350, gap: "10px" }}>
        <Dropdown
          value={taxCategorization?.label}
          options={tempFilters}
          optionValue="label"
          optionLabel="label"
          placeholder={t("books.taxCategorization")}
          scrollHeight="600px"
          display="chip"
          panelStyle={{
            width: 400
          }}
          onChange={(option) => {
            const filter = tempFilters.find(filter => filter.label == option.value)
            if (filter) {
              setTaxCategorization(filter)
            }
          }}
          style={{ height: "35px", minWidth: 250, fontSize: "14px" }}
          itemTemplate={(opt) => filterItemTemplate(opt)}
        />
        <div className="btn btn-primary open-modal-icon" onClick={openDropDownModal}>
          <FontAwesomeIcon icon={faUpRightFromSquare} />
        </div>
      </div>


    </>

  );


  const filterItemTemplate = (option) => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div
        // style={{width: "300px"}}
        >
          {option.label}
        </div>
        <div>
          {option?.showCount == undefined && (
            <span className="badge bg-primary rounded-pill mr-1 ml-3 text-white">
              {option?.count1 ?? 0}
            </span>
          )}
          {/* {option?.showCount == undefined && (
            <MyTooltip text="Vlera pa TVSH">
              <span className="badge bg-secondary rounded-pill text-bright mr-1">
                {option?.count2 ?? 0}
              </span>
            </MyTooltip>
          )}
          {option?.showCount == undefined && (
            <MyTooltip text="Vlera e TVSH">
              <span className="badge bg-info rounded-pill text-white mr-1">
                {option?.count3 ?? 0}
              </span>
            </MyTooltip>
          )} */}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center my-0">
        <div className="left-header d-flex align-items-center">
          <h3 className="mb-0 p-0 d-flex align-items-center">
            {t("navbar.salesBook")}
          </h3>
          <small className="ml-3">
            <ExportCsvPrime
              data={csvData}
              onClick={() => getFilteredResults({ exportCsv: true })}
              excludedColumns={["space", "actions"]}
              columns={exportColumns({ simpleExport: true })}
              tooltip={t("books.onlyGridColumns")}
            />
          </small>
          <small className="ml-3">
            <ExportCsvPrime
              label="Export All"
              data={csvData}
              onClick={() => getFilteredResults({ exportCsv: true })}
              columns={exportColumns({ simpleExport: false })}
              excludedColumns={["space", "actions"]}
              tooltip={t("books.allColumns")}
            />
          </small>
        </div>
        <CisMessage objectType={"GET_S_INVOICES_FROM_CIS"} />
        <div className="ml-md-auto mb-md-0 mt-4 d-flex align-items-center">
          <label className="align-items-center mr-2 mb-0">
            {t("queue.period")}:
          </label>
          <CalendarWrapper
            id="periodDate"
            ref={periodRef}
            selected={period}
            placeholderText={t("queue.selectDate")}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            onChange={(date) =>
              setPeriod(new Date(date).setHours(2, 0, 0, 0))
            }
            customInput={
              <div className="form-control d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCalendar} />
                <div className="ml-1">
                  {period
                    ? moment(period).format("MM/yyyy")
                    : moment().format("MM/yyyy")}
                </div>
              </div>
            }
          />
        </div>
        <div className="col-6 col-md-2 d-flex pr-0 align-items-center mt-4">
          <label className="align-items-center mr-2 mb-0">BU</label>
          <Select
            styles={selectStyle}
            className="w-100"
            style={{ width: "14em" }}
            components={{ Option: CustomOption }}
            options={businessUnitCodes}
            value={
              businessUnitCodes.find(
                (businessUnitCode) =>
                  businessUnitCode.value === sessionData.businessUnitCode
              ) || {
                label: t("invoice.businessUnitCode"),
                value: "",
              }
            }
            isSearchable={true}
            onChange={(businessUnitCode) => {
              setPersistedData({
                ...persistedData,
                businessUnitCode: businessUnitCode.value,
              });
              setSessionData({
                ...sessionData,
                businessUnitCode: businessUnitCode.value,
              });
            }}
            name="businessUnitCode"
          />
        </div>
      </div>
      <div className="row px-1">
        <ul className="col-12 col-sm-6 col-md-3 p-2 mb-1">
          {filterStatisticCard(typeOptions, {
            title: t("home.invoiceType"),
            cardClasses: "grid-2",
          })}
        </ul>
        <ul className="col-12 col-sm-6 col-md-3 p-2 mb-1">
          {filterStatisticCard(sourceOptions, {
            title: t("common.source"),
            cardClasses: "grid-2",
            type: "checkbox",
          })}
        </ul>
        <ul className={`col-12 col-sm-6 col-md-6 p-2 mb-1`}>
          {filterStatisticCard(otherOptions, {
            title: t("invoice.other"),
            cardClasses: "grid-6",
            type: "checkbox",
          })}
        </ul>
        {/*<ul className="col-12 col-sm-6 col-md-2 p-2"></ul>*/}
      </div>
      {pdfEmbed}
      <div className="card-body p-0">
        <PrimeTable
          rowClassName="tvsh-categorization"
          showFilters={true}
          reorderableColumns={true}
          filters={[filters, setFilters]}
          defaultFilters={defaultFilters}
          headerButtonsLeft={headerButtonsLeft}
          tableRows={[tableRows, setTableRows]}
          persistedRowPerPage={[persistedRowPerPage, setPersistedRowPerPage]}
          value={data?.saleBookList ?? []}
          selectedColumns={[selectedColumns, setSelectedColumns]}
          searchableKeys={["buyerName", "ublCustomerCityName", "buyerNuis"]}
          columns={columns}
          expandedRows={expandedRows}
          onRowExpand={onRowExpand}
          onRowCollapse={onRowCollapse}
          responsiveLayout="scroll"
          onRemoteDataChange={(remoteData)=>{
            const updatedFilters = remoteData.filters ?? filters
            const updatedTableData = remoteData.tableRows ?? tableRows
            let { currentPage, rowsPerPage, sortCriteria } = updatedTableData
            sortCriteria=sortCriteria?[sortCriteria]:[]
            getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage, sortCriteria })
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          onClearFilter={() => setTaxCategorization(null)}
          defaultSortCriteria={defaultSortCriteria}
          columnDeps={[data,loadingPdf]}
        >
          <Column
            expander={() => true}
            style={{ width: "0.5em" }}
            header=""
            field="expansion"
            frozen={true}
          />
        </PrimeTable>
      </div>
    </>
  );
};

export default SaleBook;

import React, { useState } from "react";
import DatePicker from "react-datepicker";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mainAxios } from "../../../services/mainAxios";
import { toast } from "react-toastify";
import func from "../../../services/functions";
import Loader from "../../Layout/Loader";
import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from "../../../AppContext";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";

const AddCompanyBranch = ({ onAddbranch, companyId, ...props }) => {
  const { t, i18n } = useTranslation("translations");
  const [state, setState] = useState({
    branch: {
      businUnitCode: "",
      sellerAddress: "",
      sellerTown: "",
      sellerName: "",
      profiscStatus: false,
      softCode:""
    },
  });
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
  } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [errors,setErrors]=useState({})

  const addBranch = (e) => {
    e.preventDefault();
    const {softCode } = state.branch

    if(softCode?.length>0 && !new RegExp(/[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}/).test(softCode)){
      setErrors((errors)=>({
        ...errors,
        softCode:t("toast.invalidSoftCode")
      }))
      return
    }
    setLoading(true);
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "CompanyBranches",
        content: {
          company: { id: companyId },
          ...state.branch,
        },
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(companyId)
        )?.nuis,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === true) {
          history.push("/companies/" + companyId);
          onAddbranch();
          toast.success(t(["toast.addBranch"]), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            containerId: "all_toast",
          });
        }
    });
  };


  const handleBranchInputs = (e, selector = null, selectorValue = null, trim = false) => { 
    const key = selector ? selector : e.target.name;
    let value = selectorValue !== null && selectorValue !== undefined ? selectorValue : e?.target?.value;
    if (trim) value = value ? value.trim() : "";
    if (errors[key]) {
      setErrors((errors) => ({
        ...errors,
        [key]: null
      }));
    }
  
    setState((state) => ({
      ...state,
      branch: {
        ...state.branch,
        [key]: value
      },
    }));
  };


  if (loading) return <Loader />;

  return state.branch ? (
    <>
      <form onSubmit={addBranch}>
        <div className="card border-0 ">
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="business_unit_code">
                {t("company.businUnitCode")}
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="business_unit_code"
                name="businUnitCode"
                placeholder="E.g '123456'"
                value={state.branch.businUnitCode}
                onChange={(e)=> handleBranchInputs(null,"businUnitCode",e.target.value,true)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="seller_country">
                {t("company.sellerCountry")}
              </label>
              <input
                type="text"
                className="form-control"
                id="seller_country"
                name="sellerCountry"
                placeholder="E.g 'Albania'"
                value={state.branch.sellerCountry}
                onChange={handleBranchInputs}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="seller_town">
                {t("company.sellerTown")} (Max 99)
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="seller_town"
                name="sellerTown"
                placeholder="E.g 'Tirane'"
                maxLength="99"
                value={state.branch.sellerTown}
                onChange={handleBranchInputs}
              />
            </div>
            <div className="form-group">
              <label htmlFor="seller_address">
                {t("company.sellerAddress")} (Max 200)
              </label>
              <input
                required
                type="text"
                className="form-control"
                id="seller_address"
                name="sellerAddress"
                maxLength="200"
                placeholder="E.g 'Bulevardi Zogu I'"
                value={state.branch.sellerAddress}
                onChange={handleBranchInputs}
              />
            </div>
            <div className="form-group">
              <label htmlFor="softCode">{t('nslf.softCode')}</label>
              <input
                type="text"
                className={`form-control ${
                  errors.softCode ? "is-invalid" : ""
                } `}
                id="softCode"
                name="softCode"
                maxLength="10"
                placeholder="E.g 'aa000aa000'"
                value={state.branch.softCode}
                onChange={(e)=>handleBranchInputs(null,"softCode",e.target.value,true)} />
                {errors.softCode && (
                  <div className="invalid-feedback">
                    {errors.softCode}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="seller_name">{t("company.sellerName")}</label>
              <input
                required
                type="text"
                min="0"
                className="form-control"
                id="seller_name"
                name="sellerName"
                placeholder="E.g 'Ahmet Zogu'"
                value={state.branch.sellerName}
                onChange={handleBranchInputs}
              />
            </div>
            <div className="custom-control custom-switch mb-3">
              <input
                type="checkbox"
                className="custom-control-input"
                id="profiscStatusCheck"
                name="profiscStatus"
                checked={state?.branch.profiscStatus}
                onChange={(e) =>
                  handleBranchInputs(null, "profiscStatus", e.target.checked)
                }
              />
              <label
                className="custom-control-label"
                htmlFor="profiscStatusCheck"
              >
                {t(["company.profiscStat"])}
              </label>
            </div>

          </div>
          </div>
        <div className="d-flex justify-content-between align-items-center">
          <button
            type="submit"
            disabled={loading}
            style={{ minWidth: "100px" }}
            className="btn btn-primary"
          >
            {loading ? (
              <Loader size="xs" color="white" />
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                {t("common.save")}
              </>
            )}
          </button>
        </div>
      </form>
    </>
  ) : (
    <Loader />
  );
};

export default AddCompanyBranch;

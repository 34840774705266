import React, { useState, useRef,useEffect, useMemo } from "react";

import Navbar from "./Navbar";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { useAppContext } from "../../AppContext";
import func from "../../services/functions";
import jwt_decode from "jwt-decode";
import axios from "axios";
import WebSocket from "../WebSocket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation, withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import mainAxios, { logout } from "../../services/mainAxios";
import SideBar from "./SideBar";
import UseScreenSize from "../../utils/UseSizeScreen";
import { faEdit, faSignOutAlt, faSyncAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import useClickOutside from "../../utils/useClickOutside";
import { Helmet } from "react-helmet";
import { DisableLayout } from "./DisableLayout";


const TITLE = "ProFisc" + process.env.REACT_APP_TITLE;

const Wrapper = (props) => {
  const { t, i18n } = useTranslation("translations");
  const [selected, setSelected] = useState("");
  const screenSize = UseScreenSize()
  const contentWrapper = useRef();
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany],
    company:[fullcompany,setFullCompany],
    getUnitOfmeasure,
    getStatus
  } = useAppContext();

  useEffect(() => {
    const token = func.getCookie("token");
    if (token) {
      let username;
      try {
        username = jwt_decode(token).sub;
      } catch (error) {
        username = "";
      }

      axios
        .post(
          process.env.REACT_APP_JAVA_SERVER_URL + "apiEndpoint/search",
          {
            object: "GetUser",
            value: null,
            type: "basic",
            params: JSON.stringify({ username }),
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((res) => {
          if (res && res.data?.content?.[0]) {
            let fullUser = res.data.content[0];

            if (
              !fullUser.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
              )
            ) {
              setUser(null);
            } else {
              delete res.data.content[0]["companyBranchesTcr"];
              setUser({...fullUser,username});
            }
          }
        });
    }
  }, []);


  useEffect(()=>{
    const token = func.getCookie("token");
    if(token && selectedCompany){
      getCompany(selectedCompany)
    }
  },[selectedCompany])


  React.useEffect(() => {
    const token = func.getCookie("token");
    if(token){
      getUnitOfmeasure()
      getStatus()
    }
  }, [])

  

  const getCompany=(companyId)=>{
    mainAxios.post('apiEndpoint/search', {
        object: "GetCompany",
        params: {
            id: companyId
        }
    }).then(res => {
        if(res.data.status) {
            setFullCompany(res.data.content[0])
        }
    })
  }


  if (user && !Object.keys(user).includes("companiesRights")) {
    localStorage.clear();
  }

  const currentCompany = user?.companiesRights?.find(
    (company) => company.id === parseInt(selectedCompany)
  )?.name;

  const flags = () => {
    return (
      <div className="d-flex align-items-center">
        <div className=" contact-info pl-2 ">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("alb");
              saveLanguage("alb");
            }}
          >
            <img src="/albania-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
        <div className="contact-info px-2">
          <a
            href="#"
            className="nav-link p-0"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage("en");
              saveLanguage("en");
            }}
          >
            <img src="/england-icon-round.png" style={{ width: "16px" }} />
          </a>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    let language = localStorage.getItem("language");

    if (language == "alb") {
      i18n.changeLanguage("alb");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const saveLanguage = (val) => {
    localStorage.setItem("language", val);
  };

  const isLoggedIn=user && selectedCompany




  return (
     <div className="wrapper" >
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      {isLoggedIn && (
        <div className="relative">
          <Navbar contentWrapper={contentWrapper} />
          <WebSocket />
        </div>
      )}


    <div  className="d-flex">
      <div className="p-0" >
      {isLoggedIn && (
        <SideBar currentCompany={currentCompany}  onChangeScree={()=> {
        }}  />
      )}
       </div>

        <div  className={`px-2 w-100 ${isLoggedIn?"content-wrapper":"p-0 m-0"} ${props.hideOverflow ? "overflow-hidden" : ""})`}
           ref={contentWrapper}>
              {props.children}
        </div>
      
      </div>

      <ToastContainer
        className={`custom-toast-width`}
        containerId="upload-invoice"
        enableMultiContainer
      />
    </div>
  );
};

export default withRouter(Wrapper);

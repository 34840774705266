import React,{ useMemo, useState } from "react";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import mainAxios from "../../../services/mainAxios";
import Loader from "../../Layout/Loader";
import PrimeTable from "../../PrimeTable/PrimeTable";
import func from "../../../services/functions";

const useSaleInvoicesExpand = ({getFilteredResults, columns, selectedColumns, setSelectedColumns,defaultFilters}) => {
  const { t } = useTranslation("translations");

  const defaultExpand = {
    invoiceItems: [],
    expandedRows: null,
    loadingInvoiceItems: false,
    expandedInvoiceData: null,
    loadingInvoiceData: false,
  };

  const [expanded, setExpanded] = useState(defaultExpand);


  const invoiceItemsColumns = useMemo(() => [
    {
      field: "",
      header: "",
      style: { maxWidth: 40 },
    },
    {
      field: "itemCode",
      header: t("books.itemCode"),
      style: { minWidth: 180, maxWidth: 200 },
      sortable: true,
    },
    {
      field: "itemName",
      header: t("books.itemName"),
      style: { minWidth: 180, maxWidth: 200 },
      sortable: true,
    },
    {
      field: "unitOfMeasure",
      header: t("books.unitOfMeasure"),
      style: { minWidth: 150, maxWidth: 150 },
    },
    {
      field: "itemQuantity",
      header: t("books.itemQuantity"),
      style: { minWidth: 150, maxWidth: 180 },
    },
    {
      field: "unitPriceBefore",
      header: t("books.unitPriceBefore"),
      style: { minWidth: 150, maxWidth: 150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceBefore ?? 0),
      align: "right"
    },
    {
      field: "unitPriceAfter",
      header: t("books.unitPriceAfter"),
      style: { minWidth: 180, maxWidth: 180 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.unitPriceAfter ?? 0),
      align: "right"
    },
    {
      field: "vatRate",
      header: t("books.vatRate"),
      style: { minWidth: 100, maxWidth: 100 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatRate ?? 0),
      align: "right"
    },
    {
      field: "linePriceBefore",
      header: t("books.linePriceBefore"),
      style: { minWidth: 200, maxWidth: 200 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.linePriceBefore ?? 0),
      align: "right"
    },
    {
      field: "vatAmount",
      header: t("books.vatAmount"),
      style: { minWidth: 150, maxWidth: 150 },
      body: (row) =>
        func.getFormattedMoneyValue(row?.vatAmount ?? 0),
      align: "right"
    },
    {
      field: "",
      header: "",
      style: { width: 2500 },
    },

  ], [t]);


  const correctiveOnExpand = async (event) => {
    const data = event.data;
    const expandedInvoiceData = {
      corrective: [],
      corrected: []
    };
  
    setExpanded((state) => ({ ...state, loadingInvoiceData: true }));
    const promises = [];
  
    if (data.invoiceType === "Korrigjuese" || data.correctiveInvoiceType === "CORRECTIVE") {
      let params = { iic: data.iicRef };
      const correctedPromise = getFilteredResults({
        data: { params: { ...defaultFilters.params, ...params } },
        page: 1,
        setQueueValues: false,
      }).then((result) => (expandedInvoiceData.corrected = result));
      promises.push(correctedPromise);
    }
  
    if ((data.fiscStatus === -100 && !data?.einvoice) || (data.einvoice === true && data.einvoiceStatus === -100)) {
      let params = { iicRef: data.iic };
      const correctivePromise = getFilteredResults({
        data: { params: { ...defaultFilters.params, ...params } },
        page: 1,
        setQueueValues: false,
      }).then((result) => (expandedInvoiceData.corrective = result));
      promises.push(correctivePromise);
    }
    await Promise.all(promises);
  
  
    setExpanded((state) => ({
      ...state,
      loadingInvoiceData: false,
      expandedInvoiceData,
    }));
  };
  

  const invoiceItemsOnExpand = (event) => {
    setExpanded((state) => ({ ...state, loadingInvoiceItems: true, expandedRows: [event.data] }));
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetSaleInvoiceItems",
        params: { saleInvoiceId: event?.data?.id?.toString() },
      })
      .then((res) => {
        setExpanded((state) => ({ ...state, loadingInvoiceItems: false }));
        if (res) {
          setExpanded((state) => ({ ...state, invoiceItems: res?.data?.content }));
        }
      })
      .catch((err) => setExpanded((state) => ({ ...state, loadingInvoiceItems: false })));
  };

  const rowExpansionTemplate = () => {
    const expandedInvoiceData = expanded?.expandedInvoiceData;
  
    if (expanded?.loadingInvoiceData || expanded?.loadingInvoiceItems) {
      return <div style={{ width: "100vw" }}><Loader /></div>;
    }
  
    return (
      <div className="d-flex flex-column ml-3 mt-3">
        {expandedInvoiceData && (
          <div className="mb-4">
            {/* Corrective Section */}
            {expandedInvoiceData.corrective?.length > 0 && (
              <div className="mb-4">
                <h4>{t("queue.correctiveInvoice")}</h4>
                <PrimeTable
                  loading={expanded?.loadingInvoiceData}
                  value={expandedInvoiceData.corrective}
                  columns={columns}
                  showFilters={false}
                  paginator={false}
                  filterDisplay={false}
                  selectedColumns={[selectedColumns, setSelectedColumns]}
                  showHeader={false}
                >
                  <Column style={{ width: 40 }} />
                </PrimeTable>
              </div>
            )}
  
            {/* Corrected Section */}
            {expandedInvoiceData.corrected?.length > 0 && (
              <div className="mb-4">
                <h4>{t("queue.correctedInvoice")}</h4>
                <PrimeTable
                  loading={expanded?.loadingInvoiceData}
                  value={expandedInvoiceData.corrected}
                  columns={columns}
                  showFilters={false}
                  paginator={false}
                  filterDisplay={false}
                  selectedColumns={[selectedColumns, setSelectedColumns]}
                  showHeader={false}
                >
                  <Column style={{ width: 40 }} />
                </PrimeTable>
              </div>
            )}
          </div>
        )}
  
        {/* Invoice Items Section */}
        <div className="mb-5" style={{ width: "100vw" }}>
          <h4>Invoice Items</h4>
          <PrimeTable
            loading={expanded?.loadingInvoiceItems}
            value={expanded.invoiceItems}
            columns={invoiceItemsColumns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            responsiveLayout="scroll"
          />
        </div>
      </div>
    );
  };
  

  const onRowExpand = async (event) => {
    onRowCollapse()
    correctiveOnExpand(event);
    invoiceItemsOnExpand(event);
  };

  const onRowCollapse = () => {
    setExpanded(defaultExpand);
  };

  const expansionColumn=(
    <Column
      expander={() => true}
      style={{ minWidth: "75px",maxWidth:"75px" }}
      header=""
      field="expansion"
      frozen={true}
    />
  )

  return {
    expanded,
    expansionColumn,
    onRowExpand,
    rowExpansionTemplate,
    setExpanded,
    onRowCollapse
  };
};

export default useSaleInvoicesExpand;

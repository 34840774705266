import React, { useMemo, useCallback } from 'react';
import ExportCsvPrime from './ExportCsvPrime'; // Adjust the import path as necessary
import { useTranslation } from 'react-i18next';
import func from '../../services/functions';


const ExportCsvMultiple = ({ data, columns, selectedColumns,excludedColumns=[], onClick, callback }) => {
  const { t,i18n } = useTranslation("translations");

  const allCols=useMemo(()=>{
    if (!selectedColumns) return [];
    const newCols= columns.filter(col => selectedColumns?.every((sCol=>sCol.field!==col?.field))).map(foundCol=>({...foundCol,show:!!foundCol.persisted,newCol:true}))
    let currCols = []
    selectedColumns.forEach(sCol=>{
      const currCol=columns.find(col=>col.field===sCol.field)
      if(currCol)currCols.push({...currCol,show:sCol.show})
    })
    return [...newCols,...currCols]
  },[selectedColumns, i18n.language])

  const filteredColumns = useMemo(() => {
    let _filteredColumns = [];
    selectedColumns.forEach((persistedCol) => {
      const column = columns.find((col) => col.field === persistedCol.field);
      if (column && (persistedCol.show || column.persisted)) _filteredColumns.push(column);
    });
    return _filteredColumns;
  }, [selectedColumns, columns]);

  const exportColumns = useCallback(
    ({ simpleExport }) => {
      if (simpleExport) {
        return (
          filteredColumns?.map((col) => ({
            ...col,
            field: col.field,
            header: col.header ?? func.camelToFlat(col.field),
          })) ?? []
        );
      }
      return (
        allCols?.map((col) => ({
          ...col,
          field: col.field,
          header: col.header ?? func.camelToFlat(col.field),
        })) ?? []
      );
    },
    [columns, filteredColumns]
  );

console.log({dataInsideMultipe:data});


  return (
    <div className='d-flex align-items-center'>
      <small>
        <ExportCsvPrime
          data={data}
          onClick={onClick}
          excludedColumns={excludedColumns}
          columns={exportColumns({ simpleExport: true })}
          tooltip={t("books.onlyGridColumns")}
          callback={callback}
        />
      </small>
      <small className="ml-2">
        <ExportCsvPrime
          label="Export All"
          data={data}
          onClick={onClick}
          columns={exportColumns({ simpleExport: false })}
          excludedColumns={excludedColumns}
          tooltip={t("books.allColumns")}
          callback={callback}
        />
      </small>
    </div>
  );
};

export default ExportCsvMultiple;

import React from 'react'

import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainAxios from '../../../services/mainAxios'
import Loader from '../../Layout/Loader'
import { toast } from 'react-toastify'

import { useTranslation, withTranslation } from "react-i18next";
import { useAppContext } from '../../../AppContext'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import selectStyle from '../../../design/selectStyle'



const AddUser = ({onUserAdded,defaultUser={}}) => {
    const { user: [user], selectedCompany: [selectedCompany] } = useAppContext()
    const nuis = user.companiesRights.find(company => company.id === parseInt(selectedCompany))?.nuis
    const { t, i18n } = useTranslation('translations');
    const [passwordType, setPasswordType] = React.useState({password:"password",confirmPassword:"password"})


    const mfaOptions = [
        {
            value: 1,
            label: "Email"
        },
        {
            value: 2,
            label: "Sms"
        },
        {
            value: 3,
            label: t("invoice.disabled")
        }
    ]
    const apiSourceOptions=[
        {label:t("company.yes"),value:"true"},
        {label:t("company.no"),value:"false"}
    ]

    const [state, setState] = React.useState({
        user: {
            active: true,
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            role: null,
            purchaseRole: null,
            mfa: 3,
            apiSource:"false",
            ...defaultUser
        },
        error: null,
        error_password: null,
        roles: null
    })
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetRoles",
            value: null,
            type: "basic"
        }).then(res => {
            if (res.data.status == true) {
                setState((state) => ({
                    ...state,
                    roles: res.data.content[0],
                    purchaseRoles: res.data.content[1],
                    user: {
                        ...state.user,
                        role: {
                            id: res.data.content[0][0].id
                        },
                        purchaseRole: {
                            id: res.data.content[1][1].id
                        }
                    },
                }))
            }
        })
    }, [])

    const addUser = (e) => {
        e.preventDefault()
        let { confirmPassword, ...user } = state.user
        if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(user.password)) {
            if (confirmPassword === user.password) {
                setLoading(true)
                mainAxios.post('apiEndpoint/saveOrUpdate', {
                    object: "User",
                    content: user,
                    nuis: nuis
                }).then(res => {
                    if (res?.data?.status === true) {
                        if(onUserAdded)onUserAdded()
                        toast.success(t(['toast.addUser']), {
                            containerId: "all_toast"
                        })
                    }
                    setLoading(false)
                })
                    .catch((e) => {
                        setLoading(false)
                    })
            }
            else {
                setState(state => ({
                    ...state,
                    error: t(['toast.identical'])
                }))
            }
        }
        else {
            setState(state => ({
                ...state,
                error_password: t(['toast.passwordError'])
            }))
        }
    }

    const handleUserInputs = (e, selectedKey, selectedValue) => {
        const key = e ? e.target.name : selectedKey;
        const value = e ? e.target.value : selectedValue;
        setState(state => ({
            ...state,
            user: {
                ...state.user,
                [key]: value
            }
        }))
    }

    const roleOptions = state.roles?.map(role => ({ label: role.name, value: role.id, ...role }))
    const purchaseRolesOptions = state.purchaseRoles?.map(role => ({ label: role.name, value: role.id, ...role }))
    
    return (
        state.roles ?
            <div className="row mt-4">
                <form className="col-12 mx-auto" onSubmit={addUser}>
                <div className="row">
                                <div className="col-xl-6 col-12">
                                    <div className="form-group">
                                        <label htmlFor="first_name">{t('users.firstName')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="first_name"
                                            name="firstName"
                                            placeholder="E.g. 'John'"
                                            value={state.user.firstName}
                                            onChange={handleUserInputs}
                                            required />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-12">
                                    <div className="form-group">
                                        <label htmlFor="last_name">{t('users.lastName')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="last_name"
                                            name="lastName"
                                            placeholder="E.g. 'Smith'"
                                            value={state.user.lastName}
                                            onChange={handleUserInputs}
                                            required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            placeholder="E.g. 'johnsmith'"
                                            value={state.user.username}
                                            onChange={handleUserInputs}
                                            required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="E.g. 'johnsmith@example.com'"
                                            value={state.user.email}
                                            onChange={handleUserInputs}
                                            required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="phone">{t("users.phone")}</label>
                                        <PhoneInput
                                            country='al'
                                            inputStyle={{ "width": "100%" }}
                                            value={(state?.user?.phone ?? "")}
                                            placeholder="E.g. '+355690000000'"
                                            onChange={(value) => handleUserInputs(null, "phone", value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="role">{t("users.profiscRoles")}</label>
                                        <Select
                                            styles={selectStyle}
                                            className="w-100"
                                            options={roleOptions}
                                            value={roleOptions.find((role) => role.value == state?.user?.role?.id)}
                                            onChange={(role) => handleUserInputs(null, "role", { id: role.id, name: role.name })}
                                            name="role"
                                        />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="purchaseRole">{t("users.purchaseRoles")}</label>
                                        <Select
                                            styles={selectStyle}
                                            className="w-100"
                                            options={purchaseRolesOptions}
                                            value={purchaseRolesOptions?.find((role) => role.value == state?.user?.purchaseRole?.id || "")}
                                            onChange={(purchRole) => { handleUserInputs(null, "purchaseRole", { id: purchRole.id, name: purchRole.name }) }}
                                            name="purchaseRole"
                                        />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="purchaseRole">{t("users.apisource")}</label>
                                        <Select
                                            styles={selectStyle}
                                            className="w-100"
                                            options={apiSourceOptions}
                                            value={apiSourceOptions?.find((opt) => opt.value == state?.user?.apiSource || "")}
                                            onChange={(opt) => { handleUserInputs(null, "apiSource", opt.value) }}
                                        />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="mfa">{t("users.mfaType")}</label>
                                        <Select
                                            styles={selectStyle}
                                            className="w-100"
                                            options={mfaOptions}
                                            value={mfaOptions.find((mfa) => mfa.value == state.user?.mfa)}
                                            onChange={(mfa) => { handleUserInputs(null, "mfa", mfa.value) }}
                                            name="mfa"
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="password">
                                            {state.error_password && <span className="d-block text-danger">{state.error_password}</span>}
                                            {t('users.password')}
                                        </label>
                                        <div className='position-relative h-full'>
                                        <input
                                            type={passwordType.password}
                                            className={"form-control" + (state.error_password ? " border-danger" : "")}
                                            id="password"
                                            name="password"
                                            placeholder="Enter Password"
                                            value={state.user.password}
                                            onChange={handleUserInputs}
                                            required />
                                            <div className="eye-container">
                                                <FontAwesomeIcon
                                                    icon={passwordType.password === "password"?faEye:faEyeSlash}
                                                    onClick={() => setPasswordType((state)=>({...state,password:passwordType.password === "password" ? "string" : "password"}))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="confirm_password">
                                            {t('users.confirmPassword')}
                                            {state.error && <span className="ml-2 text-danger">{state.error}</span>}
                                        </label>
                                        <div className='position-relative h-full'>
                                            <input
                                                type={passwordType.confirmPassword}
                                                className={"form-control" + (state.error ? " border-danger" : "")}
                                                id="confirm_password"
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={state.user.confirmPassword}
                                                onChange={handleUserInputs}
                                                required />
                                                <div className="eye-container">
                                                    <FontAwesomeIcon
                                                        icon={passwordType.confirmPassword === "password"?faEye:faEyeSlash}
                                                        onClick={() => setPasswordType((state)=>({...state,confirmPassword:passwordType.confirmPassword === "password" ? "string" : "password"}))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit mt-3"
                                disabled={loading}
                                style={{ minWidth: "100px" }}
                                className="btn btn-primary">
                                {loading ? <Loader size="xs" color="white" /> :
                                    (<>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
                                    </>)}
                            </button>
                </form>
            </div>
            :
            <Loader />
    )
}

export default AddUser

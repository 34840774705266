import React from 'react'
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import func, { isEmpty } from '../../../services/functions';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { toast } from "react-toastify";
import { useAppContext } from '../../../AppContext';
import ImportExcel from '../../ImportExcel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ReactSelectPagination from '../../global/ReactSelectPagination';
import { uomStyle } from '../../../design/selectStyle';

const DemoWTNtable = ({
    wtnState,
    canEdit,
    errors,
    deleteItem,
    setWtnState
}) => {

    const { unitsOfMeasure,getUnitOfmeasure,
      uomPagination,
      setUomPagination
    } = useAppContext()
    const { t, i18n } = useTranslation("translations");
    const { countDecimals } = func;

    const handleItemChange = ({cellOptions, key, value, length}) => {

    if (key == "quantity") {
        let count = countDecimals(value);
        if (count > 3) return;
    }
        value = value?.length != undefined ? value.slice(0, length) : value;
        const index = cellOptions?.rowIndex ?? 0;
        setWtnState((wtnState) => ({
            ...wtnState,
            items: [
                ...wtnState.items.slice(0, index),
                {
                    ...wtnState.items[index],
                    [key]: value,
                },
                ...wtnState.items.slice(index + 1),
            ],
        }
        ));
        return value
    };

    let unitsOfMeasureDefault = unitsOfMeasure?.find(unitsOfMeasure => unitsOfMeasure?.code === "C62") ?? null;

    const addItem = () => {

        const allFieldsNotEmpty = wtnState.items.every(row => (
            row.code !== "" && row.name !== "" && row.quantity !== ""
        ));

        if (!allFieldsNotEmpty) {
            toast.error(t(["All Items must be filled."]), {
                containerId: "all_toast",
            });
            return;
        }
        setWtnState((wtnState) => ({
            ...wtnState,
            items: [
                ...wtnState.items,
                {
                    id: wtnState.items.length,
                    code: "",
                    name: "",
                    unitOfMeasureId:unitsOfMeasureDefault,
                    quantity: "",
                },
            ],
        }));
    };

    const unitOfMeasureLabel = (unitOfMeasure) => {
      return i18n.language == "alb"
        ? unitOfMeasure?.albanianName
        : unitOfMeasure?.englishName;
    };

    const unitOfMeasureOptions =
      unitsOfMeasure?.map((unitOfMeasure) => {
        return {
          label: unitOfMeasure?.code + '-' + unitOfMeasureLabel(unitOfMeasure),
          value: unitOfMeasure,
        };
      }) ?? [];



  React.useEffect(()=>{
    if(wtnState?.items[0]?.unitOfMeasureId==null){
      handleItemChange({
        key:"unitOfMeasureId",
        value:unitsOfMeasureDefault
      })
    }
    if(wtnState?.items[0]?.unitOfMeasureId?.code === "H87"){
      let xppObj= unitsOfMeasure.find(unitOfMeasure => unitOfMeasure?.value === "XPP")
      handleItemChange({
          key:"unitOfMeasureId",
          value:xppObj
        })
    }

  },[unitsOfMeasure])


  const BodyWrapper=({item,options,children,validate=false})=>{
    const index=options.rowIndex
    if(validate && errors?.[index+"_"+options.field] && isEmpty(item[options.field])){
      return <input className="form-control form-control-sm is-invalid" />
    }else if(item[options.field]==""){
      return <InputText  />
    }
    return children ?? " "
  }

  const unitOfMeasureCellConfig={
    type: "REACT-SELECT",
    options: unitsOfMeasure,
    getOptionLabel:(uom)=>uom?.code + '-' + unitOfMeasureLabel(uom),
    optionValue:"id",
    placeholder:t("item.measure"),
    menuPosition:"fixed",
    isClearable:false,
    getFilteredResults:getUnitOfmeasure,
    pagination:uomPagination,
    setPagination:setUomPagination,
    onChange: ({
      key,
      value,
      cellOptions,
    }) =>
      handleItemChange({
        key,
        value,
        cellOptions,
      }),

    }


    const columns = [
        {
            header: t("item.code"),
            field: "code",
            style: { minWidth: '12rem', maxWidth: '12rem' },
            cellConfig: {
                onChange: ({ key, value, cellOptions }) =>
                    handleItemChange({ key, value, cellOptions, length: 50 }),
            },
            body: (item, options) => <BodyWrapper validate={true} item={item} options={options}>{item?.code ?? ""}</BodyWrapper>
        },
        {
            header: t("item.name"),
            field: "name",
            style: { minWidth: '12rem', maxWidth: '12rem' },
            cellConfig: {
                onChange: ({ key, value, cellOptions }) =>
                    handleItemChange({ key, value, cellOptions, length: 50 }),
            },
            body: (item, options) => <BodyWrapper validate={true} item={item} options={options}>{item?.name ?? ""}</BodyWrapper>
        },
        {
            header: t("item.measure"),
            field: "unitOfMeasureId",
            style: { minWidth: '12rem', maxWidth: "12rem" },
            cellConfig: unitOfMeasureCellConfig,
            body: (item) =>  {
                return unitOfMeasureLabel(item.unitOfMeasureId)}
        },
        {
            style: { minWidth: '12rem', maxWidth: "12rem" },
            header: t("item.quantity"),
            field: "quantity",
            cellConfig: {
                onChange: ({ key, value, cellOptions }) =>
                    handleItemChange({ key, value, cellOptions, length: 50 }),
            },
            body: (item, options) => <BodyWrapper validate={true} item={item} options={options}>{item.quantity ?? ""}</BodyWrapper>

        },


    ];

    const deleteRow = (
   (     <Column
          body={(...props) => {
            const rowIndex = props?.[0]?.id;
            return (
                <div className="d-flex justify-content-left gap-5">
                <div>
                     <i
                        style={{fontSize:"1rem"}}
                       onClick={() => {canEdit && deleteItem(rowIndex)}}
                       className="pi pi-trash trash"
                     ></i>
                 </div>
                {/* <div>
                <i
                      className="pi pi-plus-circle plus"
                      onClick={() => {addItem()}}
                    ></i>
                 </div> */}
               </div>
            );
          }}
          headerStyle={{ width: "10%", minWidth: "2rem" }}
          header={t("home.actions")}
          alignFrozen="left"
          field="actions"
          frozen={true}
        />)
      );


    const editor = (props) => {
        const { col, cellOptions } = props;
        const cellConfig = col?.cellConfig ?? {};
        const currentValue = cellOptions?.value ?? "";
        if(cellConfig?.type=="REACT-SELECT"){
          const {
            optionLabel = "label",
            optionValue = "value",
            onChange,
            ...cellProps
          } = cellConfig;
          return (
            <ReactSelectPagination
            styles={uomStyle}
            isDisabled={ !canEdit}
            value={currentValue}
            onChange={(value) => {
              const key = col?.field;
              cellOptions.editorCallback(value);
              if (onChange) onChange({ key, value, cellOptions });
            }}
            getOptionLabel={option=>option[optionLabel]}
            getOptionValue={(option)=>option[optionValue]}
            {...cellProps}
            />
          )
        }

        if (cellConfig?.type === "DROPDOWN") {
            const {
                optionLabel = "label",
                optionValue = "value",
                onChange,
                ...cellProps
              } = cellConfig;

            return (
                <Dropdown
                    placeholder={t("item.measure")}
                    disabled={!canEdit}
                    filter
                    filterPlaceholder="Search"
                    value={currentValue}
                    onChange={(e) => {
                        const key = col?.field;
                        let value = e?.value;
                        if (onChange) onChange({ key, value, cellOptions });
                        cellOptions.editorCallback(e.value);
                    }}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    {...cellProps}
                    />
            );
        }



        const { type = "text", onChange, ...cellProps } = cellConfig;
        return (
          <InputText
            disabled={!canEdit}
            className=""
            type={col.field == 'quantity' ? 'number' : type}
            onChange={(e) => {
              const key = col?.field;
              let value = e.target.value;
              if (onChange) {
                const newValue = onChange({ key, value, cellOptions });
                cellOptions.editorCallback(newValue);
              }
            }}

            value={currentValue}
            {...cellProps}
          />
        );

    };

    const filteredColumns = () => {
        const _columns = [];
        columns.forEach((visibleCol) => {
            const column = columns.find((col) => col.field === visibleCol.field);
            if (column) _columns.push(column);
        });
        return _columns;
    };

    const dynamicColumns = filteredColumns().map((col, i) => {
        const colProps = {
            style: { height: "2.6rem" },
            key: col?.field,
            field: col?.field,
            header: col?.header,
            rowEdito: false,
            ...col,
        };

        if (col?.editor !== false)
            colProps.editor = (options) => editor({ col, cellOptions: options });


        return <Column sortable {...colProps} />;
    });

    const toString=(num)=>{
        return num!=null && num!=undefined ? num.toString():""
      }

    const onItemsImport=(importState)=>{
        let { importedItems,unitsOfMeasureDefault,allUnitsOfMeasure=[] } = importState
        if(importedItems && importedItems?.length==0) return

        const parsedItems=importedItems.map(item=>{
          let unitOfMeasureId=unitsOfMeasureDefault;
          let {name,code,quantity}=item
          if(item?.unitOfMeasureId){
          const found =allUnitsOfMeasure?.find(({albanianName="",code="",englishName=""})=>
          {
            const check=[albanianName.toLowerCase(),((code ?? "").toString().toLowerCase()),englishName.toLowerCase() ].includes(
              (item?.unitOfMeasureId??"").toString().toLowerCase())
              if(check) 

            return check
          }
            )
            if(found) unitOfMeasureId = found
          }

        let content ={
          name: toString(name),
          code: toString(code),
          quantity: func.isNumeric(quantity)?toString(quantity):null,
          unitOfMeasureId,
      }
        return content
        })

        setWtnState((wtnState)=>({...wtnState,items:parsedItems}))


      }

    const imporItemsDialogHeader=(
        <div className="d-flex align-items-center">
        <h3 className="m-0">{t("invoice.importItems")}</h3>
        <a
                href={"/wtnItemstemplate.xlsx"}
                className="btn btn-primary shadow-sm btn-sm text-light ml-2"
                download="/wtnItemstemplate.xlsx"
              >
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  {t("invoice.itemsTemplate")}
                </div>
              </a>
        </div>

      )

    const header = <div className="border-0 d-flex align-items-center justify-content-between">
    <h3 className="mb-0 ml-2 d-flex align-items-center">
        {t("navbar.products")}
        <ImportExcel
          onItemsImport={onItemsImport}
          requiredColumns={["code","name","quantity"]}
          header={imporItemsDialogHeader}
          isWtn={true}

        />
    </h3>
    <div className="actions">
        {canEdit && (
            <button
                className="btn btn-outline-success btn-sm"
                onClick={addItem}
            >
                {t("item.addItem")}
            </button>
        )}
    </div>
</div> 


    return (
        <div className='card mt-2'>
        <div className="table-container">
            <DataTable
                scrollable
                scrollHeight="260px"
                editMode="cell"
                resizableColumns
                columnResizeMode="expand"
                responsiveLayout="scroll"
                size="small"
                inline
                stripedRows
                selectionMode="single"
                value={wtnState.items ?? []}
                header={header}
            >
                {dynamicColumns}
                {canEdit ? deleteRow : null}
        
            </DataTable>
        </div>
    </div>
    )
}

export default DemoWTNtable
import React, { useEffect, useState } from "react";

import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainAxios from "../../../services/mainAxios";
import Loader from "../../Layout/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../../AppContext";
import "./styles.scss";
import UsersTable from "./UsersTable";
import { useTranslation } from "react-i18next";
import { EditableTable } from "../../EditableTable";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import selectStyle from "../../../design/selectStyle";
import AlertCertificate from "../../global/AlertCertificate";

var _ = require("lodash");
const AddUser = (props) => {
  const {
    user: [currentUser],
  } = useAppContext();

  const { t, i18n } = useTranslation("translations");

  const {
    user: [loggedInUser, setLoggedInUser],
    selectedCompany: [selectedCompany],
    getUser
  } = useAppContext();

  const [editLoading, setEditLoading] = useState(false);
  const [tableData, setTableData] = useState(null);

  const [state, setState] = useState({
    user: null,
    purchaseRoles: null,
    profiscRoles: null,
    companiesForTable: null,
  });
console.log({state});

  let userId = props.match.params.id;
  const isLoggedInUser=userId==loggedInUser.id

  const getCurrentUser=()=>{
    return mainAxios
      .post("apiEndpoint/search", {
        object: "GetUser",
        value: null,
        type: "basic",
        params: { id: userId },
      })
      .then((res) => {
        if (res && res.data.content[0]) {
          let user = res.data.content[0];
          setState((state) => ({
            ...state,
            user,
          }));

          if (loggedInUser.role.id != 1) return;
          let userCompanies = user.companiesRights;

          let dataForOperatorCodes = userCompanies
            .map((t2) => {
              let temp = null;

              let exists = user?.userCompanyOperator?.find((t1) => {
                return t2.id === t1.company;
              });

              if (exists) {
                temp = {
                  company_operator: exists.id,
                  id: exists.company,
                  nuis: t2.nuis,
                  company_id: exists.company,
                  company_name: t2.name,
                  operator: exists.operator,
                  recordDate: exists.recordDate,
                  recordUser: exists.recordUser,
                };
              } else {
                temp = {
                  company_operator: null,
                  id: t2.id,
                  nuis: t2.nuis,
                  company_id: t2.id,
                  company_name: t2.name,
                  operator: "",
                };
              }
              return temp;
            })
            .filter(function (el) {
              return el != null;
            });

          setTableData(dataForOperatorCodes);
          return res.data.content[0]
        }
        return null
      })
      .catch(()=>{
        return null
      })
  }

  useEffect(() => {
    getCurrentUser()
  }, [userId]);

  useEffect(() => {
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetRoles",
        value: null,
        type: "basic",
      })
      .then((res) => {
        if (res.data.status == true) {
          setState((state) => ({
            ...state,
            profiscRoles: res.data.content[0],
            purchaseRoles: res.data.content[1],
          }));
        }
      });
  }, []);

  const mfaOptions = [
    {
      value: 1,
      label: "Email",
    },
    {
      value: 2,
      label: "Sms",
    },
    {
      value: 3,
      label: t("invoice.disabled"),
    },
  ];

  const sendNotificationOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const editUser = (e) => {
    e.preventDefault();
    // const { userCompanyOperator, ...userToUpdate } = state.user;
    const { companiesRights, userCompanyOperator, ...userToUpdate } =
      state.user;
    if (state.user?.phone == "" && state.user.mfa === 2) {
      toast.error(t("users.mfaSmsError"), {
        containerId: "all_toast",
      });
      return;
    }
    setEditLoading(true);
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "User",
        content: userToUpdate,
        nuis: loggedInUser.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setState((state) => ({
            ...state,
            error: null,
          }));
          toast.success(t(["toast.userUpdate"]), {
            containerId: "all_toast",
          });
          if(isLoggedInUser){
            getUser()
          }else if([1].includes(loggedInUser.role.id)) {
              props.history.goBack();
          }
        } else {
          toast.error("An error occured!", {
            containerId: "all_toast",
          });
        }
        setEditLoading(false);
      })
      .catch((err) => {
        setEditLoading(false);
      });
  };
  const handleUserInputs = (e, selectedKey, selectedValue) => {
    const key = e ? e?.target?.name : selectedKey;
    let value = e ? e?.target?.value : selectedValue;

    setState((state) => ({
      ...state,
      user: {
        ...state.user,
        [key]: value,
      },
    }));
  };

  const columns = [
    {
      name: "Company",
      selector: "company_name",
      sortable: true,
      editable: false,
    },
    {
      name: "Operator Code",
      selector: "operator",
      //sortable: true,
      editable: true,
    },
  ];

  const getOperator = (prev, companyOperator) => {
    let data = {
      object: "UserCompanyOperator",
      content: {
        company: companyOperator.company_id,
        user: state.user.id,
        operator: companyOperator.operator,
        recordDate: companyOperator.recordDate,
        recordUser: companyOperator.recordUser,
      },
      username: state.user.username,
      nuis: companyOperator.nuis,
    };

    if (prev.operator) {
      let op = tableData.find(
        (op) => op.company_id === companyOperator.company_id
      );
      data.content.id = op.company_operator;
    }
    mainAxios.post("apiEndpoint/saveOrUpdate", data).then((res) => {
      if (res?.data?.status === true) {
        //console.log(res.data.content[0])

        let newOperators = [...tableData];
        newOperators = newOperators.map((op) => {
          //console.log({op})
          if (op.company_id === companyOperator.company_id) {
            //console.log("found the op to update", {op})

            let operator = {
              company_operator: res.data.content[0].id,
              id: companyOperator.company_id,
              nuis: companyOperator.nuis,
              company_id: companyOperator.company_id,
              company_name: companyOperator.company_name,
              operator: companyOperator.operator,
            };
            return operator;
          } else return op;
        });

        //console.log({newOperators})
        setTableData([...newOperators]);
        if(isLoggedInUser){
          getUser()
        }
        toast.success("Operator Code was changed successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          containerId: "all_toast",
        });
      }
    });
  };

  let filteredProfiscRoles = state?.profiscRoles;
  if (loggedInUser?.role?.id != 1 && state?.user?.role?.id != 1) {
    filteredProfiscRoles = state?.profiscRoles?.filter(
      (role) => role?.id !== 1
    );
  }
  filteredProfiscRoles = filteredProfiscRoles?.map((role) => ({
    label: role.name,
    value: role.id,
    ...role,
  }));

  const purchaseRolesOptions = state.purchaseRoles?.map((role) => ({
    label: role.name,
    value: role.id,
    ...role,
  }));
  const isRoleDisabled =
    loggedInUser?.role?.id != 1 ? state?.user?.role?.id != 1 : false;

  const apiSourceOptions = [
    { label: t("company.yes"), value: "true" },
    { label: t("company.no"), value: "false" }
  ]
  console.log({ user: state.user })

  return state.user ? (
    <div className="row mt-5">
      <form
        className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto"
        onSubmit={(e) => editUser(e)}
      >
        <div className="card filter-card-dashboard shadow mb-4">
            <div className="card-title p-3 ml-2 text-left d-flex align-items-center">
              <h3 className="mb-0">{t("users.editUser")}</h3>
              <AlertCertificate />
            </div>
          <div className="card-body">
            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="first_name">{t("users.firstName")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="firstName"
                    placeholder="E.g. 'John'"
                    value={state.user.firstName}
                    disabled={isRoleDisabled}
                    onChange={handleUserInputs}
                    required
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="last_name">{t("users.lastName")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="lastName"
                    disabled={isRoleDisabled}
                    placeholder="E.g. 'Smith'"
                    value={state.user.lastName}
                    onChange={handleUserInputs}
                    required
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="active">{t("company.active")}</label>
                  <select
                    className="form-control"
                    id="active"
                    name="active"
                    disabled={isRoleDisabled}
                    value={state.user.active}
                    onChange={handleUserInputs}
                  >
                    <option value="false">{t("company.no")}</option>
                    <option value="true">{t("company.yes")}</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="phone">{t("users.phone")}</label>
                  <PhoneInput
                    country="al"
                    inputStyle={{ width: "100%" }}
                    value={state?.user?.phone ?? ""}
                    disabled={isRoleDisabled}
                    placeholder="E.g. '+355690000000'"
                    onChange={(value) => handleUserInputs(null, "phone", value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="role">{t("users.role")}</label>
                  <Select
                    styles={selectStyle}
                    className="w-100"
                    options={filteredProfiscRoles}
                    isDisabled={isRoleDisabled}
                    value={filteredProfiscRoles?.find(
                      (role) => role.value == state.user?.role?.id
                    )}
                    onChange={(role) =>
                      handleUserInputs(null, "role", {
                        id: role.id,
                        name: role.name,
                      })
                    }
                    name="role"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="purchaseRole">
                    {t("users.purchaseRoles")}
                  </label>
                  <Select
                    styles={selectStyle}
                    className="w-100"
                    options={purchaseRolesOptions}
                    isDisabled={isRoleDisabled}
                    value={purchaseRolesOptions?.find(
                      (role) => role.value == state.user?.purchaseRole?.id || ""
                    )}
                    onChange={(purchRole) =>
                      handleUserInputs(null, "purchaseRole", {
                        id: purchRole.id,
                        name: purchRole.name,
                      })
                    }
                    name="purchaseRole"
                  />
                </div>
              </div>
              {loggedInUser.role.id == 1 && (
                <div className="col-xl-6 col-12">
                  <div className="form-group">
                    <label htmlFor="mfa">{t("users.mfaType")}</label>
                    <Select
                      styles={selectStyle}
                      className="w-100"
                      options={mfaOptions}
                      value={mfaOptions.find(
                        (mfa) => mfa.value == state.user?.mfa
                      )}
                      isDisabled={isRoleDisabled}
                      onChange={(mfa) => {
                        handleUserInputs(null, "mfa", mfa.value);
                      }}
                      name="mfa"
                    />
                  </div>
                </div>
              )}

              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="username">{t("users.username")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="E.g. 'johnsmith'"
                    value={state.user.username}
                    onChange={handleUserInputs}
                    disabled
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="E.g. 'johnsmith@example.com'"
                    value={state.user.email}
                    onChange={handleUserInputs}
                    disabled={isRoleDisabled}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-12">
              <div className="form-group">
                  <label htmlFor="active">{t("users.apisource")}</label>
                  <Select
                    styles={selectStyle}
                    className="w-100"
                    options={apiSourceOptions}
                    isDisabled={isRoleDisabled}
                    value={apiSourceOptions?.find((opt) => opt.value == (state?.user?.apiSource ?? "")?.toString() || "")}
                    onChange={(opt) => { handleUserInputs(null, "apiSource", opt.value) }}
                  />
                </div>
                </div>

              <div className="col-xl-6 col-12">
                <div className="form-group">
                  <label htmlFor="receiveAdministrativeNotification">
                    {t("users.reciveAdministrativeNotification")}
                  </label>
                  <Select
                    styles={selectStyle}
                    className="w-100"
                    options={sendNotificationOptions}
                    isDisabled={isRoleDisabled}
                    value={sendNotificationOptions.find(
                      (notification) =>
                        notification.value ==
                        state.user?.receiveAdministrativeNotification
                    )}
                    onChange={(notification) => {
                      handleUserInputs(
                        null,
                        "receiveAdministrativeNotification",
                        notification?.value
                      );
                    }}
                    name="receiveAdministrativeNotification"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <Link
                    to={"/users/edit-email/" + state.user.id}
                    className="nav-link p-0"
                  >
                    {t("users.changeEmail")}
                  </Link>
                  {state?.user?.active==true && <Link
                    to={"/users/edit-password/" + state.user.id}
                    className="nav-link p-0"
                  >
                    {t("users.changePassword")}
                  </Link>}
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={editLoading}
              style={{ minWidth: "100px" }}
              className="btn btn-primary mt-5"
            >
              {editLoading ? (
                <Loader size="xs" color="white" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  {t("common.save")}
                </>
              )}
            </button>
          </div>
        </div>
        {tableData && (
          <div className="card filter-card-dashboard shadow mb-4">
            <div className="card-title p-3 ml-2 text-left d-flex align-items-center">
              <h3 className="mb-0">Operator Codes</h3>
            </div>
            <div className="card-body">
              <EditableTable
                columns={columns}
                data={[...tableData]}
                getEditedData={getOperator}
              />
            </div>
          </div>
          
        )}
      </form>
      {[1].includes(loggedInUser.role?.id) && (
        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
          <div className="card filter-card-dashboard border shadow mb-4">
            <div className="p-0 card-body">
              <UsersTable state={state} setState={setState} refreshUser={getCurrentUser}/>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default AddUser;

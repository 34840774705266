import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Layout/Loader";
import mainAxios from "../../../services/mainAxios";
import Table from "../../Table";
import selectStyle from "../../../design/selectStyle";
import { useTranslation, withTranslation } from "react-i18next";
import AlertCertificate from "../../global/AlertCertificate";
import ExportCsv from "../../global/ExportCsv";
import Select from "react-select"
import { useAppContext } from "../../../AppContext";
import CellInput from "../../global/CellInput";
import PrimeTable from "../../PrimeTable/PrimeTable";
import AddUser from "./AddUser";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
const moment = require("moment");

const OperatorsCis = ({company}) => {
  const { user: [user], selectedCompany: [selectedCompany] } = useAppContext();
  const [users, setOperatorsCis] = React.useState(null);
  const [usersForCsv, setOperatorsCisForCsv] = useState([]);
  const { t, i18n } = useTranslation("translations");
  const [openAddUser,setOpenAddUser]=useState({visible:false,data:null})
  const [cisLoading,setCisLoading]=useState(false)

  const defaultSortCriteria={
    field: "recordDate",
    direction: "DESC"
  }
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
    sortCriteria:defaultSortCriteria
  });

  const defaultFilters = { params: {} }
  const [filters, setFilters] = React.useState(defaultFilters);

  React.useEffect(() => {
    getOperatorsCisResults(10, 1);
  }, [selectedCompany]);



  const getOperatorsCisResults = ({ updatedFilters,rowsPerPage = 10, currentPage = 1, sortCriteria = [ defaultSortCriteria ], }) => {
    let compId = company.id
    let requestBody = {
      object: "GetOperatorCis",
      nuis:company?.nuis,
      company:compId,
      sortCriteria,
      params:updatedFilters?.params,
      pagination: {
        pageSize: rowsPerPage,
        pageNumber: currentPage,
      },
    };
    return mainAxios.post(
      // apiEndpoint/search
      "/apiEndpoint/search"
      , requestBody).then((res) => {
      if (res?.data?.status === true) {
          setOperatorsCisForCsv(res.data.content)
          setOperatorsCis(res.data.content);
          //set total rows from totalSize of response
          setTableRows((tableRows) => ({
            ...tableRows,
            totalRows: res?.data?.totalSize,
          }));
      }
    });
  };

  const onAddUser=(e,cisUser)=>{
    e.preventDefault();
    const {operatorFirstName:firstName,operatorLastName:lastName,}=cisUser
    const username=(firstName?.[0] ?? "")+(lastName ?? "")
    const user={
      firstName,lastName,username
    }
    setOpenAddUser({visible:true,data:user})
  }

  const addUserBody=(user)=>{
    return ( <>
      <Link onClick={e=>onAddUser(e,user)} className="nav-link p-0">
        <FontAwesomeIcon icon={faPlus} className="mr-1" />
        {t("selfServices.addProfiscUser")}
      </Link>
    </> )
  }


  const columns = [
    {
      header: t(["home.actions"]),
      style:{minWidth:"180px",maxWidth:"180px"},
      body: addUserBody,
    },
    {
      header: t(["users.firstName"]),
      field: "operatorFirstName",
      filter: true,
      sortable: true,
      style:{minWidth:"150px",maxWidth:"150px"},
      body: (invoice) => <CellInput width={60} value={invoice?.operatorFirstName ?? ""} />

    },
    {
      header: t(["users.lastName"]),
      field: "operatorLastName",
      sortable: true,
      filter: true,
      style:{minWidth:"150px",maxWidth:"150px"},
      body:(invoice)=><CellInput width={60} value={invoice?.operatorLastName ?? ""}/>

    },
    {
      header: t("table.operatorid"),
      field: "operatorId",
      sortable: true,
      filter: true,
      style:{minWidth:"170px",maxWidth:"180px"},
      body:(invoice)=><CellInput width={60} value={invoice?.operatorId ?? ""}/>

    },
    {
      header: "Operator Valid From",
      field: "operatorValidFrom",
      style:{minWidth: "200px",maxWidth:"200px"},
      sortable: true,
      body: (user) =>
        user?.operatorValidFrom
          ? moment(user?.operatorValidFrom).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      header: "Operator Valid To",
      field: "operatorValidTo",
      style:{minWidth: "200px",maxWidth:"200px"},
      sortable: true,
      body: (user) =>
        user?.operatorValidTo
          ? moment(user?.operatorValidTo).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      header: t("company.cisUpdate"),
      field: "cisLastUpdate",
      style:{minWidth: "260px",maxWidth:"300px"},
      sortable: true,
      body: (user) =>
        user?.cisLastUpdate
          ? moment(user?.cisLastUpdate).format("DD/MM/YYYY HH:mm")
          : "",
    },
    {
      header: "Missing From CIS",
      field: "missingFromCis",
      style:{minWidth: "200px",maxWidth:"200px"},
      sortable: true,
      // body: (user) => (user?.missingFromCis ? t(["company.yes"]) : t(["company.no"])),
      // body: (user) => actions(user, "missingFromCis"),
      filter:true,
      filterType:"TRISTATECHECKBOX",
      dataType:"boolean"
    },
    {
      header: t(["invoice.Operator"]),
      field: "operatorCode",
      style:{minWidth: "150px",maxWidth:"150px"},
      filter: true,
      sortable: true,
    },
  ];

  if (!users) return <Loader />

  const refreshDataFromCos=()=>{
    setCisLoading(true)
    mainAxios.post("/endpoint/v3/getDataFromCis",{
        object: "GetOperator",
        nuis:company.nuis,
        company:company.id
    }).then(res=>{
        setCisLoading(false)
      if(res.data.status){
        getOperatorsCisResults(10,1)
          toast.success(t(['purchases.defaultAction']), {
              containerId: "all_toast"
          })
      }
    })
    .catch(err=>{
        setCisLoading(false)
    })
}


  const headerButtonsRight=(
    <div className='d-flex align-items-center btn btn-sm btn-outline-primary mr-3' onClick={() => { if (!cisLoading) refreshDataFromCos()}}>
    {cisLoading?<Loader size={"xs"}/>:"Get Operators From CIS"}
</div>
  )

  return (
    <>
      <Dialog
        header={t('users.add')}
        visible={openAddUser.visible}
        onHide={() => setOpenAddUser({ visible: false, data: null })}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}

      >
        {openAddUser.visible &&<AddUser
          defaultUser={openAddUser?.data ?? null}
        />}
      </Dialog>
      <PrimeTable
        stripedRows={false}
        filters={[filters, setFilters]}
        tableRows={[tableRows, setTableRows]}
        value={users ?? []}
        columns={columns}
        reorderableColumns={true}
        showFilters={true}
        filterDisplay={"row"}
        responsiveLayout="scroll"
        showHeader={true}
        dataKey="id"
        onRemoteDataChange={(remoteData) => {
          const updatedFilters = remoteData.filters ?? filters
          const updatedTableData = remoteData.tableRows ?? tableRows
          let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
          sortCriteria=sortCriteria?[sortCriteria]:null
          getOperatorsCisResults({updatedFilters, rowsPerPage, currentPage, sortCriteria })
        }}
        defaultSortCriteria={defaultSortCriteria}
        clearFilters={false}
        showSelectColumns={false}     
        headerButtonsRight={headerButtonsRight}  
        />
    </>
  )
};

export default OperatorsCis;

import React from 'react'
import ReactDOM from 'react-dom'
import { useAppContext } from '../../AppContext'

export const DisableLayout = ({ children }) => {
  const { disabledLayout } = useAppContext()

  const overlay = disabledLayout ? (
    <div
      style={{
        position: 'fixed', 
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: 'rgba(255, 255, 255, 0.6)',
        zIndex: 1000, 
        pointerEvents: 'all', 
        userSelect: 'none',
        cursor: 'not-allowed',
      }}
    />
  ) : null;

  return (
    <div style={{ position: 'relative' }}>
      {disabledLayout && ReactDOM.createPortal(overlay, document.body)}
      
      <div
        style={{
          pointerEvents: disabledLayout ? 'none' : 'auto', 
          opacity: disabledLayout ? 0.9 : 1, 
        }}
      >
        {children}
      </div>
    </div>
  )
}

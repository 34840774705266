import React, { useCallback, useEffect, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../Layout/Loader'
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import selectStyle from '../../../design/selectStyle'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { globalHandleChange } from '../../../services/functions'
import { useSubscriptionRequests } from '../../../requests/SubscriptionsRequests'



const AddEditSubscription = ({ company, subscription, onSubscriptionChange }) => {
  const isEdit = !!subscription?.id
  const { t } = useTranslation('translations');
  const [packages, setPackages] = useState([])
  const [pageLoading, setPageLoading] = useState(false)

  const defaultState=  {
    company: company.id,
    subscriptionId: subscription?.subscriptionId,
    startDate: new Date(moment()),
    endDate: new Date(moment().add(6, 'months')),
    period: 12,
    notes: "",
    addons: []
    // "noInvoicesTot": 100,
    // "noTcrsTot": 10,
    // "noUsersTot": 5,
  }

  const [initialState,setInitialState]=useState(defaultState)
  const [state, setState] = useState(defaultState)
  const { saveCompanySubscription, getSubscriptionPackages,getSubscriptionById, actionLoading } = useSubscriptionRequests({ company, onSubscriptionChange })

  useEffect(()=>{
    (async () => {
      if(!subscription?.id) return 
      setPageLoading(true)
      const content=await getSubscriptionById(subscription.id)

      if(content){
        setPageLoading(false)
        setState({...content})
        setInitialState({...content})
      }
    })();
  },[company,subscription])

  useEffect(() => {
    if (state.startDate && state.period != "custom") {
      const newEndDate = moment(state.startDate).add(state.period, 'months');
      setState(prevState => ({
        ...prevState,
        endDate: newEndDate.toDate()
      }));
    }
  }, [state.startDate, state.period]);


  useEffect(() => {
    (async () => {
      let _packages = await getSubscriptionPackages()
      setPackages(_packages ?? [])
    })();
  }, [company?.id])




  const handleSubmit = (e) => {
    e.preventDefault();
    saveCompanySubscription({ state,onSubscriptionChange})
  };


  const handleChange = (key, value, length) => globalHandleChange({ key, value, setState, length })


  let periodOptions = [
    {
      label: "6 muaj",
      value: 6
    },
    {
      label: "1 vit",
      value: 12
    },
    {
      label: "2 vite",
      value: 24
    },
    {
      label: "3 vite",
      value: 36
    },
    {
      label: "Other",
      value: 'custom'
    }
  ]
  const corporateSub=state?.subscriptionId==5
  const customSubscription=state?.subscriptionId ? state?.subscriptionId==6 :state?.subscription?.id==6
  
  const checkCorporate = useCallback((value) => {
    const currSub = state[value] ?? ""
    return (state?.subscriptionId == 5) ? '\u221E' : (currSub ?? "")
  },[state])

  const handlePackgeChange=(newPackage)=>{

    const currSub = packages?.find(sub => sub.id == newPackage?.id )
    let {noInvoice:subPacgNoInvoices,noUsers:subPacgNoUsers,noTcrs:subPacgNoTcrs } = currSub

    if(newPackage.id==5 && isEdit){//only in edit modal,the state on other needs to revert to initial state
      let { subPacgNoInvoices:_subPacgNoInvoices,subPacgNoUsers:_subPacgNoUsers,subPacgNoTcrs:_subPacgNoTcrs}=initialState 
      subPacgNoInvoices=_subPacgNoInvoices
      subPacgNoUsers=_subPacgNoUsers
      subPacgNoTcrs=_subPacgNoTcrs
    }

    setState((state)=>{
      return {
      ...state,
      subPacgNoInvoices,
      subPacgNoUsers,
      subPacgNoTcrs,
      subscriptionId:newPackage.id
      }
      
    })
  }

const handleStartChange=(startDate)=>{
  setState((state)=>({
    ...state,
    startDate,
    endDate:moment(state.endDate).isBefore(startDate)?null:state.endDate
  }))
}

  if (pageLoading) return <Loader />

  return (
    <div className="row mt-4 mx-0">
      <form className=" mx-auto" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="first_name">{t("subscription.name")}</label>
              <Select
                styles={selectStyle}
                className="w-100"
                options={packages}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.name}
                value={packages.find(sub => sub.id == (state.subscriptionId ??state.subscription?.id ))}
                onChange={(newPackage) => handlePackgeChange(newPackage)}
                name="name"
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noInvoice">{t("navbar.invoices")}</label>
              <input
                type={corporateSub?"text":"number"}
                className="form-control"
                value={checkCorporate("subPacgNoInvoices")}
                onChange={(e) => handleChange("subPacgNoInvoices", parseInt(e.target.value))}
                disabled={!customSubscription} />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noUsers">{t("navbar.users")}</label>
              <input
                type={corporateSub?"text":"number"}
                className="form-control"
                value={checkCorporate("subPacgNoUsers")}
                onChange={(e) => handleChange("subPacgNoUsers", parseInt(e.target.value))}
                disabled={!customSubscription} />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="noTcrs">TCR</label>
              <input
                type={corporateSub?"text":"number"}
                className="form-control"
                value={checkCorporate("subPacgNoTcrs")}
                onChange={(e) => handleChange("subPacgNoTcrs", parseInt(e.target.value))}
                disabled={!customSubscription} />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="first_name">{t("subscription.subPeriod")}</label>
              <Select
                // required={true}
                styles={selectStyle}
                className="w-100"
                options={periodOptions}
                value={periodOptions.find(opt => opt.value == state.period)}
                getOptionValue={(opt) => opt.value}
                onChange={(sub) => handleChange("period", sub.value)}
                name="period"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="startDate">{t("invoice.sDate")}</label>
              <DatePicker
                required={true}
                selected={state?.startDate}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                onChange={date => handleStartChange(date)}
                customInput={<input className="form-control form-control-sm" />}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="endDate">{t("invoice.endDate")}</label>
              <DatePicker
                required={true}
                selected={state?.endDate}
                minDate={state?.startDate}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                onChange={date => handleChange('endDate', date)}
                customInput={<input className="form-control form-control-sm" />}
                disabled={state?.period!='custom'}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="notes">{t("item.notes")}</label>
              <textarea
                rows={2}
                type="text"
                maxLength={50}
                name="notes"
                value={state?.notes}
                onChange={(e) =>
                  handleChange("notes", e?.target?.value)
                }
                className="form-control"
              />
              <div className={`length-text ${state?.notes?.length == 50 ? "text-danger" : ""}`}>
                {state?.notes?.length ?? "0"}/50
              </div>
            </div>
          </div>
          {/* {isEdit && state?.companySubscriptionAddons?.length>0 && ( <>
            <h3 className='ml-3'>Addons</h3>
          <AddonsList addons={state?.companySubscriptionAddons ?? []} disabled={true}/>
          </>)} */}


        </div>
        <button
          type="submit mt-3"
          disabled={actionLoading}
          style={{ minWidth: "100px" }}
          className="btn btn-primary">
          {actionLoading ? <Loader size="xs" color="white" /> :
            (<>
              <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
            </>)}
        </button>
      </form>
    </div>
  )
}

export default AddEditSubscription

import { Dialog } from 'primereact/dialog'
import React, { useState,useMemo } from 'react'
import AddBankAccount from './AddBankAccount'
import Table from '../../Table'
import { useTranslation } from 'react-i18next'
import TooltipOverlay from '../../global/TooltipOverlay'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import mainAxios from '../../../services/mainAxios'
import PrimeTable from '../../PrimeTable/PrimeTable'
import { toast } from "react-toastify";
import currencies from "../../../services/currencies.json"

const BankAccounts = ({company,setCompany,getCompany}) => {
  const [addBankAccountVisible, setAddBankAccountVisible] = useState({show:false,id:null})
  const { t, i18n } = useTranslation("translations");
  const [reorderRow, setReorderRow] = useState(null);
  const [loading,setLoading]=useState(false)


  const setDefaultBankAccount = (e, bankAccountId) => {
    e.preventDefault();
    const index = company.bankAccounts.findIndex(
      (ba) => ba.id === bankAccountId
    );
    const bankAccounts = [
      ...company.bankAccounts
        .slice(0, index)
        .map((bankAccount) => ({ ...bankAccount, default: false })),
      {
        ...company.bankAccounts[index],
        default: true,
      },
      ...company.bankAccounts
        .slice(index + 1)
        .map((bankAccount) => ({ ...bankAccount, default: false })),
    ];

    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "SetDefaultBankAccount",
        operation: JSON.stringify({ id: bankAccountId }),
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success("Bank Account was set as default", {
            containerId: "all_toast",
          });
          setCompany((state) => ({
              ...state,
              bankAccounts,
          }));
        }
      });
  };

  const bankAccountColumns = [
    {
      header: t(["home.actions"]),
      style:{minWidth: "100px",maxWidth:"150px"},
      body: (bankAccount) => (
        <div className='d-flex align-items-center'>
          <TooltipOverlay title={t("common.edit")} show={0} hide={0}>
            <Link
                href="#"
                onClick={(e)=>{
                  e.preventDefault()
                  setAddBankAccountVisible({show:true,id:bankAccount.id.toString()})
                }
                }
              // to={`/companies/${company.id}/bank-accounts/${bankAccount.id}`}
                className="nav-link p-0 mr-3"
            >
              <FontAwesomeIcon icon={faEdit} className="mr-1" />
            </Link>
          </TooltipOverlay>
          {!bankAccount.default ? (
            <TooltipOverlay title={t("company.setDefault")} show={0} hide={0}>
              <Link
                href="#"
                className="nav-link p-0"
                onClick={(e) => {
                  setDefaultBankAccount(e, bankAccount.id);
                }}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-1" />
              </Link>
            </TooltipOverlay>
          ) : (
            <div className="badge badge-success">Default</div>
          )}
        </div>
      ),
    },
    {
      header: t(["bankAccount.accountName"]),
      filter: true,
      field: "bankName",
      style:{minWidth: "180px",maxWidth:"200px"},
      sortable: true,
      body: (bankAccount) => bankAccount.bankName || "",
    },
    {
      header: "IBAN",
      field: "iban",
      filter: true,
      style:{minWidth: "180px",maxWidth:"200px"},
      sortable: true,
      body: (bankAccount) => bankAccount.iban || "",
    },
    {
      header: t(["invoice.currency"]),
      field: "currency",
      filter: true,
      sortable: true,
      style:{minWidth: "180px",maxWidth:"200px"},
      filter:true,
      filterType:"DROPDOWN",
      filterConfig: {
        filter:true,
        options: currencies,
      },
      body: (bankAccount) => bankAccount.currency || "",
    },

    {
      header: t(["bankAccount.includeInInvoice"]),
      sortable: true,
      style:{minWidth: "100px",maxWidth:"100px"},
      field: "includeInInvoice",
      filter:true,
      filterConfig: {
        field: "isActive"
      },
      filterType:"TRISTATECHECKBOX",
      dataType:"boolean",
    },
    {
      header: t(["bankAccount.activate"]),
      sortable: true,
      style:{minWidth: "50px",maxWidth:"50px"},
      filter:true,
      filterConfig: {
        field: "isActive"
      },
      filterType:"TRISTATECHECKBOX",
      dataType:"boolean",
      field: "active",
    },
  ];

  const conditionalBankAccountRowStyles = [
    {
      when: (account) => account?.default,
      style: {
        backgroundColor: "#d4edda",
        color: "#155724",
        "&:hover": {
          cursor: "#155724",
        },
      },
    },
  ];

  const saveReorderRows = (newBankAccounts) => {
    setLoading(true)
    mainAxios
      .post('apiEndpoint/saveOrUpdate', {
        object: 'ReorderBankAccounts',
        nuis: company.nuis,
        content: newBankAccounts.map(({id,orderNo})=>({ id,orderNo })),
      })
      .then((res) => {
        if(res.data.status){
          setCompany((company)=> ({
            ...company,
            bankAccounts:newBankAccounts
          }));
        }
        setLoading(false)
        toast.success(t("invoice.bankAccOrder"), {
          containerId: "all_toast",
        });
      })
      .catch((err) => {
        console.log(err);
      });
      
  };
  


  const orderedBankAccounts=useMemo(()=>{
    return  company?.bankAccounts.sort((a, b) => a.orderNo - b.orderNo);
  },[company?.bankAccounts])

    return (
        <>
            <div className="branches-actions  d-flex justify-content-end align-items-end mb-4">
                <button className="btn btn-sm btn-outline-primary justify-content-center align-items-center mb-0"
                    onClick={() => setAddBankAccountVisible({ show: true, id: null })}>
                    {t("bankAccount.add")}
                </button>
            </div>
            <Dialog
                header={addBankAccountVisible.id ? t("bankAccount.edit") : t("bankAccount.add")}
                visible={addBankAccountVisible.show}
                onHide={() => setAddBankAccountVisible((state) => ({ ...state, show: false }))}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                closable={true}
                dismissableMask={true}

            >
                <AddBankAccount
                    company={company}
                    bankAccountId={addBankAccountVisible.id}
                    onBankAdded={() => {
                        setAddBankAccountVisible({ show: false, id: null })
                        getCompany({ reloadParameters: false, reload: true })
                    }}
                />
            </Dialog>
            {/* <Table
                columns={bankAccountColumns}
                data={[...(company.bankAccounts || [])]}
                searchableKeys={[
                    "bankName",
                    "iban",
                    "currency",
                    "includeInInvoice",
                    "default",
                ]}
                defaultSortField="modificationDate"
                conditionalRowStyles={conditionalBankAccountRowStyles}
            /> */}

  
      
        <PrimeTable
          reorderableRows 
          stripedRows={false}
          reorderableColumns={true}
          loading={loading}
          // rowClassName={branchActiveClass}
          value={orderedBankAccounts?? []}
          columns={bankAccountColumns}
          showFilters={true}
          filterDisplay={"row"}
          responsiveLayout="scroll"
          showHeader={false}
          dataKey="id"
          scrollHeight="580px"
          simpleTable={true}
          onRowReorder={(e) => {
            const newBankAccounts = [...orderedBankAccounts]; 
            const draggedRow = newBankAccounts[e.dragIndex];
            newBankAccounts.splice(e.dragIndex, 1); 
            newBankAccounts.splice(e.dropIndex, 0, draggedRow);
            newBankAccounts.forEach((bankAccount, index) => {
              bankAccount.orderNo = index + 1; 
            });
            saveReorderRows(newBankAccounts);
          }}
        />
      

        </>
    )
}

export default BankAccounts
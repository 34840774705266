import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import func from '../../services/functions';
import mainAxios from '../../services/mainAxios';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import PopoverPicker from '../global/PopoverPicker';
import Select from "react-select";
import { customStyles } from '../../design/selectStyle';
import Loader from '../Layout/Loader';
import { useAppContext } from '../../AppContext';



const CompanyAttributes = ({company,isAdvanced=false,getCompany,editCompany,handleCompanyInputs
  // defCompanyAttributes=[],
  // staticCompanyAttributes

}) => {
  const { t, i18n } = useTranslation("translations");

  const {
    company: [fullCompany, setFullCompany],
    user: [user],
    selectedCompany: [selectedCompany]
  } = useAppContext();


  const isSimpleUser=![1,2].includes(user?.role?.id)
  const isAdmin=user?.role?.id===2
  


      const sortAttributes = (a, b) => {
        if (a.type === "NUMBER" && b.type !== "NUMBER") return -1; 
        if (a.type !== "NUMBER" && b.type === "NUMBER") return 1;
        return 0;
      };

      const operatorModeOptions=[
        {
            code: "ON",
            name: "ON"
        },
        {
            code: "OFF",
            name: "OFF"
        },
        {
            code: "TEST",
            name: "TEST"
        }
    ]

    const tcrDefaultOptions=useMemo(()=>{
      const _tcrs=[]
      const companyRight=user?.companiesRights?.find(companyRight=>companyRight.id==selectedCompany)
      if(companyRight){
        (companyRight?.branches ?? []).forEach(branch=>{
          (branch?.tcrTypes ?? []).forEach(tcr=>{if(tcr.tcrCode && tcr.allowPurchase){_tcrs.push({code:tcr.id, name: tcr.tcrCode})}})
        })
      }
      return _tcrs
    },[user,selectedCompany])

    
     const defaultRegularCompanyAttributes = [
        { param: "AdditionalId1", value: "AdditionalId1", type: "TEXT", },
        { param: "AdditionalId2", value: "AdditionalId2", type: "TEXT" },
        { param: "internalId", value: "Erp Id", type: "TEXT" },
        { param: "backgroundColor", value: "#40D97D", type: "COLOR" },
        { param: "fontColor", value: "#000000", type: "COLOR" },
      ]
        const staticRegularCompanyAttributes = [
        { param: "issuerInVat", value: t('company.isInVat'), type: "NUMBER", onChange:handleCompanyInputs },
        { param: "groupAction", value: t("purchases.groupAction"), type: "NUMBER", onChange:handleCompanyInputs },
        { param: "showCancelInvoiceButton", value:t("company.showCancelInvoiceButton"), type: "NUMBER", onChange:handleCompanyInputs },
        { param: "showMyPurchaseDownloadedStatus", value:t("company.showMyPurchaseDownloadedStatus"), type: "NUMBER", onChange:handleCompanyInputs },
      ]
    
      const staticAdvancedAttributes = [
        { param: "preventErpDuplication", value: t("company.preventErpDup"), type: "NUMBER", onChange: handleCompanyInputs },
        { param: "checkBuyerNuis", value: t("company.checkBuyerNuis"), type: "NUMBER", onChange: handleCompanyInputs },
        { param: "operatorMode",options:operatorModeOptions, value: t("parameters.operatorMode"), type: "SELECT", onChange: handleCompanyInputs },
        { param: "defaultTcr",options:tcrDefaultOptions, value: "Default TCR", type: "SELECT", onChange: handleCompanyInputs },
        { param: "maxDaysPurchSearch",value: "Max. Days for Purchase Search", type: "INPUT_NR", onChange: handleCompanyInputs },
      ];
    
      const defCompanyAttributes=isAdvanced?[]:defaultRegularCompanyAttributes
      const staticCompanyAttributes=isAdvanced?staticAdvancedAttributes:staticRegularCompanyAttributes

      const [companyAttributes, setCompanyAttributes] = React.useState(defCompanyAttributes);
      const [tabLoading,setTabLoading]=useState(true)


  useEffect(()=>{
    const companyId = company.id;
    let config = {
        headers: {
          Authorization: "Bearer " + func.getCookie("token"),
        },
      };
    axios.post(
      `${process.env.REACT_APP_JAVA_SERVER_URL}apiEndpoint/search`,
      {
        object: "GetCompanyAttributes",
        company: companyId,
      },
      config
    )
    .then((res) => {
      setTabLoading(false)
      if (res.data.status == true) {
        let companyAttrs = [...companyAttributes];
        let content=res.data.content
        
        if(isAdvanced){
          content=content.filter(attr=>attr.advanced==true)
        }else{
          content=content.filter(attr=>!attr.advanced)
        }

        content.forEach((attr) => {
          let index = companyAttrs.findIndex(
            (attribute) => attribute.param == attr.param
          );

          if (index >= 0) {
            companyAttrs.splice(index, 1);
          }
        });
        const allAttributes = [...content, ...companyAttrs].sort(sortAttributes);
        setCompanyAttributes(allAttributes);
      }
    });
  },[])    
  const saveTemplate = (attr, value, multiselect) => {
    let attrIndex = companyAttributes.findIndex(
      (companyAttr) => companyAttr.param == attr
    );
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "CompanyAttributes",
        nuis: company?.nuis,
        content: multiselect ? { ...value } : {
          param: attr,
          value: value ?? companyAttributes[attrIndex]?.value,
        },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          getCompany({reload:true,reloadParameters:false})
          toast.success(t("company.parameterSaved"), {
            containerId: "all_toast",
          });
        }
      });
  };

  // const pickerStyle = {
  //   filter: disabledByRole ? 'grayscale(100%)' : 'none',
  // };
  
  const handleAttributesChange = (param, value, multiselect) => {

    setCompanyAttributes(attributesState => {
      return attributesState.map((attribute) => {
        if (param === attribute.param) {
          let content = { ...attribute }
          if (multiselect) content.companyAttributesValues = value
          else{
            content.value = value
          }
        
          return content
        }
        return { ...attribute }
      })
    })
  };

  const buildCompanyAttrLabel = (param,attrLabel) => {
    switch (param) {
      case "backgroundColor":
        return t("company.chosePdfBGColor");
      case "fontColor":
        return t("company.chosePdfFontColor");
      case "AdditionalId1":
        return t("pos.additional") + "1";
      case "AdditionalId2":
        return t("pos.additional") + "2";
      case "internalId":
        return t("home.internalId");
      default:
        if(attrLabel) return attrLabel
       
        const label = i18n.exists(`translations:companyParams.${param}`) ? t(`companyParams.${param}`) : param.match(/([A-Z]?[^A-Z]*)/g).join(" ")
        return (label?.[0]?.toUpperCase() ?? "") + (label ? label.slice(1) : "");
    }
  };

  const buildCompanyAttributeValue = ({ companyAttr, index, optionLabel = "label", optionValue = "value",onChange, disabled=false }) => {
    switch (companyAttr.type) {
      case "COLOR":
        return (
          <PopoverPicker
            color={companyAttr.value}
            onChange={(color) => onChange ?onChange(null,companyAttr.param, color):handleAttributesChange(companyAttr.param, color)
            }
            disabled={disabled}
            saveTemplate={(e) => {
              saveTemplate(companyAttr.param);
            }}
          />
        );
      case "TEXT":
        return (
           <div className="input-group">
            <input
              type="text"
              id="inputText"
              name={onChange?company[companyAttr.param] :companyAttr.value}
              className="form-control"
              value={companyAttr.value}
              disabled={disabled}
              onChange={(e) =>onChange?onChange(null,companyAttr.param, e.target.value): handleAttributesChange(companyAttr.param, e.target.value)}
            />
            <div className="input-group-append">
              <button
               disabled={disabled}
                className="btn btn-sm btn-outline-primary"
                type="button"
                onClick={() => {
                  if(onChange){
                    editCompany()
                  }else{
                    saveTemplate(companyAttr.param, companyAttr?.value)
                  }}}
              >
                {t("common.save")}
              </button>
            </div>
          </div>
        );
      case "INPUT_NR":
        return (
            <div className="input-group">
            <input
              type="number"
              className="form-control"
              value={onChange?company[companyAttr.param] :companyAttr.value }
              disabled={disabled}
              onChange={(e) =>{console.log(e.target.value);
                onChange?onChange(null,companyAttr.param,  parseInt(e.target?.value)): handleAttributesChange(companyAttr?.param, parseInt(e.target?.value))}}
            />
            <div className="input-group-append">
              <button
                disabled={disabled}
                className="btn btn-sm btn-outline-primary"
                type="button"
                onClick={() => {
                  if(onChange){
                    editCompany()
                  }else{
                    saveTemplate(companyAttr.param, companyAttr?.value)
                  }}}
              >
                {t("common.save")}
              </button>
            </div>
          </div>
        );
  
      case "NUMBER":
        return (
          <div className="custom-control custom-switch mb-3 d-flex align-items-center" >
            <input
              type="checkbox"
              id={companyAttr?.id || index}
              className="custom-control-input"
              disabled={disabled}
              checked={onChange?company[companyAttr.param] :companyAttr.value == "1"}
                onChange={(event) => {
                  if(onChange){
                    const newCompany=onChange(null,companyAttr.param, event.target.checked)

                    if(newCompany)editCompany({newCompany})
                  }else{
                    handleAttributesChange(companyAttr.param, event.target.checked ? "1" : "0")
                    saveTemplate(companyAttr.param, event.target.checked ? "1" : "0"); 
                  }
              }}
             />
            <label className="custom-control-label" htmlFor={companyAttr?.id || index} />


          </div >
        );
      case "SELECT": {
        const valueOpt = (companyAttr?.options?.find((opt) => opt.code === (onChange?company[companyAttr.param]:companyAttr?.value)))
        
        return (
          <div className="d-flex align-items-center w-100">
            <Select
              styles={customStyles}
              className="w-100"
              options={companyAttr?.options ?? []}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.code}
              value={valueOpt}
              isDisabled={disabled}
              onChange={(e) =>{onChange?onChange(null,companyAttr.param, e.code): handleAttributesChange(companyAttr.param, e.code)}}
              name="companyAttr"
              />
            <button className="btn btn-sm btn-outline-primary ml-1" disabled={disabled} onClick={() => {
              if(onChange){
                editCompany()
              }else{
                saveTemplate(companyAttr.param, valueOpt.value)
              }}}>{t("common.save")}</button>
          </div>
        )
      }
      case "MULTI_SELECT": {
        const values = companyAttr?.companyAttributesValues?.map(attr => {
          const existed = companyAttr?.options?.find(opt => opt?.code === attr.value)

          return { ...existed, ...attr }
        })
        return <div className="d-flex align-items-center w-100">
          <Select
            styles={customStyles}
            isMulti={true}
            className="w-100"
            options={companyAttr?.options ?? []}
            value={values ?? []}
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt.code}
            onChange={(selectedOpts) =>onChange?onChange(null,companyAttr.param, selectedOpts): handleAttributesChange(companyAttr.param, selectedOpts,true)}
            name="companyAttr"
            isDisabled={disabled}

          />
          <button className="btn btn-sm btn-outline-primary ml-1" disabled={disabled} onClick={() => {
            let { id, type, options, ...restValue } = companyAttr
             
            restValue.companyAttributesValues = restValue?.companyAttributesValues?.map(({ id, value, code }) => {
              if (id) return { id, value }
              return { value: code, id: null }
              
            }) ??[]
           
            saveTemplate(companyAttr.param, { ...restValue }, true)
          }}>{t("common.save")}</button>
        </div>
      }


      default:
        return <></>;
    }
  };

  

  if(tabLoading) return <><Loader/></>

  return (
    
    <div style={{minHeight: "60vh", maxHeight: "74vh", paddingRight:"7rem", overflowY:'auto'}}>
    {staticCompanyAttributes?.map((companyAttr, index) => {
      return (
        <div>
        <div key={companyAttr?.id} className="d-flex justify-content-between align-items-center" style={{height:60}}>
          <div
            className="font-weight-bold text-dark align-middle"
            style={{ fontSize: 15 }}
          >
            {buildCompanyAttrLabel(companyAttr.param,companyAttr.value)}
          </div>
          <div style={{minWidth:"35%",maxWidth:"35%"}} className="d-flex justify-content-stretch align-items-center w-100">
            {buildCompanyAttributeValue({ companyAttr, index, optionLabel: "name", optionValue: "code",onChange:companyAttr.onChange, disabled:isAdmin || isSimpleUser })}
          </div>
        </div>
        <hr className="m-0"/>
        </div>

      );
    })}
    {companyAttributes?.map((companyAttr, index) => {
        return (
          <div>
          <div key={companyAttr?.id} className="d-flex justify-content-between align-items-center" style={{height:60}}>
            <div
              className="font-weight-bold text-dark align-middle"
              style={{ fontSize: 15 }}
            >
              {buildCompanyAttrLabel(companyAttr.param)}
            </div>
            <div style={{minWidth:"35%",maxWidth:"35%"}} className="d-flex justify-content-stretch align-items-center w-100">
              {buildCompanyAttributeValue({ companyAttr, index, optionLabel: "name", optionValue: "code",disabled:isSimpleUser })}
            </div>
          </div>
          <hr className="m-0"/>
          </div>

        );
      })}
    </div>

  )
}

export default CompanyAttributes
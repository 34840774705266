import React, { useEffect, useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainAxios from '../../../services/mainAxios'
import { toast } from 'react-toastify'
import Loader from '../../Layout/Loader'
import Page404 from '../../Page404'
import {useTranslation, withTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../../AppContext'
import func from '../../../services/functions'

const EditCompanyBranch = ({company,branchId,onEditBranchChange}) => {

    const {t,i18n} = useTranslation('translations');
    const [state, setState] = useState({
        branch: null
    })
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(!!branchId)
    const {user: [user], company: [fullCompany, setFullCompany],selectedCompany:[selectedCompany]} = useAppContext()
    const history = useHistory()
    const companyId = company.id
  const [errors,setErrors]=useState({})


    useEffect(() => {
        mainAxios.post('apiEndpoint/search', {
            object: "GetBranches",
            params: {
                id: branchId
            },
            company: companyId
        }).then(res => {
            if(res) {
                setState(state => ({
                    ...state,
                    branch: res.data.content[0]
                }))
            }
            setPageLoading(false)
        })
    }, [])
    
    const editBranch = (e) => {
        e.preventDefault()

        const {softCode } = state.branch

        if(softCode?.length>0 && !new RegExp(/[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}/).test(softCode)){
          setErrors((errors)=>({
            ...errors,
            softCode:t("toast.invalidSoftCode")
          }))
          return
        }
        setLoading(true)


        mainAxios.post('apiEndpoint/saveOrUpdate', {
            object: "CompanyBranches",
            content: {
                company: {id: companyId},
                ...state.branch
              },nuis:user.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
              )?.nuis,
        }).then(res => {
            if(res?.data?.status === true) {
                if(companyId==fullCompany.id) {
                    const branchIndex=fullCompany.branches.findIndex(
                        (branch) => branch.id === parseInt(branchId)
                    )
                    setFullCompany((company)=>({
                        ...company,
                        branches:[
                            ...company.branches.slice(0, branchIndex),
                            res.data.content[0],
                            ...company.branches.slice(branchIndex + 1),
                        ]
                    }))
                }
                toast.success(t(['toast.updateBranch']), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    containerId: "all_toast"
                })
                history.push('/companies/' + companyId)
                onEditBranchChange()
            }
            setLoading(false)
        })

    }

    const handleBranchInputs = (e, selector = null, selectorValue = null, trim = false) => { 
      const key = selector ? selector : e.target.name;
      let value = selectorValue !== null && selectorValue !== undefined ? selectorValue : e?.target?.value;
      if (trim) value = value ? value.trim() : "";
      if (errors[key]) {
        setErrors((errors) => ({
          ...errors,
          [key]: null
        }));
      }
    
      setState((state) => ({
        ...state,
        branch: {
          ...state.branch,
          [key]: value
        },
      }));
    };

    const handleNextNumber=(key,value)=>{
        setState(state => ({
            ...state,
            branch: {
                ...state.branch,
                nextNumberSimpleDTO: {
                    ...state?.branch?.nextNumberSimpleDTO,
                    [key]:value

                    // !isNaN(parseFloat(value)) && isFinite(value)?parseInt(value): state?.branch?.nextNumberSimpleDTO?.[key]
                }
            }
        }))
    }

    return (
        !pageLoading ?
            state.branch ?
            <>
              <form onSubmit={editBranch}>
  {/* <h3 className="mt-4 mb-4">{t('company.editBranch')}</h3> */}
  <div className="card border-0 mb-4">
    <div className="card-body">
      <div className="form-group">
        <label htmlFor="business_unit_code">{t('company.businUnitCode')}</label>
        <input
          type="text"
          className="form-control"
          id="business_unit_code"
          name="businUnitCode"
          placeholder="E.g '123456'"
          value={state.branch.businUnitCode}
          onChange={(e)=> handleBranchInputs(null,"businUnitCode",e.target.value,true)}
          />
      </div>
      <div className="form-group">
        <label htmlFor="seller_country">{t('company.sellerCountry')}</label>
        <input
          type="text"
          className="form-control"
          id="seller_country"
          name="sellerCountry"
          placeholder="E.g 'Albania'"
          value={state.branch.sellerCountry}
          onChange={handleBranchInputs} />
      </div>
      <div className="form-group">
        <label htmlFor="seller_town">{t('company.sellerTown')}</label>
        <input
          type="text"
          className="form-control"
          id="seller_town"
          name="sellerTown"
          placeholder="E.g 'Tirane'"
          value={state.branch.sellerTown}
          onChange={handleBranchInputs} />
      </div>
      <div className="form-group">
        <label htmlFor="seller_address">{t('company.sellerAddress')}</label>
        <input
          type="text"
          className="form-control"
          id="seller_address"
          name="sellerAddress"
          placeholder="E.g 'Bulevardi Zogu I'"
          value={state.branch.sellerAddress}
          onChange={handleBranchInputs} />
      </div>
      <div className="form-group">
        <label htmlFor="softCode">{t('nslf.softCode')}</label>
        <input
          type="text"
          className={`form-control ${
            errors.softCode ? "is-invalid" : ""
          } `}
          id="softCode"
          name="softCode"
          maxLength="10"
          placeholder="E.g 'aa000aa000'"
          value={state.branch.softCode}
          onChange={(e)=>handleBranchInputs(null,"softCode",e.target.value,true)} />
          {errors.softCode && (
            <div className="invalid-feedback">
              {errors.softCode}
            </div>
          )}
      </div>
      <div className="form-group">
        <label htmlFor="seller_name">{t('company.sellerName')}</label>
        <input
          type="text"
          min="0"
          className="form-control"
          id="seller_name"
          name="sellerName"
          placeholder="E.g 'Ahmet Zogu'"
          value={state.branch.sellerName}
          onChange={handleBranchInputs} />
      </div>
      {state?.branch?.nextNumberSimpleDTO?.id &&
      <>
      <div className="form-group">
        <label htmlFor="nextNumber">{t("selfServices.lastInvoiceNumber")}</label>
        <input
            onKeyDown={(event)=>{if(event.key==='.'){event.preventDefault();}}}
          type="text"
          className="form-control"
          id="lastInvoiceNumber"
          disabled
          name="lastInvoiceNumber"
          value={state?.branch?.nextNumberSimpleDTO?.lastInvoiceNumber ?? ""}
          onChange={(e)=>handleNextNumber("lastInvoiceNumber",e.target.value)} />
      </div>
      <div className="form-group">
        <label htmlFor="nextNumber">{t('company.nextNumber')}</label>
        <input
            onKeyDown={(event)=>{if(event.key==='.'){event.preventDefault();}}}
          type="text"
          className="form-control"
          id="nextNumber"
          name="nextNumber"
          value={state?.branch?.nextNumberSimpleDTO?.nextNumber ?? ""}
          onChange={(e)=>{
            let value = e.target.value
            if(func.isNumeric(value)){
              value=parseInt(value)
            }else if(value=="" || value==null){
              value=""
            }else{
              return
            }
            handleNextNumber( "nextNumber", value)
            }} />
      </div>
      </>
      }
                     <div className="custom-control custom-switch mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="profiscStatusCheck"
                        checked={state.branch.profiscStatus}
                        onChange={(e) =>
                          handleBranchInputs(null, "profiscStatus", e?.target?.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="profiscStatusCheck"
                      >
                        {t(["company.profiscStat"])}
                      </label>
                    </div>
    </div>
  </div>
  <div className="d-flex justify-content-between align-items-center">
    <button 
      type="submit"
      disabled={loading}
      style={{minWidth: "100px"}}
      className="btn btn-primary">
      {loading ? <Loader size="xs" color="white"/> : 
      (<>
          <FontAwesomeIcon icon={faCheck} className="mr-2" />{t('common.save')}
      </>)}
    </button>
  </div>
</form>

            </>
            :
            <Page404/>
        :
        <Loader/>
    )
}

export default EditCompanyBranch
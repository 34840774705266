import {
  faEdit,
  faPlus,
  faFileUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{ useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import mainAxios from "../../services/mainAxios";

import { useTranslation, withTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { useAppContext } from "../../AppContext";
import ImportCustomers from "./Users/ImportCustomers";
import ExportCsv from "../global/ExportCsv";
import AlertCertificate from "../global/AlertCertificate";
import EditCustomer from "./EditCustomers";
import { Dialog } from "primereact/dialog";
import CreateCustomer from "./Users/CreateCustomer";
import PrimeTable from "../PrimeTable/PrimeTable";
import ExportCsvPrime from "../global/ExportCsvPrime";
const moment = require("moment");

const Customers = ({customers:customModal}) => {
  const [customers, setCustomers] = useState([]);
  const [customersForCsv, setCustomersForCsv] = useState([]);
  const [customerQuery, setCustomerQuery] = useState('');
  const [tableRows, setTableRows] = useState({
    totalRows: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [openModal,setOpenModal]=useState({visible:false,id:null})
  const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState(false);
  const [loadingGrid,setLoadingGrid]=useState(false)
  const [expandedRows,setExpandedRows]=useState([])
  const [filters,setFilters]=useState({
    params:{}
  })

  const defaultFilters = {}

  const { t, i18n } = useTranslation("translations");
  const {
    user: [user, setUser],
    selectedCompany: [selectedCompany, setSelectedCompany],
  } = useAppContext();

  React.useEffect(() => {
    getFilteredResults(10,1);
  }, [selectedCompany]);

  const getFilteredResults=({updatedFilters,rowsPerPage=10, currentPage=1, exportCsv = false,sortCriteria,searchQuery}={})=>{
    setLoadingGrid(true)
    return mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomer",
        value: null,
        params:updatedFilters?.params,
        pagination: exportCsv ? null : {
          pageSize: rowsPerPage,
          pageNumber: currentPage,
        },
        sortCriteria
      })
      .then((res) => {
        setLoadingGrid(false)

        if (res.data.status===true) {
          if(exportCsv){
            setCustomersForCsv(res.data.content)
          }else{
            setCustomers(res.data.content);
            setTableRows((tableRows) => ({
              ...tableRows,
              totalRows: res?.data?.totalSize,
          }));
          }
          return true
        }
      })
      .catch(err=>{
        setLoadingGrid(true)
      })
  }
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const indicator = setTimeout(() => {
      getFilteredResults(10,1)
    }, 400);

    return () => {
      clearTimeout(indicator);
    };
  }, [customerQuery]);

  const tinTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];

  const  columns = [
    {
      header: t("item.name"),
      field: "name",
      sortable: true,
      filter:true,
      style:{ minWidth: '240px', maxWidth:"240px" },
    },
    {
      header: "Tin",
      field: "tin",
      sortable: true,
      filter:true,
      style:{ minWidth: '200px', maxWidth:"200px" },

    },
    {
      header: "Email",
      field: "email",
      filter: true,
      sortable: true,
      style:{ minWidth: '200px', maxWidth:"200px" },

    },
    {
      header: t("customers.address"),
      field: "address",
      format:(customer)=> (customer.address??'').replaceAll(`,`,' '),
      sortable: true,
      style:{ minWidth: '200px', maxWidth:"200px" },
    },
    {
      header: t("customers.town"),
      field: "town",
      filter: true,
      style:{ minWidth: '200px', maxWidth:"200px" },
      sortable: true,
    },
    {
      header: t("customers.country"),
      field: "country",
      filter: true,
      sortable: true,
      style:{ minWidth: '200px', maxWidth:"200px" },
    },
    {
      header: t(["customers.identificationType"]),
      field: "type",
      formatExportedValue:true,
      filter: true,
      filterType: "DROPDOWN",
      filterConfig: {
        options: tinTypes,
        optionLabel: "label",
        optionValue: "value",
        splitKey: false,
      },
      body: (customers) => tinTypes.find(type => type.value == customers.type)?.label,
      format: (customers) => tinTypes.find(type => type.value == customers.type)?.label,
      style:{ minWidth: '200px', maxWidth:"200px" },
    },
    {
      header: t(["home.actions"]),
      field:"actions",
      style:{ minWidth: '200px', maxWidth:"200px" },
      body: (customers) => (
        <>
          <div
            // to={"/customers/update/" + customers?.id}
            className="nav-link p-0 text-primary"
            onClick={()=>{setOpenModal({visible:true,id:customers?.id})}}
            style={{cursor:"pointer"}}
          >

            <FontAwesomeIcon icon={faEdit} className="mr-1" />
            {t("company.edit")}
          </div>
        </>
      ),
    },
  ];

  const openUploadCustomers = (e) => {
    e.preventDefault();
    swal(
      <ImportCustomers
        user={user}
        selectedCompany={selectedCompany}
        swal={swal}
      />,
      { buttons: false }
    );
  };

  const handleRowsPerPage = (pageSize, page) => {
    getFilteredResults( pageSize, page);
     };

    // if(loading) return  <Loader />
  return  (
    <>
        <Dialog
          header="Edit Buyer"
          visible={openModal?.visible}
          onHide={() => setOpenModal({visible:false,id:null})}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          closable={true}
          dismissableMask={true}
        >
          <EditCustomer
            id={openModal?.id ?openModal?.id?.toString(): null}
            onCustomerUpdate={(customer) => {
               setOpenModal({visible:false,id:null})
               getFilteredResults(tableRows.rowsPerPage,tableRows.currentPage)
            }}
          />
        </Dialog>

      <Dialog
        header={t("customers.addCustomer")}
        visible={openAddCustomerDialog}
        onHide={() => setOpenAddCustomerDialog(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <CreateCustomer
          onCustomerUpdate={(customer) => {
            setOpenAddCustomerDialog(false);
            getFilteredResults(tableRows.rowsPerPage,tableRows.currentPage)
          }}
        />
      </Dialog>


    {
      !customModal &&
      <div className="d-flex justify-content-between align-items-center my-2">
      <div className="d-flex align-items-center">
      <h3 className="d-flex align-items-center p-0 m-0">
        {t("navbar.customers")}
        <small className="ml-3">
          <ExportCsvPrime
              data={customersForCsv}
              columns={columns}
              onClick={()=>getFilteredResults({exportCsv:true,updatedFilters:filters})}
              excludedColumns={["actions"]}
              callback={(customer, formattedObject) => {
                formattedObject["Type"] = tinTypes.find(tinType=>tinType.value==customer.type)?.label ||   "";
              }}
            />
        </small>
        <small className="ml-3">
          <a
            href="#"
            onClick={(e) => openUploadCustomers(e)}
            className="btn btn-sm btn-light bg-white shadow-sm btn-custom text-primary"
          >
            <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
            <span
              onClick={(e) => openUploadCustomers(e)}
              className="text-dark"
            >
              Import
            </span>
          </a>
        </small>
        <small className="ml-3">
          <a
            href={"/customers_csv_template.csv"}
            className="btn btn-sm btn-light bg-white shadow-sm btn-custom btn-sm text-primary"
            download="/customers_csv_template.csv"
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              CSV Import Template
            </div>
          </a>
        </small>
        <small className="ml-3">
          <a
            href={"/country_codes_type.csv"}
            className="btn btn-sm btn-light bg-white shadow-sm btn-custom btn-sm text-primary"
            download="/country_codes_type.csv"
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              CSV Country List and Type Value
            </div>
          </a>
        </small>
      </h3>
        <AlertCertificate />
      </div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setOpenAddCustomerDialog(true)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              {t("customers.addCustomer")}
            </button>

    </div>
    }

      <div className={`card border-0 ${customModal ? '' : 'shadow'}`}>
        <PrimeTable
                loading={loadingGrid}
                tableRows={[tableRows, setTableRows]}
                value={customers}
                columns={columns}
                defaultFilters={defaultFilters}
                onRowToggle={(e) => setExpandedRows(e.data)}
                expandedRows={expandedRows}
                filters={[filters,setFilters]}
                responsiveLayout="scroll"
                dataKey="id"
                clearFilters={true}
                showSelectColumns={false}
                headerButtonsRight
                searchQuery={true}
                filterDisplay="row"
                reorderableColumns={true}
                onRemoteDataChange={(remoteData) => {
                    const updatedFilters = remoteData.filters
                    const updatedTableData = remoteData.tableRows ?? tableRows
                    let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                    sortCriteria=sortCriteria?[sortCriteria]:null
                    getFilteredResults({updatedFilters, rowsPerPage, currentPage,sortCriteria,searchQuery:remoteData?.searchQuery})
                }}
            />
      </div>
    </>
  )
};

export default Customers;

import React, { useCallback, useRef, useState } from 'react'
import func from '../../services/functions';
import { useAppContext } from '../../AppContext';
import mainAxios from '../../services/mainAxios';
import { Menu } from 'primereact/menu';

const useShowInvoicePdf = ({loadingIdKey="id"}={}) => {
  const [loadingPdf, setLoadingPdf] = React.useState({loading:false,key:null,id:null});
  const pdfContainer = useRef();
  const loadingPdfRef = useRef();
  const templateRefMenu=useRef()
  const [,setRefresh]=useState(null)
  const [pdfToDisplay, setPdfToDisplay] = useState(null);

  const {
    company: [fullCompany],
  } = useAppContext();





  React.useEffect(() => {
    loadingPdfRef.current = loadingPdf;
  }, [loadingPdf]);


  const getFiscPdf=({invoice,params,key="iic"})=>{
    let parsedParams = {
      iic: invoice.iic,
    }
    setLoadingPdf({key,id:invoice[loadingIdKey],loading: true});
    mainAxios
    .post("apiEndpoint/search", {
      object: "GetFiscPDF",
      params:params ?? parsedParams
    })
    .then((res) => {
      if (res.data.status === true) {
        const blob = func.blobToFile(
          func.b64toBlob(res.data.content[0], "application/pdf"),
          "Fisc Pdf"
        );
        setPdfToDisplay(URL.createObjectURL(blob));
        setTimeout(() => {
          if(pdfContainer?.current?.classList){
            pdfContainer.current.classList.toggle("open");
          }

        }, 0);
      }
      setLoadingPdf({key,id:invoice[loadingIdKey],loading: false});
    })
    .catch(()=>{
        setLoadingPdf({key,id:invoice[loadingIdKey],loading: false});
    })
  }

  const getEinvoicesRequest=({invoice})=>{
    setLoadingPdf({key:"eic",id:invoice[loadingIdKey],loading: true});

    mainAxios
      .post("apiEndpoint/search", {
        object: "GetEinvoiceFromFilesystem",
        nuis: fullCompany?.nuis,
        params: {
          iic: invoice.iic,
          tcrCode: invoice.tcrCode,
          search: "einvoice",
          dataFrom: "CIS",
        },
      })
      .then((res) => {
        if (res?.data?.content[0]?.pdf) {
          const blob = func.blobToFile(
            func.b64toBlob(res.data.content[0].pdf, "application/pdf"),
            "Einvoice Pdf"
          );
          setPdfToDisplay(URL.createObjectURL(blob));
          setTimeout(() => {
            if(pdfContainer?.current?.classList){
              pdfContainer.current.classList.toggle("open");
            }
          }, 0);
        }
        setLoadingPdf({key:"eic",id:invoice[loadingIdKey],loading: false});
      })
      .catch(()=>{
          setLoadingPdf({key:"eic",id:invoice[loadingIdKey],loading: false});
      })
  }

  const getTemplateMenuOptions = useCallback((currInvoice) =>{
    let companyAttributesValues = fullCompany?.companyAttributes?.find(attr => { return attr?.attribute?.param === "pdfTemplate" })?.companyAttributesValues ?? []

    let templates = companyAttributesValues?.map(attr=>{
          const params = {
            iic: currInvoice.iic ?? '',
            specificTemplate:attr.value
          }
          return {
              label:attr.value,
              command: () => { getFiscPdf({key:"iic", invoice:currInvoice,params}) },
              value:attr.value
          }
      })??[]
    return templates
  },[fullCompany?.companyAttributes])


  const checkFisc=({invoice,showTemplates=true,e})=>{
    if( showTemplates==false) {
      getFiscPdf({invoice})
      return
    }

    const templates=getTemplateMenuOptions(invoice)

    if(templates.length>1){
        setRefresh((state)=>!state)
        templateRefMenu.current.templateData=templates
        templateRefMenu.current.toggle(e)
    }else{
    const specificTemplate = templates?.[0]?.value
    if(specificTemplate){
      const params={
        iic: invoice.iic,
        specificTemplate
      }
      getFiscPdf({invoice,params})
    }else{
      getFiscPdf({invoice})
    }

  }
  }

  const getAttachmentByte=({invoice})=>{
    setLoadingPdf({key:"attachmentByte",id:invoice[loadingIdKey],loading: true});
    mainAxios
    .post("endpoint/v2/getAtachementByte", {value:invoice.attachmentId})
    .then((res) => {
      if(res.data.status){
        const blob = func.blobToFile(
          func.b64toBlob(res.data.content[0], "application/pdf"),
          "Custom Invoice"
      );
      setPdfToDisplay(URL.createObjectURL(blob));
      setTimeout(() => {
        pdfContainer.current.classList.toggle("open");
      }, 0);
      }
      setLoadingPdf({key:"attachmentByte",id:invoice[loadingIdKey],loading: false});

    })
    .catch(_=>{
      setLoadingPdf({key:"attachmentByte",id:invoice[loadingIdKey],loading: true});
    })
  }

  const showInvoicePdf = ({key, invoice,e,showTemplates=true}) => {
    if(e){e.preventDefault();}
    if(loadingPdfRef?.current?.key === key &&
      loadingPdfRef?.current?.loading
     ) {
        return;
    }
    if (key === "iic") {
      checkFisc({invoice,showTemplates,e})
    } else if (key === "eic") {
      getEinvoicesRequest({invoice})
    } else if (key === "id") {
      const params = {
        id: invoice.id.toString() ?? '',
        isDraftInvoice: true
      }
      getFiscPdf({invoice,params,key:"id"})
    }else if(key=="attachmentByte"){
      getAttachmentByte({invoice})
    } 
  };






  const pdfEmbed = (
    <>
      <Menu model={templateRefMenu?.current?.templateData ??[]} popup ref={templateRefMenu} />
        <div className="pdf-container" ref={pdfContainer}>
        {pdfToDisplay && (
          <div className="pdf-viewer">
            <div
              className="pdf-overlay"
              onClick={() => {
                setTimeout(() => {
                  setPdfToDisplay(null);
                }, 150);
                pdfContainer.current.classList.toggle("open");
              }}
            />
            <div className="pdf-content">
              <embed src={pdfToDisplay} />
            </div>
          </div>
        )}
      </div>
      </>
    )






  return {showInvoicePdf,loadingPdf,pdfEmbed}
}

export default useShowInvoicePdf
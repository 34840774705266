import {
  faColumns,
  faFileExcel,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext, usePersistedState } from "../../../AppContext";
import func, { removePropertiesRecursive, validateInvoice } from "../../../services/functions";
import invoiceStatuses from "../../../services/invoiceStatuses.json";
import mainAxios from "../../../services/mainAxios";
import Loader from "../../Layout/Loader";
import Select from "react-select";
import UploadInvoice from "../../Invoice/UploadInvoice";
import generateWtnColumns from "./generateWtnColumns";
import { useTranslation, withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import swal from "@sweetalert/with-react";
import DateRange from "../../global/DateRange";
import AlertCertificate from "../../global/AlertCertificate";
import PrimeTable from "../../PrimeTable/PrimeTable";
import ExportCsvPrime from "../../global/ExportCsvPrime";
import { useHistory } from 'react-router-dom'
import { globalFilterStatisticCard } from "../../global/StatisticsCards";
import { defaultTableRows } from "../../../services/data";


const moment = require("moment");

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#000" : styles.color,
    backgroundColor: state.isSelected ? "#0075670d" : styles.color,
    borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#0075670d",
    },
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 0.rem rgba(68, 68, 68, 0.135)" : 0,
    borderWidth: state.isFocused ? "2px" : "1px",
    borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
    "&:hover": {
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
    },
  }),
};

const CustomInvoices = () => {
  const { t } = useTranslation("translations");

  const {
    user: [user],
    selectedCompany: [selectedCompany],
    wtnColumns: [wtnColumns, setWtnColumns],
    company: [fullCompany],
    dates: [dates, setDates],
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [wtnInvoices, setWtnInvoices] = useState(null);
  const [invoicesForCSV, setInvoicesForCSV] = useState([]);
  const [pageState, setPageState] = React.useState({
    loading: true,
    saving: false
})
  const [persistedData, setPersistedData] = usePersistedState(
    null,
    "sessionData"
  );

  const [refresh, setRefresh] = useState(false)

  const [persistedRowPerPage, setPersistedRowPerPage,] = usePersistedState(
    10,
    "rowsPerPage"
);

  const defaultSortCriteria={
    field: "issueDate",
    direction: "DESC"
  }

  const [tableRows, setTableRows] = useState({
    ...defaultTableRows,
    rowsPerPage: persistedRowPerPage,
    sortCriteria: defaultSortCriteria
  });

  const businessUnitCodes = React.useMemo(() => {
    return [
      {
        label: t("invoice.businessUnitCode"),
        value: null,
      },
    ].concat(
      company.branches.map((branch) => {
        return {
          label: branch.businUnitCode + " | " + branch.sellerName,
          value: branch.businUnitCode,
        };
      })
    );
  }, [selectedCompany]);

  const filterParams = {
    businessUnitCode: businessUnitCodes?.some(
      (branch) => branch?.value === persistedData?.businessUnitCode
    )
      ? persistedData?.businessUnitCode
      : null,
    internalId: null,
    operatorCode: null,
    wtnic: null,
    fwtnic: null,
    startAddress: null,
    destinationAddress: null,
    status: null,
    type: null,
    transaction: [],
    idType: null,
    statusObj: [],
    transporterId: null,
    wtnOrderNum: null,
    valueOfGoods: null,
    issueDate: null,
    vehiclePlate:null,
    transporterIdType:null,
    vehicleOwnership:null,
    startingPoint:null,
    pointOfArrival:null,
    wtnStartDate:null,
    wtnEndDate:null
  };

  const defaultFilters = {
    fromDate: dates.fromDate,
    toDate: dates.toDate,
    params: {
      ...filterParams,
    },
  };

  const [errors, setErrors] = React.useState([]);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [showDeleted, setShowDeleted] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [statistics, setStatistics] = useState(null);
  const [pdfToDisplay, setPdfToDisplay] = React.useState(null);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const pdfContainer = React.useRef();
  const history = useHistory()

  const getFilteredResults = ({ data, pageSize = (persistedRowPerPage?? defaultTableRows.rowsPerPage),
    page = defaultTableRows.currentPage, sortCriteria = [ defaultSortCriteria ],exportCsv }) => {
    let wtnFilters = data ? data : filters;
    let params = wtnFilters?.params;
    if(!exportCsv) setLoading(true);
    // const { rowsPerPage: pageSize , currentPage: pageNumber }=tableRows
    return mainAxios
      .post("apiEndpoint/search", {
        object: "GetCustomWTNV",
        ...wtnFilters,
        pagination:exportCsv?null: {
          pageSize,
          pageNumber:page,
        },
        params: {
          ...(params?.businessUnitCode && {
            businessUnitCode: "<L>" + params.businessUnitCode + "<L>",
          }),
          ...(params?.internalId && { internalId: params?.internalId + "<L>" }),
          ...(params?.operatorCode && { operatorCode: params?.operatorCode }),
          ...(params?.startAddress && { startAddress: "<L>" + params?.startAddress + "<L>",
          }),
          ...(params?.destinationAddress && { destinationAddress: "<L>" + params?.destinationAddress + "<L>" ,
          }),
          ...(params?.wtnic && { wtnic: "<L>" + params?.wtnic + "<L>" }),
          ...(params?.idType && { idType: "<L>" + params?.idType + "<L>" }),
          ...(params?.fwtnic && { fwtnic: "<L>" + params?.fwtnic + "<L>" }),
          ...(params?.status && { status: "<L>" + params?.status + "<L>" }),
          ...(params?.type && { type: params.type }),
          ...(params?.transaction?.length > 0 && {
            transaction: params.transaction.join(","),
          }),
          ...(params?.transporterId && { transporterId: params.transporterId }),
          ...(params?.statusObj?.length > 0 && {
            statusObj: params.statusObj.join(","),
          }),
          ...(params?.goodsFammable && { goodsFammable: params.goodsFammable }),
          ...(params?.wtnOrderNum && { wtnOrderNum: "<L>" + params.wtnOrderNum + "<L>" }),
          ...(params?.valueOfGoods && { valueOfGoods: params.valueOfGoods }),
          ...(params?.issueDate && { issueDate: params.issueDate }),
          ...(params?.vehiclePlate && { vehiclePlate: "<L>" + params.vehiclePlate + "<L>" }),
          ...(params?.transporterIdType && { transporterIdType: params.transporterIdType }),
          ...(params?.vehicleOwnership && { vehicleOwnership: params.vehicleOwnership }),
          ...(params?.startingPoint && { startingPoint: params.startingPoint }),
          ...(params?.pointOfArrival && { pointOfArrival: params.pointOfArrival }),
          ...(params?.wtnStartDate && { wtnStartDate: params.wtnStartDate }),
          ...(params?.wtnEndDate && { wtnEndDate: params.wtnEndDate }),
        },
        sortCriteria
      })
      .then((res) => {
        if(!exportCsv) setLoading(false);
        if (res?.data?.status === true) {
          if(exportCsv){
            setInvoicesForCSV(res.data.content);
          }else{
            setTableRows((tableRows)=>({
              ...tableRows,
                totalRows: res?.data?.totalSize,
            }));
          setWtnInvoices(res.data.content);
          }
          return true
        } else {
          toast.warning(t(["toast.warningInvoice"]), {
            containerId: "all_toast",
          });
          setWtnInvoices([]);
        }
        return false
      })
      .catch((err) => {
        if(!exportCsv) setLoading(false);
        return false;
      });
  };

  React.useEffect(() => {
    getFilteredResults({ data: defaultFilters });
  }, [selectedCompany, refresh]);


  const openUploadInvoice = () => {
    swal(
      <UploadInvoice
        user={user}
        selectedCompany={selectedCompany}
        swal={swal}
      />,
      { buttons: false }
    );
  };

  const handleParamsChange = (e, label, val) => {
    const key = e ? e.target.name : label;
    const value = e ? e.target.value : val;

    setFilters((filters) => ({
      ...filters,
      params: {
        ...filters.params,
        [key]: value,
      },
    }));
  };
  const deleteWtn = (e, wtn) => {
    e.preventDefault();
    setDeleting(true);
    mainAxios
      .post("apiEndpoint/saveOrUpdate", {
        object: "DeleteWTN",
        content: {
          ...wtn,
          id: wtn?.id,
        },
        nuis: company.nuis,
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setRefresh((refresh) => !refresh)
          toast.success("Draft WTN was successfully deleted!", {
            containerId: "all_toast",
          });
        }
        setDeleting(false);
      })
      .catch(() => {
        toast.error("An error occurred! Please try again later.");
        setDeleting(false);
      });
  };

  const showWtnPdf = (wtnic) => {
    setLoadingPdf(true);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetWtnPDF",
        params: {
          wtnic,
        },
      })
      .then((res) => {
        const blob = func.blobToFile(
          func.b64toBlob(res.data.content[0], "application/pdf"),
          "Wtn Invoice"
        );
        setPdfToDisplay(URL.createObjectURL(blob));
        setTimeout(() => {
          pdfContainer.current.classList.toggle("open");
          setLoadingPdf(false);
        }, 0);
      });
  };

  React.useEffect(() => {
    if (!(filters?.fromDate && filters?.toDate)) {
      return;
    }

    let endpoint = persistedData?.businessUnitCode
      ? "/endpoint/v2/reports/mywtn/branch/aggregate"
      : "/endpoint/v2/reports/mywtn/company/aggregate";
    mainAxios
      .post(endpoint, {
        object: "GetMyWtnCashDepositStatisticsAnalytics",
        fromDate: moment(filters?.fromDate).format("YYYY-MM-DD"),
        toDate: moment(filters?.toDate).format("YYYY-MM-DD"),
        ...(filters?.params?.businessUnitCode
          ? { params: { businessUnitCode: filters?.params?.businessUnitCode } }
          : {}),
      })
      .then((res) => {
        if (res?.data?.status) {
          let getStatistics = calculateStatistics(res.data.content);
          setStatistics(getStatistics);
        }
      });
  }, [
    filters?.fromDate,
    filters?.toDate,
    filters?.params?.businessUnitCode,
    selectedCompany,
    refresh
  ]);

  const calculateStatistics = (data) => {
    if (data?.length > 0) {
      const {
        id,
        tcrCode,
        businessUnitCode,
        actionFrom,
        company,
        recordDate,
        ...rest
      } = data[0];
      let stats = {};
      Object.keys(rest).forEach((key) => {
        stats[key] = data.reduce((sum, tcrStat) => sum + tcrStat[key], 0);
      });
      return stats;
    }
    return [];
  };


  const statusOptions = [
    {
      key: "statusObj",
      count: statistics?.approvedWtnNo,
      multiSelect: true,
      label: t("status.approved"),
      value: "9",
    },
    {
      key: "statusObj",
      count: statistics?.errorWtnNo,
      multiSelect: true,
      label: t("home.error"),
      value: "35",
    },
    {
      key: "statusObj",
      count: statistics?.retryWtnNo,
      multiSelect: true,
      label: t("status.retry"),
      value: "36",
    },
    {
      key: "statusObj",
      count: statistics?.deletedWtnNo,
      multiSelect: true,
      label: t("status.deleted"),
      value: "10",
    },
    {
      key: "statusObj",
      count: statistics?.draftWtnNo,
      multiSelect: true,
      label: t("status.draft"),
      value: "7",
    },
  ];
  const transactionOptions = [
    {
      key: "transaction",
      count: statistics?.transferTransactionNo,
      multiSelect: true,
      label: t("invoice.transfer"),
      value: "TRANSFER",
    },
    {
      key: "transaction",
      count: statistics?.doorTransactionNo,
      multiSelect: true,
      label: t("status.door"),
      value: "DOOR",
    },
    {
      key: "transaction",
      count: statistics?.salesTransactionNo,
      multiSelect: true,
      label: t("status.sales"),
      value: "SALES",
    },
    {
      key: "transaction",
      count: statistics?.examinationTransactionNo,
      multiSelect: true,
      label: t("status.examination"),
      value: "EXAMINATION",
    },
  ];

  const typeOptions = [
    {
      key: "type",
      count: statistics?.wtnTypeNo,
      label: t("invoice.wtn"),
      value: "WTN",
    },
    {
      key: "type",
      count: statistics?.saleTypeNo,
      label: t("invoice.sale"),
      value: "SALE",
    },
  ];

  const transporterTypes = [
    {
      key: "transporterId",
      count: statistics?.companyTransporterNo,
      value: "NUIS",
      label: t("customers.company"),
    },
    {
      key: "transporterId",
      count: statistics?.idTransporterNo,
      value: "ID",
      label: t("status.individ"),
    },
  ];

  const userCompanyOperator =
    user?.userCompanyOperator?.map((userOpCode) => ({
      label: userOpCode.operator,
      value: userOpCode.operator,
    })) ?? [];

  const operatorCodes = [
    {
      label: "Select...",
      value: "",
    },
  ]
    .concat(
      [
        ...new Set(
          company.branches
            .reduce((tcrTypes, next) => tcrTypes.concat(next.tcrTypes), [])
            .map((tcrType) => tcrType.operatorCode)
        ),
      ].map((operatorCode) => ({
        label: operatorCode,
        value: operatorCode,
      }))
    )
    .concat(userCompanyOperator);


    const data =
    wtnInvoices &&
    (showDeleted
      ? wtnInvoices
      : wtnInvoices.filter(
        (wtnInvoice) => filters.params.statusObj.includes("10") && !loading ? true : wtnInvoice.status.status !== "DELETED"
      ));

      const getDataFromWtnRequest = ({wtnInvoice}) => {
         return mainAxios.post("apiEndpoint/search", {
              object: "GetCustomWTN",
              params: {
                  id: String(wtnInvoice.id)
              }
          }).then(res => {
              if (res?.data?.status) {
                  return res.data.content[0]
              }
              return null
          }).catch((error)=>{
            return null
          })
      }

      const handleDublicateInvoice = async ({wtnInvoice}) => {
        const invoice = await getDataFromWtnRequest({wtnInvoice})
        if(!invoice) return
        setLoadingDuplicate(true)
        const {
          recordDate,
          recordUser,
          modificationDate,
          modificationUser,
          wtnOrderNum,
          wtnNum,
          wtnic,
          fwtnic,
          send,
          qrUrl,
          fiscStatus,
          errorMessage,
          einvoice,
          ...restInvoice
        } = invoice;

        const pathsToPreservId = ['items'];
        const cleanedRestInvoice = removePropertiesRecursive(restInvoice, pathsToPreservId);
        const currentDate = new Date();

        cleanedRestInvoice.status = {
          id: 7,
          status: "DRAFT",
        };

        cleanedRestInvoice.items = cleanedRestInvoice?.items?.map((items) => {
          const { id, ...restItems } = items;
          return restItems;
        })?? null;

        cleanedRestInvoice.address[0].date = currentDate;
        cleanedRestInvoice.address[1].date = null;
        cleanedRestInvoice.issueDate = currentDate;
        cleanedRestInvoice.internalId = null;

        if (cleanedRestInvoice.parties.transporter.name) cleanedRestInvoice.parties.transporter.name = cleanedRestInvoice.parties.transporter.name.trim();
        if (cleanedRestInvoice.parties.transporter.idType) cleanedRestInvoice.parties.transporter.idType = cleanedRestInvoice.parties.transporter.idType.trim();
        if (cleanedRestInvoice.parties.seller.name) cleanedRestInvoice.parties.seller.name = cleanedRestInvoice.parties.seller.name.trim();

        mainAxios
          .post("apiEndpoint/saveOrUpdate", {
            object: "CustomWTN",
            content: {
              ...cleanedRestInvoice,
              itemsNum: cleanedRestInvoice?.items?.length,
            },
            nuis: company.nuis.trim(),
          })
          .then((res) => {
            if (res?.data?.status === true) {
                toast.success(t(["invoice.isInvoiceDuplicated"]), { containerId: "all_toast" });
                history.push("/edit-wtn/" + res.data.content[0].id)
            }
            setPageState((setPageState) => ({
              ...setPageState,
              saving: false,
            }));
          setLoadingDuplicate(false)
          })
          .catch(() => {
            setPageState((setPageState) => ({
              ...setPageState,
              saving: false,
            }));
          });
      };

  let columns = generateWtnColumns({
    wtnColumns,
    showWtnPdf,
    loadingPdf,
    deleteWtn,
    deleting,
    operatorCodes,
    wtnInvoices,
    t,
    handleDublicateInvoice,
    loadingDuplicate
  });

  const filterStatisticCard=(typeOptions,props)=>globalFilterStatisticCard(typeOptions,{...props,handleParamsChange,getFilteredResults,user,filters})


  const [selectedColumns, setSelectedColumns] = usePersistedState(
    columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
    "wtnColumns2"
  );

  return wtnInvoices ? (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="left-header d-flex align-items-center">
          <h3 className="mb-0 p-0">{t("navbar.MyWTNs")}</h3>
          <small className="ml-3">
            <ExportCsvPrime
              data={invoicesForCSV}
              columns={columns}
              onClick={() => getFilteredResults({ exportCsv: true })}
              excludedColumns={["actions"]}
            />
          </small>
          {[1, 2, 3, 4, 5].includes(user.role.id) && (
            <div className="col ml-2">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => openUploadInvoice()}
              >
                {t("invoice.upload")}
              </button>
            </div>
          )}
          <div className="actions">
            <Link to="create-wtn" className="btn btn-sm btn-primary">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t("navbar.create")}
            </Link>
          </div>
          <AlertCertificate />
        </div>
        <div className="ml-md-auto mb-4 mb-md-0">
          <DateRange
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            onChange={(dates) => {
              let fromDate = dates[0];
              let toDate = dates[1];
              if (fromDate && toDate) {
                getFilteredResults({
                  data: {
                    fromDate,
                    toDate,
                    params: { ...filters.params },
                  }
                });
              }
              setFilters((filters) => ({
                ...filters,
                fromDate,
                toDate,
              }));
              setDates({ fromDate, toDate })
              // setPersistedData({ ...persistedData, fromDate, toDate });
            }}
          />
        </div>
        <div className="col-6 col-md-2 d-flex align-items-center">
          <label className="align-items-center mr-2 mb-0">BU</label>
          <Select
            styles={customStyles}
            className="w-100"
            options={businessUnitCodes}
            value={
              filters?.params?.businessUnitCode ? businessUnitCodes.find(
                (businessUnitCode) =>
                  businessUnitCode?.value === filters?.params?.businessUnitCode
              ) : {
                label: t("invoice.businessUnitCode"),
                value: "",
              }
            }
            isSearchable={true}
            onChange={(businessUnitCode) => {
              handleParamsChange(null, "businessUnitCode", businessUnitCode.value);
              setPersistedData({
                ...persistedData,
                businessUnitCode: businessUnitCode.value,
              });
              getFilteredResults({
                data:{
                  ...filters,
                params: {
                  ...filters.params,
                  businessUnitCode: businessUnitCode.value,
                }},
              });
            }}
            name="businUnitCode"
          />
        </div>
      </div>
      <div className="row px-1">
        <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
          {filterStatisticCard(statusOptions, { title: t("home.status"), cardClasses: "grid-4" })}
        </ul>
        <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
          {filterStatisticCard(typeOptions, { title: t("home.invoiceType"), cardClasses: "grid-2" })}
        </ul>
        <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
          {filterStatisticCard(transactionOptions, { title: t("invoice.transaction"), cardClasses: "grid-4" })}
        </ul>
        <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
          {filterStatisticCard(transporterTypes, { title: t("invoice.transporter"), cardClasses: "grid-2" })}
        </ul>
      </div>

      <div className="card border-0 mb-2 shadow">
        <div className="card-body p-0">
          <PrimeTable
            showFilters={true}
            filters={[filters, setFilters]}
            tableRows={[tableRows, setTableRows]}
            persistedRowPerPage={[persistedRowPerPage, setPersistedRowPerPage]}
            defaultFilters={defaultFilters}
            value={data}
            reorderableColumns={true}
            columns={columns}
            dataKey="ublId"
            onRemoteDataChange={(remoteData) => {
              const updatedFilters = remoteData.filters ?? filters
                 const updatedTableData = remoteData.tableRows ?? tableRows
                 let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                 sortCriteria=sortCriteria?[sortCriteria]:[]
                 getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage,sortCriteria })
            }}
            selectedColumns={[selectedColumns, setSelectedColumns]}
            onClearFilter={() => {
              getFilteredResults({ data: { ...filters, params: { ...defaultFilters.params } } });
            }}
            columnDeps={[fullCompany]}
            likeValues={false}
            loading={loading}
            defaultSortCriteria={defaultSortCriteria}
          />
        </div>
      </div>

      <div className="pdf-container" ref={pdfContainer}>
        {pdfToDisplay && (
          <div className="pdf-viewer">
            <div
              className="pdf-overlay"
              onClick={() => {
                setTimeout(() => {
                  setPdfToDisplay(null);
                }, 150);
                pdfContainer.current.classList.toggle("open");
              }}
            />
            <div className="pdf-content">
              <embed src={pdfToDisplay} />
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default CustomInvoices;

import { faArrowCircleUp, faArrowUp, faBars, faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import React,{  useMemo, useState } from "react";
import { swalAlert } from "../../services/functions";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const MyMultiSelect = ({columns,newColumns, currentColumns, selectedColumns,setSelectedColumns,onChange, }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [search,setSearch]=useState("")
    const { t } = useTranslation('translations');


    const onItemChange=({item,value})=>{
      onChange()
      if(item?.newCol){
          const newItem={show:item.show,field:item.field}
          if(item.persisted) newItem.persisted=true
          setSelectedColumns((selectedColumns)=>([newItem,...selectedColumns,]))
        }else {
          setSelectedColumns(selectedColumns=>{
            return currentColumns.map(sCol=>{
                if(sCol.field===item.field){
                    let newItem={show:value,field:item.field}
                    if(item.persisted) newItem.persisted=true
                    return newItem
                }
                return {show:sCol.show,field:sCol.field}
                })
        })
        }
    }

    const moveToTop = (item) => {
      setSelectedColumns(selectedColumns => {
          const filteredColumns = selectedColumns.filter(col => col.field !== item.field);
          return [{ show: item.show, field: item.field, ...(item.persisted ? { persisted: true } : {}) }, ...filteredColumns];
      });
  };

  const DragComponent = ({ item, newItem, dragOverId }) => {

    const checked = (newItem ? false : (item.persisted || item.show));
    const disabled = newItem ? false : item.persisted;
    return (
        <div className={`my-dropdown-menu-item ${item.field === dragOverId ? "drag-border" : ""} py-3`}>
            <div className="checkbox-label" style={{ cursor: !disabled ? "pointer" : "auto" }}>
                <div className="checkbox">
                    <Checkbox id={item.field} checked={checked} onChange={(e) => { onItemChange({ item, value: e.target.checked }) }} disabled={disabled} />
                </div>
                <div className="label" htmlFor={item.field} onClick={(e) => { if (!disabled) onItemChange({ item, value: !checked }) }}>
                    {item.header}
                </div>
            </div>
            {!newItem?  (
                <div className="actions">
                    {newColumns && search?.length === 0 ? <FontAwesomeIcon icon={faBars} style={{ cursor: "pointer", marginLeft: "5px" }} /> : null}
                 <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`} className="custom-tooltip">{t("table.moveTop")}</Tooltip>}
                    >
                        <FontAwesomeIcon icon={faArrowCircleUp} style={{ cursor: "pointer", marginLeft: "10px" }} onClick={() => moveToTop(item)} />
                    </OverlayTrigger>
                </div>
            ):<>
            <div className="new-column-badge">New</div>
            </>}
        </div>
    );
};



    const value=useMemo(()=>currentColumns.filter(currCol=>currCol.show || currCol.persisted).map(item=>item.header).join(","),[currentColumns])
    const newColumnsFiltered =useMemo(()=>{return search?.length>0?newColumns.filter(col=>(col?.header ?? "")?.toLowerCase().includes(search.toLocaleLowerCase())):newColumns},[search,newColumns])
    const currentColumnsFiltered =useMemo(()=>{return search?.length>0?currentColumns.filter(col=>(col?.header ?? "")?.toLowerCase().includes(search.toLocaleLowerCase())):currentColumns},[search,currentColumns])
    const allColumns=useMemo(()=>newColumns.concat(currentColumns),[newColumns,currentColumns])
    const checked=useMemo(()=>allColumns.every(col=>col.show ||col.persisted),[allColumns])

    const onSelectAll=(value)=>{
      setSelectedColumns(()=>{
        return allColumns.map(col=>{
          let item = {show:value?true:(col.default || col.persisted),field:col.field}
          if(col.persisted) item.persisted=true
          return item
        })
      })
    }

    const resetColumns=async ()=>{
      const res = await swalAlert({title:"Are you sure you want to reset the columns.",text:"This will show only the default columns and olso reset the default column order.",t})
      if(res){
        setSelectedColumns(()=>{
          return columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})}))
        })
      }

    }

    return (
      <>
      {isOpen && <div className="my-dropdown-layover" onClick={()=>setIsOpen(false)} />}
        <div className="my-dropdown" >
          <div className="my-dropdown-toggle" onClick={() => setIsOpen((open) => !open)}>
            <div className="selected-values">
              {value}
            </div>
              <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <div className={`my-dropdown-menu`} style={{display:isOpen?"block":"none"}}>
            <div className="my-dropdown-header">
              <Checkbox checked={checked} onChange={(e)=>onSelectAll(e.target.checked)}/>
              <InputText className="my-dropdown-search" type="text" value={search} onChange={(e)=>setSearch(e.target.value)}/>
              <FontAwesomeIcon className="menu-close-button"  icon={faTimes} onClick={()=>setIsOpen(false)}/>
            </div>
            <div className="my-dropdown-body">
            {newColumnsFiltered.map(newCol=><DragComponent item={newCol} newItem={true}/>)}
            <SortableList
                items={currentColumnsFiltered}
                setItems={setSelectedColumns}
                component={DragComponent}
                draggable={search?.length===0}
            />
            </div>
            <div className="my-dropdown-footer">
              <div className="footer-actions" onClick={resetColumns}>Reset</div>
            </div>
          </div>
        </div>
      </>
    )
  }
  

  const SortableList = ({ items, setItems, component,key="field",draggable=true,...props }) => {
    const [dragId, setDragId] = useState("");
    const [dragOverId,setDragOverId]=useState("")

    const arrayMove=(arr, oldIndex, newIndex)=> {
        if (newIndex >= arr.length) {
          let k = newIndex - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
      
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      
        return arr;
      }
  
    const onDrop = (ev, item) => {
      let currentPos = 0,
        droppedPos = 0;
  
      for (let i = 0; i < items.length; i++) {
        if (dragId == items[i][key]) {
          currentPos = i;
        }
  
        if (ev.currentTarget.id == items[i][key]) {
          droppedPos = i;
        }
      }
  
      const newItems = arrayMove([...items], currentPos, droppedPos);
      const mappedItems=newItems.map(item=>({show:item.show,field:item.field,...(item.persisted?{persisted:true}:{})}))
      setItems(mappedItems);
    };
  
    const onDragStart = (ev, item) => {
        console.log({evStart:ev})
      setDragId(item[key]);
    };
  
    const renderComponent = ({componentJsx, item, index,dragOverId}) => {
      const Component = componentJsx;
  
      return <Component item={item} index={index} dragOverId={dragOverId}/>;
    };
  
    return (
      <>
        {items.map((item, index) => {
          return <Draggable
            key={index}
            draggable={draggable}
            id={item?.id ?? item?.[key]}
            onDrop={onDrop}
            onDragStart={onDragStart}
            item={item}
            {...props}
          >
            {renderComponent({componentJsx:component, item, index,dragOverId})}
          </Draggable>
  })}
      </>
    );
  };
  
  export const Draggable=({
    children,
    id,
    item = {},
    onDragStart = (event, item) => {},
    onDrop = (event, item) => {},
    ...props
  }) => {
    const handleDrag = (ev) => {
      onDragStart(ev, item);
    };
  
    const handleDrop = (ev) => {
      onDrop(ev, item);
    };
  
    return (
      <div
        id={id}
        onDragOver={(ev) => ev.preventDefault()}
        onDragStart={handleDrag}
        onDrop={handleDrop}
        className="animation-move"
        {...props}
      >
        {children}
      </div>
    );
  }
import React from "react";
import InvoiceStatus from "./InvoiceStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBan,
    faCalendar,
    faCheckCircle,
    faEye,
    faQrcode,
    faRedoAlt,
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import func from "../../../services/functions";
import ReactTooltip from "react-tooltip";
import { getStore } from "../../../AppContext";
import PopOver from "../../global/PopOver";
import { printStatuses } from "../../../services/printInvoiceOnError";
import CellInput from "../../global/CellInput";
import DatePicker from "react-datepicker";
import profileIDS from "../../../services/profileIDS.json"
import { Menu } from "primereact/menu";
import { paymentOptions } from "../../../services/data";
import LoadingLayover from "../../global/LoadingLayover";

const moment = require("moment");

const actionsBody = ({ queue, fullCompany, t, cancelSuccessQueue, loadingRequest, user, resendQueue, cancelQueue, verifyInvoices, exportAction }) => {
    let button = null;
    const status = func.getInvoiceType(
        queue.fiscStatus,
        queue.einvoiceStatus,
        queue.ublInvoiceTypeCode,
        queue.correctiveInvoiceType,
        queue.einvoice
    )
    const showCancel = fullCompany?.showCancelInvoiceButton === true
    const toBeVerifiedStatus = queue?.cisConfirmed == "To be verified"
    // const cisConfirmedStatus = queue?.cisConfirmed=="Confirmed"
    let exportStatus = ""
    switch (
    status
    ) {
        case 5:
            if ([1, 2, 3, 5].includes(user.role.id)) {
                if (queue.einvoiceStatus == -100) exportStatus = t("common.cancelled")

                button = (
                    <>
                        <a
                            href="#"
                            className={"nav-link p-0 d-flex align-items-center text-danger" +
                                ((queue.einvoiceStatus == -100 || loadingRequest) ? " disabled" : "")}
                            onClick={(e) => { if (!loadingRequest) cancelSuccessQueue(e, queue); }}
                        >
                            {showCancel && <FontAwesomeIcon icon={faBan} className="mr-1" />}
                            {(queue.einvoiceStatus == -100) ? t("common.cancelled") : !showCancel ? "" : t("common.cancel")}
                        </a>


                    </>
                );
            }
            break;
        case 12: if ([1, 2, 3, 5].includes(user.role.id)) {
            if (queue.fiscStatus == -100) exportStatus = t("common.cancelled")

            button = (
                <div className='d-flex align-items-center'>
                    <a
                        href="#"
                        className={"nav-link p-0 d-flex align-items-center text-danger" +
                            ((queue.fiscStatus == -100 || loadingRequest) ? " disabled" : "")}
                        onClick={(e) => { if (!loadingRequest) cancelQueue(e, queue); }}
                    >
                        {showCancel && <FontAwesomeIcon icon={faBan} className="mr-1" />}
                        {(queue.fiscStatus == -100) ? t("common.cancelled") : !showCancel ? "" : t("common.cancel")}
                    </a>
                </div>
            );
        }
            break;
        case 8:
            if ([1, 2, 3, 5].includes(user.role.id)) {
                button = (
                    <PopOver
                        title="Fature elektronike me error. (Kjo fature nuk afishohet te libri i shitjeve.)"
                        customStyle={{
                            borderRadius: "40%",
                            width: "19px",
                            height: "19px",
                            transform: "scale(0.80)",
                            backgroundColor: "#343a40",
                            color: "#fff",
                        }}
                    />
                );
            }

            break;
        case 9:
            if ([1, 2, 3, 5].includes(user.role.id)) {
                exportStatus = t("actions.resend") + "(F+E)"
                button = (
                    <a
                        href="#"
                        className={"nav-link p-0 d-flex align-items-center text-info " +
                            (loadingRequest ? " disabled" : "")}
                        onClick={(e) => { if (!loadingRequest) resendQueue(e, queue) }}
                        data-tip="Resend the Invoice to be Fiscalized and made into Electronic Invoice"
                    >
                        <ReactTooltip />
                        <FontAwesomeIcon icon={faRedoAlt} className="mr-1" />
                        {t("actions.resend")} (F+E)
                    </a>
                );
            }

            break;
        case 10:
            if ([1, 2, 3, 4, 5].includes(user.role.id)) {
                exportStatus = t("actions.resend") + "(F+E)"
                button = (

                    <a
                        href="#"
                        className={"nav-link p-0 d-flex align-items-center text-info " +
                            (loadingRequest ? " disabled" : "")}
                        onClick={(e) => { if (!loadingRequest) resendQueue(e, queue) }}
                        data-tip="Resend the Fiscalized Invoice to make it Electronic Invoice"
                    >
                        <ReactTooltip />
                        <FontAwesomeIcon icon={faRedoAlt} className="mr-1" />
                        {t("actions.resend")} (F+E)
                    </a>
                );
            }
            break;
        default:
            button = null;
    }

    if (exportAction) {
        return exportStatus
    }
    return (
        <div className="d-flex align-items-center gap-2">
            <a
                href={`${queue?.qrurl}`}
                target="_blank"
                className="nav-link p-0 d-flex align-items-center mr-1"
            >
                <FontAwesomeIcon size="lg" className="mr-1" icon={faQrcode} />
                {"QR Url"}
            </a>
            {button}
            {/* Verify Button */}
            {(queue?.toBeVerified && toBeVerifiedStatus) && (
                <a
                    href="#"
                    className={"nav-link p-0 d-flex align-items-center text-success ml-2" +
                        (loadingRequest ? " disabled" : "")}
                    onClick={(e) => { if (!loadingRequest) verifyInvoices(e, queue); }}
                >
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    {t("common.verify")}
                </a>
            )}

        </div>
    );
}

const generateQueueColumns = (
    {
        resendQueue,
        cancelQueue,
        verifyInvoices,
        cancelSuccessQueue,
        loadingRequest,
        t,
        loadingPdf,
        showInvoicePdf,
        getFilteredResults,
        tableRows,
        loadingRefreshStatus,
        operatorCodes,
        handleParamsChange,
        filters,
        fullCompany,
        user,
        isRefreshStatus
    }
        = {}) => {
    // let columns = queueColumns != null ? queueColumns.split(",") : null;
    const invoiceTypeOptions = [
        { value: "Normale", label: t("invoice.normal") },
        { value: "Korrigjuese", label: t("invoice.corrective") },
        { value: "Autofature", label: "AutoFature" },
    ];

    const profileIdOptions = profileIDS.map((profId) => ({ ...profId, label: t([profId.label]) }))


    const currencyOptions = [
        { value: "ALL", label: "Lek" },
        { value: "EUR", label: "Euro" },
        { value: "USD", label: "USD" },
    ];

    const cisOptions = [
        {
            label: func.capitalizeFirstLetter(t("status.DELIVERED")),
            value: "DELIVERED"
        },
        {
            label: t("queue.accepted"),
            value: "ACCEPTED"
        },
        {
            label: t("purchases.rejected"),
            value: "REFUSED"
        },
        {
            label: func.capitalizeFirstLetter(t("status.PARTIALLY_PAID")),
            value: "PARTIALLY_PAID"
        },
        {
            label: func.capitalizeFirstLetter(t("status.PAID")),
            value: "PAID"
        },

    ]
    const periodFilter = (filterOptions) => {
        let selected = filters?.params?.["period"]
        return (
            <div>
                <DatePicker
                    id="periodDate"
                    placeholderText={t("queue.selectDate")}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    onChange={(date) => {
                        let value = new Date(date).setHours(2, 0, 0, 0)
                        filterOptions.filterCallback(value)
                        value = moment(value).format("M/yyyy")
                        handleParamsChange(null, "period", value)
                        getFilteredResults({ data: { ...filters, params: { ...filters.params, "period": value } } })
                    }
                    }
                    customInput={
                        <div className="form-control d-flex justify-content-center align-items-center" style={{ width: 150, position: "relative" }}>
                            <FontAwesomeIcon icon={faCalendar} />
                            <div className="ml-1">
                                {filters?.params?.["period"] ? filters?.params?.["period"] : t("vat.none")}
                            </div>
                            {selected && <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    filterOptions.filterCallback("")
                                    handleParamsChange(null, "period", null)
                                    getFilteredResults({ data: { ...filters, params: { ...filters.params, "period": null } } })
                                }}
                                style={{ position: "absolute", zIndex: 99999, top: 6, right: 10, padding: "0px 5px", cursor: "pointer", fontSize: 16, fontWeight: 400 }}>
                                {`\u2715`}
                            </div>}
                        </div>
                    }
                />
            </div>
        )
    }


    const formatArrayString = (str) => {
        if (str) {
            try {
                return JSON.parse(str ?? "")?.reduce(
                    (acc, next) => (acc ?? "") + (next ? ", " + next : "", "")
                )
            } catch (e) {
                return str
            }
        }
        return ""
    }

    return [
        {
            header: t(["home.buyerName"]),
            field: "buyerName",
            style: { minWidth: "250px", maxWidth: "250px", overflow: "hidden" },
            persisted: true,
            default: true,
            filter: true,
            sortable: true,
            body: (queue) => queue.buyerName || "",
        },
        {
            header: "Print",
            field: "print",
            style: { minWidth: "150px", maxWidth: "300px" },
            persisted: true,
            default: true,
            body: (queue) => {
                const isLoadingPdf = loadingPdf?.id == queue?.id && loadingPdf?.loading

                return (<>
                    <div className="d-flex">
                        {printStatuses.includes(queue.fiscStatus) && (
                            <a
                                href="#"
                                className={
                                    "nav-link p-0 d-flex-align-items-center mr-1"
                                }
                                onClick={(e) => showInvoicePdf({ key: "iic", invoice: queue, e })}
                            >
                                <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key === "iic"))}>
                                    <img
                                        src="https://profisc.al/wp-content/uploads/2021/10/Asset-11.svg"
                                        title={t("invoice.printFisc")}
                                        width="38"
                                        height="32"
                                    />
                                </LoadingLayover>

                            </a>
                        )}
                        {printStatuses.includes(queue.einvoiceStatus) && (
                            <a
                                href="#"
                                className={
                                    "nav-link p-0 d-flex-align-items-center mx-1"}
                                onClick={(e) => showInvoicePdf({ key: "eic", invoice: queue, e })}
                            >
                                <LoadingLayover loading={(isLoadingPdf && (loadingPdf?.key === "eic"))}>
                                    <img
                                        src="https://profisc.al/wp-content/uploads/2021/10/Asset-12.svg"
                                        title={t("invoice.electronicInv")}
                                        width="38"
                                        height="32"
                                    />
                                </LoadingLayover>

                            </a>
                        )}
                        {/* */}
                    </div>
                </>)
            },
        },
        {
            header: "Email",
            field: "sendEmail",
            style: { minWidth: "50px", maxWidth: "300px" },
            body: (queue) => (
                <>
                    {printStatuses.includes(queue.fiscStatus) && (
                        <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            size={"2x"}
                            className={`ml-2`} // ${loadingPdf ? "text-secondary" : "text-primary"}`}
                            style={{ cursor: "pointer", height: "25px" }}
                            color={"#6fc483"}
                            disabled={loadingPdf}
                            onClick={() =>
                                func.sendEmailPdf(
                                    queue.iic,
                                    queue?.customer,
                                    queue?.buyerNuis,
                                    t
                                )
                            }
                            title={t("email.sendByEmail")}
                        />
                    )}
                </>
            ),
            default: false
        },
        {
            header: t(["home.actions"]),
            exportHeader: "Status",
            field: "actions",
            style: { minWidth: "180px", maxWidth: "300px" },
            center: true,
            persisted: true,
            formattedExportValue: true,
            body: (queue) => actionsBody({ fullCompany, t, cancelSuccessQueue, loadingRequest, queue, user, resendQueue, cancelQueue, verifyInvoices }),
            format: (queue) => actionsBody({ fullCompany, t, cancelSuccessQueue, loadingRequest, queue, user, resendQueue, cancelQueue, verifyInvoices, exportAction: true }),
            default: true
        },
        {
            header: t("queue.isDifferentPeriod"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'differentPeriod',
            persisted: true,
            filter: true,
            filterType: "TRISTATECHECKBOX",
            filterConfig: {
                field: "isDifferentPeriod"
            },
            dataType: "boolean",
            default: true
        },
        {
            header: t(["table.recordDate"]),
            field: "recordDate",
            style: { minWidth: "200px", maxWidth: "300px" },
            filter: true,
            filterType: "DATE",
            filterConfig: {
                field: "invoiceDate",
                like: false
            },
            sortable: true,
            persisted: true,
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (queue) =>
                queue.recordDate
                    ? moment(queue.recordDate).format("DD/MM/YYYY HH:mm:ss")
                    : "",
            default: true
        },
        {
            header: t(["table.buyerNIPT"]),
            field: "buyerNuis",
            style: { minWidth: "150px", maxWidth: "300px" },
            filter: true,
            persisted: true,
            default: true,
        },
        {
            header: t(["table.ublCustomerCityName"]),
            field: "ublCustomerCityName",
            style: { minWidth: "180px", maxWidth: "300px" },
            filter: true,
            default: true,
            persisted: true
        },
        {
            header: t(['invoice.profileId']),
            field: "ublProfileId",
            style: { minWidth: "180px", maxWidth: "300px" },
            default: true,
            body: (queue) => queue.ublProfileId,
            filter: true,
            filterType: "MULTISELECT",
            filterConfig: {
                options: profileIdOptions
            }
        },
        {
            header: t(["invoice.DocNumber"]),
            filter: true,
            field: "ublId",
            format: (invoice) => func?.preventExcelFormatFn(invoice?.ublId || ""),
            style: { minWidth: "180px", maxWidth: "300px" },
            sortable: true,
            persisted: true,
            default: true
        },
        {
            header: t(["purchases.cisStatus"]),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "cisStatus",
            default: true,
            body: (queue) => {
                let loading =
                    loadingRefreshStatus?.loading &&
                    loadingRefreshStatus?.eic == queue?.eic;
                const { einvoiceStatus } = queue;
                let refreshStatus =
                    einvoiceStatus === 56 ||
                    einvoiceStatus === 101 ||
                    einvoiceStatus === 8101;
                return (
                    <>
                        {queue.cisStatus}
                        {((queue?.eic && queue.cisStatus != "PAID") || refreshStatus) && (
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                style={{ cursor: "pointer" }}
                                className={`ml-2 ${loading ? "text-secondary" : "text-primary"
                                    }`}
                                disabled={loading}
                                onClick={() => {
                                    if (loadingRefreshStatus.loading) return;
                                    let refreshStatusParams = {
                                        action: "Refresh",
                                        eic: queue.eic,
                                    };

                                    if (refreshStatus)
                                        refreshStatusParams = {
                                            action: "Refresh",
                                            value: "UpdateError56Status",
                                            iic: queue.iic,
                                        };
                                    getFilteredResults({
                                        page: tableRows.currentPage,
                                        refreshSatus: refreshStatusParams
                                    }
                                    );
                                }}
                            />
                        )}
                    </>
                );
            },
            filter: true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: cisOptions,
                optionValue: "value",
                optionLabel: "label",
            },

        },
        {
            header: t(["invoice.refreshDateTime"]),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "statusChangeDateTime",
            formattedExportValue: true,
            preventExcelFormat: true,
            default: true,
            body: (queue) =>
                queue.statusChangeDateTime
                    ? moment(queue.statusChangeDateTime).format("DD/MM/YYYY HH:mm")
                    : "",
        },
        {
            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID",
            field: "erpId",
            filter: true,
            default: true,
            style: { minWidth: "250px", maxWidth: "200px" },
            sortable: true,
            body: (queue) => <CellInput width={180} value={queue.erpId ?? ""} onFocus={(event) => event.target.select()} />
        },
        {
            header: "EIC",
            field: "eic",
            default: true,
            filter: true,
            style: { minWidth: "250px", maxWidth: "200px" },
            body: (queue) => <CellInput width={220} value={queue.eic ?? ""} onFocus={(event) => event.target.select()} />,
        },
        {
            header: t("invoice.FIC"),
            field: "fic",
            default: true,
            filter: true,
            persisted: true,
            style: { minWidth: "250px", maxWidth: "200px" },
            body: (queue) => <CellInput value={queue?.fic ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },

        {
            header: t("invoice.IIC"),
            field: "iic",
            filter: true,
            style: { minWidth: "250px", maxWidth: "200px" },
            body: (queue) => <CellInput value={queue?.iic ?? ""} width={220} onFocus={(event) => event.target.select()} />,
        },
        {
            header: t(["home.invoiceType"]),
            field: "invoiceType",
            style: { minWidth: "200px", maxWidth: "200px" },
            filter: true,
            persisted: true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: invoiceTypeOptions,
                optionValue: "value",
                optionLabel: "label",
            },
        },

        {
            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId1")?.value ?? (t("pos.additional") + " 1"),
            filter: true,
            filterConfig: {
                like: false
            },
            style: { minWidth: "180px", maxWidth: "200px" },
            field: "additionalId1",
            body: (queue) => <CellInput width={180} value={queue.additionalId1 ?? ""} onFocus={(event) => event.target.select()} />,

        },

        {
            header: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId2")?.value ?? (t("pos.additional") + " 2"),
            style: { minWidth: "200px", maxWidth: "200px" },
            field: "additionalId2",
            filterConfig: {
                like: false
            },
            filter: true,
            body: (queue) => <CellInput width={180} value={queue?.additionalId2 ?? ""} onFocus={(event) => event.target.select()} />,
        },
        {
            header: "Fisc Status",
            field: "fiscStatus",
            persisted: true,
            filterConfig: {
                like: false
            },
            style: { minWidth: "150px", maxWidth: "300px" },
            body: (queue) => (
                <InvoiceStatus
                    fiscStatus={queue.fiscStatus}
                    einvoiceStatus={queue.einvoiceStatus}
                    ublInvoiceTypeCode={queue.ublInvoiceTypeCode}
                    correctiveInvoiceType={queue.correctiveInvoiceType}
                    einvoice={queue.einvoice}
                    isRefreshStatus={isRefreshStatus}
                    invoice={queue}
                />
            ),
        },
        {
            header: t(["invoice.operatorCode"]),
            style: { minWidth: "200px", maxWidth: "300px" },
            filter: true,
            default: true,
            field: "operatorCode",
            body: (queue) => queue?.operatorCode || "",
        },
        {
            header: t(["invoice.businessUnit"]),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "businessUnitCode",
            default: true,
            filter: true,
            body: (queue) => queue.businessUnitCode || "",
        },
        {
            header: "Cash/NonCash",
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "fiscInvoiceType",
            default: true,
            filter: true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: paymentOptions,
            },
            body: (queue) => queue.fiscInvoiceType || "",
        },
        {
            header: "eInvoice Status",
            style: { minWidth: "200px", maxWidth: "300px" },
            field: "einvoiceStatus",
            filter: true,
            filterConfig: {
                like: false
            },
        },
        {
            header: t(["invoice.currency"]),
            field: "ublCurrency",
            default: true,
            persisted: true,
            style: { minWidth: "150px", maxWidth: "300px" },
            body: (queue) => queue.ublCurrency,
            filter: true,
            filterType: "MULTISELECT",
            filterConfig: {
                options: currencyOptions
            }
        },
        {
            header: t(["home.exchangeRate"]),
            field: "exchangeRate",
            default: true,
            style: { minWidth: "150px", maxWidth: "300px" },
            body: (queue) =>
                queue.exchangeRate !== "ALL" ? queue.exchangeRate : "",
        },

        {
            header: t(["table.ublTaxExclusiveAmount"]),
            field: "ublTaxExclusiveAmount",
            style: { minWidth: "280px", maxWidth: "300px" },
            sortable: true,
            persisted: true,
            align: "right",
            body: (queue) => func.getFormattedMoneyValue(queue?.ublTaxExclusiveAmount ?? 0),
        },
        {
            header: t(["table.ublTaxAmount"]),
            field: "ublTaxAmount",
            style: { minWidth: "280px", maxWidth: "300px" },
            sortable: true,
            default: true,
            persisted: true,
            align: "right",
            body: (queue) => func.getFormattedMoneyValue(queue?.ublTaxAmount),
        },
        {
            header: t(["table.ublTaxInclusiveAmount"]),
            field: "ublTaxInclusiveAmount",
            style: { minWidth: "280px", maxWidth: "300px" },
            sortable: true,
            default: true,
            persisted: true,
            align: "right",
            body: (queue) => func.getFormattedMoneyValue(queue?.ublTaxInclusiveAmount),
        },

        {
            header: t("queue.bookingDate"),
            style: { minWidth: "200px", maxWidth: "300px" },
            field: 'bookingDate',
            formattedExportValue: true,
            preventExcelFormat: true,
            body: queue => queue?.bookingDate ? moment(queue.bookingDate).format("DD/MM/YYYY HH:mm") : "",
        },
        {
            header: t("queue.period"),
            style: { minWidth: "180px", maxWidth: "180px" },
            field: 'period',
            default: true,
            body: queue => queue.period || "",
            filter: true,
            filterElement: periodFilter
        },

        {
            header: t(["common.username"]),
            style: { minWidth: "150px", maxWidth: "300px" },
            field: "recordUser",
            filter: true,
            default: true,
            body: (queue) => queue.recordUser || "",
        },
        {
            field: "carrier",
            header: t(["table.carrier"]),
            style: { width: 150 },
            sortable: true,
        },
        {
            field: "correctiveDate",
            header: t(["table.correctiveDate"]),
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (queue) => queue?.correctiveDate ? moment(queue.correctiveDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
            sortable: true,
        },
        {
            field: "correctiveInvoiceType",
            header: t(["table.correctiveInvoiceType"]),
            style: { minWidth: 250, maxWidth: 350 },

        },
        {
            field: "dueDate",
            header: t(["table.dueDate"]),
            formattedExportValue: true,
            // preventExcelFormat: true,
            body: (queue) =>
                queue?.dueDate ? moment(queue?.dueDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
            sortable: true,
        },
        {
            field: "iicRef",
            header: t("purchases.iicRef"),
            style: { minWidth: 200, maxWidth: 300 },
            filter: true,
        },
        {
            field: "oldInvoiceNumber",
            header: t("queue.oldInvoiceNumber"),
            style: { minWidth: 200, maxWidth: 300 },
            sortable: true,
        },
        {
            field: "issueDatetime",
            header: t(["table.issueDatetime"]),
            body: (queue) => queue?.issueDatetime ? moment(queue?.issueDatetime).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
            filter: true,
            filterType: "DATE",
            filterConfig: {
                field: "invoiceIssueDateTime",
                like: false
            },
            persisted: true,
            formattedExportValue: true,
            preventExcelFormat: true,
            sortable: true,
        },
        {
            field: "modificationDate",
            header: t(["table.modificationDate"]),
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (queue) => queue?.modificationDate ? moment(queue.modificationDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
            sortable: true,
        },
        {
            field: "modificationUser",
            header: t(["table.modificationUser"]),
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "pdfPath",
            header: t(["table.pdfPath"]),
            style: { minWidth: 200, maxWidth: 300 },
        },

        {
            field: "tcrCode",
            header: "Tcr Code",
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "total",
            header: "Total",
            body: (invoice) => invoice?.total ? func.getFormattedMoneyValue(invoice?.total) : "",
            style: { minWidth: 200, maxWidth: 300 },
            sortable: true,
        },
        {
            field: "ublContractDocumentReferenceId",
            header: t(["table.ublContractDocumentReferenceId"]),
            body: (value) => formatArrayString(value?.ublContractDocumentReferenceId),
            style: { minWidth: 250, maxWidth: 350 },
        },
        {
            field: "ublCustomerIdentificationCode",
            header: t(["table.ublCustomerIdentificationCode"]),
            sortable: true,
            style: { minWidth: 200, maxWidth: 300 },

        },
        {
            field: "ublCustomerSchemeId",
            header: t(["table.ublCustomerSchemeId"]),
            style: { minWidth: 250, maxWidth: 350 },
        },
        {
            field: "ublEndDate",
            header: t(["table.ublEndDate"]),
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (queue) => queue?.ublEndDate ? moment(queue.ublEndDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "ublInvoiceDocumentReferenceId",
            header: t(["table.ublInvoiceDocumentReferenceId"]),
            style: { minWidth: 250, maxWidth: 350 },
        },
        {
            field: "ublInvoiceTypeCode",
            header: t(["table.ublInvoiceTypeCode"]),
            style: { minWidth: 200, maxWidth: 300 },

        },
        {
            field: "ublOrderReference",
            header: t(["table.ublOrderReference"]),
            style: { minWidth: 200, maxWidth: 300 },

        },
        {
            field: "ublPaymentMeansName",
            header: t(["table.ublPaymentMeansName"]),
            body: (value) => formatArrayString(value?.ublPaymentMeansName),
            style: { minWidth: 200, maxWidth: 300 },
            formattedExportValue: true,
        },
        {
            field: "ublReceiptDocumentReference",
            header: t(["table.ublReceiptDocumentReference"]),
            body: (value) => formatArrayString(value?.ublReceiptDocumentReference),
            style: { minWidth: 250, maxWidth: 350 },
        },
        { field: "ublRefId", header: t(["table.ublRefId"]), style: { minWidth: 200, maxWidth: 300 }, },
        {
            field: "ublStartDate",
            header: t(["table.ublStartDate"]),
            formattedExportValue: true,
            preventExcelFormat: true,
            body: (queue) => queue.ublStartDate ? moment(queue.ublStartDate).format("DD/MM/YYYY HH:mm") : "",
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "ublSupplierCompanyId",
            header: t(["table.ublSupplierCompanyId"]),
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "ublSupplierIdentificationCode",
            header: t(["table.ublSupplierIdentificationCode"]),
            style: { minWidth: 200, maxWidth: 300 },
        },
        {
            field: "ublSupplierRegistrationName",
            filter: true,
            header: t(["table.ublSupplierRegistrationName"]),
            style: { minWidth: 200, maxWidth: 300 },
            body: (value) => {
                return typeof value?.ublSupplierRegistrationName == "object" && value?.ublSupplierRegistrationName ? JSON?.parse(value?.ublSupplierRegistrationName ?? "")?.reduce(
                    (acc, next) => acc + ", " + next
                ) : value?.ublSupplierRegistrationName
            },
        },
        {
            field: "softwareCode",
            header: t(["nslf.softCode"]),
            filter: true,
            style: { minWidth: 120, maxWidth: 120 },
        },
    ];
};

export default generateQueueColumns;

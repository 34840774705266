import React, { useEffect } from 'react'
import WTN from './WTN'
import Loader from '../../Layout/Loader'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../AppContext'
import mainAxios from '../../../services/mainAxios'
import { useTranslation } from "react-i18next";
import invoiceStatuses from '../../../services/invoiceStatuses.json'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy,faFilePdf, faQuestionCircle,faSpinner } from '@fortawesome/free-solid-svg-icons'
import func, { removePropertiesRecursive, validateInvoice, validateInvoiceWtn } from '../../../services/functions'
import ReactTooltip from 'react-tooltip'
import Accordion from '../WizardSteps/Accordion'
import DemoWtn from '../demoWtn/DemoWtn'
import { useHistory,Redirect } from 'react-router-dom';
import moment from 'moment'




const EditWTNInvoice = ({ match: { params: { id: wtnInvoiceId } },  }) => {
    const { t, i18n } = useTranslation('translations');
    const {
        user: [user,],
        selectedCompany: [selectedCompany,],
    } = useAppContext()

    const company = user.companiesRights.find(company => company.id === parseInt(selectedCompany))
    const [loadingDuplicate, setLoadingDuplicate] = React.useState(false);
    const history = useHistory()
    const [pageState, setPageState] = React.useState({
        loading: true,
        saving: false
    })
    const [redirect, setRedirect] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [state, setState] = React.useState(null)
    const [pdfToDisplay, setPdfToDisplay] = React.useState(null)
    const [loadingPdf, setLoadingPdf] = React.useState(false)
    const pdfContainer = React.useRef()

    const getWtnInvoice=(id)=>{
        mainAxios.post("apiEndpoint/search", {
            object: "GetCustomWTN",
            params: {
                id: id ?? wtnInvoiceId 
            }
        }).then(res => {
            if (res?.data?.status) {
                setState(res.data.content[0])
                setPageState(pageState => ({
                    ...pageState,
                    loading: false
                }))
            }
        })
    }

    React.useEffect(() => {
        getWtnInvoice()
    }, [selectedCompany,wtnInvoiceId])


    const handleSave = () => {

        const validateInvoiceFields = validateInvoiceWtn({ setErrors, toast, state: state,t })
        const { vehicle: { vehiclePlate }, } = state;

        if (state.parties.transporter.idType) state.parties.transporter.idType = state.parties.transporter.idType.trim();
        if (state.parties.transporter.name) state.parties.transporter.name = state.parties.transporter.name.trim();
        if (state.parties.seller.name) state.parties.seller.name = state.parties.seller.name.trim();

        if (!new RegExp(/^([a-zA-Z0-9-]+)$/).test(vehiclePlate)) {
            toast.error(t(["toast.incorrectPlate"]), { containerId: "all_toast" });
        } else {
            setPageState((setPageState) => ({
                ...setPageState,
                loading: false,
                saving: false,
            }));
        }

        if (validateInvoiceFields) return
        if (!state.items.length) {
            toast.error("Items should be completed!", {
                containerId: "all_toast",
            });
            setPageState((setPageState) => ({
                ...setPageState,
                saving: false,
            }));
        } else {
            mainAxios.post("apiEndpoint/saveOrUpdate", {
                object: "CustomWTN",
                content: {
                    ...state,
                    status: invoiceStatuses[0],
                    itemsNum: state?.items.length
                },
                nuis: company.nuis
            }).then(res => {
                if (res?.data?.status === true) {
                    toast.success(t(['toast.draftSaved']), { containerId: "all_toast" })
                    // getWtnInvoice(res.data.content?.[0]?.id.toString())
                    setState({ ...state, status: res.data.content[0].status });
                }
                setPageState(setPageState => ({
                    ...setPageState,
                    saving: false
                }))
            }).catch(() => {
                setPageState(setPageState => ({
                    ...setPageState,
                    saving: false
                }))
            })
        }

    }

    const handleDelete = () => {
        setPageState(setPageState => ({
            ...setPageState,
            saving: true
        }))
        mainAxios.post("apiEndpoint/saveOrUpdate", {
            object: "CustomWTN",
            content: {
                ...state,
                status: invoiceStatuses[3]
            },
            nuis: company.nuis
        }).then(res => {
            if (res?.data?.status === true) {
                toast.success("Draft WTN was successfully deleted!", { containerId: "all_toast" })
                history.push("/view-wtn")
            }
            setPageState(setPageState => ({
                ...setPageState,
                saving: false
            }))
        }).catch(() => {
            toast.error("An error occurred! Please try again later.")
            setPageState(setPageState => ({
                ...setPageState,
                saving: false
            }))
        })
    }
  
    const handleDuplicateInvoice = () => {
        const validateInvoiceFields = validateInvoiceWtn({ setErrors, toast, state, t });
        if (validateInvoiceFields) return;
        setLoadingDuplicate(true);
        const {
          recordDate,
          recordUser,
          modificationDate,
          modificationUser,
          wtnOrderNum,
          wtnNum,
          wtnic,
          fwtnic,
          send,
          qrUrl,
          fiscStatus,
          errorMessage,
          einvoice,
          ...restInvoice
        } = state;
    
        const pathsToPreservId = ['items'];
        const cleanedRestInvoice = removePropertiesRecursive(restInvoice, pathsToPreservId);
        const currentDate = new Date();
    
        cleanedRestInvoice.status = {
          id: 7,
          status: "DRAFT",
        };
    
        cleanedRestInvoice.items = cleanedRestInvoice.items.map((items) => {
          const { id, ...restItems } = items;
          return restItems;
        })
        cleanedRestInvoice.address[0].date = currentDate;
        cleanedRestInvoice.address[1].date = null;
        cleanedRestInvoice.issueDate = currentDate;
        cleanedRestInvoice.internalId = null;

        if (cleanedRestInvoice.parties.transporter.idType) cleanedRestInvoice.parties.transporter.idType = cleanedRestInvoice.parties.transporter.idType.trim();
        if (cleanedRestInvoice.parties.transporter.name) cleanedRestInvoice.parties.transporter.name = cleanedRestInvoice.parties.transporter.name.trim();
        if (cleanedRestInvoice.parties.seller.name) cleanedRestInvoice.parties.seller.name = cleanedRestInvoice.parties.seller.name.trim();

        mainAxios
          .post("apiEndpoint/saveOrUpdate", {
            object: "CustomWTN",
            content: {
              ...cleanedRestInvoice,
              itemsNum: cleanedRestInvoice?.items.length,
            },
            nuis: company.nuis.trim(),
          })
          .then((res) => {
            if (res?.data?.status === true) {
                toast.success(t(["invoice.isInvoiceDuplicated"]), { containerId: "all_toast" });
                // Redirect to the newly duplicated invoice
                history.push(`/edit-wtn/${res.data.content[0].id}`);
            }
            setPageState((setPageState) => ({
              ...setPageState,
              saving: false,
            }));
            setLoadingDuplicate(false);
          })
          .catch(() => {
            setPageState((setPageState) => ({
              ...setPageState,
              saving: false,
            }));
          });
    };
    


    const handleApprove = () => {
        if (validateWtnInvoice()) {
            setPageState(setPageState => ({
                ...setPageState,
                saving: true
            }))
            mainAxios.post("apiEndpoint/saveOrUpdate", {
                object: "CustomWTN",
                content: {
                    // action: invoiceActions[0],
                    ...state,
                    status: invoiceStatuses[2], //Approved
                    itemsNum: state.items.length
                },
                nuis: company.nuis
            }).then(res => {
                if (res?.data?.status === true) {
                    setState(res.data.content[0])
                    toast.success(t(['toast.successApproval']), { containerId: "all_toast" })
                }
                setPageState(setPageState => ({
                    ...setPageState,
                    saving: false
                }))
            }).catch(() => {
                setPageState(setPageState => ({
                    ...setPageState,
                    saving: false
                }))
            })
        }
    }

    const validateWtnInvoice = () => {
        const invalidFields = [
            ...document.querySelectorAll([
                "[name='businUnitCode']",
                "[name='packNum']",
                "[name='valueOfGoods']",
                "[name='vehiclePlate']",
                "[name='idNum']",
                "[name='transporterName']",
                "[name='transporterAddress']",
                "[name='startDate']",
                "[name='startAddress']",
                "[name='destinationDate']",
                "[name='destinationAddress']"
            ])
        ].filter(element => !element.value)
        if (invalidFields.length > 0) {
            const invalidField = invalidFields[0]
            const label = document.querySelector(`label[for="${invalidField.name}"]`)
            label.scrollIntoView()
            invalidField.focus()
            toast.warning(`${label.innerText} is required!`, { containerId: "all_toast" })
            return false
        }
        else {
            const itemsInvalidField = state?.items.filter(item => {
                const { code, name, unitOfMeasureId, quantity } = item
                return !code || !name || unitOfMeasureId == null || !quantity
            })
            //if(state.items.length === 0 || itemsInvalidField.length > 0) {
            if (itemsInvalidField.length > 0) {
                // document.querySelector("#items").scrollIntoView()
                toast.warning("You must have at least 1 item and all fields need to be completed!", { containerId: "all_toast" })
                return false
            }
        }

        const { vehicle: { vehiclePlate } } = state
        if (!new RegExp(/^([a-zA-Z0-9-]+)$/).test(vehiclePlate)) {
            toast.error(t(['toast.incorrectPlate']), { containerId: "all_toast" })
            return false;
        }

        if(moment(state?.address?.[0]?.date).isSameOrAfter(state.address?.[1]?.date)){
            toast.error(t(['invoice.desitnationDateWarning']), { containerId: "all_toast" })
            return 
        }

        return true
    }

    const showWtnPdf = () => {
        setLoadingPdf(true)
        mainAxios.post('apiEndpoint/search', {
            object: "GetWtnPDF",
            params: {
                wtnic: state.wtnic
            },
        }).then(res => {
            const blob = func.blobToFile(func.b64toBlob(res.data.content[0], "application/pdf"), "Wtn Invoice")
            setPdfToDisplay(URL.createObjectURL(blob))
            setTimeout(() => {
                pdfContainer.current.classList.toggle('open')
                setLoadingPdf(false)
            }, 0)
        })
    }


    let buttons = (
        <div className='mb-2'>
              { state?.status?.status !== "ERROR" &&
             <button
             onClick={handleDuplicateInvoice}
             className="btn btn-sm btn-outline-primary mr-3"
             >
            {loadingDuplicate ? <FontAwesomeIcon className='mr-1' size='lg' icon={faSpinner} spin /> :  <FontAwesomeIcon className="mr-1" icon={faCopy} />}
                {t("invoice.duplicate")}
             </button>
            }
            <Link to="/create-wtn" className={`${false ? 'btn btn-sm btn-info' : 'btn btn-sm btn-info'} mr-3`}>
                {t('navbar.create')}
            </Link>
            {state?.status?.status !== "DELETED" && <button
                className={`${false ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-sm btn-danger'} mr-3`}
                disabled={pageState.saving}
                style={{ minWidth: "80px" }}
                onClick={handleDelete}>
                {pageState.saving ? <Loader size="xs" color="white" /> : t(['common.delete'])}
            </button>}
            <button
                className={`${false ? 'btn btn-sm btn-success' : 'btn btn-sm btn-sm btn-success'} mr-3`}
                disabled={pageState.saving}
                style={{ minWidth: "80px" }}
                onClick={handleSave}>
                {pageState.saving ? <Loader size="xs" color="white" /> : t(['common.save'])}
            </button>
            <button
                className={`${false ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-sm btn-primary'} ml-3`}
                disabled={pageState.saving}
                style={{ minWidth: "80px" }}
                onClick={handleApprove}>
                {pageState.saving ? <Loader size="xs" color="white" /> : t(['status.approve'])}
            </button>

        </div>
    )

    let badgeClass = "secondary"
    let statusLabel = "Draft"

    if (state && state?.status?.status === "APPROVED") {
        buttons = (
            <div className="d-flex justify-content-center">
            <div className="actions mr-3">
                <button
                    onClick={handleDuplicateInvoice}
                    className="btn btn-sm btn-outline-primary"
                >
                   {loadingDuplicate ? <FontAwesomeIcon className='mr-1' size='lg' icon={faSpinner} spin /> :  <FontAwesomeIcon className="mr-1" icon={faCopy} />}
                   {t("invoice.duplicate")}
                </button>
            </div>
            <Link to="/create-wtn" className="btn btn-sm mb-2 btn-info">
                {t('navbar.create')}
            </Link>
        </div>
        )
        badgeClass = "success"
        statusLabel = "Approved"
    }
    // else if (state && state?.status?.status === "DELETED") {
    //     buttons = (
    //         <Link to="/create-wtn" className="btn btn-sm btn-info">
    //            {t('navbar.create')}
    //         </Link>
    //     )
    //     badgeClass = "danger"
    //     statusLabel = "Deleted"
    // }

    const canEdit = state && state?.status?.status !== "APPROVED"

    return (
        !pageState.loading ?
            <div className="row">
                <div className={`${false ? "col-xl-10" : "col-xl-12"} col-12 mx-auto wizard-form`}>
                    {
                        false ?
                            <div className="mt-2 mb-2 d-flex align-items-center justify-content-between">
                                <h3 className="mb-0 d-flex gap-8">
                                    <div>
                                        Edit WT Note
                                        <small className={`badge badge-${badgeClass} ml-2`}>{statusLabel}</small>
                                        {
                                            !state.cisStatus ? null
                                                :
                                            state.cisStatus === "SUCCESS" ? <small className="badge badge-success ml-2">Success</small>
                                                :
                                                state.cisError ?
                                                    <small className="badge badge-danger d-inline-flex align-items-center ml-2" data-tip={state.cisError}>
                                                        Error
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />
                                                    </small>
                                                    :
                                                    <small className="badge badge-danger ml-2">
                                                        Error
                                                </small>
                                        }
                                    </div>

                                    <div>
                                    </div>
                                </h3>
                                {/* If Invoice is approved, extra information will be shown */}
                                {(state.wtnic || state.fwtnic) &&
                                    <div className="card border-0 shadow card-accordion">
                                        {  state.status.status != "ERROR" &&
                                            <Accordion
                                                heading={<>
                                                    {state.wtnic &&
                                                        <button
                                                            className="btn btn-sm btn-primary ml-3"
                                                            disabled={loadingPdf}
                                                            style={{ minWidth: "80px" }}
                                                            onClick={e => { e.stopPropagation(); showWtnPdf() }}>
                                                            {loadingPdf ? <Loader size="xs" color="white" /> : <><FontAwesomeIcon icon={faFilePdf} className="mr-1" /> Print</>}
                                                        </button>
                                                    }
                                                    {
                                                        state.qrUrl &&
                                                        <a href={state.qrUrl} onClick={e => e.stopPropagation()} className="btn btn-sm btn-info d-flex-align-items-center ml-3" target="_blank">
                                                            QR Url
                                                        </a>
                                                    }
                                                </>}
                                            >
                                                <div style={{ padding: "10px 20px 20px" }}>
                                                    <p className='d-flex alight-items-start'>
                                                        <strong>WTNIC: </strong> {state.wtnic}
                                                    </p>
                                                    <p className='d-flex alight-items-start'>
                                                        <strong>FWTNIC: </strong> {state.fwtnic}
                                                    </p>
                                                </div>
                                            </Accordion>
                                            }
                                    </div>
                                }
                                {
                                    false ? <div className="actions d-flex align-items-center justify-content-end">

                                        {buttons}
                                    </div> :
                                        null
                                }

                            </div>

                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="p-1">
                                    {/* If Invoice is approved, extra information will be shown */}
                                    {(state?.wtnic || state?.fwtnic) &&
                                        <div className="card border-0 shadow card-accordion">
                                           {  state.status.status != "ERROR" &&
                                            <Accordion
                                                heading={<>
                                                    {state?.wtnic &&
                                                        <button
                                                            className="btn btn-sm btn-primary ml-3"
                                                            disabled={loadingPdf}
                                                            style={{ minWidth: "80px" }}
                                                            onClick={e => { e.stopPropagation(); showWtnPdf() }}>
                                                            {loadingPdf ? <Loader size="xs" color="white" /> : <><FontAwesomeIcon icon={faFilePdf} className="mr-1" /> Print</>}
                                                        </button>
                                                    }
                                                    {
                                                        state.qrUrl &&
                                                        <a href={state.qrUrl} onClick={e => e.stopPropagation()} className="btn btn-sm btn-info d-flex-align-items-center ml-3" target="_blank">
                                                            QR Url
                                                        </a>
                                                    }
                                                </>}
                                            >
                                                <div style={{ padding: "10px 20px 20px" }}>
                                                    <p className='d-flex alight-items-start'>
                                                        <strong>WTNIC: </strong> {state.wtnic}
                                                    </p>
                                                    <p className='d-flex alight-items-start'>
                                                        <strong>FWTNIC: </strong> {state.fwtnic}
                                                    </p>
                                                </div>
                                            </Accordion>
                                            }
                                        </div>
                                    }
                                    {
                                        false ? <div className="actions d-flex align-items-center justify-content-end">
                                            {buttons}
                                        </div> :
                                            null
                                    }

                                </div>
                            </div>
                    }

                    {
                        false ?
                            <WTN wtnState={state} setWtnState={setState} canEdit={canEdit} />
                            :
                            <DemoWtn wtnState={state} errors={errors} setErrors={setErrors} setWtnState={setState} canEdit={canEdit} buttons={buttons} isEdit={true}/>
                    }
                    {
                        false ?
                            <div className="d-flex justify-content-end">
                                {buttons}
                            </div>
                            :
                            null
                    }

                </div>
                <div className="pdf-container" ref={pdfContainer}>
                    {pdfToDisplay &&
                        <div className="pdf-viewer">
                            <div className="pdf-overlay" onClick={() => { setTimeout(() => { setPdfToDisplay(null) }, 150); pdfContainer.current.classList.toggle('open') }} />
                            <div className="pdf-content">
                                <embed src={pdfToDisplay} />
                            </div>
                        </div>
                    }
                </div>
                <ReactTooltip />
            </div>
            :
            <Loader />
    )
}

export default EditWTNInvoice
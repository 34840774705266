import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import mainAxios from '../../../services/mainAxios'
import Loader from '../../Layout/Loader'
import { useTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle from "../../../design/selectStyle";
import ReactSelect from '../../ReactSelect'
import SelectInput from '../../SelectInput'
import func from '../../../services/functions'


const RegisterEditTcr = ({ tcr,company, onTcrChanged }) => {
  const isEdit = !!tcr
  const { t } = useTranslation('translations');
  const [state, setState] = React.useState(tcr ?? {
    branch: null,
    allowPurchase: true,
    allowInvoice: true,
    isTechnical: false,
    company: null,
    tcrCode: "",
    tcrIntID: "",
    operatorCode: "",
    type: "REGULAR",
    validFrom: new Date(),
    validTo:""
  })


  const TcrType = [

    { label: "Regular", value: "REGULAR" },
    { label: "Vending", value: "VENDING" },
  ]
  const [loading, setLoading] = React.useState(false)


  const generateTCR = (e) => {
    e.preventDefault()
    setLoading(false)
    let content = {
      value: null,
      tcrCode: state?.tcrCode,
      tcrIntID: state?.tcrIntID,
      operatorCode: state?.operatorCode,
      type: state?.type,
      issuerNUIS: company?.nuis,
      businUnitCode: state?.branch?.businUnitCode,
      maintainerCode: process.env.REACT_APP_MAINTAINER_CODE,
      validFrom: state?.validFrom,
      validTo: state?.validTo,
      company: company.id,
      branch: state?.branch?.id,
      allowPurchase: state?.allowPurchase,
      allowInvoice: state?.allowInvoice,
      isTechnical: state?.isTechnical,
    }

    // "id": 328,
    // "value": null,
    // "businUnitCode": "nz461vo187",
    // "allowInvoice": true,
    // "allowPurchase": true,
    // "tcrCode": "ch226pz644",
    // "nextNumberId": 2712,
    // "nextNumber": 1,
    // "lastInvoiceNumber": "1/2023/ch226pz644",
    // "lastIic": "AC63424CE514CBF718E15E8BEE059600",
    // "year": 2024
    if(isEdit){
      const {  id=null,status,businUnitCode=null,tcrCode=null,allowInvoice=null,allowPurchase=null,nextNumberId,nextNumber,lastInvoiceNumber,year,isTechnical
      } = state

      content= {id,value:null,businUnitCode,allowInvoice,allowPurchase,tcrCode,nextNumberId,nextNumber,lastInvoiceNumber,year,status,isTechnical}
    }


    mainAxios.post('apiEndpoint/saveOrUpdate', {
      object: isEdit?"UpdateTCR" :"RegisterTCR",
      content,
      nuis: company.nuis
    }).then(res => {
      setLoading(false)
      if (res.data.status) {
        toast.success(isEdit? t("toast.tcrUpdated"): t(['toast.TCRregistered']), { containerId: "all_toast" })
        onTcrChanged()
      }
    })
      .catch(err =>{console.log({err}); setLoading(false)})
  }

  const handleInputChange = (e, label, val,trim=false) => {
    const key = e ? e?.target?.name : label
    let value = e ? e?.target?.value : val
    if(trim) value=value?value.trim():""

    setState(state => ({
      ...state,
      [key]: value
    }))
  }

  return (
    <form onSubmit={generateTCR}>
      <div className="row m-0 p-0">
        {!isEdit && <div className="col-12 mb-4">
          <span className="alert alert-warning">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            {t('company.TCREmpty')}
          </span>
        </div>}
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="branch">{t('invoice.branch')}</label>
            <SelectInput disabled={isEdit} value={state?.businUnitCode}>
              <ReactSelect
                name="branch"
                id="branch"
                required
                styles={selectStyle}
                value={state.branch}
                getOptionValue={(opt) => opt.businUnitCode}
                getOptionLabel={(opt) => opt.businUnitCode + (opt?.sellerName ? " - " + opt?.sellerName : "")}
                options={company?.branches ?? []}
                onChange={(type) =>
                  handleInputChange(null, "branch", type)
                }
            />
            </SelectInput>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="tcrCode">{t('company.tcrTypes')}</label>
            <input
              type="text"
              className="form-control"
              name="tcrCode"
              value={state.tcrCode}
              onChange={(e)=>handleInputChange(null,"tcrCode",e.target.value,true)} 
              disabled={isEdit}
              />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="tcrIntID">{t('company.TCRIntId')}</label>
            <input
              type="text"
              className="form-control"
              name="tcrIntID"
              value={state.tcrIntID}
              onChange={handleInputChange}
              required 
              disabled={isEdit}
              />
          </div>
        </div>
{/* 
    {isEdit &&  <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="operatorCode">{t('company.operatorCode')}</label>
            <input
              type="text"
              className="form-control"
              name="operatorCode"
              value={state.operatorCode}
              onChange={handleInputChange}
              disabled={isEdit}
            />
          </div>
        </div>} */}

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="type">{t('subscription.type')}</label>
            <SelectInput disabled={isEdit} value={state?.type}>
              <Select
                name="type"
                id="type"
                styles={selectStyle}
                value={TcrType.find((type) => type.value == state?.type)}
                options={TcrType}
                onChange={(type) =>
                  handleInputChange(null, "type", type.value)
                }
              />
            </SelectInput>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="sellerTown">{t('subscription.validFrom')}

            </label>
            <DatePicker
              selected={state?.validFrom ?? ""}
              dateFormat="dd/MM/yyyy"
              onChange={date => handleInputChange(null, "validFrom", new Date(date.setHours(2, 0, 0, 0)))}
              customInput={<input className="form-control" required />} 
              disabled={isEdit}
              />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="validTo">
              {t('subscription.validTo')} 
            </label>
            <DatePicker
              selected={state?.validTo ?? ""}
              dateFormat="dd/MM/yyyy"
              onChange={date => handleInputChange(null, "validTo", new Date(date.setHours(2, 0, 0, 0)))}
              customInput={<input className="form-control" required />} 
              disabled={isEdit}
              />
          </div>
        </div>
        {isEdit && 
          <>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="nextNumber">{t("selfServices.lastInvoiceNumber")}</label>
                <input
                  onKeyDown={(event) => { if (event.key === '.') { event.preventDefault(); } }}
                  type="text"
                  className="form-control"
                  id="lastInvoiceNumber"
                  name="lastInvoiceNumber"
                  disabled
                  value={state?.lastInvoiceNumber ?? ""}
                  onChange={(e) => handleInputChange(null,"lastInvoiceNumber", e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="nextNumber">{t('company.nextNumber')}</label>
                <input
                  onKeyDown={(event) => { if (event.key === '.') { event.preventDefault(); } }}
                  className="form-control"
                  id="nextNumber"
                  name="nextNumber"
                  value={state?.nextNumber ?? ""}
                  onChange={(e) => {
                    let value = e.target.value
                    if(func.isNumeric(value)){
                      value=parseInt(value)
                    }else if(value=="" || value==null){
                      value=""
                    }else{
                      return
                    }
                    handleInputChange(null, "nextNumber", value)
                  }} />
              </div>
            </div>
          </>
        }

        <div className="col-md-4 d-flex align-items-center">
          <div className="custom-control custom-switch mt-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"profiscActive"}
              checked={state.status===100}
              onChange={(e) =>
                handleInputChange(null, "status", e.target.checked?100:-100)
              }
            />
            <label
              className="custom-control-label"
              htmlFor={"profiscActive"}
            >
              {t("selfServices.profiscActive")}
            </label>
          </div>


        </div>
        <div className="col-md-4 d-flex align-items-center">
          <div className="custom-control custom-switch mt-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"allowPurchase"}
              checked={state?.allowPurchase}
              onChange={(e) =>
                handleInputChange(null, "allowPurchase", e.target.checked)
              }
            />
            <label
              className="custom-control-label"
              htmlFor={"allowPurchase"}
            >
              Allow Purchase
            </label>
          </div>
        </div>

        <div className="col-md-4 d-flex align-items-center">
          <div className="custom-control custom-switch mt-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"allowInvoice"}
              checked={state?.allowInvoice}
              onChange={(e) =>
                handleInputChange(null, "allowInvoice", e.target.checked)
              }
            />
            <label
              className="custom-control-label"
              htmlFor={"allowInvoice"}
            >
              Allow Invoice
            </label>
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-center">
        <div className="custom-control custom-switch mt-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"isTechnical"}
              checked={state?.isTechnical}
              onChange={(e) =>
                handleInputChange(null, "isTechnical", e.target.checked)
              }
            />
            <label
              className="custom-control-label"
              htmlFor={"isTechnical"}
            >
              Technical
            </label>
          </div>
        </div>

      </div>


      <button
        className="btn btn-primary mt-3"
        disabled={loading}
        style={{ minWidth: "100px" }}
        type="submit">
        {loading ? <Loader size="xs" color="white" /> :
          (<>
            <FontAwesomeIcon icon={faCheck} className="mr-2" />{isEdit?t("selfServices.editTcr") :t('navbar.create')}
          </>)}
      </button>
    </form>
  )
}

export default RegisterEditTcr
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAppContext, usePersistedState } from "../../AppContext";
import func, { swalAlert, validateInvoice } from "../../services/functions";
import mainAxios from "../../services/mainAxios";
import Loader from "../Layout/Loader";
import invoiceActions from "../../services/invoiceActions.json";
import invoiceStatuses from "../../services/invoiceStatuses.json";
import countries from "../../services/countries.json";
import UploadInvoice from "./Upload";
import { useTranslation } from "react-i18next";
// import swal from "@sweetalert/with-react";
import AlertCertificate from "../global/AlertCertificate";
import AlertSubscription from "../global/AlertSubscription";
import moment from "moment/moment";
import { Card, } from "react-bootstrap";
import DemoInvoiceFiscalization from "./DemoWizardSteps/DemoInvoiceFiscalization";
import DemoInvoiceDatas from "./DemoWizardSteps/DemoInvoiceDatas";
import DemoInvoiceTotals from "./DemoWizardSteps/DemoInvoiceTotals";
import PopOver from "../global/PopOver";
import DemoTable from "./DemoWizardSteps/DemoTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import {
  faRemove,
  faInfo,
  faSpinner,
  faAngleDoubleRight,
  faFileInvoice,
  faChevronRight,
  faArrowCircleRight,
  faCircleArrowRight,
  faChevronCircleRight,
  faClone,
  faChevronLeft,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import DemoInvoiceTabsNAT from "./DemoWizardSteps/DemoInvoiceTabsNAT";
import Swal from "sweetalert2";
import DemoSideMenu from "./DemoWizardSteps/DemoSideMenu";
import DemoParties from "./DemoWizardSteps/DemoParties";
import { buyerIDTypes } from "./WizardSteps/data";

const CreateInvoice = (props, { disabled }) => {
  const { t } = useTranslation("translations");

  const {
    handleShowVersion,
    showVersion: showVersion,
    loadingVersion: loadingVersion,
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany],
  } = useAppContext();

  const company = user.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );
  const [visibleUpload, setVisibleUpload] = useState(false)
  const [visible, setVisible] = useState(false);
  const [sideBarVisible, setSideBarVisible] = usePersistedState("true", "sideBar1")
  const [invoiceAutosave, setInvoiceAutosave] = usePersistedState("false", "invoiceAutosave")
  const invoiceAutosaveRef = useRef()
  const [pageState, setPageState] = React.useState({
    bankAccounts: [],
    loading: true,
  });

  React.useEffect(() => {
    if (fullCompany?.id) {
      setPageState((pageState) => ({
        ...pageState,
        bankAccounts: fullCompany.bankAccounts || [],
        loading: false,
      }));
    }
  }, [fullCompany]);


  const stateDefault = {
    parties: {
      buyer: {
        tin: "",
        name: "",
        address: "",
        country: "Albania",
        town: "",
        buyerIDType: "NUIS",
      },
      seller: {
        tin: "",
        address: "",
        country: "",
        town: "",
        name: "",
        sellerIDType: "NUIS",
      },
    },
    customer: {
      tin: "",
      name: "",
      address: "",
      country: "ALB",
      town: "",
      type: "1",
    },
    type: process.env.REACT_APP_EINVOICE_CODE,
    correctiveInvType: "",
    profileId: "P1",
    number: "",
    issueDate: new Date(),
    dueDate: new Date(),
    invoiceStartDate: "",
    invoiceEndDate: "",
    discount: {
      type: t(["invoice.percentage"]),
      value: "",
      vat: company.issuerInVat ? "20" : "0",
      vatLabel: null,
    },
    bankAccount: null,
    attachments: [],
    currency: "ALL",
    rate: "1",
    contract: "",
    info: "",
    invoiceTotalWithoutVat: "0",
    invoiceTotalDiscount: "0",
    invoiceDiscountedTotalWithoutVat: "0",
    invoiceTotalVat: "0",
    invoiceTotalWithVat: "0",
    tcrCode: "",
    businessUnitCode: "",
    opCode: user?.userCompanyOperator?.find(
      (uco) => parseInt(uco.company) === parseInt(selectedCompany)
    )?.operator,
    paymentMethodType: "noncash",
    //means einvoice included
    einvoice: true,
    paymentMeans: "ZZZ",
    internalId: "",
    typeOfSelfInvoice: "",
    selfInvoice: false,
    reverseCharge: false,
    iic_ref: "",
    refInvIIC: "",
    refInvIssueDateTime: "",
    hasTransport: false,
    transporter: "",
    vehiclePlate: "",
    destinationAddress: "",
    correctionOfSelfInvoice: false,
    items: [
      {
        name: "",
        code: "",
        description: "",
        unitOfMeasureId: null,
        quantity: "1",
        unitPrice: "",
        priceWithoutVat: "",
        priceWithVat: "",
        discountValue: "",
        discountType: "Percentage",
        vatCategory: company.issuerInVat ? "20" : "0",
        vatLabel: null,
        investment: false,
        totalWithoutVat: "",
        totalWithVat: "",
      },
    ],
    subSequenceDelivType: "",
    param1: "",
    param2: "",
    // param3:"",
    // param4:"",
    paymentNote: "",
    paymentMethod: [
      {
        paymentMethodType: "noncash",
        paymentMeans: "ZZZ",
        paymentAmount: "",
        company: selectedCompany,
        paymentMeansValue: "OTHER"
      }
    ]
  }

  const [state, setState] = React.useState(stateDefault);

  const lastStateRef = useRef();
  const lastStateHelper = useRef();



  React.useEffect(() => {
    const branch =
      company.branches.find((branch) => branch.default) || company.branches[0];
    let businessUnitCode = branch?.businUnitCode ?? "";
    let tcrCode =
      branch.tcrTypes.find(
        (tcrType) =>
          tcrType.businUnitCode === businessUnitCode && tcrType?.allowInvoice
      )?.tcrCode || "";
    const seller = {
      tin: company.nuis,
      address: branch.sellerAddress,
      country:
        countries.find((country) => country.label === branch.sellerCountry)
          ?.value || "Albania",
      town: branch.sellerTown,
      name: company.name,
      sellerIDType: "NUIS",
    };
    setState((invoiceState) => {
      const content = {
        ...invoiceState,
        parties: {
          ...invoiceState.parties,
          seller,
        },
        businessUnitCode,
        tcrCode,
      }
      lastStateRef.current = content
      return content
    });
  }, [selectedCompany]);

  const [loading, setLoading] = React.useState(false);

  const tinTypes = [
    { label: "NUIS", value: "1" },
    { label: "ID", value: "2" },
    { label: "PASS", value: "3" },
    { label: "VAT", value: "4" },
    { label: "TAX", value: "5" },
    { label: "SOC", value: "6" },
  ];
  const [errors, setErrors] = useState({});


  const issueDateAlert = () => {
    if (state.subSequenceDelivType == '') return;
    if (moment().diff(state.issueDate, "days") > 0) {
      return Swal.fire({
        title: t(['notification.subseqNotification']),
        icon: 'warning',
        width: '800px',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleInvoice = async ({ autosave, autoSaveState }) => {
    let newState = autoSaveState ?? state
    let res = null
    if (!autoSaveState) {
      res = await issueDateAlert()
    }

    if (res && res?.isDismissed) return;
    newState = func.validateMultiPayment({ state: newState, toast })
    if (!newState) return
    const {
      type,
      hasTransport,
      transporter,
      vehiclePlate,
      destinationAddress,
      invoiceStartDate,
      businessUnitCode,
      tcrCode,
      invoiceEndDate,
      issueDate,
      dueDate,
      typeOfSelfInvoice,
      internalId,
      parties: { buyer, seller },
      rate,
      ...rest
    } = newState;

    let customer = newState.customer;
    let party = newState.profileId == "P12" ? seller : buyer;
    let { name, town, address, country, tin } = party;
    let typeLabel =
      newState.profileId == "P12" ? party.sellerIDType : party.buyerIDType;
    country =
      countries.find((country) => country.value === party?.country)
        ?.shortCode ?? "ALB";

    if (
      newState?.customer?.tin?.length == 0 ||
      (newState?.customer?.tin?.length != 0 &&
        newState?.profileId == "P12" &&
        newState?.customer?.tin != seller?.tin)
    ) {
      customer = {
        name,
        town,
        address,
        country,
        tin,
        type: tinTypes.find((tinType) => tinType.label === typeLabel).value,
      };
    }

    const validateInvoiceFields = validateInvoice({ setErrors, toast, state: newState, setVisible, autoSaveState })
    if (!validateInvoiceFields) return
    if (newState.reverseCharge && newState.typeOfSelfInvoice !== "3" && !autoSaveState) {
      const res=await swalAlert({title:t("toast.youWantToProceed"),text:t("toast.reverseChargeCheck"),t})
      if(!res) return
    } 
    if (
      buyer.buyerIDType === "NUIS" &&
      !new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyer.tin) && !autoSaveState
    ) {
      toast.error(t(["toast.incorrectNiptForBuyer"]), {
        containerId: "all_toast",
      });
      //setInputClass("error")
    } else if (type == "389" && typeOfSelfInvoice === "" && !autoSaveState) {
      toast.error(t(["toast.typeOfSelfInvoiceEmpty"]), {
        containerId: "all_toast",
      });
    } else if (
      seller.tin === buyer.tin &&
      newState.profileId != "P10" &&
      newState.profileId != "P12" && !autoSaveState
    ) {
      toast.error(t(["toast.diffNipt"]), { containerId: "all_toast" });
    } else if (
      newState.paymentMeans === "FACTORING" &&
      newState.bankAccount === null && !autoSaveState
    ) {
      toast.error(t(["toast.bankAccount"]), { containerId: "all_toast" });
    } else {
      setLoading(false);

      if (newState.businessUnitCode) newState.businessUnitCode = newState.businessUnitCode.trim();
      if (newState.opCode) newState.opCode = newState.opCode.trim();
      if (newState.parties.buyer.name) newState.parties.buyer.name = newState.parties.buyer.name.trim();
      if (newState.parties.seller.name) newState.parties.seller.name = newState.parties.seller.name.trim();

      const content = {
        action: invoiceActions[0],
        status: invoiceStatuses[0],
        ...func.parseCustomInvoiceRequest({
          ...newState,
          parties: {
            ...newState.parties,
          },
          customer,
          version: showVersion ? 1 : 2
        }),
      };
      let loadingToastId = null
      if (autoSaveState) {
        loadingToastId = toast.loading("Auto Saving...", {
          containerId: "all_toast",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        })
      }
      mainAxios
        .post("endpoint/v2.2/saveOrUpdate", {
          object: "CustomInvoice",
          content,
          nuis: company.nuis.trim(),
        })
        .then((res) => {
          if (loadingToastId) {
            setTimeout(() => {
              toast.dismiss(loadingToastId)
            }, 2000)
          }
          if (res?.data?.status === true) {
            if (!autoSaveState) {
              toast.success(t(["toast.draftSaved"]), {
                containerId: "all_toast",
              });
            }

            if (autosave) {
              const parsedInvoice = func.parseCustomInvoiceResponse(
                res.data.content[0]
              );
              parsedInvoice.paymentMethod = parsedInvoice?.paymentMethod?.map(payment => {
                return {
                  ...payment,
                  customInvoice: parsedInvoice.id
                }
              }) ?? []


              lastStateRef.current = parsedInvoice
              lastStateHelper.current = { saved: true, date: moment().format("HH:mm:ss") }

              setState(parsedInvoice)
            } else {
              props.history.push("/edit-invoice/" + res.data.content[0].id);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          if (loadingToastId) toast.dismiss(loadingToastId)
          setLoading(false);
        });
    }
  };

  const stateRef = useRef();

  useEffect(() => {
    invoiceAutosaveRef.current = invoiceAutosave
  }, [invoiceAutosave])

  useEffect(() => {
    stateRef.current = state
  }, [state])

  useEffect(() => {
    if (invoiceAutosave !== "1") return
    const interval = setInterval(() => {
      if (invoiceAutosaveRef.current !== "1") return
      const isEqual = JSON.stringify(stateRef.current) === JSON.stringify(lastStateRef.current)
      if (!isEqual) {
        handleInvoice({ autosave: true, autoSaveState: stateRef.current })
      }

    }, 30000)

    return () => clearInterval(interval);

  }, [invoiceAutosave])



  const handleInvoiceTcrCodeChange = (tcrCode) => {
    setState((invoiceState) => ({
      ...invoiceState,
      tcrCode,
    }));
  };

  const handleSelfInvoiceTypeChange = (key, value) => {
    setState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  const handleSelfInvoiceChange = (e) => {
    const value = e.target.checked;

    setState((invoiceState) => ({
      ...invoiceState,
      selfInvoice: value,
    }));
    if (value === false) {
      setState((invoiceState) => ({
        ...invoiceState,
        typeOfSelfInvoice: "",
        reverseCharge: false,
      }));
    }
  };

  const handleReverseChargeChange = (e) => {
    const value = e.target.checked;
    setState((invoiceState) => ({
      ...invoiceState,
      reverseCharge: value,
    }));
  };

  const handlePaymentMethodTypeChange = (newPaymentMethodType) => {
    const { paymentMethodType, paymentMeans } = state;

    let newPaymentMeans = "";

    if (paymentMethodType === "noncash") newPaymentMeans = "10";
    else newPaymentMeans = "ZZZ";
    setState((invoiceState) => ({
      ...invoiceState,
      paymentMethodType: newPaymentMethodType,
      paymentMeans: newPaymentMeans,
    }));
  };

  const handlePaymentMeansChange = (key, value) => {
    setState((invoiceState) => ({
      ...invoiceState,
      [key]: value,
    }));
  };

  // const openUploadInvoice = () => {
  //     return <UploadInvoice
  //       user={user}
  //       selectedCompany={selectedCompany}
  //       swal={swal}
  //       visibleUpload={visibleUpload}
  //       setVisibleUpload={setVisibleUpload}
  //     />
  // };

  //   only fiscalization function
  function changeOnlyFisc() {
    if (!state.einvoice) {
      setState((invoiceState) => ({
        ...invoiceState,
        einvoice: true,
        parties: {
          ...invoiceState.parties,
          buyer: {
            ...invoiceState.parties.buyer,
            buyerIDType: "NUIS",
          },
        },
        paymentMethodType: "noncash",
        paymentMeans: "ZZZ",
      }));
    } else {
      Swal.fire({
        title: t(["notification.einvoice"]),
        icon: "warning",
        width: "800px",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value == true) {
          const buyerIDType = buyerIDTypes.find((type) => type.value == state?.customer?.type)?.label ?? 'ID';
          setState((invoiceState) => ({
            ...invoiceState,
            einvoice: false,
            parties: { ...state.parties, buyer: { ...state?.parties?.buyer, buyerIDType } }
          }));
        }
      });
    }
  }
  // ////////////////////

  const handleInvoiceChange = (value, key) => {
    const keys = key.split(".");

    switch (keys.length) {
      case 1:
        setState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: value,
        }));
        break;
      case 2:
        setState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: value,
          },
        }));
        break;
      case 3:
        setState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: {
              ...invoiceState[keys[0]][keys[1]],
              [keys[2]]: value,
            },
          },
        }));
        break;
      default:
        return;
    }
  };
  const sideBarToggle = ({ ...props } = {}) => (
    <div className="side-bar-handle" style={{ ...props }}>
      <div className="side-bar-handle-content" onClick={() => { setSideBarVisible((barVisible) => barVisible === "false" ? "true" : "false") }}>
        {/* <img src={sideMenuIcons} alt="side"/> */}
        <FontAwesomeIcon icon={sideBarVisible === "true" ? faChevronRight : faChevronLeft} className="" />
      </div>
    </div>
  )

  return !pageState.loading ? (
    <div>
        <div className="demoInvoice">
          <div>
            <div className="row">
              <div className={`col-12 col-md-${sideBarVisible === "true" ? "10" : "12"}`} style={{ position: "relative" }}>
                <Card className="p-xl-2">
                  <div className="d-flex  justify-content-between align-items-center">
                    <div className="d-flex align-items-center">

                      {/* {showBanner && (
                          <div className="d-flex justify-content-center align-items-center mr-4">
                            <button
                              className="btn-sm btn-success w-100"
                              onClick={handleShowVersion}
                              disabled={loadingVersion}
                            >
                              <FontAwesomeIcon className="mr-2" icon={faFileInvoice} />
                              <span style={{ fontSize: "13px" }}>
                                {t("invoice.alertInvoiceOld")}
                                {loadingVersion ? (
                                  <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner} />
                                ) : (
                                  <FontAwesomeIcon className="ml-2 pulse-icon" icon={faAngleDoubleRight} style={{ verticalAlign: 'middle' }} />
                                )}
                              </span>
                            </button>
                          </div>
                        )} */}

                      <div className="custom-control custom-checkbox mt-1 mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isEinvoice"
                          name="isEinvoice"
                          checked={!state.einvoice}
                          onChange={changeOnlyFisc}
                          disabled={state.type == 389 || disabled}
                        />
                        <label
                          style={{ paddingTop: "3px", paddingLeft: "2px" }}
                          htmlFor="isEinvoice"
                          className="custom-control-label"
                        >
                          {t("parameters.onlyFiscalization")}
                          <PopOver title={t("hints.onlyFiscalization")} />
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mt-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="sumInv"
                          name="sumInv"
                          checked={state.sumInv}
                          onChange={(e) =>
                            handleInvoiceChange(e.target.checked, "sumInv")
                          }
                          disabled={disabled}
                        />
                        <label
                          style={{ paddingTop: "3px", paddingLeft: "2px" }}
                          htmlFor="sumInv"
                          className="custom-control-label"
                        >
                          {t("invoice.summaryInvoice")}
                          <PopOver title="Zgjidhni kte opsion ne qofte se fature eshte permbledhese" />
                        </label>
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center ml-3 mt-1">
                        <div className="custom-control custom-switch d-flex align-items-center" >
                          <input
                            type="checkbox"
                            id="invoice_autosave"
                            className="custom-control-input"
                            checked={invoiceAutosave === "1"}
                            onChange={(event) => {
                              setInvoiceAutosave(event.target.checked ? "1" : "0")
                            }}
                          />
                          <label className="custom-control-label " htmlFor="invoice_autosave" style={{ paddingTop: "2px", paddingLeft: "2px" }}>
                            {t("invoice.autoSave")}
                            <PopOver title={t("invoice.autoSaveDesc")} />

                          </label>

                        </div>
                        {lastStateHelper?.current?.date && <div style={{ fontSize: 12, fontWeight: 700 }}>
                          {t("invoice.lastAutosaved")}: {lastStateHelper?.current?.date ?? ""}
                        </div>}
                      </div>

                    </div>
                    <div>




                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center mr-3">
                        <AlertCertificate />
                        <AlertSubscription />
                      </div>


                      <div className="actions mr-3">
                        <button
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => setVisibleUpload(true)}
                        >
                          {t("navbar.uploadInvoice")}
                        </button>
                        <button
                          className="btn btn-sm btn-success ml-3"
                          disabled={loading}
                          style={{ minWidth: "80px" }}
                          onClick={handleInvoice}
                        >
                          {loading ? (
                            <Loader size="xs" color="white" />
                          ) : (
                            t(["common.save"])
                          )}
                        </button>
                      </div>
                    </div>
                    <Dialog
                          header={t("invoice.upload")}
                          visible={visibleUpload}
                          onHide={() => setVisibleUpload(false)}
                          style={{ width: "30vw" }}
                          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                        >
                        <UploadInvoice
                          user={user}
                          selectedCompany={selectedCompany}
                          setVisibleUpload={setVisibleUpload}
                        />
                    </Dialog>
                  </div>

                  <div className="my-2 border-top border-success"></div>

                  <div className="row">
                    {/*  type  */}
                    <div className="col-12 col-md-4 border-right h-100">
                      <DemoInvoiceFiscalization
                        handleInvoiceTcrCodeChange={handleInvoiceTcrCodeChange}
                        handleSelfInvoiceChange={handleSelfInvoiceChange}
                        handleSelfInvoiceTypeChange={
                          handleSelfInvoiceTypeChange
                        }
                        handleReverseChargeChange={handleReverseChargeChange}
                        handlePaymentMethodTypeChange={
                          handlePaymentMethodTypeChange
                        }
                        handlePaymentMeansChange={handlePaymentMeansChange}
                        invoiceState={state}
                        setInvoiceState={setState}
                      />
                    </div>
                    {/* //////// */}

                    {/*  Seller   */}
                    <div className="col-12 col-md-4 h-100">
                      <DemoParties
                        errors={errors}
                        setErrors={setErrors}
                        visible={visible}
                        setVisible={setVisible}
                        invoiceState={{
                          ...state,
                          parties: { ...state.parties, ...state.seller },
                        }}
                        setInvoiceState={setState}
                      />
                    </div>
                    {/* ////// */}

                    {/* Buyer */}
                    <div className="col-12 col-md-4 border-left h-100">
                      <DemoInvoiceDatas
                        errors={errors}
                        invoiceState={{
                          ...state,
                          parties: { ...state.parties, ...state.seller },
                        }}
                        setInvoiceState={setState}
                        stateDefault={stateDefault}
                        handleInvoiceChange={handleInvoiceChange}
                      />
                    </div>
                    {/* /// */}
                  </div>

                  <DemoTable
                    invoiceState={state}
                    setInvoiceState={setState}
                    errors={errors}
                    setErrors={setErrors}
                    disabled={disabled}
                    lastStateRef={lastStateRef}
                    lastStateHelper={lastStateHelper}
                  />

                  <div className="row mt-1 mx-1">
                    {/* InvoiceNotes */}
                    <div className="col-12 border-top col-md-6">
                      <DemoInvoiceTabsNAT
                        errors={errors}
                        invoiceState={state}
                        setInvoiceState={setState}
                      />
                    </div>
                    {/* /// */}
                    {/* InvoiceTotals */}
                    <div className="col-12 col-md-6 border-top total-list">
                      <DemoInvoiceTotals invoiceState={state} />
                    </div>
                    {/* /// */}
                  </div>
                </Card>
                {sideBarToggle({ right: -2, left: "auto" })}
              </div>
              {sideBarVisible === "true" && <div className="col-12 col-md-2 px-0" style={{ height: "100%", position: "relative" }}>
                <div className="pt-md-1 pt-xl-2">
                  <DemoSideMenu
                    errors={errors}
                    invoiceState={state}
                    setInvoiceState={setState}
                    bankAccounts={pageState.bankAccounts}
                  />
                </div>
                {/* {sideBarToggle()} */}
              </div>}
            </div>
          </div>
        </div>
    </div>
  ) : (
    <Loader />
  );
};
export default CreateInvoice;
